import axios from 'axios';
import config from '../../helpers/api-urls';

const _getDashboardDetails = (userId) => {
    return axios.post(config.getDashboardConfig.requestPayload.url.replace("{userID}", userId), {}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
};

export const getDashboardDetails = (userId) => {
    return {
        type: "FETCH_DASHBOARD",
        payload: _getDashboardDetails(userId),
    };
};