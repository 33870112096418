import { Component } from 'react';
import Header from '../components/global/header';
import releaseData from '../API_JSONs/ReleaseNotes/releases.json';
import upcomingFeatures from '../API_JSONs/ReleaseNotes/upcomingFeatures.json';
import UpcomingFeatures from '../components/releaseNotes/upcomingFeatures';
import Release from '../components/releaseNotes/release';
import '../css/pages/releaseNotes.scss';

class ReleaseNotes extends Component {

    render() {
        const releases = releaseData.releases;
        const {userData} = this.props;
        return (
            <>
                <Header
                    values={userData}
                    showSearch={true}
                    showUser={true}
                    pageName={"releaseNotes"}
                />
                <section className="release-notes">
                    <UpcomingFeatures title={upcomingFeatures.title} features={upcomingFeatures.features}/>
                    {releases?.length > 0 && releases.map(release => 
                        <Release title={release.title} context={release.context} sections={release.sections}/>)}
                </section>
            </>
        );
    }
}

export default ReleaseNotes;
