//FILE_UPLOAD
export const FILE_UPLOAD_PENDING = 'FILE_UPLOAD_PENDING';
export const FILE_UPLOAD_FULFILLED = 'FILE_UPLOAD_FULFILLED';
export const FILE_UPLOAD_REJECTED = 'FILE_UPLOAD_REJECTED';

//FILE_DOWNLOAD
export const FILE_DOWNLOAD_PENDING = 'FILE_DOWNLOAD_PENDING';
export const FILE_DOWNLOAD_FULFILLED = 'FILE_DOWNLOAD_FULFILLED';
export const FILE_DOWNLOAD_REJECTED = 'FILE_DOWNLOAD_REJECTED';

//FILE_DOWNLOAD
export const FILE_DELETE_PENDING = 'FILE_DELETE_PENDING';
export const FILE_DELETE_FULFILLED = 'FILE_DELETE_FULFILLED';
export const FILE_DELETE_REJECTED = 'FILE_DELETE_REJECTED';

//FILE_DOWNLOAD
export const FILE_LIST_PENDING = 'FILE_LIST_PENDING';
export const FILE_LIST_FULFILLED = 'FILE_LIST_FULFILLED';
export const FILE_LIST_REJECTED = 'FILE_LIST_REJECTED';
export const FILE_LIST1_PENDING = 'FILE_LIST1_PENDING';
export const FILE_LIST1_FULFILLED = 'FILE_LIST1_FULFILLED';
export const FILE_LIST1_REJECTED = 'FILE_LIST1_REJECTED';
export const FILE_LIST2_PENDING = 'FILE_LIST2_PENDING';
export const FILE_LIST2_FULFILLED = 'FILE_LIST2_FULFILLED';
export const FILE_LIST2_REJECTED = 'FILE_LIST2_REJECTED';

export const ATTACHMENT_LIST_PENDING = 'ATTACHMENT_LIST_PENDING';
export const ATTACHMENT_LIST_FULFILLED = 'ATTACHMENT_LIST_FULFILLED';
export const ATTACHMENT_LIST_REJECTED = 'ATTACHMENT_LIST_REJECTED';