import { Component } from "react";

class RestMembers extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { member: { participantName, points }, index } = this.props;
        return <li className="ideator" key={index}>
            <p>{index}</p>
            <p><span>{participantName}</span></p>
            <p>{points} points</p>
        </li>
    };
}

export default RestMembers;
