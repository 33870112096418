import axios from "axios";
import config from "../../helpers/api-urls";
import mockTopChallenges from "../../API_JSONs/Dashboard/challenges.json";
import mockChallengeDetails from "../../API_JSONs/Chalenge Details PUT/challengeDetailsPut.json";

const _challenges = () => {
  if (config.challengeDetail.useMocks) {
    return Promise.resolve(mockChallengeDetails);
  } else {
    return axios.get(config.challengeDetail.requestPayload.url, {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchChallengeDetail = () => {
  return {
    type: "FETCH_CHALLENGE_DETAILS",
    payload: _challenges(),
  };
};

const _fetchTopChallenges = (pageIndex, perPageRows, user) => {
  if (config.challengeDetail.useMocks) {
    return Promise.resolve(mockTopChallenges);
  } else {
    return axios.get(config.challengeDetail.requestPayload.url + "/" + pageIndex + "?pageSize=" + perPageRows + "&userID=" + user, {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchTopChallenges = (pageIndex, perPageRows, user) => {
  return {
    type: "FETCH_TOP_CHALLENGES",
    payload: _fetchTopChallenges(pageIndex, perPageRows, user),
  };
};

// const _acceptChallenges = () => {
//   return axios.post(config.acceptChallenge.requestPayload.url);
// };

// export const acceptChallenges = () => {
//   return {
//     type: "ACCEPT_CHALLENGE",
//     payload: _acceptChallenges(),
//   };
// };

// const _challengeList = () => {
//   return axios.post(config.challengeList.requestPayload.url);
// };

// export const challengeList = () => {
//   return {
//     type: "FETCH_CHALLENGE_LIST",
//     payload: _challengeList(),
//   };
// };

const _fetchSavedChallenge = (id, user) => {
  return axios.get(config.challengeDetail.requestPayload.url + "/" + id + "?userId=" + user, {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const fetchSavedChallenge = (id, user) => {
  return {
    type: "FETCH_SAVED_CHALLENGE",
    payload: _fetchSavedChallenge(id, user),
  };
};

const _setSuccessMessage = (data) => {
  return data;
};

export const setSuccessMessage = (data) => {
  return {
    type: "SET_SUCCESS_MESSAGE",
    payload: _setSuccessMessage(data),
  };
};

const _fetchChallengesAllUser = (payload) => {
  return axios.post(config.challengeListAllConfig.url, payload, {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const fetchChallengesAllUser = (payload) => {
  return {
    type: "FETCH_CHALLENGE_USER",
    payload: _fetchChallengesAllUser(payload),
  };
};
