import { Component } from "react";

class EventCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventList: this.props.dataList,
    };
  }

  // componentDidUpdate(prevProps) {
  //   if(prevProps.eventList !== this.props.dataList) {
  //     this.setState({eventList: this.props.dataList})
  //   }
  // }

  render() {
    const {dataList} = this.props
    return (
      <div>
        {dataList.map((event) => (
          <a className="eventName" href={`/events?id=${event.eventId}&name=${event.eventName}`}>
            <div className="eventCard" key={event.eventId}>
              <span>{event.eventName}</span>
              <div
                className={`eventStatus ${
                  event.eventStatus === 'TO_BE_STARTED' ? 'redEvent' :
                    event.eventStatus === 'ONGOING' ? 'orangeEvent' :
                      event.eventStatus === 'COMPLETED' ? 'greenEvent' :
                      'greyEvent'
                }`}
              >
                {
                  event.eventStatus === 'TO_BE_STARTED' ? 'To be started' :
                    event.eventStatus === 'ONGOING' ? 'On going' :
                      event.eventStatus === 'COMPLETED' ? 'Completed' :
                      'Inactive'
                }
              </div>
            </div>
          </a>
        ))}
      </div>
    );
  }
}

export default EventCard;
