import DOMPurify from "dompurify";
import { Component } from "react";

class NoAccessSection extends Component {
  render() {
    const htmlContent = this.props.htmlContent || '';
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }}></div>
      </>
    );
  }
}

export default NoAccessSection;
