import { Component } from "react";

class UpcomingFeatures extends Component {

    render() {
        const {title, features} = this.props;
        return <>
            <h2>{title}</h2>
            <ul >
                {features?.map(feature => <li>{feature}</li>)}
            </ul>
        </>
    }
}

export default UpcomingFeatures;