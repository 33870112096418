import React, { Component } from "react";
import { generateRandomKey } from "../../helpers/utils";
import SubQuestionView from "./subQuestionView";
import TextAreaOnly from "./textAreaOnly";

class QuestionLayerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: this.props.questionDetail || {},
            customClass: this.props.customClass || "",
            showPanelOpinionText: this.props.questionDetail.options[0].selected,
            stepID: this.props.stepID,
            firstReviewer: this.props.firstReviewer,
            commentVal: this.props.questionDetail.options[0].textBoxValue || ''
        };
    }

    render() {
        return (
            <>
                <div className={`question-cont ${this.state.customClass} ${this.state.question.questionType}`}>
                    {this.state.question.questionText !== '' && this.state.question.questionType !== 'conditionalCheckboxOption' && (
                        <div className="question-title">{this.state.question.questionText}</div>
                    )}
                    {this.state.question.questionText !== '' && this.state.question.questionType === 'conditionalCheckboxOption' && this.state.question.options[0].textBoxValue !== '' && (
                        <div className="question-title">{this.state.question.questionText}</div>
                    )}
                    {this.state.question.statementText !== '' && (
                        <div className="question-subtitle" dangerouslySetInnerHTML={{ __html: this.state.question.statementText }}></div>
                    )}
                    {this.state.question.editOverlay === true && this.state.question.questionType === 'radioText' && (
                        <>
                            {this.state.question.options.map((option) => {
                                if (option.selected) {
                                    return <p className="answer-value" key={generateRandomKey()}>{option.text}</p>
                                }
                            })}
                            {this.state.question.options.map((radioItem) => {
                                if (radioItem.selected) {
                                    return (
                                        <>
                                            {radioItem.showTextBox && radioItem.textBoxValue !== '' && (
                                                <p className="answer-value">{radioItem.textBoxValue}</p>
                                            )}
                                            {radioItem.subQuestions !== null && radioItem.subQuestions.length > 0 && radioItem.subQuestions.map((subQ) => {
                                                return (
                                                    <>
                                                        {subQ.questionType !== 'note' && (
                                                            <SubQuestionView
                                                                stepID={this.state.stepID}
                                                                subQuestion={subQ}
                                                                key={generateRandomKey()} />
                                                        )}
                                                        {subQ.questionType === 'note' && (
                                                            <em className="other-note-text" key={generateRandomKey()}>{subQ.statementText}</em>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </>
                                    );
                                }
                            })}
                        </>
                    )}
                    {this.state.question.questionType === 'rating' && (
                        <div className="IdeaRadioButtons">
                            {this.state.question.options.length > 0 && this.state.question.options.map((ratingVal) => {
                                if (ratingVal.selected) {
                                    return <p className="answer-value" key={generateRandomKey()}>{ratingVal.text}</p>
                                }
                            })}
                        </div>
                    )}
                    {this.state.question.questionType === 'ratingCustomHelpText' && (
                        <>
                            <div className="IdeaRadioButtons">
                                {this.state.question.options.length > 0 && this.state.question.options.map((ratingVal) => {
                                    if (ratingVal.selected) {
                                        return <p className="answer-value" key={generateRandomKey()}>{ratingVal.text}</p>
                                    }
                                })}
                            </div>
                        </>
                    )}
                    {this.state.question.questionType === 'radioTextDescription' && (
                        <div className="RatingWholeScetion">
                            {this.state.question.options.length > 0 && this.state.question.options.map((radioVal) => {
                                if (radioVal.selected) {
                                    return <>
                                        <p className="answer-value" key={generateRandomKey()}>{radioVal.text}</p>
                                        {radioVal.showTextBox && radioVal.textBoxValue !== '' && (
                                            <p className="answer-value">{radioVal.textBoxValue}</p>
                                        )}
                                    </>
                                }
                            })}
                        </div>
                    )}
                    {this.state.question.questionType === 'textOnly' && this.state.question.options[0].textBoxValue !== '' && (
                        <p className="answer-value">{this.state.question.options[0].textBoxValue}</p>
                    )}
                    {this.state.question.questionType === 'conditionalCheckboxOption' && this.state.firstReviewer && (
                        <>
                            {this.state.showPanelOpinionText && this.state.question.options[0].textBoxValue !== '' && (
                                <>
                                    <p className="answer-value">{this.state.question.options[0].textBoxValue}</p>
                                </>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default QuestionLayerView;