import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userProfile from './user-profile';
import ideas from './ideas';
import dashboard from './dashboard';
import challenges from './challenges';
import utilities from './utilities';
import gamify from './gamify';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    userProfile: userProfile,
    ideas: ideas,
    dashboard: dashboard,
    challenges: challenges,
    utilities: utilities,
    gamify
});

export default createRootReducer;
