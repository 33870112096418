import React, { Component } from "react";

class challengeEnableOptions extends Component {
    constructor(props) {
    super(props);
    }

    render() {
        const generateRows = () => {
            const htmlData = this.props.optionalFields.map((el) => {
                return(
                    <tr>
                        <td>{el.field}</td>
                        <td>{el.type}</td>
                        <td><input type='checkbox' checked={el.checked} disabled={el.disabled} onChange={this.props.handleOptionalFields} name={el.field}/></td>
                    </tr>
                )
            })
            return htmlData
        }

        return(
            <div className="challengeEnableContainer">
                <p className='optionDisclaimer'>Please uncheck the fields that are not relevant for your challenge. By default, all fields are enabled.</p>
                <p className='optionDisclaimer'>Note: The Enabled fields will be marked as a Required field for the Idea Submitter.</p>
                <div className='optionTableContainer'>
                    <table>
                        <tr>
                            <th>Field Name</th>
                            <th>Type</th>
                            <th>Enabled?</th>
                        </tr>
                        {generateRows()}
                    </table>
                </div>
            </div>
        )
    }
}

export default challengeEnableOptions