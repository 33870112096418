import React, { Component } from "react";
import noDraftHero from '../../img/home/noDraftHero.svg';
import draftHero from '../../img/home/draftHero.svg';
import publishedIdea from '../../img/publishedIdea.svg';
import seedIcon from '../../img/seedIcon.svg';
import editPencil from '../../img/editPencil.svg';
import draftIdea from '../../img/draftIdea.svg';

class homeHero extends Component {
    render() {
        const hero = this.props.dashboardDetails;
        return <>
            <section className="homeHero">
                <article className="heroText">
                    {(hero.draftCount == undefined || hero.draftCount === 0) ?
                        <div className="noDraftHeroLeft">
                            <div className="heroHeaderSection">
                                <div className="heroHeader">
                                    All ideas start with a SEED of thought
                                </div>
                                <div className="heroSubHeader1">
                                    Flex your brain and earn seeds!
                                </div>
                                <div className="heroSubHeader2">
                                    Share your most innovative ideas and get &nbsp;<img src={seedIcon} alt="" className="heroGem" />&nbsp; 20.
                                </div>
                            </div>
                            <div className="draftHeroImage">
                                <img src={hero.draftCount === 0 && hero.publishedIdeasCount === 0 ? noDraftHero : draftHero} alt="" />
                            </div>
                            {/* <div className="heroCTASection">
                                <div className="ctaText">
                                    Got a concrete idea? Tell us what it's like
                                </div>
                                <div className="heroCTA">
                                    <a href="/createidea" className="btn primary" role="button">Submit an Idea</a>
                                </div>
                            </div> */}
                        </div>
                        :
                        <div className="draftHeroLeft">
                            <div className="heroHeaderSection">
                                <div className="heroHeader">
                                    Continue from where you left
                                </div>
                                <div className="draftNameSection">
                                    {/* <div className="draftName">{hero.recentDraftName}</div> */}
                                    <div className="draftName" title={hero.recentDraftName ? hero.recentDraftName : ''}>{hero.recentDraftName && hero.recentDraftName.length > 100 ? `${hero.recentDraftName.substring(0, 99)} ...` : hero.recentDraftName}</div>
                                    <div className="editDraft">
                                        <a href={`/createidea?draft=${hero.draftId}`}>
                                            <img src={editPencil} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="draftFlag">
                                    DRAFT
                                </div>
                            </div>
                            <hr />
                            <div className="draftHeroImage">
                                <img src={hero.draftCount === 0 && hero.publishedIdeasCount === 0 ? noDraftHero : draftHero} alt="" />
                            </div>
                           {/*  <div className="heroCTASection">
                                <div className="ctaText">
                                    Got a concrete idea? Tell us what it's like
                                </div>
                                <div className="heroCTA">
                                    <a href="/createidea" className="btn primary" role="button">Submit an idea</a>
                                </div>
                            </div> */}
                        </div>
                    }
                </article>
                <article className="heroImageSection">
                    {/* Right section */}
                    {(hero.draftCount === 0 || hero.draftCount == null) && (hero.publishedIdeasCount == null || hero.publishedIdeasCount === 0) ?
                        <div className="noDraftHero">
                            <img src={noDraftHero} alt="" />
                        </div>
                        :
                        <div className="draftHero">
                            <div className="draftHeroImage">
                                <img src={draftHero} alt="" />
                            </div>
                            <div className="draftHeroCount">
                                <a href="/draftidea" className="buttonAnchor">
                                    <div className="heroCounter">
                                        <div className="heroCounterImage">
                                            <img src={draftIdea} alt="" />
                                        </div>
                                        <div className="heroCounterData">
                                            <div className="count">
                                                {hero.draftCount ? hero.draftCount : 0}
                                            </div>
                                            <div>
                                                Draft Ideas
                                            </div>
                                        </div>

                                    </div>
                                </a>
                                <a href="/submittedidea" className="buttonAnchor">
                                    <div className="heroCounter">
                                        <div className="heroCounterImage">
                                            <img src={publishedIdea} alt="" />
                                        </div>
                                        <div className="heroCounterData">
                                            <div className="count">
                                                {hero.publishedIdeasCount ? hero.publishedIdeasCount : 0}
                                            </div>
                                            <div>
                                                Published Ideas
                                            </div>
                                        </div>

                                    </div>
                                </a>
                            </div>
                        </div>
                    }
                </article>
            </section>
        </>
    }
}

export default homeHero;