import { combineReducers } from "redux";
import { DEFAULT, FAILED, FULFILLED, PENDING } from "../../helpers/constants";
import {
    GET_TOP_IDEATORS_PENDING,
    GET_TOP_IDEATORS_FULFILLED,
    GET_TOP_IDEATORS_REJECTED,
    GET_MOST_LIKED_IDEAS_PENDING,
    GET_MOST_LIKED_IDEAS_FULFILLED,
    GET_MOST_LIKED_IDEAS_REJECTED,
    GET_MOST_INVESTED_IDEAS_PENDING,
    GET_MOST_INVESTED_IDEAS_FULFILLED,
    GET_MOST_INVESTED_IDEAS_REJECTED
} from "../action-types/gamify";

const initialMetaState = {
    GET_TOP_IDEATORS: DEFAULT,
    GET_MOST_LIKED_IDEAS: DEFAULT,
    GET_MOST_INVESTED_IDEAS: DEFAULT
};

const initialDataState = {
    topTeamIdeators: [],
    mostLikedIdeas: [],
    mostInvestedIdeas: [],
};

const metaReducer = (state = initialMetaState, action) => {
    switch (action.type) {
        case GET_TOP_IDEATORS_PENDING:
            return { ...state, GET_TOP_IDEATORS: PENDING };
        case GET_TOP_IDEATORS_FULFILLED:
            return { ...state, GET_TOP_IDEATORS: FULFILLED };
        case GET_TOP_IDEATORS_REJECTED:
            return { ...state, GET_TOP_IDEATORS: FAILED };
        case GET_MOST_LIKED_IDEAS_PENDING:
            return { ...state, GET_MOST_LIKED_IDEAS: PENDING };
        case GET_MOST_LIKED_IDEAS_FULFILLED:
            return { ...state, GET_MOST_LIKED_IDEAS: FULFILLED };
        case GET_MOST_LIKED_IDEAS_REJECTED:
            return { ...state, GET_MOST_LIKED_IDEAS: FAILED };
        case GET_MOST_INVESTED_IDEAS_PENDING:
            return { ...state, GET_MOST_INVESTED_IDEAS: PENDING };
        case GET_MOST_INVESTED_IDEAS_FULFILLED:
            return { ...state, GET_MOST_INVESTED_IDEAS: FULFILLED };
        case GET_MOST_INVESTED_IDEAS_REJECTED:
            return { ...state, GET_MOST_INVESTED_IDEAS: FAILED };
        default:
            return state;
    }
};

const dataReducer = (state = initialDataState, action) => {
    const { payload = {} } = action;
    switch (action.type) {
        case GET_TOP_IDEATORS_FULFILLED:
            return { ...state, topTeamIdeators: payload.data.topTeamIdeators };
        case GET_TOP_IDEATORS_REJECTED:
            return { ...state, topTeamIdeators: [] };

        case GET_MOST_LIKED_IDEAS_FULFILLED:
            return { ...state, mostLikedIdeas: payload.data };
        case GET_MOST_LIKED_IDEAS_REJECTED:
            return { ...state, mostLikedIdeas: [] };

        case GET_MOST_INVESTED_IDEAS_FULFILLED:
            return { ...state, mostInvestedIdeas: payload.data };
        case GET_MOST_INVESTED_IDEAS_REJECTED:
            return { ...state, mostInvestedIdeas: [] };
        default:
            return state;
    }
};

export default combineReducers({
    meta: metaReducer,
    data: dataReducer
});