import React, { Component } from "react";
import { connect } from "react-redux";
import GlobalSearchAutoCompleteList from "./globalSearchAutoCompleteList";
import AutoSuggest from "../global/autoSuggest";
import { generateRandomKey } from "../../helpers/utils";
import { getAutocompleteResults } from "../../redux/actions/ideas";

class GlobalSearchAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      searchTerm: "",
      minCharactersRequired: 2,
      globalResults: this.props.autocompleteResults ? this.props.autocompleteResults.ideasList : []
    };
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("term")) {
      const term = urlParams.get("term");
      this.setState({ searchTerm: term });
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.autocompleteResults !== this.props.autocompleteResults) {
      this.setState({
        loading: false,
        globalResults: this.props.autocompleteResults.ideasList,
      });
    }
  };
  onEnterClicked(event) {
    event && event.stopPropagation();
    if (this.state.searchTerm.length > 0) {
      window.location.href = "/search-results?term=" + this.state.searchTerm;
    }
  };
  onTextChange = (searchText) => {
    this.setState({ searchTerm: searchText });
    const textLength = searchText.length;
    if (textLength > this.state.minCharactersRequired) {
      this.setState({ loading: true });
      const requestPayload = {
        "advancedSearchFilters": {
          "categoryIds": [],
          "tagIds": [],
          "talentGroupIds": []
        },
        "currentPaginationLevel": 0,
        "pageSize": 10,
        "searchTerm": searchText
      }
      this.props.getAutocompleteResults(requestPayload);
    } else {
      this.setState({ loading: false });
    }
  };

  handleCloseSearch = () => {
    this.props.closeSearch();
  };

  render() {
    const { userData } = this.props;
    return (
      <div className="global-auto-search-container">
        <AutoSuggest
          placeHolderText="What are you looking for?"
          id="auto-suggest"
          inputVal={this.state.searchTerm}
          minCharactersRequired={this.state.minCharactersRequired}
          textChange={this.onTextChange}
          onEnterClicked={this.onEnterClicked.bind(this)}
          closeSearch={this.handleCloseSearch.bind(this)}
        >
          {this.state.loading && (
            <div className="search-loading-text">Loading...</div>
          )}
          {!this.state.loading && (
            <ul
              role="menu"
              className={
                this.state.searchTerm.length > this.state.minCharactersRequired
                  ? "dropdown-menu show"
                  : "dropdown-menu d-none"
              }
              aria-labelledby={"auto-suggest"}
            >
              {this.state.globalResults && this.state.globalResults.length === 0 && (
                <div className="global-search-results-container">
                  <li className="global-search-results-no_result">
                    <p className="">No Results Found!</p>
                  </li>
                </div>
              )}
              {this.state.globalResults && this.state.globalResults.length >= 1 &&
                this.state.searchTerm.length >
                this.state.minCharactersRequired && (
                  <div className="global-search-results-container">
                    {this.state.globalResults.slice(0, 10).map((item) => (
                      <GlobalSearchAutoCompleteList
                        key={generateRandomKey()}
                        list={item}
                        term={this.state.searchTerm}
                        userData={userData}
                      />
                    ))}
                    {
                      <li className="global-search-results-view_more">
                        <a
                          href={
                            "/search-results?term=" + this.state.searchTerm
                          }
                        >
                          <p className="">View All</p>
                        </a>
                      </li>
                    }
                  </div>
                )}
            </ul>
          )}
        </AutoSuggest>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    FETCH_AUTOCOMPLETE_RESULTS_STATUS:
      state.ideas.meta.FETCH_AUTOCOMPLETE_RESULTS_STATUS,
    autocompleteResults: state.ideas.data.autocompleteResults,
  };
};

const mapDispatchToProps = {
  getAutocompleteResults,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSearchAutoComplete);
