import { Component } from "react";
import { withHookHOC } from "../../hooks/withHookHOC";

class ContextMenu extends Component {
    constructor(props) {
        super(props);
        this.customContextHandle = this.customContextHandle.bind(this);
        this.openInNewTab = this.openInNewTab.bind(this);
        this.showContextMenu = this.showContextMenu.bind(this);
    }

    openInNewTab() {
        const { contextLink } = { ...this.props }
        let a = document.createElement('a');
        this.props.setClicked(false);
        a.target = '_blank';
        a.href = contextLink;
        a.click();
    }

    showContextMenu() {
        const parent = document.querySelector(`div.${this.props.className}`);
        const element = parent.querySelector('.context-menu');
        if (element) {
            element.style.display = 'block';
        }
    }

    customContextHandle(e) {
        e.preventDefault();
        if (e.target.className == this.props.className) { return; }
        this.props.setClicked(true);
        this.props.setPoints({
            x: e.pageX,
            y: e.pageY - 100,
        });
        this.showContextMenu();
    }



    render() {
        const { clicked, points, className } = { ...this.props };
        return <div className={className}
            onClick={this.props.onClick}
            onContextMenu={this.customContextHandle}>
            {clicked &&
                <ul className="context-menu" style={{ top: points.y, left: points.x }}>
                    <li className="menu-item" onClick={this.openInNewTab}>
                        Open in new tab
                    </li>
                </ul>}
            {this.props.children}
        </div>;
    }
}

const ContextMenuWithHook = withHookHOC(ContextMenu);

export default ContextMenuWithHook;