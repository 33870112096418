import React, { Component } from "react";
import "../css/pages/makeItRain.scss";
import Header from "../components/global/header";
import Bulbicon from "../img/ideation/bulb.svg";
import make from "../img/seedIcon.svg";
import makeItRain from "../img/ideation/makeItRain.svg";
import challengeNew from "../img/ideation/idea1.svg";
import seedInsight from '../img/seedInsight.svg';
import Breadcrumbs from "../components/global/breadcrumbs";

class MakeItRain extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const userData = this.props.userData;
        const breadcrumbJSON = [
            {
                url: "/dashboard",
                text: "Home",
            },
            {
                url: "/user-profile",
                text: "Profile",
            },
            {
                url: "",
                text: "SEED Insights",
            },
        ];
        return (
            <>
                <Header
                    values={userData}
                    showSearch={true}
                    showUser={true}
                    pageName={"seedInsights"}
                />
                <Breadcrumbs breadcrumbs={breadcrumbJSON} />
                <section className="makeitRain">
                    <div className="makeRainMain">
                        <div className="makeRainwhole">
                            <div className="makeitRainHeader">It's Raining Seeds</div>
                            <div className="makeRainGift">
                                To get you started on your SEED Innovation journey, here is a gift from us to begin with. Happy investing and happy innovating!
                            </div>
                            {/* <div className="makeRainLink">
                                <a>Don't show this again</a>
                            </div> */}
                        </div>
                        <div className="makeRainRft">
                            <div className="makeItRainLeft">
                                <img src={makeItRain} alt="makeitrain" />
                                {/* <div><img src={make} className="seedIcon1"></img></div>
                                <div className="seedCoins"> 500</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="makeRainMiddle">
                        <div className="makerainImg">
                            <img src={challengeNew} className="makeImg"></img>
                        </div>
                        <div className="makerainMiddlergt">
                            <div className="makeRainHeading">Let us take you through</div>
                            <div className="makeRainHeading sub">How it Works!</div>
                            <div className="makeRainDesc">
                            We understand you are eager to know how our SEED gamification works and helps you, and the practice gets more innovative.  
                            We will make it easier for you - here is How it Works!
                            </div>
                        </div>
                    </div>

                    <div className="seedTitle">SEED</div>
                    <div className="makeitRainSection">
                        <div className="makeLeft leftSec">
                            <div className="leftSection">
                                <div className="makeRainsub">
                                    <img src={Bulbicon} className="bulbIcon"></img>
                                </div>
                                <div className="make">
                                    <div className="makeRainNote">Remember</div>
                                    <span className="seedDesc">
                                        Seeds can be used only to invest in ideas and can be increased by investing in ideas
                                    </span>
                                </div>
                            </div>
                            <div className="makeBottom">
                                <div className="makeRainsubHeader">
                                    How to invest and Grow ?
                                </div>
                                <div className="makerainTopic minimumCoins">
                                    One should invest a minimum of 100<img src={make} className="seedIcon"/> on any idea and can invest maximum of 300<img src={make} className="seedIcon"/> in an idea at any time 
                                </div>
                                <div className="makerainTopic secondTopic1 ">
                                    There will be a lock-in period of 30 Days for investment on any idea.
                                </div>
                                <div className="makerainTopic ideaChange">
                                    The investment can be made in the multiple of 10<img src={make} className="seedIcon"/>. eg. Starting from 100 and then 110, 120...
                                </div>
                            </div>

                            <div className="makerainbottom">
                                <div className="makerainDescription ideaSubmit">
                                    <div className="ideaValue">
                                        <span className="value">
                                            The investment can be done with the 500 investment seeds across multiple ideas (300 per idea limit)
                                        </span>
                                    </div>
                                </div>
                                <div className="makerainDescription ideaApproved">
                                    The investor can invest only on maximum of 4 ideas
                                </div>
                                <div className="makerainDescription  ideaIncrease">
                                    When idea moves from one stage to another, the investor gets 20% on the invested value on each upward level jump and the investment earned gets credited to the <span className="span-underline">Reward Seeds wallet</span>
                                </div>
                                <div className="makerainDescription  ideaIncrease1">
                                    <img src={seedInsight} alt="seed"/>
                                </div>
                            </div>
                            <div className="makerainveryBottom">
                                If the investor decides to withdraw the seeds from an idea, then all the seeds that has been invested should go back to the <span className="span-underline">Investment Seeds wallet</span>
                            </div>
                            <div className="makerainveryBottom">
                                Based on factors such as number of ideas in different stages, number of challenges getting hosted, practice participation and firm guidance - seeds would be allocated at periodic intervals to the investment wallet to continue investing and helping make the idea successful.
                            </div>
                        </div>
                        <div className="makeMiddle middleSec">
                            <div className="makeLeft ">
                                <div className="leftSection subSection">
                                    <div className="makeRainsub">
                                        <img src={Bulbicon} className="bulbIcon"></img>
                                    </div>
                                    <div className="make">
                                        <div className="makeRainNote">Remember</div>
                                        <span className="seedDesc">
                                            Profits made from investment can be redeemed
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="makeBottom">
                                <div className="makeRainsubHeader">How do you earn?</div>
                                <div className="makerainTopic2">
                                On creation of a new idea
                                </div>
                                <div className="makerainTopic2">
                                Idea approved and moved to next upward stage 
                                </div>
                                <div className="makerainTopic2">
                                Participating in Challenges -
                                <br/>
                                Accepting a challenge & submitting ideas to solve it!
                                </div>
                            </div>
                        </div>

                        <div className="makeRight">
                            <div className="makeBottom">
                                <div className="makeRainsubHeader">Coming Soon!</div>
                                <div className="makerainTopic2">
                                Interaction with an Idea
                                </div>
                                <div className="makerainTopic2">
                                Hosting an event
                                </div>
                                <div className="makerainTopic2">
                                Publish Blogs
                                </div>
                                <div className="makerainTopic2">
                                Referrals to invite more people to SEED Portal
                                </div>
                                <div className="makerainTopic2">
                                Participation in forums
                                </div>
                                <div className="makerainTopic2">
                                Top innovators of the month
                                </div>
                                <div className="leftSection section Whole">
                                    <div className="makeRainsub">
                                        <img src={Bulbicon} className="bulbIcon"></img>
                                    </div>
                                    <div className="make">
                                        <div className="makeRainNote">Who are the innovators?</div>
                                        <span className="seedDesc bottom">
                                            Innovators are the seeders who generate the most successful
                                            ideas that have received most appreciations from fellow
                                            seeders and the idea approvers board
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="Badges">Badges</div>
                            <div className="makeWholeRight">
                                <div className="makeLeft2">
                                    <div className="leftSection section">
                                        <div className="makeRainsub">
                                            <img src={Bulbicon} className="bulbIcon"></img>
                                        </div>
                                        <div className="make">
                                            <div className="makeRainNote">Remember</div>
                                            <span className="seedDesc">
                                                Badges depend on how much successful ideas are generated
                                                and how active the seeder is on the portal
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="makeRainsubHeader rightSec">
                                    Types of Badges
                                </div>
                                <div className="makeBadges">
                                    <div className="badgesTypes">Shark of the month</div>
                                    <div className="badgesTypes">Innovator of the month</div>
                                    <div className="badgesTypes">Top seeder of the month</div>
                                    <div className="badgesTypes">Idea champ</div>
                                    <div className="badgesTypes">Budding</div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

            </>
        );
    }
}

export default MakeItRain;