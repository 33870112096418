import React, { Component } from "react";
import RadioText from "./radioText";
import { generateRandomKey } from "../../helpers/utils";
import SubQuestion from "./subQuestion";
import Rating from "./rating";
import RadioTextDescription from "./radioTextDescription";
import InpTextBox from "./inpTextBox";
import TextAreaOnly from "./textAreaOnly";
import config from "../../helpers/api-urls";
import axios from "axios";
import DOMPurify from "dompurify";

class QuestionLayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: this.props.questionDetail || {},
            customClass: this.props.customClass || "",
            showPanelOpinionText: this.props.questionDetail.options[0].selected,
            stepID: this.props.stepID,
            firstReviewer: this.props.firstReviewer,
            commentVal: this.props.questionDetail.options[0].textBoxValue || '',
            userData: this.props.userData
        };
    }

    /**
     * handles the checkbox change
     * @param {Event} e event
     */
    handleChange = (e) => {
        e && e.stopPropagation();
        let renderJSONTemp = this.state.question;
        renderJSONTemp.options[0].selected = e.target.checked;
        this.setState({ question: renderJSONTemp, showPanelOpinionText: e.target.checked });
        this.props.isEmailRequired(e.target.checked);
    };

    /**
     * handles the area change on click
     * @param {Number} selectedValue selected area type
     * @param {string} value selected area text
     */
    handleAreaChange = (selectedValue, value) => {
        let renderJSONTemp = this.state.question;
        renderJSONTemp.options.forEach((option) => {
            option.selected = false;
            if (option.text === value) {
                option.selected = true;
            }
        });
        this.setState({ question: renderJSONTemp });
    };

    /**
    * handles the rating type change on click
    * @param {Number} selectedValue selected option id
    * @param {String} value selected option text
    * @param {number} score selected score
    * @param {number} questionId selected question id
    */
    onRatingValueChange = (selectedValue, value, score, questionId) => {
        let renderJSONTemp = this.state.question;
        renderJSONTemp.options.forEach((option) => {
            option.selected = false;
            if (option.text === value) {
                option.selected = true;
                this.props.selectedAnswerDetails(renderJSONTemp.questionId, option.id, score, this.state.stepID);
            }
        });
        this.setState({ question: renderJSONTemp });
        this.props.onScoreChange(score, questionId);
        this.props.updateQuestionArr([{ stepID: this.state.stepID, question: questionId }]);
    };

    /**
     * reset score, pass to parent
     */
    resetScore = () => {
        this.props.handleScoreReset();
    };

    /**
     * handles the radio type change on click
     * @param {Number} selectedValue selected option id
     * @param {String} value selected option text
     */
    onRadioValueChange = (selectedValue, value, score, questionId, stepID) => {
        let renderJSONTemp = this.state.question;
        renderJSONTemp.options.forEach((option) => {
            option.selected = false;
            if (option.text === value) {
                option.selected = true;
                this.props.overallSummaryDetails(renderJSONTemp.questionId, option.id, this.state.stepID);
            }
        });
        this.setState({ question: renderJSONTemp });
        this.props.updateQuestionArr([{ stepID: this.state.stepID, question: this.state.question.questionId }]);
    };

    /**
     * handles score change, passing to parent
     * @param {number} score individual selected score
     * @param {Number} questionId question id
     */
    onScoreChange = (score, questionId) => {
        this.props.onScoreChange(score, questionId);
    };

    /**
     * handles the area radio change and pass to parent
     * @param {boolean} flag flag for other area selection
     * @param {Number} questionCount question count for ech selection
     */
    handleOtherArea = (flag, questionCount) => {
        this.props.handleOtherAreaApproval(flag, questionCount);
    }

    /**
     * pass the overall summary to parent
     */
    overallSummaryDetails = () => {
        this.state.question.options.forEach((option) => {
            if (option.selected) {
                if (parseInt(this.state.stepID) === 5) {
                    this.props.overallSummaryDetails(this.state.question.questionId, option.id, this.state.stepID);
                }
            }
        });
    }

    /**
     * handles question change and pass to parent
     * @param {array} arr score and question array
     */
    updateQuestionArr = (arr) => {
        this.props.updateQuestionArr(arr);
    };

    /**
     * handles closing comments textarea
     * @param {string} val value of the entered comment
     */
    onCommentChange = (val) => {
        this.setState({ commentVal: val });
        let renderJSONTemp = this.state.question;
        renderJSONTemp.options[0].textBoxValue = val;
        this.props.overallSummaryDetails(renderJSONTemp.questionId, this.state.question.options[0].id, this.state.stepID, val);
        this.setState({ question: renderJSONTemp });
        this.props.updateQuestionArr([{ stepID: this.state.stepID, question: this.state.question.questionId }]);
    };

    /**
     * handles closing comments textarea
     * @param {Event} e value of the entered comment
     */
    onInputChange = (e) => {
        const val = DOMPurify.sanitize(e.target.value);
        this.setState({ commentVal: val });
        let renderJSONTemp = this.state.question;
        renderJSONTemp.options[0].textBoxValue = val;
        this.setState({ question: renderJSONTemp });
    };

    onInputBlur = () => {
        this.enableSignInBtn(this.state.commentVal);
    }

    enableSignInBtn = (value) => {
        if (value === '' || value === this.state.userData.email) {
            this.props.handlePanelEmailUpdate(this.state.question.questionId, this.state.question.options[0].id, this.state.commentVal, this.state.stepID, false);
        } else {
            const payload = {
                "memberName": "",
                "memberRole": "",
                "panelist": true,
                "userEmail": value
            };
            const url = config.validateEmailConfig.requestPayload.url;
            axios.post(url, payload, {
                headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
            })
                .then((response) => {
                    this.props.handlePanelEmailUpdate(this.state.question.questionId, this.state.question.options[0].id, this.state.commentVal, this.state.stepID, response.data);
                }).catch((error) => {
                    console.log(error);
                });
        }
    };

    /**
     * handles textarea comment blur
     */
    onClosingCommentBlur = () => {
        this.props.updateQuestionArr([{ stepID: this.state.stepID, question: this.state.question.questionId }]);
        this.props.overallSummaryDetails(this.state.question.questionId, this.state.question.options[0].id, this.state.stepID, this.state.commentVal);
    };

    /**
     * selected question answer details and pass to parent
     * @param {Number} questionId
     * @param {Number} selectedId
     * @param {string} customText
     * @param {Number} score
     */
    selectedAnswerDetails = (questionId, selectedId, customText, score, subCustomText) => {
        let parentSelectedId = 0;
        this.state.question.options.forEach((option) => {
            if (option.selected) {
                parentSelectedId = option.id;
            }
        });
        this.props.saveSubQDetails(questionId, selectedId, customText, score, this.state.question.questionId, parentSelectedId, this.state.stepID, subCustomText);
    }

    /**
     * handles the input change and pass to parent
     * @param {String} value
     * @param {Number} stepId
     */
    handleInputUpdate = (value, stepId) => {
        this.props.handleInputUpdate(value, stepId, this.state.question.questionId);
    };

    updateOtherComment = (id, stepId, subCustomText) => {
        this.props.updateOtherComment(this.state.question.questionId, id, stepId, subCustomText);
    }

    render() {
        const {questionId} = this.props.questionDetail;
        return (
            <>
                <div className={`question-cont ${this.state.customClass} ${this.state.question.questionType}`}>
                    {this.state.question.questionText !== '' && (
                        <div className="question-title">{this.state.question.questionText}</div>
                    )}
                    {this.state.question.statementText !== '' && (
                        <div className="question-subtitle" dangerouslySetInnerHTML={{ __html: this.state.question.statementText }}></div>
                    )}
                    {this.state.question.editOverlay === true && this.state.question.questionType === 'radioText' && (
                        <>
                            <RadioText
                                updateQuestionArr={this.updateQuestionArr.bind(this)}
                                question={this.state.question}
                                resetScore={this.resetScore}
                                onAreaChange={this.handleAreaChange}
                                handleOtherArea={this.handleOtherArea.bind(this)}
                                stepID={this.state.stepID}
                                overallSummaryDetails={this.overallSummaryDetails.bind(this)}
                            />
                            {this.state.question.options.map((radioItem) => {
                                if (radioItem.selected) {
                                    return (
                                        <>
                                            {radioItem.showTextBox && (
                                                <InpTextBox key={generateRandomKey()}
                                                    stepID={this.state.stepID}
                                                    radioItem={radioItem}
                                                    updateQuestionArr={this.updateQuestionArr.bind(this)}
                                                    handleInputUpdate={this.handleInputUpdate.bind(this)}
                                                />
                                            )}
                                            {radioItem.subQuestions !== null && radioItem.subQuestions.length > 0 && radioItem.subQuestions.map((subQ) => {
                                                return (
                                                    <>
                                                        {subQ.questionType !== 'note' && (
                                                            <SubQuestion
                                                                updateQuestionArr={this.updateQuestionArr.bind(this)}
                                                                stepID={this.state.stepID}
                                                                subQuestion={subQ}
                                                                key={generateRandomKey()}
                                                                onScoreChange={this.onScoreChange.bind(this)}
                                                                selectedAnswerDetails={this.selectedAnswerDetails.bind(this)}
                                                            />
                                                        )}
                                                        {subQ.questionType === 'note' && (
                                                            <em className="other-note-text" key={generateRandomKey()}>{subQ.statementText}</em>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </>
                                    );
                                }
                            })}
                        </>
                    )}
                    {this.state.question.questionType === 'rating' && (
                        <>
                            <div className={`top ${this.state.customClass}`}>
                                {`< ${this.state.question.helpTextLow}`}<span></span>{`${this.state.question.helpTextHigh} >`}
                            </div>
                            <div className="IdeaRadioButtons">
                                {this.state.question.options.length > 0 && this.state.question.options.map((ratingVal) => {
                                    return (
                                        <Rating
                                            key={generateRandomKey()}
                                            questionId={this.state.question.questionId}
                                            radioClass={"radio-rating"}
                                            ratingVal={ratingVal}
                                            radioName={this.state.question.questionText}
                                            onRatingValueChange={this.onRatingValueChange.bind(this)}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {this.state.question.questionType === 'ratingCustomHelpText' && (
                        <>
                            <div className="IdeaRadioButtons">
                                {this.state.question.options.length > 0 && this.state.question.options.map((ratingVal) => {
                                    return (
                                        <Rating
                                            key={generateRandomKey()}
                                            questionId={this.state.question.questionId}
                                            radioClass={"radio-invest-rating"}
                                            ratingVal={ratingVal}
                                            radioName={"invest-rating"}
                                            onRatingValueChange={this.onRatingValueChange.bind(this)}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {this.state.question.questionType === 'radioTextDescription' && (
                        <div className="RatingWholeScetion">
                            {this.state.question.options.length > 0 && this.state.question.options.map((radioVal) => {
                                return (
                                    <RadioTextDescription
                                        stepID={this.state.stepID}
                                        key={generateRandomKey()}
                                        radioValues={radioVal}
                                        onRadioValueChange={this.onRadioValueChange}
                                        updateOtherComment={this.updateOtherComment.bind(this)}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {this.state.question.questionType === 'textOnly' && (
                        <TextAreaOnly
                            key={questionId}
                            handleChange={this.onCommentChange.bind(this)}
                            placeHolder={this.state.question.placeHolder}
                            id={this.state.question.questionId}
                            inputVal={this.state.commentVal}
                            handleBlur={this.onClosingCommentBlur.bind(this)}
                        />
                    )}
                    {this.state.question.questionType === 'conditionalCheckboxOption' && this.state.firstReviewer && (
                        <>
                            <div className="form-group">
                                <input checked={this.state.question.options[0].selected} type="checkbox" id="check1" onChange={(e) => this.handleChange(e)} />
                                <label htmlFor="check1">{this.state.question.questionText}</label>
                            </div>
                            {this.state.showPanelOpinionText && (
                                <>
                                    <input
                                        className={`panel-email-input`}
                                        onChange={(e) => this.onInputChange(e)}
                                        value={this.state.commentVal}
                                        type="email"
                                        id="panelEmail"
                                        name="panelEmail"
                                        required={true}
                                        autoComplete="off"
                                        maxLength="100"
                                        tabIndex="0"
                                        placeholder="Add a recommended panelist email id"
                                        onBlur={(e) => this.onInputBlur(e)}
                                    />
                                    {this.props.showError && (
                                        <label className="validate-email-label">Please enter a valid Panelist's email address</label>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default QuestionLayer;