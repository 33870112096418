import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/global/header";
import "../css/pages/leaderReview.scss";
import { generateRandomKey, hideLoading, showLoader, checkForRecommendedStatus } from "../helpers/utils";
import { fetchIdeaDetails, getReviewDetails } from "../redux/actions/ideas";
import config from "../helpers/api-urls";
import axios from "axios";
import ideaImage from "../img/ideation/idea1.png";
import Modal from "react-bootstrap/Modal";
import { _CONSTANTS } from "../helpers/constants";
import { MdAdd } from "react-icons/md";
import PanelLeadEvaluateIdea from '../components/ideaapproval/PanelLeadEvaluateIdea';
import CompletedPanelReview from "../components/ideaapproval/completedPanelReview";
import DOMPurify from "dompurify";

class LeaderReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressArr: [],
            showPanelRecommendation: false,
            selectedReason: '',
            showOtherComment: false,
            otherComment: '',
            otherCommentCount: 0,
            maxCharCount: 255,
            sendBackComment: '',
            sendBackCommentCount: 0,
            disableSendBackBtn: true,
            enableApproveBtn: true,
            disableAssignPanel: true,
            showErrorMessage: false,
            ideaId: 0,
            panelEmailVal: '',
            showPanel2AssignSuccess: false,
            showApprovedIdea: false,
            isFirstPanelAssign: false,
            hideCrossBtn: false,
            acceptedRecommendationPanel: '',
            acceptedRecommendationStatus: '',
            reviewDetails: {},
            panelReviewList: [],
            showBacklogModal: false,
            overrideObj: {},
            errorClass: '',
            showReview: true,
            showAnotherPanel: false,
            showEvaluateSection: false,
            panelErrorMessage: ''
        };
        this.preparePayload = this.preparePayload.bind(this);
        this.findPanelExisting = this.findPanelExisting.bind(this);
    }

    componentDidMount () {
        showLoader();
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("id")) {
            this.setState({ ideaId: parseInt(urlParams.get("id")) });
            this.props.fetchIdeaDetails(parseInt(urlParams.get("id")), this.props.userData.id);
            this.props.getReviewDetails(parseInt(urlParams.get("id")));
        }
        // if (urlParams.has("isFirstPanel")) {
        //     this.setState({ isFirstPanelAssign: urlParams.get("isFirstPanel") });
        // }
    }

    componentDidUpdate (prevProps) {
        if (prevProps.reviewDetails !== this.props.reviewDetails) {
            this.setState({ reviewDetails: this.props.reviewDetails, panelReviewList:this.props.reviewDetails.reviewSummaryList, isFirstPanelAssign: this.props.reviewDetails.reviewSummaryList == null || this.props.reviewDetails.reviewSummaryList?.length == 0 });
        }
        if (prevProps.ideaDetails !== this.props.ideaDetails) {
            this.setState({ showReview: (this.props.ideaDetails.ideaStatus === 'Evaluation Review' || this.props.ideaDetails.ideaStatus === 'Under Review' || this.props.ideaDetails.ideaStatus === 'Submitted') ? true : false })
        }
        setTimeout(() => {
            hideLoading();
        }, 1000);
    }
    
    updateSendBackComment = (e) => {
        const descp = e.target.value;
        if (descp.length <= this.state.maxCharCount) {
            this.setState({ sendBackComment: descp, sendBackCommentCount: descp.length });
        }
        if (descp !== '') {
            this.setState({ disableSendBackBtn: false });
        } else {
            this.setState({ disableSendBackBtn: true });
        }
    };

    /**
    * handles the checkbox change
    * @param {Event} e event
    */
    handleChange = (e) => {
        if (!e.target.checked) {
            this.setState({ enableApproveBtn: true });
        }
        this.setState({ showPanelRecommendation: e.target.checked });
    };

    /**
    * handles the radio change
    * @param {Event} e event
    */
    handleRadioChange = (e) => {
        const { id, value } = e.target;
        this.setState({ selectedReason: value, enableApproveBtn: false, overrideObj: { id: id, value: value } });
    };

    updateOtherComment = (e) => {
        const descp = e.target.value;
        if (descp.length <= this.state.maxCharCount) {
            this.setState({ otherComment: descp, otherCommentCount: descp.length });
        }
    };

    // enableSignInBtn = (element) => {
    //     // this.setState({ panelEmailVal: element.target.value });
    //     let username = document.querySelector('#Username');
    //     let regex = /^(?!(.[.@-][.@-].))([a-zA-Z0-9][a-zA-Z0-9_.+-]*@[a-zA-Z0-9.-]*[a-zA-Z0-9])$/;
    //     if (regex.test(username.value)) {
    //         element.target.removeAttribute('class');
    //         this.setState({ disableAssignPanel: false });
    //     } else {
    //         element.target.setAttribute('class', 'invalidInputField');
    //         this.setState({ disableAssignPanel: true });
    //     }
    // };

    findPanelExisting(currentPanelEmail){
        return this.state.panelReviewList?.find(panel => panel.panelEmail === currentPanelEmail);
    }


    enableSignInBtn = (element) => {
        let errorMessage = _CONSTANTS.ERRORMESSAGES.INVALID_PANEL_EMAIL;
        const foundPanel = this.findPanelExisting(this.state.panelEmailVal)
        if (element.target.value === '' || !element.target.validity.valid) {
            this.setState({ disableAssignPanel: true, errorClass: 'invalidInputField', showErrorMessage: true, panelErrorMessage: errorMessage });
        } else {
            const payload = {
                "memberName": "",
                "memberRole": "",
                "panelist": true,
                "userEmail": this.state.panelEmailVal
            };
            const url = config.validateEmailConfig.requestPayload.url;
            setTimeout(() => {
                axios.post(url, payload, {
                    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
                })
                    .then((response) => {
                        if(foundPanel?.panelEmail){
                            errorMessage = `${foundPanel.panelName}${_CONSTANTS.ERRORMESSAGES.DUPLICATE_PANEL_ERROR}`;
                            this.setState({ disableAssignPanel: true, errorClass: 'invalidInputField', showErrorMessage: true, panelErrorMessage: errorMessage });
                        } else {
                            this.setState({
                                disableAssignPanel: !response.data,
                                showErrorMessage: !response.data,
                                errorClass: response.data ? '' : 'invalidInputField',
                                panelErrorMessage: response.data ? '' : errorMessage
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            disableAssignPanel: true,
                            errorClass: 'invalidInputField',
                            showErrorMessage: true,
                            panelErrorMessage: errorMessage
                        });
                    });
            }, 500);
        }
    };

    onEmailInpChange = (e) => {
        const val = DOMPurify.sanitize(e.target.value)
        this.setState({ panelEmailVal: val });
    };

    preparePayload() {
        const { ideaId: ideaID, panelEmailVal: panelEmail } = this.state;
        const contextPanelReviewer = {panelEmail, ideaID, panelID: "", panelStatus: ""};
        return [contextPanelReviewer];
    }

    assignPanel = () => {
        showLoader();
        const payload = this.preparePayload();
        axios.post(config.assignPanelConfig.requestPayload.url, payload, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
            .then(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                this.setState({ showPanel2AssignSuccess: true, hideCrossBtn: true, showAnotherPanel: false});
                hideLoading();
            }).catch((error) => { console.log(error); hideLoading(); });
    };

    overrideRecommendation = (comment) => {
        showLoader();
        const finalStatus = checkForRecommendedStatus(this.state.overrideObj.value);
        const payload = {
            "acceptReview": false,
            "additionalPanelEmail": "",
            "assignToAdditionalPanel": false,
            "ideaId": this.state.ideaId,
            "overrideReccommendation": false,
            "overridenRecommendationID": this.state.overrideObj.id,
            "overridenRecommendationText": finalStatus,
            "reviewed": true,
            "tgleadComment": comment || "",
            "completeEvalution": true
        };
        axios.post(config.approveIdeaConfig.requestPayload.url, payload, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
            .then(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                this.setState({ showApprovedIdea: true, hideCrossBtn: true, acceptedRecommendationPanel: "", acceptedRecommendationStatus: finalStatus, showEvaluateSection: false });
                hideLoading();
            })
            .catch((error) => { 
                console.log(error);
                hideLoading();
                this.setState({showEvaluateSection: false})
             });
    };

    sendToBacklogHandler = () => {
        showLoader();
        this.setState({ showBacklogModal: false });
        const payload = {
            "status": _CONSTANTS.IDEASTATUS.BACKLOG,
            "feedbackComments": this.state.sendBackComment,
            "ideaID": this.state.ideaId
        }
        axios.post(config.rejectIdeaConfig.requestPayload.url, payload, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
            .then((response) => {
                window.location.href = '/ideaSubmission';
                hideLoading();
            })
            .catch((error) => { console.log(error); hideLoading(); });
    }

    checkForApprovedStatus = (status) => {
        let approvedText = '';
        if (status) {
            if (status === _CONSTANTS.IDEASTATUS.PITCH)
                approvedText = "Idea selected for Pitch";
            if (status === _CONSTANTS.IDEASTATUS.MENTOR)
                approvedText = "Idea selected for next stage. Mentor To Be Assigned";
            if (status === _CONSTANTS.IDEASTATUS.PROTOTYPE)
                approvedText = "Idea selected for Prototype";
            if (status === _CONSTANTS.IDEASTATUS.PILOT)
                approvedText = "Idea selected for Pilot";
            if (status === _CONSTANTS.IDEASTATUS.BACKLOG)
                approvedText = "Idea sent to Backlog";
            if (status === _CONSTANTS.IDEASTATUS.SELECTEDFORLAUNCH)
                approvedText = "Idea is Selected For Launch";
            if (status === _CONSTANTS.IDEASTATUS.OTHER)
                approvedText = "Idea selected as Others";
            return approvedText;
        }
    };

    addProgressClasses = (overallScore, index) => {
        if(overallScore){
            const maxScore = 20;
            const greenScoreCutoff = 30;
            const redScoreCutoff = 15;
            const overallScorePercent = Math.round((overallScore / maxScore) * 15);
            const boxes = document.querySelectorAll(`.dot${index}:nth-child(-n+${overallScorePercent})`);
            for (const box of boxes) {
                if (overallScore > greenScoreCutoff) {
                    box.classList.add('color-green');
                } else if (overallScore <= greenScoreCutoff && overallScore >= redScoreCutoff) {
                    box.classList.add('color-yellow');
                } else if (overallScore < redScoreCutoff) {
                    box.classList.add('color-red');
                }
            }
        }        
    }

    render () {
        const { userData } = this.props;
        const ideaDetails = this.props.ideaDetails || {};
        const { avgScoreSummaryPojo } = this.state.reviewDetails;
        let recommendationStatusClass = '';
        if (this.state.progressArr.length === 0) {
            for (let i = 0; i < 15; i++) {
                this.state.progressArr.push(i);
            }
        }
        if (this.state.acceptedRecommendationPanel === '' && this.state.acceptedRecommendationStatus !== '' && this.state.acceptedRecommendationStatus === 'Backlog') {
            recommendationStatusClass = 'backlog-idea';
        }
        const completedPanels = this.state.panelReviewList?.filter(panelReview => (panelReview?.panelStatus === 'Completed')) || [];
        setTimeout(() => {
            if (avgScoreSummaryPojo && completedPanels?.length > 0) {
                this.addProgressClasses(avgScoreSummaryPojo.avgOverallScore, '-overall');
            }
        }, 50);

        return (
            <>
                <Header hideHeaderCrossBtn={this.state.hideCrossBtn} values={userData} 
                    showSearch={false} showUser={false} pageName={"leader-review"}
                    ideaId={this.state.ideaId}
                />
                <div className={`leader-review-main section-container ${this.state.showReview ? '' : 'hide'}`}>
                    {/* <div className="page-header">{typeof ideaDetails !== undefined ? ideaDetails.ideaName : ""}</div> */}
                    <div className="page-header" title={typeof ideaDetails !== undefined ? ideaDetails.ideaName : ""}>{typeof ideaDetails !== undefined ? (ideaDetails.ideaName?.length > 100 ? `${ideaDetails.ideaName.substring(0, 99)} ...` : ideaDetails.ideaName) : ""}</div>
                    <section className="leader-review">
                        {!this.state.showEvaluateSection && this.state.isFirstPanelAssign && !this.state.showPanel2AssignSuccess && !this.state.showApprovedIdea && (
                            <>
                                <div className="sub-header">
                                    <div className="sub-header_lt">
                                        {this.state.isFirstPanelAssign ? "Assign a panelist" : "Approve evaluation that you resonate with"}
                                    </div>
                                    <div className="sub-header_rt">
                                        <p>Does not fit with the current dynamics?</p>
                                        <button className="btn btnText" onClick={() => this.setState({ showBacklogModal: true })}>Backlog Idea</button>
                                        <Modal
                                            show={this.state.showBacklogModal}
                                            onHide={() => this.setState({ showBacklogModal: false })}
                                            backdrop="static"
                                            keyboard={false}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                            dialogClassName="sendBack-modal"
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Send to Backlog</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="bodyHeader">
                                                    <div className="bodyHeaderHeading">
                                                        Add your comment below
                                                    </div>
                                                    <textarea value={this.state.sendBackComment} type="text" placeholder="Add in your comment" onChange={(e) => this.updateSendBackComment(e)} />
                                                    <span className="charcter-counter">{this.state.sendBackCommentCount}/{this.state.maxCharCount}</span>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button
                                                    className="btn secondary"
                                                    onClick={() => this.setState({ showBacklogModal: false })}>
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn primary"
                                                    disabled={this.state.disableSendBackBtn}
                                                    onClick={() => this.sendToBacklogHandler()}>
                                                    Send to Backlog
                                                </button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                                <div className="detail-container not-assigned">
                                    <div className="detail-container_heading">Panel 1: <span className="not-assigned">Not Assigned</span></div>
                                    <div className="detail-container_review-detail">
                                        <div className="review-detail_lt">
                                            <p>Choose the panel to review the idea</p>
                                            <input
                                                id="Username"
                                                maxLength="100"
                                                name="Username"
                                                placeholder="Add a panelist email id"
                                                tabIndex="0"
                                                type="email"
                                                required={true}
                                                onKeyUp={(e) => this.enableSignInBtn(e)}
                                                autoComplete="off"
                                                value={this.state.panelEmailVal}
                                                onChange={(e) => this.onEmailInpChange(e)}
                                                className={this.state.errorClass}
                                            />
                                            {this.state.showErrorMessage && (
                                                <label className="validate-email-label" id="validate-email-label">{this.state.panelErrorMessage}</label>
                                            )}
                                        </div>
                                        <div className="review-detail_rt">
                                            <button className="btn primary" disabled={this.state.disableAssignPanel} onClick={() => this.assignPanel()}>Send for review</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {!this.state.showEvaluateSection && !this.state.isFirstPanelAssign && !this.state.showPanel2AssignSuccess && !this.state.showApprovedIdea && (
                            <>
                                {avgScoreSummaryPojo && <div className="detail-container">
                                    <div className="detail-container_heading">Score Overview</div>
                                    <div className="detail-container_review-detail">
                                        <div className="review-detail_lt">
                                            <p>Overall Score<span className={`review-score ${avgScoreSummaryPojo.avgOverallScore <= 30 && avgScoreSummaryPojo.avgOverallScore >= 15 ? 'color-yellow' : (avgScoreSummaryPojo.avgOverallScore < 15 ? 'color-red' : '')}`}>{avgScoreSummaryPojo.avgOverallScore}</span><span className="max-score">/20</span></p>
                                            <ul>
                                                <li>Originality<span>{avgScoreSummaryPojo.avgRelevanceScore}</span></li>
                                                <li>Market Demand <span>{avgScoreSummaryPojo.avgInvestmentScore}</span></li>
                                                <li>Feasibility​ <span>{avgScoreSummaryPojo.avgFesibilityScore}</span></li>
                                                <li>Business Value <span>{avgScoreSummaryPojo.avgImpactScore}</span></li>
                                            </ul>
                                        </div>
                                        <div className="review-detail_rt">
                                            <p>Overall confidence of Evaluator</p>
                                            <div className="progress-bar-cont">
                                                {this.state.progressArr.map(() => {
                                                    return <div className={`dot dot-overall`} key={generateRandomKey()}></div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-cont">
                                        <button className="btn primary" onClick={() => {this.setState({showEvaluateSection: true})}}>Evaluate</button>
                                    </div>
                                </div>}
                                {Object.keys(this.state.reviewDetails).length > 0 && this.state.panelReviewList !== null && this.state.panelReviewList.map((review, index) => {
                                    if (review.panelStatus.toLowerCase() === 'completed') {
                                        return <CompletedPanelReview
                                            panelReviewIndex={index}
                                            review={review}
                                            ideaId={this.state.ideaId}
                                            key={review.panelEmail}
                                        />
                                    } else if (review.panelStatus.toLowerCase() === 'pending') {
                                        return <>
                                            <div className="detail-container under-review" key={review.panelEmail}>
                                                <div className="detail-container_heading">Panel {index + 1}: {review.panelName}</div>
                                                <div className="detail-container_review-detail">
                                                    <div className="review-detail_lt">
                                                        <p>This idea is under review of the panelist.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                })}
                            </>
                        )}
                        {!this.state.showEvaluateSection && this.state.showAnotherPanel && !this.state.showPanel2AssignSuccess && !this.state.showApprovedIdea && Object.keys(this.state.reviewDetails).length > 0 && this.state.panelReviewList?.length >= 1 && (
                            <div className="detail-container not-assigned">
                                <div className="detail-container_heading">Panel {this.state.panelReviewList.length + 1}: <span className="not-assigned">Not Assigned</span></div>
                                <div className="detail-container_review-detail">
                                    <div className="review-detail_lt">
                                        <p>Want another panel evaluation?</p>
                                        <input
                                            id="Username"
                                            maxLength="100"
                                            name="Username"
                                            placeholder="Add a panelist email id"
                                            tabIndex="0"
                                            type="email"
                                            required={true}
                                            onKeyUp={(e) => this.enableSignInBtn(e)}
                                            autoComplete="off"
                                            value={this.state.panelEmailVal}
                                            onChange={(e) => this.onEmailInpChange(e)}
                                            className={this.state.errorClass}
                                        />
                                        {this.state.showErrorMessage && (
                                            <label className="validate-email-label">{this.state.panelErrorMessage}</label>
                                        )}
                                    </div>
                                    <div className="review-detail_rt">
                                        <button className="btn primary" disabled={this.state.disableAssignPanel} onClick={() => this.assignPanel()}>Send for review</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                        {!this.state.showEvaluateSection && !this.state.showPanel2AssignSuccess && !this.state.showApprovedIdea && (<div className="add-another-panel">
                            <button className="btn btn-action" 
                                disabled={this.state.isFirstPanelAssign || this.state.showAnotherPanel}
                                onClick={() => {this.setState({showAnotherPanel: true})}}>
                                <MdAdd />
                                Add Another Panelist
                            </button>
                        </div>)}

                        {this.state.showEvaluateSection && <PanelLeadEvaluateIdea
                            avgScoreSummaryPojo={avgScoreSummaryPojo}
                            panelReviewList={this.state.panelReviewList}
                            progressArr={this.state.progressArr}
                            handleRadioChange={this.handleRadioChange}
                            updateOtherComment={this.updateOtherComment}
                            overrideRecommendation={this.overrideRecommendation}
                            addProgressClasses={this.addProgressClasses}
                        />}

                        <div className="sub-header_mobile">
                            <p>Does not fit with the current dynamics?</p>
                            <button className="btn btnText">Backlog Idea</button>
                        </div>
                    </section>
                    {this.state.showPanel2AssignSuccess && (
                        <>
                            <section className="leader-panel-success">
                                <div className="detail-container">
                                    <div className="assignImage">
                                        <img src={ideaImage} alt={_CONSTANTS.IDEASTATUS.SUBMITTED} />
                                    </div>
                                    <div className="assignDetails">
                                        <div className="assignDetails_header">Panel added successfully!</div>
                                        <div className="assignDetails_subheader">
                                            This Idea is sent to
                                            <b>“{this.state.panelEmailVal}”</b>
                                            for additional review
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="idea-explore-btn idea-btn-group">
                                <a href={`/talentReview?id=${this.state.ideaId}`} className="btn secondary" role="button">Assign Panelist(s)</a>
                                <a href={'/ideaSubmission'} className="btn primary" role="button">Evaluate more ideas</a>
                            </div>
                        </>
                    )}
                    {this.state.showApprovedIdea && (
                        <>
                            <section className="leader-panel-success">
                                <div className="detail-container">
                                    <div className="assignImage">
                                        <img src={ideaImage} alt={_CONSTANTS.IDEASTATUS.SUBMITTED} />
                                    </div>
                                    <div className="assignDetails">
                                        <div className={`assignDetails_header ${recommendationStatusClass}`}>
                                            {/* Idea Approved! */}
                                            {this.checkForApprovedStatus(this.state.acceptedRecommendationStatus)}
                                        </div>
                                        <div className="assignDetails_subheader">
                                            {this.state.acceptedRecommendationPanel !== '' && (
                                                <>You have successfully accepted recommendation of {this.state.acceptedRecommendationPanel}.</>
                                            )}
                                            {this.state.acceptedRecommendationPanel === '' && (
                                                <>You have successfully provided the recommendation.</>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="idea-explore-btn">
                                <a href={'/ideaSubmission'} className="btn primary" role="button">Evaluate more ideas</a>
                            </div>
                        </>
                    )}
                </div>
                {!this.state.showReview && (
                    <div className="leader-review-main section-container">
                        <div className="page-header" title={typeof ideaDetails !== undefined ? ideaDetails.ideaName : ""}>{typeof ideaDetails !== undefined ? (ideaDetails.ideaName.length > 100 ? `${ideaDetails.ideaName.substring(0, 99)} ...` : ideaDetails.ideaName) : ""}</div>
                        <p className="review-message">Your Review has already been submitted for this idea.</p>
                        <div className="idea-explore-btn">
                            <a href={'/ideaSubmission'} className="btn primary" role="button">Evaluate more ideas</a>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        FETCH_IDEA_DETAILS_STATUS: state.ideas.meta.FETCH_IDEA_DETAILS_STATUS,
        ideaDetails: state.ideas.data.ideaDetails,
        FETCH_REVIEW_DETAILS_STATUS: state.ideas.meta.FETCH_REVIEW_DETAILS_STATUS,
        reviewDetails: state.ideas.data.reviewDetails
    };
};

const mapDispatchToProps = {
    fetchIdeaDetails,
    getReviewDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderReview);