import { _CONSTANTS } from "./constants";

export /**
 * check if user has access to `idea` feature
 *
 * @param {*} user
 * @return {*} 
 */
const hasIdeaAccess = (user) => {
    let hasAccess = false;

    // allow idea feature access to anyone without submitter role or an submitter who is part of a team
    hasAccess = user && (user.userType !== _CONSTANTS.USER.SUBMITTER || user.teamName)

    return true;
};
