import { Component } from "react";
import challengeIcon from "../../img/challenge-icon.svg";

class ChallengeIcon extends Component {
    render() {
        return <div className="challenge-icon">
            <img
                src={challengeIcon}
                alt="challenge-icon"
            />
        </div>
    }
}

export default ChallengeIcon;
