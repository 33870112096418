import React, { Component } from "react";
import { generateRandomKey } from "../../helpers/utils";

class SubQuestionView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subQuestion: this.props.subQuestion || {},
            stepID: this.props.stepID,
            questionArr: []
        };
    }

    render() {
        return (
            <>
                <div className="question-cont">
                    {this.state.subQuestion.questionText !== '' && (
                        <div className="question-title">{this.state.subQuestion.questionText}</div>
                    )}
                    {this.state.subQuestion.statementText !== '' && (
                        <div className="question-subtitle">{this.state.subQuestion.statementText}</div>
                    )}
                    {this.state.subQuestion.questionType === 'radioTextDescription' && (
                        <div className="RatingWholeScetion">
                            {this.state.subQuestion.options.length > 0 && this.state.subQuestion.options.map((radioVal) => {
                                if (radioVal.selected) {
                                    return <>
                                        <p className="answer-value" key={generateRandomKey()}>{radioVal.text}</p>
                                        {radioVal.showTextBox && radioVal.textBoxValue !== '' && (
                                            <p className="answer-value">{radioVal.textBoxValue}</p>
                                        )}
                                    </>
                                }
                            })}
                        </div>
                    )}
                    {this.state.subQuestion.questionType === 'ratingSubtext' && (
                        <div className="IdeaRadioButtons">
                            {this.state.subQuestion.options.length > 0 && this.state.subQuestion.options.map((ratingVal) => {
                                if (ratingVal.selected) {
                                    return <p className="answer-value" key={generateRandomKey()}>{ratingVal.text}</p>
                                }
                            })}
                        </div>
                    )}
                </div>
            </>
        )
    }
}

export default SubQuestionView;