// Action/Status variables
export const PENDING = "PENDING";
export const FULFILLED = "FULFILLED";
export const FAILED = "FAILED";
export const DEFAULT = "DEFAULT";
export const SUCCESS = "SUCCESS";
export const REJECTED = "REJECTED";

export const _CONSTANTS = {
    EVENTS: {
        INVESTED_IN_IDEA: "INVESTED_IN_IDEA",
        IDEA_SUBMITTED: "IDEA_SUBMITTED",
        CHALLENGE_ACCEPTED: "CHALLENGE_ACCEPTED",
        LIKED_AN_IDEA: "LIKED_AN_IDEA",
        UNLIKE_AN_IDEA: "UNLIKE_AN_IDEA",
        DISINVEST_IN_IDEA: "DISINVEST_IN_IDEA",
        IDEA_APPROVAL: "IDEA_APPROVAL",
        IDEA_NEXT_LEVEL: "IDEA_NEXT_LEVEL",
        ADD_MEMBER: "ADD_MEMBER",
        APPROVE_MEMBER: "APPROVE_MEMBER",
        SIGNIN_USER: "SIGNIN_USER",
        DELETE_MEMBER: "DELETE_MEMBER"
    },
    CHALLENGESTATUS: {
        ACTIVE: "ACTIVE",
        INACTIVE: "INACTIVE",
        EXPIRED: "EXPIRED",
        CREATE_CHALLENGE: "CREATE_CHALLENGE",
        CHALLENGE_ATTACHMENT: "CHALLENGE_ATTACHMENT",
        UPLOAD: "UPLOAD"
    },
    USER: {
        PANELLEAD: "PANELLEAD",
        ADMIN: "ADMIN",
        PANELIST: "PANELIST",
        SUBMITTER: "SUBMITTER"
    },
    IDEASTATUS: {
        INREVIEW: "In Review",
        UNDERREVIEW: "Under Review",
        OTHER: "Other",
        SUBMITTED: "Submitted",
        APPROVED: "Approved",
        PENDING: "Pending",
        DUPLICATE: "Duplicate",
        BACKLOG: "Backlog",
        COMPLETE: "Complete",
        COMPLETED: "Completed",
        EVALUATIONREVIEW: "Evaluation Review",
        PITCH: "Pitch",
        PROTOTYPE: "Prototype",
        MENTOR: "Mentor",
        PILOT: "Pilot",
        SELECTEDFORLAUNCH: "Selected For Launch"
    },
    IDEAPROBLEM: {
        OTHERS: "Others",
        CLIENTS: "Clients",
        DELOITTEINTERNAL: "Deloitte Internal"
    },
    ERRORMESSAGES: {
        DUPLICATE_PANEL_ERROR: " is already assigned, please assign another panelist",
        INVALID_PANEL_EMAIL: "Please enter a valid Panelist's email address"
    },
    HTTPMETHOD: {
        GET: 'get',
        POST: 'post'
    },

    NOCONTENTMESSAGES: {
        NOLIKEDIDEAS1: "No ideas have been liked yet.",
        NOLIKEDIDEAS2: "Explore more ideas and don't forget to like the ones that inspire you!",
        NOINVESTEDIDEAS1: "No ideas have been invested in yet.",
        NOINVESTEDIDEAS2: "Find and invest in ideas that interest you!"
    }
}

// Environment url variables
export const SharepointUrl = "https://amedeloitte.sharepoint.com/:f:/s/SEEDCoreOperations/EplK-tLU8OhEje-_O-lB3sMB0XqNnOhVGBNf9TRinIdrPA?e=4S1nFP";

// Base config urls
export const baseConfigUrl = {
    "local": {
        "ipAddress": "http://seeddevbe-509010082.us-east-2.elb.amazonaws.com/",
        "uploadUrl": "https://y0zpuuppnj.execute-api.us-east-2.amazonaws.com/dev/api/v1/utils/uploadtos3bucket?filename=",
        "getS3FileUrl": "https://y0zpuuppnj.execute-api.us-east-2.amazonaws.com/dev/api/v1/utils/getfileurl?filename=",
        "marketingChallengeId": 176,
        "genAIChallengeId": 177,
        "cmAIChallengeId": 289,
        "commerceChallengeId": 175,
        "lightnathonChallengeId": 178,
        "SSTChallengeId": 2530
    },
    "development": {
        "ipAddress": "https://qa.seed.deloitte.com/",
        "clientId": '68kja94n680cvav8n6eul2hkqm',
        "basicCognitoUrl": "https://seed-api-dev.auth.us-east-2.amazoncognito.com/",
        "redirectUrl": "https://qa.seed.deloitte.com",
        "uploadUrl": "https://qa.seed.deloitte.com/api/v1/utils/uploadtos3bucket?filename=",
        "getS3FileUrl": "https://qa.seed.deloitte.com/api/v1/utils/getfileurl?filename=",
        "marketingChallengeId": 176,
        "genAIChallengeId": 177,
        "cmAIChallengeId": 289,
        "commerceChallengeId": 175,
        "lightnathonChallengeId": 178,
        "SSTChallengeId": 2530
    },
    "production": {
        "ipAddress": "https://seed.deloitte.com/",
        "clientId": '2talm8915gm1s45ta99rgjd0eq',
        "basicCognitoUrl": "https://seed-api-prod.auth.us-east-1.amazoncognito.com/",
        "redirectUrl": "https://seed.deloitte.com",
        "uploadUrl": "https://seed.deloitte.com/api/v1/utils/uploadtos3bucket?filename=",
        "getS3FileUrl": "https://seed.deloitte.com/api/v1/utils/getfileurl?filename=",
        "marketingChallengeId": 176,
        "genAIChallengeId": 177,
        "commerceChallengeId": 175,
        "lightnathonChallengeId": 178,
        "SSTChallengeId": 231
    },
    "stage": {
        "ipAddress": "https://stg.seed.deloitte.com/",
        "clientId": '3te2vn7hf6odnnufnsc444mpec',
        "basicCognitoUrl": "https://seed-api-stage.auth.us-east-2.amazoncognito.com/",
        "redirectUrl": "https://stg.seed.deloitte.com",
        "uploadUrl": "https://stg.seed.deloitte.com/api/v1/utils/uploadtos3bucket?filename=",
        "getS3FileUrl": "https://stg.seed.deloitte.com/api/v1/utils/getfileurl?filename=",
        "marketingChallengeId": 176,
        "commerceChallengeId": 175,
        "lightnathonChallengeId": 178,
    }
};
