import React, { Component } from "react";
import searchIcon from "../../img/search.svg";
import backIcon from "../../img/back.svg";
import InputField from "./inputField";
import DOMPurify from "dompurify";

class AutoSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisibility: false,
      showSearch: false
    };
    this.dropdownRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Close if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ listVisibility: false });
    }
  }

  onEnter(e) {
    this.props.onEnterClicked(e);
  }

  onTextChange(value) {
    const val = DOMPurify.sanitize(value);
    const searchSuggestions = document.getElementById(
      "autosuggest_list_container"
    );
    // this.props.textChange(value);
    if (val.length > this.props.minCharactersRequired) {
      this.props.textChange(val);
      this.setState({ listVisibility: true });
      searchSuggestions.style.display = "block";
    } else {
      this.props.textChange(val);
      this.setState({ listVisibility: false });
    }
  }

  toggleSearch() {
    this.setState({showSearch: !this.state.showSearch});
  }

  closeSearch = () => {
    this.props.closeSearch();
  };

  handleBlur() {
    console.log('input blur');
  }

  render() {
    const { placeHolderText, id, children, inputVal } = this.props;
    return (
      <div className="autosuggest-wrapper" data-testid="autosuggest-component">
        <div className="search">
          <button className="btnSearch-back" onClick={() => this.closeSearch()}>
            <img src={backIcon} alt="back" />
          </button>
          {this.state.showSearch && <InputField
            handleChange={this.onTextChange.bind(this)}
            placeHolder={placeHolderText}
            id={id}
            handleSubmit={this.onEnter.bind(this)}
            inputVal={inputVal}
            handleBlur={this.handleBlur.bind(this)}
          />}
          <button className="btnSearch">
            <img src={searchIcon} alt="search" onClick={this.toggleSearch}/>
          </button>
        </div>
        <div
          className="autosuggest-list-container dropdown"
          id="autosuggest_list_container"
          ref={this.dropdownRef}
        >
          {this.state.listVisibility && children}
        </div>
      </div>
    );
  }
}

export default AutoSuggest;
