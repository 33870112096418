import React, { Component } from "react";
import successIcon from "../../img/successChallenge.svg";
import closeIcon from "../../img/cross-black.svg";

class GlobalMessagePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessagePopup: this.props.showMessagePopup,
      messageData: this.props.messageData,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.messageData !== this.props.messageData) {
      this.setState({ messageData: this.props.messageData });
    }
  }
  render() {
    return (
      <>
        {this.state.showMessagePopup &&
          Object.keys(this.state.messageData).length > 0 && (
            <div className="global-message-container">
              {/* <img src={successIcon} alt="successChallenge" className="global-message-container_success-img" /> */}
              <span className="global-message-container_success-img"></span>
              <div className="global-message-container_success-detail">
                <div className="global-message-container_success-detail_title">
                  {this.state.messageData.title}
                </div>
                <p>{this.state.messageData.detailText}</p>
                <button
                  onClick={() => this.setState({ showMessagePopup: false })}
                >
                  <img src={closeIcon} alt="close" />
                </button>
              </div>
            </div>
          )}
      </>
    );
  }
}
export default GlobalMessagePopup;
