import React from 'react';

const DEFAULT_RADIUS = 60;
const MAX_VALUE = 10;

const Direction = {
    CLOCKSWISE: -1,
    ANTI_CLOCKWISE: 1,
};

const CircularProgressBar = ({
    maxValue,
    selectedValue,
    radius,
    strokeWidth,
    label,
    activeStrokeColor,
    inactiveStrokeColor,
    backgroundColor,
    textColor,
    labelFontSize,
    valueFontSize,
    withGradient,
    anticlockwise,
    initialAngularDisplacement,
}) => {
    // ----  PIE calculation funciton --------
    const generatePie = (value) => {
        const x = radius - Math.cos((2 * Math.PI) / (100 / value)) * radius;
        const y = radius + Math.sin((2 * Math.PI) / (100 / value)) * radius;
        const long = value <= 50 ? 0 : 1;
        const d = `M${radius} ${radius} L${radius} ${0} A${radius} ${radius} 0 ${long} 1 ${y} ${x} Z`;

        return d;
    };

    // ----  PIE Area calculation  --------
    const calculatePieValue = (numberOfBars) => {
        const angle = 360 / numberOfBars;
        const pieValue = Math.floor(angle / 4);
        return pieValue < 1 ? 1 : Math.floor(angle / 4);
    };

    // ----  PIE render funciton --------
    const renderPie = (i) => {
        const DIRECTION = anticlockwise ? Direction.ANTI_CLOCKWISE : Direction.CLOCKSWISE;
        // Rotation Calculation
        const primaryRotationAngle = (maxValue - 1) * (360 / maxValue);
        const rotationAngle = DIRECTION * initialAngularDisplacement
            + -1 * DIRECTION * primaryRotationAngle
            + i * DIRECTION * primaryRotationAngle;
        const rotationTransformation = `rotate(${rotationAngle}, ${radius}, ${radius})`;

        const pieValue = calculatePieValue(maxValue);
        const dValue = generatePie(pieValue);

        const activeColor = activeStrokeColor;

        const fillColor = selectedValue > 0 && i <= selectedValue
            ? activeColor : inactiveStrokeColor;

        return (
            <path
                style={{ opacity: i === 0 ? 0 : 1 }}
                key={i}
                d={dValue}
                fill={fillColor}
                transform={rotationTransformation}
            />
        );
    };

    // ----  Creates a circle by combining the Pie(s) --------
    const renderOuterCircle = () => [...Array(maxValue + 1)].map((e, i) => renderPie(i));

    const textValueY = radius + valueFontSize / 3;

    // --------  MAIN Render --------
    return (
        <svg width={radius * 2} height={radius * 2}>
            {renderOuterCircle()}

            {/* This is the overlay circle */}
            <circle r={radius - strokeWidth} cx={radius} cy={radius} fill={backgroundColor} />
            <circle r={radius - (strokeWidth + 8)} cx={radius} cy={radius} fill="#CDE5DE" />
            <circle r={radius - (strokeWidth + 10)} cx={radius} cy={radius} fill="#fff" />
            <text
                fill={textColor}
                fontSize={valueFontSize}
                fontWeight="800"
                x={radius}
                y={textValueY}
                textAnchor="middle"
            >
                {label}
            </text>
        </svg>
    );
};

CircularProgressBar.defaultProps = {
    maxValue: MAX_VALUE,
    selectedValue: 0,
    radius: DEFAULT_RADIUS,
    strokeWidth: DEFAULT_RADIUS / 10,
    label: '',
    activeStrokeColor: '#05a168',
    inactiveStrokeColor: '#ddd',
    backgroundColor: '#fff',
    textColor: '#000',
    labelFontSize: Math.floor(DEFAULT_RADIUS / 3),
    valueFontSize: Math.floor(DEFAULT_RADIUS / 2.5),
    withGradient: false,
    anticlockwise: false,
    initialAngularDisplacement: 0,
};

export default CircularProgressBar;