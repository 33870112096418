import { Component } from "react";
import { Collapse } from "react-bootstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

class CompletedPanelReview extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            openPanel: false
        }
    }

    render() {
        const {panelReviewIndex, review, ideaId} = this.props;
        return (
        <div className="detail-container">
            <div className="detail-container_heading"
                onClick={() => this.setState({ openPanel: !this.state.openPanel })}
                aria-controls="example-collapse-text"
                aria-expanded={this.state.openPanel}>
                <span>Panel {panelReviewIndex + 1} : {review.panelName}</span>
                <span>
                    {!this.state.openPanel && <MdKeyboardArrowDown />}
                    {this.state.openPanel && <MdKeyboardArrowUp />}
                </span>
            </div>
            <Collapse in={this.state.openPanel}>
                <div id="example-collapse-text" className="detail-container_review-detail">
                    <div className="review-detail_lt">
                        <p>Panel Score<span className={`review-score ${review.overallScore <= 30 && review.overallScore >= 15 ? 'color-yellow' : (review.overallScore < 15 ? 'color-red' : '')}`}>{review.overallScore}</span><span className="max-score">/20</span></p>
                        <ul>
                            <li>Originality <span>{review.relevanceScore}</span></li>
                            <li>Market Demand <span>{review.investmentScore}</span></li>
                            <li>Feasibility​ <span>{review.fesibilityScore}</span></li>
                            <li>Business Value <span>{review.impactScore}</span></li>
                        </ul>
                        {review.nextRecommendedStep !== null && review.nextRecommendedStep !== '' && (
                            <>
                                <p>Panel recommended next step</p>
                                <div className="recommended-step">{review.nextRecommendedStep}</div>
                            </>
                        )}
                        {review.needAdditionalPanel && review.additionalPanelEmail !== '' && (
                            <>
                                <p>Panel recommended Additional Panel</p>
                                <div className="recommended-step">{review.additionalPanelEmail}</div>
                            </>
                        )}
                        {(review.nextRecommendedStep == null || review.nextRecommendedStep == '') && (
                            <>
                                <p>Panel recommended next step</p>
                                <div className="recommended-step">Others</div>
                            </>
                        )}
                    </div>
                    <div className="btn-cont">
                        <button className="btn secondary" onClick={() => window.location.href = "/evaluateDetail?id=" + ideaId + "&panel=" + review.panelNumber}>View Evaluation details</button>
                    </div>
                </div>
            </Collapse>
        </div>
        );
    }
}

export default CompletedPanelReview;
