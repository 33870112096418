import { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import EventDropdown from "./eventDropdown";
import * as XLSX from 'xlsx'
import { addParticipantsToEvent, deleteParticipantsFromEvent } from "../../redux/actions/utilities";
import { hideLoading, showLoader } from "../../helpers/utils";

class NewPointEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: '',
      eventId: null,
      participants: [{participantName: '', emailId: '', points: null}],
      isRegisterBtnDisabled: true,
      isAddParticipantBtnDisabled: true,
      mode: this.props.mode,
      eventList: this.props.eventList
    };
    this.typingTimeout = null;
  }

  checkAllValidations = () => {
    const {eventName, participants, mode} = this.state;
    const isEventNameValid =  eventName !== '' && eventName.length <= 50;
    if(participants.length !== 0) {
      const areParticipantsValid = participants.every((p) => {
        const isNameValid =  mode === 'create' && p.participantName?.length <= 50 && p.participantName !== '' || mode === 'delete';
        const isEmailValid = /^[a-zA-Z0-9._%+-]+@deloitte\.com$/.test(p.emailId.toLowerCase());
        const isPointsValid = mode === 'create' && !isNaN(p.points) && p.points !== null && p.points !== '' || mode === 'delete';
        return isNameValid && isEmailValid && isPointsValid
      })
      this.setState({ isRegisterBtnDisabled: !(isEventNameValid && areParticipantsValid), isAddParticipantBtnDisabled: !areParticipantsValid })
    } else {
      this.setState({isRegisterBtnDisabled: true, isAddParticipantBtnDisabled: false})
    }
  }

  handleInputChange = (e, i) => {
    const { name, value } = e.target;
    if (name === 'eventName') {
      this.setState({eventName: value}, this.checkAllValidations)
    } else {
      const updatedParticipants = [...this.state.participants];
      updatedParticipants[i][name] = value;
      this.setState({participants: updatedParticipants}, this.checkAllValidations)
    }
    if(this.typingTimeout) { clearTimeout(this.typingTimeout) }
    this.typingTimeout = setTimeout(this.checkAllValidations, 500)
  };

  addParticipants = () => {
    this.setState((prevState) => ({
      participants: [...prevState.participants, {participantName: '', emailId: '', points: null}],
      isAddParticipantBtnDisabled: true,
      isRegisterBtnDisabled: true
    }))
  }

  handleEventChange = (id, text) => {
    this.setState({eventName: text, eventId: id}, this.checkAllValidations)
  }

  handleFileUpload = (e) => {
    showLoader()
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result)
      const workbook = XLSX.read(data, {type: 'array'})
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
      const rows = XLSX.utils.sheet_to_json(firstSheet, {header: 1})
      let participants = [];
      if(this.state.mode === 'create') {
        participants = rows.slice(1).filter((row) => {
          const nameValid = row[0] && row[0].length > 0;
          const emailValid = row[1] && row[1].toLowerCase().includes('@deloitte.com');
          return nameValid && emailValid;
        }).map((row) => ({
          participantName: row[0] || '',
          emailId: row[1] || '',
          points: row[2] || 0
        }))
      } else if (this.state.mode === 'delete') {
        participants = rows.slice(1).map((row) => ({
          emailId: row[0] || '',
        }))
      }
      if(participants.length > 0) { this.setState({participants}, this.checkAllValidations) } else { alert('Please upload valid data') }
    }
    reader.readAsArrayBuffer(file)
    hideLoading();
  }

  handleDeleteRow = (i) => {
    const{participants} = this.state
    participants.splice(i, 1)
    this.setState({participants}, this.checkAllValidations)
  }

  handleRegister = async () => {
    showLoader()
    const {eventId, participants, mode} = this.state;
    let res, data;
    switch(mode) {
      case 'create': 
        res = await addParticipantsToEvent({eventId, participants});
        data = await res.payload;
        if(data.status === 200) {this.props.handleCloseModal(); hideLoading();} else {alert('Something went wrong!'); hideLoading();}
        break;
      case 'delete':
        participants.forEach(el => { delete el.participantName; delete el.points })
        res = await deleteParticipantsFromEvent({eventId, participants});
        data = await res.payload;
        if(data.status === 200) {this.props.handleCloseModal(); hideLoading();} else {alert('Something went wrong!'); hideLoading();}
        break;
      default:
        alert('Something went wrong!')
        break;
    }
  }

  render() {
    const {eventName, participants, isRegisterBtnDisabled, isAddParticipantBtnDisabled, mode, eventList} = this.state
    return (
      <>
        <Modal
          show={true}
          onHide={() => this.props.handleCloseModal()}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{mode === 'create' ? 'New Point Entry' : 'Delete Participants'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bodyHeader">
              <div className="bodyHeaderHeading">
                <EventDropdown eventList={eventList} handleEventChange={this.handleEventChange} page='ADD_PARTICIPANTS'/>
                {participants.map((p, i) => (
                  <div className="regsiterTeamGroup" key={i}>
                    {['create'].includes(mode) && (
                    <div className="regTeamGrpIn">
                      <div className="regsiterTeamIndi_title">Name: </div>
                      <div className="regsiterTeamIndi_val">
                        <input
                          type='text'
                          name='participantName'
                          maxLength={50}
                          value={p.participantName}
                          placeholder='Participant Name'
                          onChange={(e) => this.handleInputChange(e, i)}
                        />
                      </div>
                    </div>
                    )}
                    <div className="regTeamGrpIn">
                        <div className="regsiterTeamIndi_title">Email: </div>
                        <div className="regsiterTeamIndi_val">
                          <input
                            type='email'
                            name='emailId'
                            value={p.emailId}
                            placeholder='Participant Email'
                            onChange={(e) => this.handleInputChange(e, i)}
                          />
                        </div>
                    </div>
                    {['create'].includes(mode) && (
                    <div className="regTeamGrpIn">
                      <div className="regsiterTeamIndi_title">Points: </div>
                      <div className="regsiterTeamIndi_val">
                        <input
                          type='number'
                          name='points'
                          value={p.points}
                          placeholder='Participant Points'
                          onChange={(e) => this.handleInputChange(e, i)}
                        />
                      </div>
                    </div>
                    )}
                    <div className="regTeamGrpIn">
                      <button className='deleteLink' onClick={() => this.handleDeleteRow(i)}>delete</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnText"
              onClick={() => this.props.handleCloseModal()}
            >
              Cancel
            </button>
            <div style={{marginRight: '4rem'}}>
              <label for='file-upload' className='btn btn-primary'>Bulk upload</label>
              <input id='file-upload' type='file' accept='.xlsx, .xls' style={{display: 'none'}} onChange={this.handleFileUpload}/>
            </div>
            <Button
              style={{marginRight: '4rem'}}
              variant="primary"
              disabled={isAddParticipantBtnDisabled}
              onClick={this.addParticipants}
            > <span style={{fontSize: '36px', marginRight: '10px', fontWeight: '500'}}>+</span> Add More Participant
            </Button>
            <Button
              variant="primary"
              disabled={isRegisterBtnDisabled}
              onClick={this.handleRegister}
            >
              {mode === 'create' ? 'Register Participant(s)' : 'Delete Participant(s)'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default NewPointEntry;
