import React, { Component } from "react";
import { generateRandomKey } from "../../helpers/utils";

class RatingSubtext extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: this.props.question || {},
            stepID: this.props.stepID
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.question !== this.props.question) {
            this.setState({ question: this.props.question });
        }
    };

    handleRatingChange = (e, score) => {
        const { id, value } = e.target;
        this.props.onRatingValueChange(id, value, score, this.state.question.questionId, this.state.stepID);
    };

    render() {
        return (
            <>
                <div className="top">
                    {`< ${this.state.question.helpTextLow}`}<span></span>{`${this.state.question.helpTextHigh} >`}
                </div>
                <div className="IdeaRadioButtons">
                    {this.state.question.options.length > 0 && this.state.question.options.map((ratingVal) => {
                        return (
                            <label className="radio-rating" key={generateRandomKey()}>
                                {ratingVal.text}
                                <input
                                    type="radio"
                                    name={`question-rating${this.state.question.questionId}`}
                                    id={ratingVal.id}
                                    value={ratingVal.text}
                                    onChange={(e) => this.handleRatingChange(e, ratingVal.score)}
                                    checked={ratingVal.selected}
                                />
                                <span className="checkmark"></span>
                            </label>
                        );
                    })}
                </div>
            </>
        )
    }
}

export default RatingSubtext;