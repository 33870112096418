import React, { Component } from "react";
import ChallengeIcon from "../global/challengeIcon";

class SearchItem extends Component {
  navigateToIdea = (id) => {
    window.location.href = "/idea/" + parseInt(id);
  };

  contextHandler = () => {
    this.props.setContextIdeaId(this.props.idea.ideaID)
  }

  render() {
    const { idea } = this.props;
    return (
      <div className="idea-cont search-item" onClick={() => {
        this.navigateToIdea(idea.ideaID);
      }} onMouseOver={(e) => {
        this.contextHandler(idea.ideaID);
      }}>
        {idea.challengeID && <ChallengeIcon />}
        <div className="idea-cont_detail">
          <div className="ideaHeading">
            {idea.ideaName && idea.ideaName.length > 25
              ? `${idea.ideaName.substring(0, 25)} ...`
              : idea.ideaName}
          </div>
          {/* <div className="ideaType">Idea</div> */}
          <div className="ideaType">
            {idea.ideaType && idea.ideaType.text.length > 20
              ? `${idea.ideaType.text.substring(0, 20)} ...`
              : idea.ideaType.text}
          </div>
          <div className="ideaCreator">
            {idea.createdBy.name && idea.createdBy.name.length > 25
              ? `${idea.createdBy.name.substring(0, 25)} ...`
              : idea.createdBy.name}
          </div>
        </div>
        <div className="ideaStatement">
          {idea.ideaDescription && idea.ideaDescription.length > 90 ? `${idea.ideaDescription.substring(0, 90)} ...` : idea.ideaDescription}
        </div>
      </div>
    );
  }
}
export default SearchItem;
