import axios from "axios";
import { baseConfigUrl, _CONSTANTS } from "./constants";
import htmlToDraft from "html-to-draftjs";
import { ContentState } from "draft-js";
const loader = document.querySelector('.loader');

/**
 * Returns the initials of the string
 * @param {string} name
 * @returns {string} initials
 */
export const getNameInitials = (name) => {
  if (name) {
    const fullName = name.split(' ');
    const initials = fullName.shift().charAt(0).toUpperCase() + fullName.pop().charAt(0).toUpperCase();
    return initials;
  } else {
    return false;
  }
};

/**
 * Returns a random number generated of 10 digits starting from 1
 */
export const generateRandomKey = () => {
  const integerPart = 1 + Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] % 9);
  const decimalPart = crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296; // 2^32
  return parseFloat((integerPart + decimalPart).toFixed(10));
};

/**
 * Returns color class based on the status
 * @param {string} status
 * @param {string} statusClass
 * @returns {string}
 */
export const checkForStatusColor = (status, statusClass) => {
  if (status) {
    switch (status.toLowerCase()) {
      case _CONSTANTS.IDEASTATUS.INREVIEW.toLowerCase():
      case _CONSTANTS.IDEASTATUS.UNDERREVIEW.toLowerCase():
        return `isInReview ${statusClass}`;
      case _CONSTANTS.IDEASTATUS.OTHER.toLowerCase():
        return `isInReview ${statusClass}`;
      case _CONSTANTS.IDEASTATUS.SUBMITTED.toLowerCase():
        return `isSubmitted ${statusClass}`;
      case _CONSTANTS.IDEASTATUS.APPROVED.toLowerCase():
        return `isApproved ${statusClass}`;
      case _CONSTANTS.IDEASTATUS.PENDING.toLowerCase():
        return `isPending ${statusClass}`;
      case _CONSTANTS.IDEASTATUS.DUPLICATE.toLowerCase():
      case _CONSTANTS.IDEASTATUS.BACKLOG.toLowerCase():
        return `isDuplicate ${statusClass}`;
      case _CONSTANTS.IDEASTATUS.COMPLETE.toLowerCase():
      case _CONSTANTS.IDEASTATUS.COMPLETED.toLowerCase():
      case _CONSTANTS.IDEASTATUS.EVALUATIONREVIEW.toLowerCase():
      case _CONSTANTS.IDEASTATUS.PITCH.toLowerCase():
      case _CONSTANTS.IDEASTATUS.PROTOTYPE.toLowerCase():
      case _CONSTANTS.IDEASTATUS.SELECTEDFORLAUNCH.toLowerCase():
      case _CONSTANTS.IDEASTATUS.MENTOR.toLowerCase():
      case _CONSTANTS.IDEASTATUS.PILOT.toLowerCase():
        return `isCompleted ${statusClass}`;
      default:
        return `${statusClass}`;
    }
  }
};

/**
 * check for status
 * @param {string} text
 * @returns {string} status
 */
export const checkForRecommendedStatus = (text) => {
  let status = '';
  if(text === null) {
    return status = _CONSTANTS.IDEASTATUS.OTHER;
  }
  if (text.toLowerCase().indexOf('pitch') > -1)
    status = _CONSTANTS.IDEASTATUS.PITCH;
  if (text.toLowerCase().indexOf('mentor') > -1)
    status = _CONSTANTS.IDEASTATUS.MENTOR;
  if (text.toLowerCase().indexOf('prototype') > -1)
    status = _CONSTANTS.IDEASTATUS.PROTOTYPE;
  if (text.toLowerCase().indexOf('pilot') > -1)
    status = _CONSTANTS.IDEASTATUS.PILOT;
  if (text.toLowerCase().indexOf('other') > -1)
    status = _CONSTANTS.IDEASTATUS.OTHER;
  if (text.toLowerCase().indexOf('backlog') > -1)
    status = _CONSTANTS.IDEASTATUS.BACKLOG;
  if (text.toLowerCase().indexOf('launch') > -1)
    status = _CONSTANTS.IDEASTATUS.SELECTEDFORLAUNCH;
  return status;
};

/**
 * Attempts token refresh, logout and logging in again
 * @param {object} response
 */
export const attemptTokenRefreshOrLogin = (response) => {
  showLoader();
  const baseUrl = getBaseUrl();
  const YOUR_CLIENT_ID = baseUrl.clientId;
  const redirectUri = baseUrl.redirectUrl;

  console.log(response.data);
  if (
    response.status === 401 &&
    (response.data.message === 'The incoming token has expired' ||
      response.data.error === 'invalid_token') &&
    localStorage.getItem('refresh_token')
  ) {
    console.log('Refreshing token attempt');
    const url = baseUrl.basicCognitoUrl + 'oauth2/token';
    axios
      .post(
        url,
        `grant_type=refresh_token&client_id=${YOUR_CLIENT_ID}&refresh_token=${localStorage.getItem(
          'refresh_token'
        )}`,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then((response) => {
        if (response.data && response.data.access_token) {
          if (window.localStorage) {
            const tokenexpiration = new Date();
            tokenexpiration.setSeconds(new Date().getSeconds() + parseInt(response.data.expires_in));
            localStorage.setItem('expirationdate', tokenexpiration);
            localStorage.setItem('access_token', response.data.access_token);
            // localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('id_token', response.data.id_token);
            window.open('/dashboard', '_self');
          }
        }
        hideLoading();
      })
      .catch((e) => {
        console.log(e);
        localStorage.clear();
        console.log('Logging out');
        hideLoading();
        window.open(
          baseUrl.basicCognitoUrl + `logout?client_id=${YOUR_CLIENT_ID}&logout_uri=${redirectUri}/login`,
          '_self'
        );
      });
  // } else if (response.status === 401 && response.data.message === 'Unauthorized') {
  } else {
    localStorage.clear();
    console.log('Logging out');
    window.open(
      baseUrl.basicCognitoUrl + `logout?client_id=${YOUR_CLIENT_ID}&logout_uri=${redirectUri}/login`,
      '_self'
    );
  }
};

/**
 * shows loader on page
 */
export const showLoader = () => {
  const pageLoader = loader || document.querySelector('.loader');
  if (pageLoader) {
    pageLoader.classList.remove('hide');
  }
};

/**
 * Hides loader on page
 */
export const hideLoading = () => {
  const pageLoader = loader || document.querySelector('.loader');
  if (pageLoader) {
    pageLoader.classList.add('hide');
  }
};

/**
 * check for env
 */
const getEnv = () => {
  const hostName = window.location.hostname;
  if(hostName.search('3.138.244.198') >= 0 || hostName.search('qa.seed.deloitte') >= 0) {
    return 'development'
  } else if(hostName.search('3.22.208.35') >= 0 || hostName.search('stg.seed.deloitte') >= 0){
    return 'stage'
  } else if(hostName.search('localhost') >= 0) {
    return 'local'
  } else {
    return 'production'
  }
  // return window.location.hostname.search('3.138.244.198') >= 0|| window.location.hostname.search('qa.seed.deloitte') >= 0 || window.location.hostname.search('localhost') >= 0 ? 'development' : 'production';
}

/**
 * gets the base url for the env
 */
const getBaseUrl = () => {
  return baseConfigUrl[getEnv()];
}

/**
 * Add policy cookie for the deloitte banner
 */
export const addPolicyCookieForProd = () => {
  const prodEnv = "seed.deloitte.com";  //use your production URL
  const hostName = window.location.hostname;
  const isProdEnv = prodEnv === hostName.toLowerCase();
  const script = document.createElement("script");
  let dataDomainScript;

  if (isProdEnv) {
    dataDomainScript = "4159679a-8de6-4fce-ab4b-8d5d84fdb202";   // specify your data-domain provided of production
  } else {
    dataDomainScript = "4159679a-8de6-4fce-ab4b-8d5d84fdb202-test"; //specify your data-domain provided of non-production
  }

  script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  script.type = "text/javascript";
  script.charset = "UTF-8";
  script.setAttribute("data-domain-script", dataDomainScript);
  document.getElementsByTagName("head")[0].appendChild(script);
};

/**
 * Deletes all cookies, local and storage sessions
 */
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  // for (let i = 0; i < cookies.length; i++) {
  //   const cookie = cookies[i];
  //   const eqPos = cookie.indexOf("=");
  //   const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
  //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  // }

  // clearing localstorage and session storage as well
  localStorage.clear();
  sessionStorage.clear();
}

/**
 * @returns Returns string based on event type
 * @param {string} eventType
 * @returns {string} str
 */
export const checkForEventString = (eventType) => {
  if (eventType) {
    switch (eventType.toLowerCase()) {
      case _CONSTANTS.EVENTS.INVESTED_IN_IDEA.toLowerCase():
        return "Invested in Idea";
      case _CONSTANTS.EVENTS.IDEA_SUBMITTED.toLowerCase():
        return "Idea/Solution Submitted";
      case _CONSTANTS.EVENTS.CHALLENGE_ACCEPTED.toLowerCase():
        return "Challenge Accepted";
      case _CONSTANTS.EVENTS.LIKED_AN_IDEA.toLowerCase():
        return "Liked an Idea";
      case _CONSTANTS.EVENTS.UNLIKE_AN_IDEA.toLowerCase():
        return "Unlike an Idea";
      case _CONSTANTS.EVENTS.DISINVEST_IN_IDEA.toLowerCase():
        return "De-invested in an Idea";
      case _CONSTANTS.EVENTS.IDEA_APPROVAL.toLowerCase():
        return "Idea Approval";
      case _CONSTANTS.EVENTS.IDEA_NEXT_LEVEL.toLowerCase():
        return "Idea Status Change";
      case _CONSTANTS.EVENTS.ADD_MEMBER.toLowerCase():
        return "Added Member";
      case _CONSTANTS.EVENTS.APPROVE_MEMBER.toLowerCase():
        return "Approve Member Request";
      case _CONSTANTS.EVENTS.SIGNIN_USER.toLowerCase():
        return "Login Credits";
      case _CONSTANTS.EVENTS.DELETE_MEMBER.toLowerCase():
        return "Delete Member";
      default:
        return "";
    }
  } else {
    return "";
  }
};

export /**
 * get text from html string excluding html tags, leading & trailing spaces
 *
 * @param {*} htmlString
 * @return {*} 
 */
const getTextFromHtmlString = htmlString => {
  // get the text from html-string  using same library used by wysiwyg editor to be consistent with count
  return ContentState.createFromBlockArray(htmlToDraft(htmlString ?? '').contentBlocks).getPlainText();
}

export { getEnv, getBaseUrl };