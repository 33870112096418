import React, { Component } from "react";
import "../../css/pages/challengeListAll.scss";
import { connect } from "react-redux";
// import axios from "axios";
import Header from "../global/header";
// import { challengeList } from "../../redux/actions/challenges";
import ChallengeItem from "./challengeItem";
import CarousalArrow from "../global/carousalArrow";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  generateRandomKey,
  hideLoading,
  showLoader,
} from "../../helpers/utils";
import { fetchChallengesAllUser } from "../../redux/actions/challenges";
import Pagination from "../global/pagination";

class ChallengeListAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // renderJSON: [],
      userId: this.props.userData && this.props.userData.id,
      selectedTags: [],
      tags: this.props.challengeList ? this.props.challengeList.keywords : [],
      loading: false,
      perPageRows: 4,
      pageIndex: 0,
      currentPage: 1,
      options: [1, 2, 3, 4, 5, 6],
      totalPages: this.props.challengeList
        ? parseInt(this.props.challengeList.totalRecords)
        : 0,
      challengeAccepted: this.props.challengeList
        ? this.props.challengeList.acceptedChallengesList
        : [],
      challengeAll: this.props.challengeList
        ? this.props.challengeList.nonAcceptedChallengeList
        : [],
      keywords: this.props.challengeList
        ? this.props.challengeList.keywords
        : [],
    };
  }

  componentDidMount() {
    showLoader();
    const payload = {
      currentPaginationLevel: this.state.pageIndex,
      keywordId: this.state.selectedTags,
      pageSize: this.state.perPageRows,
      userId: this.state.userId,
    };
    this.props.fetchChallengesAllUser(payload);
  }

  componentDidUpdate(prevProps) {
    setTimeout(() => {
      hideLoading();
    }, 1000);
    if (prevProps.challengeList !== this.props.challengeList) {
      this.setState({
        totalPages: parseInt(this.props.challengeList.totalRecords),
        challengeAccepted: this.props.challengeList.acceptedChallengesList,
        challengeAll: this.props.challengeList.nonAcceptedChallengeList,
        tags: this.props.challengeList.keywords,
      });
    }
  }

  sendSearchTerms = (tagID, isSelected) => {
    if (isSelected) {
      const id = this.state.selectedTags.findIndex((tag) => tag === tagID);
      this.state.selectedTags.splice(id, 1);
    } else {
      this.state.selectedTags.push(tagID);
    }
    const payload = {
      currentPaginationLevel: 0,
      keywordId: this.state.selectedTags,
      pageSize: this.state.perPageRows,
      userId: this.state.userId,
    };
    this.props.fetchChallengesAllUser(payload);
    this.setState({ pageIndex: 0, currentPage: 1 });
  };

  /**
   * change the page number from pagination
   * @param {Number} page_number current page number
   */
  pageChangeHandler = (page_number) => {
    showLoader();
    this.setState({
      currentPage: page_number,
      pageIndex: Number(page_number - 1),
    });
    const requestPayload = {
      currentPaginationLevel: Number(page_number - 1),
      keywordId: this.state.selectedTags,
      pageSize: this.state.perPageRows,
      userId: this.state.userId,
    };
    this.props.fetchChallengesAllUser(requestPayload);
  };

  /**
   * change the page number list based on start and end parameters
   * @param {Number} start Start page number
   * @param {Number} end End page number
   */
  sliceChangeHandler = (start, end) => {
    this.createSlice(start, end);
  };

  /**
   * Creates page number list
   * @param {Number} start Start Page number
   * @param {Number} end End page number
   */
  createSlice = (start = 1, end = 4) => {
    let temp = [];
    for (let i = start; i < end; i++) {
      temp.push(i);
    }
    this.setState({ options: temp });
  };

  render() {
    const { userData } = this.props;
    // let this.state.challengeAccepted = [],
    //   otherChallenges = [];
    // this.state.renderJSON.forEach((item) => {
    //   if (item.challengeAcceptedByCurrentUser === true) {
    //     this.state.challengeAccepted.push(item);
    //   } else {
    //     otherChallenges.push(item);
    //   }
    // });
    const settings = {
      dots: false,
      className: "center",
      centerMode: false,
      infinite: false,
      slidesToShow: 3.5,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      nextArrow: <CarousalArrow />,
      prevArrow: <CarousalArrow />,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2.5,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2.1,
          },
        },
      ],
    };
    return (
      <>
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"challengeList"}
        />
        <div className="section-slider-container challengeTags">
          <div className="headerTitleContent">
            <h1 className="header">Topics that might interest you</h1>
          </div>
          <div className="tagsContainer">
            <div className="tags">
              <Slider {...settings}>
                {this.state.tags &&
                  this.state.tags.length > 0 &&
                  this.state.tags.map((tagItem, index) => {
                    return (
                      <div className="tag" key={generateRandomKey()}>
                        <button
                          onClick={() =>
                            this.sendSearchTerms(
                              tagItem.tagID,
                              tagItem.selected
                            )
                          }
                          className={
                            tagItem.selected === true
                              ? "btn tagsButton isSelected"
                              : "btn tagsButton"
                          }
                        >
                          {tagItem.tag}
                        </button>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
        <div className="section-container challengeSection">
          {this.state.challengeAccepted &&
            this.state.challengeAccepted.length > 0 && (
              <div className="acceptedChallengesContainer">
                <div className="headerTitleContent">
                  <h1 className="header">Accepted Challenges</h1>
                </div>
                <div className="challengesContainer">
                  <div className="challenges">
                    {this.state.challengeAccepted.map((challengeDetail) => {
                      return (
                        <ChallengeItem
                          challenge={challengeDetail}
                          key={generateRandomKey()}
                          userData={userData}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          <div className="headerTitleContent mr-t-4">
            <h1 className="header">Challenges to look for</h1>
          </div>
          <div className="challengesContainer">
            <div className="challenges">
              {this.state.challengeAll &&
                this.state.challengeAll.length === 0 && (
                  <div className="no-results">We couldn’t find any match</div>
                )}
              {this.state.challengeAll &&
                this.state.challengeAll.length > 0 &&
                this.state.challengeAll.map((challengeDetail, index) => {
                  return (
                    <ChallengeItem
                      challenge={challengeDetail}
                      key={generateRandomKey()}
                      userData={userData}
                    />
                  );
                })}
            </div>
          </div>
          {this.state.challengeAll && this.state.challengeAll.length > 0 && (
            <Pagination
              rowCount={this.state.perPageRows}
              startCount={this.state.pageIndex}
              productListCount={Number(
                parseInt(this.state.pageIndex + 1) *
                  parseInt(this.state.perPageRows)
              )}
              totalRecordsCount={this.state.totalPages}
              onPageChange={this.pageChangeHandler.bind(this)}
              page={this.state.currentPage}
              slice={this.state.options}
              onSliceChange={this.sliceChangeHandler.bind(this)}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_CHALLENGE_USER: state.challenges.meta.FETCH_CHALLENGE_USER_STATUS,
    challengeList: state.challenges.data.challengeList,
  };
};

const mapDispatchToProps = {
  fetchChallengesAllUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeListAll);
