import React, { Component } from "react";

class RadioTextDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioValues: this.props.radioValues || {},
            questionId: this.props.questionId || 0,
            stepID: this.props.stepID,
            textCommentVal: this.props.radioValues.textBoxValue || ''
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.radioValues !== this.props.radioValues) {
            this.setState({ radioValues: this.props.radioValues });
        }
    };

    /**
     * handles textarea change
     * @param {Event} e value of the entered comment
     */
    onOtherCommentChange = (e) => {
        this.setState({ textCommentVal: e.target.value });
        let renderJSONTemp = this.state.radioValues;
        renderJSONTemp.textBoxValue = e.target.value;
        this.setState({ radioValues: renderJSONTemp });
    };

    onOtherCommentBlur = () => {
        this.props.updateOtherComment(this.state.radioValues.id, this.state.stepID, this.state.textCommentVal);
    }

    handleRadioChange = (e) => {
        e && e.stopPropagation();
        const { id, value } = e.target;
        this.props.onRadioValueChange(id, value, this.state.radioValues.score, this.state.questionId, this.state.stepID);
    };

    render() {
        return (
            <>
                <label className="radio-label">
                    {this.state.radioValues.text}
                    {this.state.radioValues.subText && this.state.radioValues.subText !== '' && (
                        <span className="radio-label_helpTxt">{this.state.radioValues.subText}</span>
                    )}
                    <input
                        type="radio"
                        name={this.state.radioValues.type + this.state.questionId}
                        id={this.state.radioValues.id}
                        value={this.state.radioValues.text}
                        onChange={this.handleRadioChange}
                        checked={this.state.radioValues.selected}
                    />
                    <span className="checkmark"></span>
                </label>
                {this.state.radioValues.showTextBox && (
                    <textarea
                        className="other-textarea"
                        onChange={this.onOtherCommentChange}
                        value={this.state.textCommentVal}
                        type="text"
                        placeholder={this.state.radioValues.textBoxPlaceholder}
                        onBlur={this.onOtherCommentBlur}
                    />
                )}
            </>
        );
    }
}

export default RadioTextDescription;