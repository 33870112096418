import React, { Component } from "react";
import ideaImage from "../../img/ideation/idea1.png";
import { _CONSTANTS } from "../../helpers/constants";

class ApproveSuccess extends Component {
    render() {
        return (
            <>
                <div className="ideaSuccessMain">
                    <div className="submittedImage">
                        <img src={ideaImage} alt={_CONSTANTS.IDEASTATUS.SUBMITTED} />
                    </div>
                    <div className="submittedDetails">
                        <div className="submittedDetails_header">{this.props.title}</div>
                        {!this.props.isOtherAreaSelected && (
                            <div className="submittedDetails_subheader">
                                We have noted your recommendation and the idea will be processed accordingly
                            </div>
                        )}
                        <div className="submittedDetails_statusBubble">
                            <div className="submittedDetails_statusBubble_header">
                                This Idea is sent to the leader for review
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ApproveSuccess;