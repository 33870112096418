import React, { Component } from "react";
import { connect } from "react-redux";
import draftIcon from "../../img/draftIdea.svg";
import publishedIdeaIcon from "../../img/publishedIdea.svg";
import likeIcon from "../../img/like-icon.svg";
import { fetchProfileCorner } from "../../redux/actions/user-profile";
import { _CONSTANTS } from "../../helpers/constants";

class ProfilePersonalCorner extends Component {
  componentDidMount() {
    this.props.fetchProfileCorner(this.props.userData.id);
  };

  handleItemClick = key => {
    if (key.toLowerCase().indexOf("draft") > -1) {
      window.location.href = '/draftidea';
    } else if (key.toLowerCase().indexOf(_CONSTANTS.IDEASTATUS.SUBMITTED.toLowerCase()) > -1) {
      window.location.href = '/submittedidea';
    }
  };

  render() {
    const profileCorner = this.props.profileCorner.data || {};

    return (
      <div className="personal-corner">
        <div className="headerRow">
          <div className="header-text">Your corner</div>
        </div>
        <div className="personal-corner_overview">
          {Object.keys(profileCorner).map((key, index) => {
            let objectKey = profileCorner[key];
            const itemImg =
              key.toLowerCase().indexOf("draft") > -1
                ? draftIcon
                : key.toLowerCase().indexOf(_CONSTANTS.IDEASTATUS.SUBMITTED.toLowerCase()) > -1
                  ? publishedIdeaIcon
                  : likeIcon;
            const itemName =
              key.toLowerCase().indexOf("draft") > -1
                ? "Draft Ideas"
                : key.toLowerCase().indexOf(_CONSTANTS.IDEASTATUS.SUBMITTED.toLowerCase()) > -1
                  ? "Submitted Ideas"
                  : "Appreciations received";
            return (
              <div className="overview-item" key={itemName + index}
                onClick={() => this.handleItemClick(key)}>
                <img src={itemImg} alt="draftIdea" />
                <div className="overview-item_detail">
                  <p className="overview-item_detail-count">
                    {objectKey === null ? "0" : objectKey}
                  </p>
                  <p>{itemName}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    FETCH_PERSONAL_CORNER_STATUS:
      state.userProfile.meta.FETCH_PERSONAL_CORNER_STATUS,
    profileCorner: state.userProfile.data.profileCorner,
  };
};

const mapDispatchToProps = {
  fetchProfileCorner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePersonalCorner);
