import React, { Component } from "react";
import MediaQuery from "react-responsive";
import editIcon from "../../img/editPencil.svg";
import makeItRain from "../../img/make-it-rain.svg";
import ProfileInfoBanner from "./profileInfoBanner";
import ProfileCurrency from "./profileCurrency";
import ProfileInvestedIdeas from "./profileInvestedIdeas";
import ProfileTransactionHistory from "./profileTransactionHistory";
import { getNameInitials } from "../../helpers/utils";

class ProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWithdrawModal: false,
      profileUpdateRequired: false
    };
  }
  updateProfileCurrency = () => {
    this.setState({ profileUpdateRequired: true });
  }
  disableProfileUpdate = () => {
    this.setState({ profileUpdateRequired: false });
  }
  render() {
    const userInfo = this.props.userInfo;
    const makeItRainBanner = {
      img: makeItRain,
      title: "FAQ",
      description:
        "Know how to earn more seeds and coins. We will also take you on a journey for a way to manage them effectively!",
      ctaIcon: true,
      ctaHref: "/faq",
      ctaText: "",
      customClass: "",
    };
    return (
      <>
        <div className="profile-seed-container">
          <MediaQuery maxWidth={767}>
            <div className="profile-intro-section">
              {/* <img src={userInfo.imageUrl} alt="user" /> */}
              <div className="userCreds">{getNameInitials(userInfo.firstName + " " + userInfo.lastName)}</div>
              <div className="profile-intro-section_userDetail">
                <div className="userDetail_name">
                  {userInfo.firstName + " " + userInfo.lastName}
                  {/* <a className="user-edit-icon" href="/dashboard">
                    <img src={editIcon} alt="edit-icon" />
                  </a> */}
                </div>
                {/* {userInfo.description && <p>{userInfo.description}</p>} */}
              </div>
            </div>
          </MediaQuery>
          <ProfileCurrency userInfo={userInfo} profileUpdateRequired={this.state.profileUpdateRequired} disableProfileUpdate={this.disableProfileUpdate.bind(this)} />
          <MediaQuery maxWidth={767}>
            <ProfileTransactionHistory userInfo={userInfo} />
            <ProfileInvestedIdeas userInfo={userInfo} updateProfileCurrency={this.updateProfileCurrency.bind(this)} />
          </MediaQuery>
          <ProfileInfoBanner data={makeItRainBanner} bannerImg={makeItRain} />
        </div>
        <MediaQuery minWidth={768}>
          <div className="profile-info-container">
            <div className="profile-intro-section">
              {/* <img src={userInfo.imageUrl} alt="user" /> */}
              <div className="userCreds">{getNameInitials(userInfo.firstName + " " + userInfo.lastName)}</div>
              <div className="profile-intro-section_userDetail">
                <div className="userDetail_name">
                  {userInfo.firstName + " " + userInfo.lastName}
                  {/* <a className="user-edit-icon" href="/dashboard">
                    <img src={editIcon} alt="edit-icon" />
                  </a> */}
                </div>
                {/* {userInfo.description && <p>{userInfo.description}</p>} */}
              </div>
            </div>
            <ProfileTransactionHistory userInfo={userInfo} />
            <ProfileInvestedIdeas userInfo={userInfo} updateProfileCurrency={this.updateProfileCurrency.bind(this)} />
          </div>
        </MediaQuery>
      </>
    );
  }
}
export default ProfileInfo;
