import React, { Component } from "react";
// import ideaLight from "../../img/idea-light.svg";
// import ideaDark from "../../img/idea-dark.svg";

class GlobalSearchAutoCompleteList extends Component {
  htmlText = (data) => {
    let divContainer = document.createElement("div");
    const termArr = this.props.term.split(" ");
    let str = data;
    termArr.forEach((term) => {
      if (term !== "") {
        str = str.replace(new RegExp(term, "gi"), (match) => `<b>${match}</b>`);
      }
    });
    return (divContainer.innerHTML = str);
  };

  render() {
    const { ideaName, ideaID } = this.props.list;
    return (
      <li className="global-search-results">
        <a
          href={"/idea/" + ideaID}
          tabIndex={0}
          role="menuitem"
          className="dropdown-item"
        >
          {/* <img src={ideaDark} alt="idea" className="idea-dark" />
          <img src={ideaLight} alt="idea" className="idea-light" /> */}
          <p dangerouslySetInnerHTML={{ __html: this.htmlText(ideaName) }}></p>
        </a>
      </li>
    );
  }
}
export default GlobalSearchAutoCompleteList;
