import { Component } from "react";
import { getNameInitials } from "../../helpers/utils";
import IdeaListImage from "../../img/ideaPlaceholder.svg";
import seedIcon from '../../img/seedIcon.svg';

class GamifiedIdea extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        this.props.gotoIdeaDetails(this.props.idea.ideaID);
    }

    render() {
        const { ideaID, ideaName, likesCount, amountInvested, userName = '', teamName } = this.props.idea;
        const byValue = (teamName !== '' && teamName !== null) ? teamName : userName ? userName : 'SEED user';
        return <div className="idea-cont" key={ideaID} onClick={this.handleClick} >
            <div className="idea-cont_image">
                <img
                    src={IdeaListImage}
                    alt="idea list image"
                />
            </div>
            <div className="idea-cont_detail">
                <div className="ideaHeading">
                    {likesCount !== undefined && <p>{likesCount} {likesCount > 1 ? 'likes': 'like'}</p>}
                    {amountInvested !== undefined && <p>
                        <img
                            src={seedIcon}
                            alt="seeds-icon"
                        /> 
                        {amountInvested}
                        </p>}
                </div>
                <div className="ideaDescription">
                    <div className="ideaDescription_description">
                        {ideaName && ideaName.length > 45
                            ? `${ideaName.substring(0, 40)} ...`
                            : ideaName}
                    </div>
                    <div className="ideaDescription_creator">
                    <div className="ideaDescription_creator-image 1">
                            <p>By: {byValue}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}



export default GamifiedIdea;
