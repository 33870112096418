export const FETCH_IDEAS_FRM_ARND_PENDING = "FETCH_IDEAS_FRM_ARND_PENDING";
export const FETCH_IDEAS_FRM_ARND_FULFILLED = "FETCH_IDEAS_FRM_ARND_FULFILLED";
export const FETCH_IDEAS_FRM_ARND_REJECTED = "FETCH_IDEAS_FRM_ARND_REJECTED";

export const FETCH_IDEA_DETAILS_PENDING = "FETCH_IDEA_DETAILS_PENDING";
export const FETCH_IDEA_DETAILS_FULFILLED = "FETCH_IDEA_DETAILS_FULFILLED";
export const FETCH_IDEA_DETAILS_REJECTED = "FETCH_IDEA_DETAILS_REJECTED";

export const FETCH_GLOBAL_RESULTS_PENDING = "FETCH_GLOBAL_RESULTS_PENDING";
export const FETCH_GLOBAL_RESULTS_FULFILLED = "FETCH_GLOBAL_RESULTS_FULFILLED";
export const FETCH_GLOBAL_RESULTS_REJECTED = "FETCH_GLOBAL_RESULTS_REJECTED";

export const FETCH_AUTOCOMPLETE_RESULTS_PENDING =
  "FETCH_AUTOCOMPLETE_RESULTS_PENDING";
export const FETCH_AUTOCOMPLETE_RESULTS_FULFILLED =
  "FETCH_AUTOCOMPLETE_RESULTS_FULFILLED";
export const FETCH_AUTOCOMPLETE_RESULTS_REJECTED =
  "FETCH_AUTOCOMPLETE_RESULTS_REJECTED";

export const INVEST_IN_IDEA_PENDING = "INVEST_IN_IDEA_PENDING";
export const INVEST_IN_IDEA_FULFILLED = "INVEST_IN_IDEA_FULFILLED";
export const INVEST_IN_IDEA_REJECTED = "INVEST_IN_IDEA_REJECTED";

export const FETCH_IDEA_INVESTMENT_DETAILS_PENDING =
  "FETCH_IDEA_INVESTMENT_DETAILS_PENDING";
export const FETCH_IDEA_INVESTMENT_DETAILS_FULFILLED =
  "FETCH_IDEA_INVESTMENT_DETAILS_FULFILLED";
export const FETCH_IDEA_INVESTMENT_DETAILS_REJECTED =
  "FETCH_IDEA_INVESTMENT_DETAILS_REJECTED";

export const FETCH_CREATE_IDEA_PENDING = "FETCH_CREATE_IDEA_PENDING";
export const FETCH_CREATE_IDEA_FULFILLED = "FETCH_CREATE_IDEA_FULFILLED";
export const FETCH_CREATE_IDEA_REJECTED = "FETCH_CREATE_IDEA_REJECTED";

export const FETCH_CHALLENGE_IDEA_PENDING = "FETCH_CHALLENGE_IDEA_PENDING";
export const FETCH_CHALLENGE_IDEA_FULFILLED = "FETCH_CHALLENGE_IDEA_FULFILLED";
export const FETCH_CHALLENGE_IDEA_REJECTED = "FETCH_CHALLENGE_IDEA_REJECTED";

export const FETCH_DRAFT_IDEA_PENDING = "FETCH_DRAFT_IDEA_PENDING";
export const FETCH_DRAFT_IDEA_FULFILLED = "FETCH_DRAFT_IDEA_FULFILLED";
export const FETCH_DRAFT_IDEA_REJECTED = "FETCH_DRAFT_IDEA_REJECTED";

export const ADD_IDEA_DETAILS = "ADD_IDEA_DETAILS";
export const ADD_USER_IDEA_TAGS = "ADD_USER_IDEA_TAGS";
export const ADD_MARKET_DETAILS = "ADD_MARKET_DETAILS";
export const ADD_IDEA_TYPE = "ADD_IDEA_TYPE";
export const ADD_PROBLEM_TYPE = "ADD_PROBLEM_TYPE";
export const ADD_TOOLS = "ADD_TOOLS";
export const ADD_TEAM_NAME = 'ADD_TEAM_NAME';
export const ADD_TALENT_DETAILS = "ADD_TALENT_DETAILS";
export const ADD_INDUSTRY_DETAILS = "ADD_INDUSTRY_DETAILS";
export const ADD_DOMAIN_DETAILS = "ADD_DOMAIN_DETAILS";
export const ADD_DRAFT_ID = "ADD_DRAFT_ID";

export const ADD_OFFERING_PORTFOLIO = "ADD_OFFERING_PORTFOLIO";
export const ADD_TALENT_OFFERING = "ADD_TALENT_OFFERING";
export const ADD_ARCHETYPE = "ADD_ARCHETYPE";
export const ADD_SECTOR = "ADD_SECTOR";

export const FETCH_SUBMITTED_IDEAS_PENDING = "FETCH_SUBMITTED_IDEAS_PENDING";
export const FETCH_SUBMITTED_IDEAS_FULFILLED =
  "FETCH_SUBMITTED_IDEAS_FULFILLED";
export const FETCH_SUBMITTED_IDEAS_REJECTED = "FETCH_SUBMITTED_IDEAS_REJECTED";

export const FETCH_IDEA_SUBMISSION_PENDING = "FETCH_IDEA_SUBMISSION_PENDING";
export const FETCH_IDEA_SUBMISSION_FULFILLED =
  "FETCH_IDEA_SUBMISSION_FULFILLED";
export const FETCH_IDEA_SUBMISSION_REJECTED = "FETCH_IDEA_SUBMISSION_REJECTED";

export const FETCH_EVALUATE_DETAILS_PENDING = "FETCH_EVALUATE_DETAILS_PENDING";
export const FETCH_EVALUATE_DETAILS_FULFILLED =
  "FETCH_EVALUATE_DETAILS_FULFILLED";
export const FETCH_EVALUATE_DETAILS_REJECTED =
  "FETCH_EVALUATE_DETAILS_REJECTED";

export const FETCH_REVIEW_DETAILS_PENDING = "FETCH_REVIEW_DETAILS_PENDING";
export const FETCH_REVIEW_DETAILS_FULFILLED = "FETCH_REVIEW_DETAILS_FULFILLED";
export const FETCH_REVIEW_DETAILS_REJECTED = "FETCH_REVIEW_DETAILS_REJECTED";

export const GET_MEMBER_DETAILS_PENDING = "GET_MEMBER_DETAILS_PENDING";
export const GET_MEMBER_DETAILS_FULFILLED = "GET_MEMBER_DETAILS_FULFILLED";
export const GET_MEMBER_DETAILS_REJECTED = "GET_MEMBER_DETAILS_REJECTED";
