import { Component } from "react";
import challengeIcon from "../img/challenge-icon.svg";

class IdeaToChallenge extends Component {

    render() {
        const { ideaDetails } = { ...this.props };
        return <>
            {ideaDetails.challengeId &&
                <>
                    <div className="challenge-link-section">
                        <img
                            className="challenge-link-section_icon"
                            src={challengeIcon}
                            alt="challenge-icon"
                        />
                        <p>This Idea is part of challenge</p>
                    </div>
                    <a href={`/challenge?id=${ideaDetails.challengeId}`} className="challenge-link-section_link">{ideaDetails.challengeName}</a>
                </>
            }
        </>
    }
}

export default IdeaToChallenge;
