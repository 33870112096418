import config from "../../helpers/api-urls";
import { _CONSTANTS } from "../../helpers/constants";
import { makeCall } from "../../helpers/httpUtil";

export const getTopIdeators = (recordCount) => {
    /* const payloadUrl = `${config.topIdeators.requestPayload.url}${recordCount}`;
    return {
        type: "GET_TOP_IDEATORS",
        payload: makeCall(payloadUrl, _CONSTANTS.HTTPMETHOD.GET)
    }; */
    const payloadUrl = `${config.topTeamIdeators.requestPayload.url}${recordCount}`;
    return {
        type: "GET_TOP_IDEATORS",
        payload: makeCall(payloadUrl, _CONSTANTS.HTTPMETHOD.GET)
    };
};

export const getTopTeamIdeators = (recordCount) => {
    const payloadUrl = `${config.topTeamIdeators.requestPayload.url}${recordCount}`;
    return {
        type: "GET_TOP_TEAM_IDEATORS",
        payload: makeCall(payloadUrl, _CONSTANTS.HTTPMETHOD.GET)
    };
};

export const getMostLikedIdeas = (recordCount) => {
    const payloadUrl = `${config.mostLikedIdeas.requestPayload.url}${recordCount}`;
    return {
        type: "GET_MOST_LIKED_IDEAS",
        payload: makeCall(payloadUrl, _CONSTANTS.HTTPMETHOD.GET)
    };
};

export const getMostInvestedIdeas = (recordCount) => {
    const payloadUrl = `${config.mostInvestedIdeas.requestPayload.url}${recordCount}`;
    return {
        type: "GET_MOST_INVESTED_IDEAS",
        payload: makeCall(payloadUrl, _CONSTANTS.HTTPMETHOD.GET)
    };
};
