import React, { Component } from "react";
// import React, {  } from "react";
import logo from "../../img/deloitte-logo.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GlobalSearchAutoComplete from "../GlobalSearchAutoComplete/globalSearchAutoComplete";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import User from "../../img/ideation/challenge.jpg";
import searchIcon from "../../img/search.svg";
import backIcon from "../../img/back.svg";
import classNames from "classnames";
import { getBaseUrl, getNameInitials } from "../../helpers/utils";
import { fetchProfileCurrency } from "../../redux/actions/user-profile";
import { connect } from "react-redux";
import seedIcon from '../../img/seedIcon.svg';
import { hasIdeaAccess } from "../../helpers/ideaAuth";
import axios from "axios";

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [{ itemtext: "Home", itemLink: "/home" }],
      showCancelDraftConfirmation: false,
      showEvaluateModal: false,
      showMobileMenu: false,
      searchShown: false,
      hideHeaderCrossBtn: typeof this.props.hideHeaderCrossBtn !== "undefined" ? this.props.hideHeaderCrossBtn : false,
      // showAMCModal: localStorage.getItem("amcUser") === null,
      // isAMCUser: localStorage.getItem("amcUser") !== null && localStorage.getItem('amcUser') === 'true' ? true : false
    };
  }

  componentDidMount() {
    this.props.fetchProfileCurrency(this.props.values.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hideHeaderCrossBtn !== this.props.hideHeaderCrossBtn) {
      this.setState({ hideHeaderCrossBtn: this.props.hideHeaderCrossBtn });
    }
    if(prevProps.values !== this.props.values){
      this.props.fetchProfileCurrency(this.props.values.id);
    }
  }

  cancelDraft = () => {
    // window.location.href = '/';
    this.setState({ showCancelDraftConfirmation: true });
  };

  cancelEvaluateIdea = () => {
    // window.location.href = '/';
    this.setState({ showEvaluateModal: true });
  };

  discardIdea = () => {
    this.props.saveDraftDetails();
  };

  hideDiscardModel = () => {
    this.setState({ showCancelDraftConfirmation: false });
  };

  // hideAMCModel = () => {
  //   this.setState({ showAMCModal: false });
  //   localStorage.setItem('amcUser', true);
  // };

  // handleNotAMC = () => {
  //   this.setState({ showAMCModal: false });
  //   localStorage.setItem('amcUser', false);
  //   window.location.href = "/sorrycomebackagain";
  // }

  hideEvaluateIdea = () => {
    this.setState({ showEvaluateModal: false });
    window.location.href = `/ideaSubmission?isDraft=true`;
  };

  toggleMobileMenu = (isOpen) => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
    if (isOpen) {
      document.querySelector('body').classList.add("modal-open");
    } else {
      document.querySelector('body').classList.remove("modal-open");
    }
  };

  openMobileSearch = () => {
    this.setState({ searchShown: true });
    document.querySelector('body').classList.add("modal-open");
  };

  handleLogout = () => {
    const baseUrl = getBaseUrl();
    // const ipAddress = baseUrl.ipAddress;
    const origin = window.location.origin;
    const reqUrl = `https://seed-api-dev.auth.us-east-2.amazoncognito.com/logout?client_id=68kja94n680cvav8n6eul2hkqm&response_type=code&logout_uri=${origin}/loginredirect&redirect_uri=${origin}/loginredirect`
    axios.get(
      reqUrl,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      );

    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    window.location.href = "/dashboard"
  };

  closeSearch = () => {
    this.setState({ searchShown: false });
    document.querySelector('body').classList.remove("modal-open");
  };

  render() {
    const values = this.props.values;
    const rewardsBalance = this.props.currency?.rewardsBalance;
    const showSearch =
      typeof this.props.showSearch !== "undefined"
        ? this.props.showSearch
        : true;
    const isLoggedIn = values && values.firstName !== "";
    const showUser =
      typeof this.props.showUser !== "undefined" ? this.props.showUser : false;
    const discardDraft =
      typeof this.props.discardDraft !== "undefined"
        ? this.props.discardDraft
        : false;
    const myStyle = {
      color: "#00354c",
      width: "30px",
      height: "30px",
    };

    return (
      <>
        <header className="main-header">
          <section className={`header ${this.props.pageName} ${this.state.searchShown ? 'is-search-visible' : ''}`} >
            <article
              className={classNames("headerLeft", {
                'is-search-visible': this.state.searchShown
              })}>
              {this.props.pageName === "createIdea" && !this.state.hideHeaderCrossBtn && (
                <img onClick={this.cancelDraft} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {this.props.pageName === "leader-review" && !this.state.hideHeaderCrossBtn && (
                <img onClick={() => window.location.href = '/ideaSubmission'} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {this.props.pageName === "evaluate-idea" && !this.state.hideHeaderCrossBtn && (
                <img onClick={this.cancelEvaluateIdea} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {this.props.pageName === "evaluate-idea-view" && !this.state.hideHeaderCrossBtn && (
                <img onClick={() => window.location.href = this.props.crossBtnHref} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {(this.props.pageName === "submittedIdea" || this.props.pageName === 'challengeIdeaList' || this.props.pageName === "userProfile" || this.props.pageName === "draftIdea") && (
                <img onClick={() => window.location.href = "/dashboard"} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {this.props.pageName === "assignRole" && (
                <img onClick={() => window.location.href = "/assignRole"} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {this.props.pageName === "ideaDetail" && (
                <img onClick={() => window.location.href = "/idealist"} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {this.props.pageName === "challengeDetail" && (
                <img onClick={() => window.location.href = (values.userType === 'PANELLEAD' || values.userType === 'ADMIN') ? "/challengeList/admin" : "/challengeList/user"} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              {this.props.pageName === "seedInsights" && (
                <img onClick={() => window.location.href = "/user-profile"} className="mobile-backBtn" src={backIcon} alt="back" />
              )}
              <div className="logo clickable" onClick={() => {
                window.location.href = "/dashboard";
              }}>
                <img src={logo} alt="Seed Logo" />
              </div>
              {(this.props.pageName !== "createIdea" && this.props.pageName !== 'evaluate-idea' && this.props.pageName !== 'evaluate-idea-view' && this.props.pageName !== 'leader-review') && (
                <div className="headerLinks">
                  <a
                    href="/dashboard"
                    className={
                      this.props.pageName === "home"
                        ? "headerLink active"
                        : "headerLink"
                    }
                  >
                    Dashboard
                  </a>
                  {( values.userType === 'ADMIN') && (
                    <a
                      href={'/assignRole'}
                      className={
                        this.props.pageName === "assignRole"
                          ? "headerLink active"
                          : "headerLink"
                      }
                    >
                      Update Role
                    </a>
                    )}
                  { hasIdeaAccess(this.props.values) && 
                    <a
                      href="/idealist"
                      className={
                        this.props.pageName === "ideas"
                          ? "headerLink active"
                          : "headerLink"
                      }
                    >
                      Ideas
                    </a>
                  }
                  <a
                    // href="/challengeList/admin"
                    href={(values.userType === 'PANELLEAD' || values.userType === 'ADMIN') ? "/challengeList/admin" : "/challengeList/user"}
                    className={
                      this.props.pageName === "challengeList"
                        ? "headerLink active"
                        : "headerLink"
                    }
                  >
                    Challenges
                  </a>
                  {(values.userType === 'PANELLEAD' || values.userType === 'PANELIST' || values.userType === 'ADMIN') && (
                    <a
                      href={'/ideaSubmission'}
                      className={
                        this.props.pageName === "idea-submission"
                          ? "headerLink active"
                          : "headerLink"
                      }
                    >
                      All Submitted Ideas
                    </a>
                  )}
                  {/* {(values.userType === 'ADMIN') && (
                    <a
                      href={'/events'}
                      className={
                        this.props.pageName === "events"
                          ? "headerLink active"
                          : "headerLink"
                      }
                    >
                      Commerce Day Events
                    </a>
                  )}

                    <a
                      href={'/events-leaderboard'}
                      className={
                        this.props.pageName === "events-leaderboard"
                          ? "headerLink active"
                          : "headerLink"
                      }
                    >
                      Commerce Day Leaderboard
                    </a> */}
                </div>
              )}
            </article>
            <article className={classNames("headerRight", {
              'is-search-visible': this.state.searchShown
            })}>
              {
              !this.state.showMobileMenu && !discardDraft && this.props.pageName !== "evaluate-idea" && this.props.pageName !== "evaluate-idea-view" && this.props.pageName !== "leader-review" && hasIdeaAccess(values)
              && (
                <button
                  className={classNames("btnSearch-mobile", {
                    'is-search-visible': this.state.searchShown
                  })}
                  onClick={() => this.openMobileSearch()}>
                  <img src={searchIcon} alt="search" />
                </button>
              )}
              {showSearch && hasIdeaAccess(values) && <GlobalSearchAutoComplete closeSearch={this.closeSearch.bind(this)} userData={values} />}
              {!discardDraft && this.props.pageName !== "leader-review" && this.props.pageName !== "evaluate-idea" && this.props.pageName !== "evaluate-idea-view" && (
                <div className={classNames("IconSection", {
                  'is-search-visible': this.state.searchShown
                })} >
                  <div className="MenuICon">
                    {this.state.showMobileMenu ? (
                      <MdClose
                        className="CloseIcon"
                        onClick={() => this.toggleMobileMenu(false)}
                        style={myStyle}
                      />
                    ) : (
                      <HiOutlineMenuAlt3
                        className="MenuICon"
                        onClick={() => this.toggleMobileMenu(true)}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="hel">
                {this.state.showMobileMenu ? (
                  <article className="headerLeft1">
                    {this.props.pageName !== "createIdea" && this.props.pageName !== "leader-review" && this.props.pageName !== "evaluate-idea" && this.props.pageName !== "evaluate-idea-view" && (
                      <div className="headerLinks1 ">
                        {!isLoggedIn ? (
                          <div className="headerbutton" onClick={() => {
                            window.location.href = "/login";
                          }}>
                            <span className="Name">Login</span>
                          </div>
                        ) : (
                          <>
                            {showUser && (
                              <div className="headerbutton" onClick={() => {
                                window.location.href = "/user-profile";
                              }}>
                                {/* <img className="UserProfileimg" src={values.imageUrl}
                                  alt={values.name}
                                  title={values.name}></img> */}
                                <div className="userCreds">{getNameInitials(values.firstName + " " + values.lastName)}</div>
                                <span className="Name">{values.firstName + " " + values.lastName}</span>
                              </div>
                            )}
                          </>
                        )}
                        <a
                          href="/dashboard"
                          className={
                            this.props.pageName === "home"
                              ? "headerLink active"
                              : "headerLink"
                          }
                        >
                          Dashboard
                        </a>
                        {/* {(values.userType === 'PANELLEAD' || values.userType === 'PANELIST' || values.userType === 'ADMIN') && ( */}
                        {(values.userType === 'ADMIN') && ( 
                    <a
                      href={'/assignRole'}
                      className={
                        this.props.pageName === "assignRole"
                          ? "headerLink active"
                          : "headerLink"
                      }
                    >
                      Assign Role
                    </a>
                    )}
                        <a
                          href="/idealist"
                          className={
                            this.props.pageName === "ideas"
                              ? "headerLink active"
                              : "headerLink"
                          }
                        >
                          Ideas
                        </a>
                        <a
                          // href="/challengeList/admin"
                          href={(values.userType === 'PANELLEAD' || values.userType === 'ADMIN') ? "/challengeList/admin" : "/challengeList/user"}
                          className={
                            this.props.pageName === "challenges"
                              ? "headerLink active"
                              : "headerLink"
                          }
                        >
                          Challenges
                        </a>
                        {(values.userType === 'PANELLEAD' || values.userType === 'PANELIST' || values.userType === 'ADMIN') && (
                          <a
                            href={'/ideaSubmission'}
                            className={
                              this.props.pageName === "idea-submission"
                                ? "headerLink active"
                                : "headerLink"
                            }
                          >
                            All Submitted Ideas
                          </a>
                        )}
                      </div>
                    )}
                  </article>
                ) :
                  null}
              </div>
              {!isLoggedIn ? (
                <div className="loginBTN">
                  <button className="btn btnPrimary" onClick={() => {
                    window.location.href = "/login";
                  }}>Login</button>
                </div>
              ) : (
                <>
                  {showUser && (
                    <>
                    <div className="userDiv">

                      <div
                        className="user"
                        onClick={() => {
                          window.location.href = "/user-profile";
                        }}
                      >
                        {/* <img
                          src={values.imageUrl}
                          alt={values.name}
                          title={values.name}
                        /> */}
                        <div className="userCreds">{getNameInitials(values.firstName + " " + values.lastName)}</div>
                      </div>
                    </div>
                    {/* <button className="btn btn-primary createChallenge" onClick={() => this.handleLogout()}>Log out</button> */}
                    </>
                  )}
                </>
              )}
              {discardDraft && !this.state.hideHeaderCrossBtn && (
                <div className="discardDraft">
                  <button
                    className="close"
                    id="btnDraftIdeaClose"
                    title="Discard Changes"
                    onClick={this.cancelDraft}
                  ></button>

                  <Modal
                    show={this.state.showCancelDraftConfirmation}
                    onHide={this.hideDiscardModel}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Discard idea!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="confirmMessage">
                        Your idea is saved as draft. Would you like to navigate away?
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btnText header-rm-20"
                        onClick={this.hideDiscardModel}
                      >
                        Continue creating idea
                      </button>
                      <Button variant="primary" onClick={this.discardIdea}>
                        Save and Navigate
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              )}
              {this.props.pageName === "leader-review" && !this.state.hideHeaderCrossBtn && (
                <div className="discardDraft">
                  <button
                    className="close"
                    id="btnDraftIdeaClose"
                    title="Discard Changes"
                    onClick={() => window.location.href = `/idea/${this.props.ideaId}`}
                  ></button>
                </div>
              )}
              {this.props.pageName === "evaluate-idea" && !this.state.hideHeaderCrossBtn && (
                <div className="discardDraft">
                  {/* <button
                    className="btn btnText"
                    id="btnDraftEvaluateIdeaClose"
                    title="Discard Changes"
                  // onClick={this.saveEvaluateDetails}
                  >Save</button> */}
                  <button
                    className="close"
                    id="btnDraftIdeaClose"
                    title="Discard Changes"
                    onClick={this.cancelEvaluateIdea}
                  ></button>

                  <Modal
                    show={this.state.showEvaluateModal}
                    onHide={this.hideEvaluateIdea}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Close</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="confirmMessage">
                        Are you sure you want to close? Your changes will not be saved if you close.
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btnText header-rm-20"
                        onClick={this.hideEvaluateIdea}
                      >
                        Save Instead
                      </button>
                      <Button variant="primary" onClick={() => { window.location.href = '/ideaSubmission' }}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              )}
              {this.props.pageName === "evaluate-idea-view" && !this.state.hideHeaderCrossBtn && (
                <div className="discardDraft">
                  <button
                    className="close"
                    id="btnDraftIdeaClose"
                    title="Discard Changes"
                    onClick={() => window.location.href = this.props.crossBtnHref}
                  ></button>
                </div>
              )}
              <Modal
                show={this.state.showAMCModal}
                onHide={this.handleNotAMC}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title>Are you part of AM&C?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="confirmMessage">
                    {/* For initial pilot, it is essential to know if you are part of AM&C for feedback purposes. Please choose the appropriate answer. */}
                    Please choose appropriate answer. The current pilot launch is for AM&C. C&M launch to follow soon!
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btnText header-rm-20"
                    onClick={this.handleNotAMC}
                  >
                    No
                  </button>
                  <Button variant="primary" onClick={this.hideAMCModel}>
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>
            </article>
          </section>
        </header>
      </>
    );


  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_PROFILE_CURRENCY_STATUS:
      state.userProfile.meta.FETCH_PROFILE_CURRENCY_STATUS,
    currency: state.userProfile.data.currency,
  };
};

const mapDispatchToProps = {
  fetchProfileCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(header);
