import React, { Component } from "react";
import { connect } from "react-redux";
import "../css/pages/evaluate-flow.scss";
import Header from "../components/global/header";
import OverallSummary from "../components/ideaapproval/overallSummary";
import IdeaSteps from "../components/ideation/ideaSteps";
import ApproveSuccess from "../components/ideaapproval/approveSuccess";
import { Modal } from "react-bootstrap";
import IdeaDetail from "../components/ideaapproval/ideaDetail";
import { getEvaluateDetails, fetchIdeaDetails } from "../redux/actions/ideas";
import ApprovalStep from "../components/ideaapproval/approvalStep";
import axios from "axios";
import config from "../helpers/api-urls";
import { showLoader, hideLoading } from "../helpers/utils";
import { _CONSTANTS } from "../helpers/constants";

class EvaluateIdeaPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousview: "",
      currentView: 1,
      saveLaterStepId: 0,
      showSaveDraftModal: false,
      leftArrowDisable: true,
      rightArrowDisable: false,
      leftArrowVisible: true,
      rightArrowVisible: true,
      disableSubmit: true,
      showIdeaDetail: false,
      basicData: {},
      ideaId: 0,
      panelId: 0,
      score: this.props.evaluateDetails.overallScore,
      previousQuestionID: 0,
      previousScore: 0,
      isOtherAreaSelected: false,
      questionCountStep1: 2,
      questionCountStep2: 1,
      questionCountStep3: 1,
      questionCountStep4: 1,
      questionCountStep5: 2,
      questionCount: 0,
      questionArrComb: [],
      isSubmitEnabled: true,
      questionAnswerObj: {},
      scoreQArr: [],
      relevanceScore: 0,
      fesibilityScore: 0,
      investmentScore: 0,
      impactScore: 0,
      hideHeaderCrossBtn: true,
      showOverallScore: true,
    };
  }

  componentDidMount() {
    showLoader();
    this.setState({ questionAnswerObj: {}, basicData: {} });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("id") && urlParams.has("panel")) {
      this.setState({
        ideaId: urlParams.get("id"),
        panelId: parseInt(urlParams.get("panel")),
      });
      this.props.fetchIdeaDetails(
        parseInt(urlParams.get("id")),
        this.props.userData.id
      );
      this.props.getEvaluateDetails(
        parseInt(urlParams.get("id")),
        parseInt(urlParams.get("panel"))
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.evaluateDetails !== this.props.evaluateDetails) {
      this.setState({ basicData: this.props.evaluateDetails });
      setTimeout(() => {
        this.setArrowData("");
        this.calculateScoreOnLoad();
      }, 700);

      this.props.evaluateDetails.reviewSteps.forEach((step, index) => {
        let count = 0;
        if (index === 0) {
          step.questions[0].options.forEach((option, index) => {
            if (option.selected) {
              count = option.subQuestions.length;
              if (option.showTextBox) {
                count = count + 1;
              }
              if (option.text === _CONSTANTS.IDEAPROBLEM.OTHERS) {
                this.setState({
                  showOverallScore: false,
                  isOtherAreaSelected: true,
                });
              } else if (
                option.text === _CONSTANTS.IDEAPROBLEM.CLIENTS ||
                option.text === _CONSTANTS.IDEAPROBLEM.DELOITTEINTERNAL
              ) {
                this.setState({ showOverallScore: true });
              }
              this.setState({ questionCountStep1: count });
            }
          });
        }
      });
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  /**
   * calculates the score on page load for draft mode
   */
  calculateScoreOnLoad = () => {
    if (Object.keys(this.state.basicData).length > 0) {
      let mainScoreArr = [],
        mainQuestionArr = [];
      this.state.basicData.reviewSteps.forEach((step) => {
        if (step.stepID === "1") {
          let parentId = "",
            questionId = "",
            score = 0,
            stepId = "",
            scoreArr = [],
            questionArr = [];
          stepId = step.stepID;
          parentId = step.questions[0].questionId;
          step.questions[0].options.forEach((option) => {
            if (!option.selected) {
              return;
            }
            if (option.text === _CONSTANTS.IDEAPROBLEM.OTHERS) {
              this.setState({ questionCountStep1: 1 });
            } else if (option.text === _CONSTANTS.IDEAPROBLEM.CLIENTS) {
              this.setState({ questionCountStep1: 2 }); //Making it 2 since we have hardcoded answer for question 1 to be clients
            } else if (
              option.text === _CONSTANTS.IDEAPROBLEM.DELOITTEINTERNAL
            ) {
              this.setState({ questionCountStep1: 2 });
            }

            if (option.showTextBox && option.textBoxValue !== "") {
              questionArr = [
                ...questionArr,
                ...[{ stepID: stepId, question: parentId }],
              ];
            }

            if (option.selected) {
              option.subQuestions.forEach((subQ) => {
                subQ.options.forEach((optn) => {
                  questionId = subQ.questionId;
                  if (optn.selected) {
                    score = optn.score;
                    if (subQ.questionType === "ratingSubtext") {
                      scoreArr = [
                        ...scoreArr,
                        ...[{ score: score, id: questionId, stepId: stepId }],
                      ];
                    }
                    questionArr = [
                      ...questionArr,
                      ...[{ stepID: stepId, question: questionId }],
                    ];
                  }
                });
              });
            }
          });
          mainScoreArr = [...mainScoreArr, ...scoreArr];
          mainQuestionArr = [...mainQuestionArr, ...questionArr];
        }
        if (step.stepID === "2" || step.stepID === "3" || step.stepID === "4") {
          let questionId = "",
            score = 0,
            stepId = "",
            scoreArr = [],
            questionArr = [];
          stepId = step.stepID;
          step.questions.forEach((question) => {
            questionId = question.questionId;
            question.options.forEach((option) => {
              if (option.selected) {
                score = option.score;
                scoreArr = [
                  ...scoreArr,
                  ...[{ score: score, id: questionId, stepId: stepId }],
                ];
                questionArr = [
                  ...questionArr,
                  ...[{ stepID: stepId, question: questionId }],
                ];
              }
            });
          });
          mainScoreArr = [...mainScoreArr, ...scoreArr];
          mainQuestionArr = [...mainQuestionArr, ...questionArr];
        }
        if (step.stepID === "5") {
          let questionId = "",
            score = 0,
            stepId = "",
            scoreArr = [],
            questionArr = [];
          stepId = step.stepID;
          step.questions.forEach((question) => {
            if (question.questionType === "radioTextDescription") {
              questionId = question.questionId;
              question.options.forEach((option) => {
                if (option.selected) {
                  score = option.score;
                  // scoreArr = [...scoreArr, ...[{ score: score, id: questionId, stepId: stepId }]];
                  questionArr = [
                    ...questionArr,
                    ...[{ stepID: stepId, question: questionId }],
                  ];
                }
              });
            }
          });
          mainScoreArr = [...mainScoreArr, ...scoreArr];
          mainQuestionArr = [...mainQuestionArr, ...questionArr];
        }
      });
      this.setState({ scoreQArr: [...this.state.scoreQArr, ...mainScoreArr] });
      this.handleQuestionSelection(mainQuestionArr);
    }
    setTimeout(() => {
      this.updateStepScoreDetails();
    }, 50);
  };

  /**
   * sets arrow data when view changes
   * @param {Number} viewData currently active view
   */
  setArrowData(viewData) {
    if (viewData === "") {
      viewData = 1;
    }
    this.setState({
      leftArrowVisible:
        viewData < this.state.basicData.breadcrumbs.length && viewData !== 1,
      rightArrowVisible: viewData !== this.state.basicData.breadcrumbs.length,
      leftArrowDisable: viewData === 1,
      // rightArrowDisable: viewData === this.state.basicData.breadcrumbs.length
      // leftArrowDisable: false ,
      rightArrowDisable: true,
    });
  }

  /**
   * next arrow click function
   */
  handleNextClick = () => {
    // showLoader();
    this.setState({ previousview: this.state.currentView });
    let self = this;
    let isSet = false;
    this.state.basicData.breadcrumbs.forEach((step, index) => {
      if (!isSet && self.state.currentView === step.breadcrumbID) {
        self.setState({
          currentView: this.state.basicData.breadcrumbs[index + 1].breadcrumbID,
        });
        self.setArrowData(
          this.state.basicData.breadcrumbs[index + 1].breadcrumbID
        );
        isSet = !isSet;
      }
    });

    if (this.state.currentView === 4) {
      this.setState({
        rightArrowVisible: false,
      });
    }
    if (this.state.isOtherAreaSelected) {
      this.setState({ currentView: this.state.basicData.breadcrumbs.length });
      self.setArrowData(this.state.basicData.breadcrumbs.length);
      this.handleOtherAreaSubmit();
    }
    setTimeout(() => {
      this.enableDisableArrow(this.state.questionArrComb);
      if (this.state.currentView !== 1) {
        this.setState({ hideHeaderCrossBtn: false });
      }
      if (this.state.isOtherAreaSelected) {
        this.setState({ hideHeaderCrossBtn: true });
      }
    }, 50);

    //save call to save review details here before resetting Obj
    const postUrl = config.saveEvaluateDetails.requestPayload.url;
    if (!this.state.isOtherAreaSelected) {
      if (Object.keys(this.state.questionAnswerObj).length > 0) {
        axios
          .post(postUrl, this.state.questionAnswerObj, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              idToken: `${localStorage.getItem("id_token")}`,
            },
          })
          .then((response) => {
            hideLoading();
          })
          .catch((error) => {
            console.log(error);
            hideLoading();
          });
      }
    }
    this.setState({ questionCount: 0, questionAnswerObj: {} });
  };

  handleOtherAreaSubmit = () => {
    showLoader();
    const postUrl = config.saveEvaluateDetails.requestPayload.url;
    let questionObj = {};
    // if (Object.keys(this.state.questionAnswerObj).length === 0) {
    const tgQuestionId =
      this.state.basicData.reviewSteps[0].questions[0].questionId;
    let tgQuestionSelectedId = 0;
    this.state.basicData.reviewSteps[0].questions[0].options.forEach(
      (option) => {
        if (option.selected) {
          tgQuestionSelectedId = option.id;
        }
      }
    );
    questionObj = {
      ideaID: parseInt(this.state.ideaId),
      isFinalStep: true,
      panelID: this.state.panelId || 1,
      reviews: [
        {
          customText: "",
          questionID: parseInt(tgQuestionId),
          score: 0,
          selectedID: parseInt(tgQuestionSelectedId),
          subQuestions: [],
        },
      ],
      stepID: parseInt(this.state.basicData.reviewSteps[0].stepID),
      summaryViewPojo: {
        acceptRecommendation: true,
        evaluatorConfidence: 0,
        ideaId: parseInt(this.state.ideaId),
        overridPanelRecommendation: true,
        panelEmail: "",
        panelId: this.state.panelId || 1,
        relevanceScore: 0,
        fesibilityScore: 0,
        investmentScore: 0,
        impactScore: 0,
        cumulativeScore: 0,
        isFirstReviewer: this.state.basicData.firstReviewer,
      },
    };
    // } else {
    //     questionObj = this.state.questionAnswerObj;
    // }
    this.setState({ questionAnswerObj: questionObj });

    if (Object.keys(questionObj).length > 0) {
      axios
        .post(postUrl, questionObj, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        })
        .then((response) => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          hideLoading();
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          hideLoading();
        });
    }
  };

  /**
   * submit evaluation click handle
   */
  handleSubmitEvaluation = (stepId) => {
    showLoader();
    //save call to save review details - final step
    const postUrl = config.saveEvaluateDetails.requestPayload.url;
    let questionObj = {};
    if (Object.keys(this.state.questionAnswerObj).length === 0) {
      const tgQuestionId =
        this.state.basicData.reviewSteps[4].questions[0].questionId;
      let tgQuestionSelectedId = 0;
      this.state.basicData.reviewSteps[4].questions[0].options.forEach(
        (option) => {
          if (option.selected) {
            tgQuestionSelectedId = option.id;
          }
        }
      );
      questionObj = {
        ideaID: parseInt(this.state.ideaId),
        isFinalStep: true,
        panelID: this.state.panelId || 1,
        reviews: [
          {
            customText: "",
            questionID: parseInt(tgQuestionId),
            score: 0,
            selectedID: parseInt(tgQuestionSelectedId),
            subQuestions: [],
          },
        ],
        stepID: parseInt(stepId),
        summaryViewPojo: {
          acceptRecommendation: true,
          evaluatorConfidence: Math.round((this.state.score / 20) * 100),
          ideaId: parseInt(this.state.ideaId),
          overridPanelRecommendation: true,
          panelEmail: "",
          panelId: this.state.panelId || 1,
          relevanceScore: this.state.relevanceScore,
          fesibilityScore: this.state.fesibilityScore,
          investmentScore: this.state.investmentScore,
          impactScore: this.state.impactScore,
          cumulativeScore: this.state.score,
          isFirstReviewer: this.state.basicData.firstReviewer,
        },
      };
      this.state.basicData.reviewSteps[4].questions.forEach((question) => {
        if (question.questionType === "radioTextDescription") {
          let reviewArr = {};
          question.options.forEach((option) => {
            if (option.selected) {
              reviewArr = {
                customText: "",
                questionID: parseInt(question.questionId),
                score: 0,
                selectedID: parseInt(option.id),
                subQuestions: [],
              };
            }
          });
          questionObj["reviews"].push(reviewArr);
        } else if (question.questionType === "textOnly") {
          const reviewArr = {
            customText: question.options[0].textBoxValue,
            questionID: parseInt(question.questionId),
            score: 0,
            selectedID: parseInt(question.options[0].id),
            subQuestions: [],
          };
          questionObj["reviews"].push(reviewArr);
        } else if (question.questionType === "conditionalCheckboxOption") {
          questionObj["summaryViewPojo"].panelEmail =
            question.options[0].textBoxValue;
        }
      });
    } else {
      questionObj = this.state.questionAnswerObj;
    }
    questionObj["isFinalStep"] = true;
    this.setState({ questionAnswerObj: questionObj });
    if (Object.keys(questionObj).length > 0) {
      axios
        .post(postUrl, questionObj, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        })
        .then((response) => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          // console.log(response)
          this.setState({ previousview: this.state.currentView });
          let self = this;
          let isSet = false;
          this.state.basicData.breadcrumbs.forEach((step, index) => {
            if (!isSet && self.state.currentView === step.breadcrumbID) {
              self.setState({
                currentView:
                  this.state.basicData.breadcrumbs[index + 1].breadcrumbID,
              });
              self.setArrowData(
                this.state.basicData.breadcrumbs[index + 1].breadcrumbID
              );
              isSet = !isSet;
            }
          });
          if (this.state.currentView === 6) {
            this.setState({ hideHeaderCrossBtn: true, leftArrowDisable: true });
          }
          hideLoading();
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          hideLoading();
        });
    }
  };

  saveforLaterSaveAction = () => {
    showLoader();
    let questionObj = {};
    if (Object.keys(this.state.questionAnswerObj).length === 0) {
      const tgQuestionId =
        this.state.basicData.reviewSteps[4].questions[0].questionId;
      let tgQuestionSelectedId = 0;
      this.state.basicData.reviewSteps[4].questions[0].options.forEach(
        (option) => {
          if (option.selected) {
            tgQuestionSelectedId = option.id;
          }
        }
      );
      questionObj = {
        ideaID: parseInt(this.state.ideaId),
        isFinalStep: false,
        panelID: this.state.panelId || 1,
        reviews: [
          {
            customText: "",
            questionID: parseInt(tgQuestionId),
            score: 0,
            selectedID: parseInt(tgQuestionSelectedId),
            subQuestions: [],
          },
        ],
        stepID: parseInt(this.state.saveLaterStepId),
        summaryViewPojo: {
          acceptRecommendation: true,
          evaluatorConfidence: Math.round((this.state.score / 20) * 100),
          ideaId: parseInt(this.state.ideaId),
          overridPanelRecommendation: true,
          panelEmail: "",
          panelId: this.state.panelId || 1,
          relevanceScore: this.state.relevanceScore,
          fesibilityScore: this.state.fesibilityScore,
          investmentScore: this.state.investmentScore,
          impactScore: this.state.impactScore,
          cumulativeScore: this.state.score,
          isFirstReviewer: this.state.basicData.firstReviewer,
        },
      };
    } else {
      questionObj = this.state.questionAnswerObj;
    }
    questionObj["isFinalStep"] = false;
    this.setState({ questionAnswerObj: questionObj });

    //save call for later evaluation
    const postUrl = config.saveEvaluateDetails.requestPayload.url;
    if (Object.keys(questionObj).length > 0) {
      axios
        .post(postUrl, questionObj, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        })
        .then((response) => {
          this.setState({ showSaveDraftModal: false });
          window.location.href = "/ideaSubmission?isDraft=true";
          hideLoading();
        })
        .catch((error) => {
          console.log(error);
          alert(error);
          hideLoading();
        });
    }
  };

  /**
   * saves review details
   * @param {Number} stepId selected step
   */
  saveForLaterAction = (stepId) => {
    this.setState({ showSaveDraftModal: true, saveLaterStepId: stepId });
  };

  /**
   * previous arrow click function
   */
  handlePreviousClick = () => {
    this.setState({ currentView: this.setState.previousview });
    let self = this;
    let isSet = false;
    this.state.basicData.breadcrumbs.forEach((step, index) => {
      if (!isSet && self.state.currentView === step.breadcrumbID) {
        self.setState({
          previousview:
            index <= 1
              ? ""
              : this.state.basicData.breadcrumbs[index - 2].breadcrumbID,
        });
        self.setState({
          currentView: this.state.basicData.breadcrumbs[index - 1].breadcrumbID,
        });
        self.setArrowData(
          this.state.basicData.breadcrumbs[index - 1].breadcrumbID
        );
        isSet = !isSet;
      }
    });

    setTimeout(() => {
      this.enableDisableArrow(this.state.questionArrComb);
      if (this.state.currentView === 1) {
        this.setState({ hideHeaderCrossBtn: true });
      }
    }, 50);
  };

  /**
   * score change handle on every question
   * @param {Number} score selected score
   * @param {Number} questionId selected question
   * @param {Number} stepId selected step
   */
  onScoreChange = (score, questionId, stepId) => {
    if (this.state.scoreQArr.length > 1) {
      this.state.scoreQArr.forEach((item) => {
        if (parseInt(item.id) === parseInt(questionId)) {
          this.setState({
            previousScore: item.score,
            previousQuestionID: parseInt(item.id),
          });
        }
      });
    }
    let tempArr = [];
    if (this.state.scoreQArr.length > 0) {
      tempArr = this.state.scoreQArr.filter(function (item) {
        return parseInt(item.id) !== parseInt(questionId);
      });
    }
    setTimeout(() => {
      let tempScore = this.state.score;
      if (this.state.previousQuestionID === parseInt(questionId)) {
        tempScore = tempScore - this.state.previousScore + score;
      } else {
        tempScore = tempScore + score;
      }
      this.setState({
        score: tempScore,
        previousScore: score,
        previousQuestionID: parseInt(questionId),
      });
    }, 50);

    this.setState({
      scoreQArr: [
        ...tempArr,
        ...[{ score: score, id: questionId, stepId: stepId }],
      ],
    });
    setTimeout(() => {
      this.updateStepScoreDetails();
    }, 50);
  };

  /**
   * score update for each step individually
   */
  updateStepScoreDetails = () => {
    let relevanceArr = [],
      feasibilityArr = [],
      investmentArr = [],
      impactArr = [];
    this.state.scoreQArr.forEach((item) => {
      if (parseInt(item.stepId) === 1) {
        relevanceArr.push(item);
      }
      if (parseInt(item.stepId) === 2) {
        feasibilityArr.push(item);
      }
      if (parseInt(item.stepId) === 3) {
        investmentArr.push(item);
      }
      if (parseInt(item.stepId) === 4) {
        impactArr.push(item);
      }
    });
    this.setState({
      relevanceScore: this.calculateStepScore(relevanceArr),
      fesibilityScore: this.calculateStepScore(feasibilityArr),
      investmentScore: this.calculateStepScore(investmentArr),
      impactScore: this.calculateStepScore(impactArr),
      score:
        this.calculateStepScore(relevanceArr) +
        this.calculateStepScore(feasibilityArr) +
        this.calculateStepScore(investmentArr) +
        this.calculateStepScore(impactArr),
    });
  };

  /**
   * calculates cumulative score for each step
   * @param {Array} arr array for items
   */
  calculateStepScore = (arr) => {
    let score = 0;
    arr.forEach((item) => {
      score = score + item.score;
    });
    return score;
  };

  /**
   * handles the area selection change
   * @param {boolean} flag flag for other area change
   * @param {Number} questionCount question count for selection
   */
  onHandleOtherArea = (flag, questionCount) => {
    this.setState({
      isOtherAreaSelected: flag,
      questionCountStep1: questionCount,
      questionAnswerObj: {},
      scoreQArr: {},
      showOverallScore: !flag,
    });
  };

  /**
   * resets the score and question
   */
  onScoreReset = () => {
    this.setState({ score: 0, previousScore: 0, previousQuestionID: 0 });
  };

  /**
   * handles the question array on every question change
   * @param {Array} arr selected array for the step and question
   */
  handleQuestionSelection = (arr) => {
    let tempArr = [];
    if (arr.length !== 0) {
      tempArr = [...this.state.questionArrComb, ...arr];
    } else if (arr.length == 0) {
      tempArr = [];
    }
    this.setState({ questionArrComb: tempArr });
    this.enableDisableArrow(tempArr);
  };

  /**
   * enable/disable the right arrow on question validation
   * @param {Array} qArr question array for validation
   */
  enableDisableArrow = (qArr) => {
    let tempArr = [];
    tempArr = qArr.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            parseInt(t.stepID) === this.state.currentView &&
            t.stepID === value.stepID &&
            t.question === value.question
        )
    );
    // tempArr.forEach((arr) => {
    //     if (parseInt(arr.stepID) === this.state.currentView) {
    //         this.setState({ questionCount: this.state.questionCount + 1 });
    //     }
    // });
    this.setState({ questionCount: tempArr.length });
    setTimeout(() => {
      let boolVal = true;
      if (this.state.currentView === 1) {
        boolVal =
          this.state.questionCount >= this.state.questionCountStep1
            ? false
            : true;
      }
      if (this.state.currentView === 2) {
        boolVal =
          this.state.questionCount >= this.state.questionCountStep2
            ? false
            : true;
      }
      if (this.state.currentView === 3) {
        boolVal =
          this.state.questionCount >= this.state.questionCountStep3
            ? false
            : true;
      }
      if (this.state.currentView === 4) {
        boolVal =
          this.state.questionCount >= this.state.questionCountStep4
            ? false
            : true;
      }
      if (this.state.currentView === 5) {
        const foundReview = this.state.questionAnswerObj.reviews?.find(review => review.questionID === 17);
        boolVal = this.state.questionCount >= this.state.questionCountStep5 && !foundReview?.customText;
        this.setState({ isSubmitEnabled: boolVal });
      }
      this.setState({
        rightArrowDisable: this.state.currentView === 5 ? true : boolVal,
      });
    }, 100);
  };

  /**
   * captures question answer details for parent
   * @param {number} questionId
   * @param {number} selectedId
   * @param {number} score
   * @param {number} stepId
   */
  questionAnswerDetails = (questionId, selectedId, score, stepId) => {
    let questionObj = {},
      reviewArr = {};
    if (Object.keys(this.state.questionAnswerObj).length === 0) {
      questionObj = {
        ideaID: parseInt(this.state.ideaId),
        isFinalStep: false,
        panelID: this.state.panelId || 1,
        reviews: [],
        stepID: parseInt(stepId),
        summaryViewPojo: null,
      };
    } else {
      questionObj = this.state.questionAnswerObj;
    }
    reviewArr = {
      customText: "",
      questionID: parseInt(questionId),
      score: score,
      selectedID: parseInt(selectedId),
      subQuestions: [],
    };
    questionObj["reviews"] = this.checkForDuplicates(
      questionObj,
      parseInt(questionId)
    );
    questionObj["reviews"].push(reviewArr);
    this.setState({ questionAnswerObj: questionObj });
    // console.log(questionObj);
  };

  /**
   * captures details for overall summary
   * @param {number} questionId
   * @param {number} selectedId
   * @param {number} stepId
   * @param {String} subCustomText
   */
  overallSummaryDetails = (questionId, selectedId, stepId, subCustomText) => {
    let questionObj = {},
      reviewArr = {};
    if (Object.keys(this.state.questionAnswerObj).length === 0) {
      const tgQuestionId =
        this.state.basicData.reviewSteps[4].questions[0].questionId;
      let tgQuestionSelectedId = 0;
      this.state.basicData.reviewSteps[4].questions[0].options.forEach(
        (option) => {
          if (option.selected) {
            tgQuestionSelectedId = option.id;
          }
        }
      );
      questionObj = {
        ideaID: parseInt(this.state.ideaId),
        isFinalStep: false,
        panelID: this.state.panelId || 1,
        reviews: [
          {
            customText: "",
            questionID: parseInt(tgQuestionId),
            score: 0,
            selectedID: parseInt(tgQuestionSelectedId),
            subQuestions: [],
          },
        ],
        stepID: parseInt(stepId),
        summaryViewPojo: {
          acceptRecommendation: true,
          evaluatorConfidence: Math.round((this.state.score / 20) * 100),
          ideaId: parseInt(this.state.ideaId),
          overridPanelRecommendation: true,
          panelEmail: "",
          panelId: this.state.panelId || 1,
          relevanceScore: this.state.relevanceScore,
          fesibilityScore: this.state.fesibilityScore,
          investmentScore: this.state.investmentScore,
          impactScore: this.state.impactScore,
          cumulativeScore: this.state.score,
          isFirstReviewer: this.state.basicData.firstReviewer,
        },
      };
    } else {
      questionObj = this.state.questionAnswerObj;
    }
    reviewArr = {
      customText: subCustomText || "",
      questionID: parseInt(questionId),
      score: 0,
      selectedID: parseInt(selectedId),
      subQuestions: [],
    };
    questionObj["reviews"] = this.checkForDuplicates(
      questionObj,
      parseInt(questionId)
    );
    questionObj["reviews"].push(reviewArr);
    this.setState({ questionAnswerObj: questionObj });
    // console.log(questionObj);
  };

  /**
   * captures details for overall summary on panel email update
   * @param {number} questionId
   * @param {number} selectedId
   * @param {String} customText
   * @param {number} stepId
   */
  handlePanelEmailUpdate = (questionId, selectedId, customText, stepId) => {
    let questionObj = {},
      reviewArr = {};
    if (Object.keys(this.state.questionAnswerObj).length === 0) {
      const tgQuestionId =
        this.state.basicData.reviewSteps[4].questions[0].questionId;
      let tgQuestionSelectedId = 0;
      this.state.basicData.reviewSteps[4].questions[0].options.forEach(
        (option) => {
          if (option.selected) {
            tgQuestionSelectedId = option.id;
          }
        }
      );
      questionObj = {
        ideaID: parseInt(this.state.ideaId),
        isFinalStep: false,
        panelID: this.state.panelId || 1,
        reviews: [
          {
            customText: "",
            questionID: parseInt(tgQuestionId),
            score: 0,
            selectedID: parseInt(tgQuestionSelectedId),
            subQuestions: [],
          },
        ],
        stepID: parseInt(stepId),
        summaryViewPojo: {
          acceptRecommendation: true,
          evaluatorConfidence: Math.round((this.state.score / 20) * 100),
          ideaId: parseInt(this.state.ideaId),
          overridPanelRecommendation: true,
          panelEmail: "",
          panelId: this.state.panelId || 1,
          relevanceScore: this.state.relevanceScore,
          fesibilityScore: this.state.fesibilityScore,
          investmentScore: this.state.investmentScore,
          impactScore: this.state.impactScore,
          cumulativeScore: this.state.score,
          isFirstReviewer: this.state.basicData.firstReviewer,
        },
      };
    } else {
      questionObj = this.state.questionAnswerObj;
    }
    reviewArr = {
      customText: customText || "",
      questionID: parseInt(questionId),
      score: 0,
      selectedID: parseInt(selectedId),
      subQuestions: [],
    };
    questionObj["reviews"] = this.checkForDuplicates(
      questionObj,
      parseInt(questionId)
    );
    questionObj["reviews"].push(reviewArr);
    questionObj["summaryViewPojo"]["panelEmail"] = customText;
    this.setState({ questionAnswerObj: questionObj });
    // console.log(questionObj);
  };

  /**
   * checks and removes duplicate subQuestions
   * @param {object} obj
   * @param {number} id
   */
  checkForDuplicateSubQ = (obj, id) => {
    let tempArr = [];
    tempArr = obj["reviews"][0]["subQuestions"].filter(function (queA) {
      return queA.subQuestionID !== id;
    });
    return tempArr;
  };

  /**
   * checks and removes duplicate arrays
   * @param {object} obj
   * @param {number} id
   */
  checkForDuplicates = (obj, id) => {
    let tempArr = [];
    tempArr = obj["reviews"].filter(function (queA) {
      return queA.questionID !== id;
    });
    return tempArr;
  };

  /**
   * captures question answer details for sub questions
   * @param {number} questionId
   * @param {number} selectedId
   * @param {string} customText
   * @param {number} score
   * @param {number} parentQuestionId
   * @param {number} parentSelectedId
   * @param {number} stepId
   * @param {string} subCustomText
   */
  saveSubQDetails = (
    questionId,
    selectedId,
    customText,
    score,
    parentQuestionId,
    parentSelectedId,
    stepId,
    subCustomText
  ) => {
    let questionObj = {},
      subQArr = {};
    if (Object.keys(this.state.questionAnswerObj).length === 0) {
      questionObj = {
        ideaID: parseInt(this.state.ideaId),
        isFinalStep: this.state.isOtherAreaSelected ? true : false,
        panelID: this.state.panelId || 1,
        reviews: [
          {
            customText: customText || "",
            questionID: parseInt(parentQuestionId),
            score: 0,
            selectedID: parseInt(parentSelectedId),
            subQuestions: [],
          },
        ],
        stepID: parseInt(stepId),
        summaryViewPojo: null,
      };
    } else {
      questionObj = this.state.questionAnswerObj;
    }
    subQArr = {
      score: score,
      subCustomText: subCustomText || "",
      subQuestionID: parseInt(questionId),
      subSecondOption: true,
      subSelectedID: parseInt(selectedId),
    };

    questionObj["reviews"][0]["subQuestions"] = this.checkForDuplicateSubQ(
      questionObj,
      parseInt(questionId)
    );

    questionObj["reviews"][0]["subQuestions"].push(subQArr);
    // let overallQScore = 0;
    // questionObj["reviews"][0]["subQuestions"].forEach((subQ) => {
    //     overallQScore = overallQScore + subQ.score;
    // })
    // questionObj["reviews"][0]["score"] = overallQScore;
    this.setState({ questionAnswerObj: questionObj });
    // console.log(questionObj);
  };

  /**
   * captures question answer details for input
   * @param {string} value
   * @param {number} stepId
   * @param {number} questionId
   */
  handleInputUpdate = (value, stepId, questionId) => {
    let questionObj = {};
    if (Object.keys(this.state.questionAnswerObj).length === 0) {
      questionObj = {
        ideaID: parseInt(this.state.ideaId),
        isFinalStep: false,
        panelID: this.state.panelId || 1,
        reviews: [
          {
            customText: "",
            questionID: parseInt(questionId),
            score: 0,
            selectedID: parseInt(questionId),
            subQuestions: [],
          },
        ],
        stepID: parseInt(stepId),
        summaryViewPojo: null,
      };
    } else {
      questionObj = this.state.questionAnswerObj;
    }
    questionObj["reviews"][0]["customText"] = value;
    this.setState({ questionAnswerObj: questionObj });
    // console.log(questionObj);
  };

  render() {
    const { userData } = this.props;
    const ideaDetails = this.props.ideaDetails || {};
    let title = "";
    if (this.state.isOtherAreaSelected) {
      title = "Thank you for your response";
    } else {
      title = "Wonderful!";
    }

    return (
      <>
        <Header
          hideHeaderCrossBtn={this.state.hideHeaderCrossBtn}
          values={userData}
          showSearch={false}
          showUser={false}
          pageName={"evaluate-idea"}
        />
        <div className="evaluate-header">
          <div
            className="evaluate-header_title"
            title={typeof ideaDetails !== undefined ? ideaDetails.ideaName : ""}
          >
            {typeof ideaDetails !== undefined
              ? ideaDetails.ideaName?.length > 100
                ? `${ideaDetails.ideaName.substring(0, 99)} ...`
                : ideaDetails.ideaName
              : ""}
          </div>
          {this.state.currentView !== 6 && (
            <div className="evaluate-header_rt">
              <div className="evaluate-header_rt-title">
                Want to look at the idea again?
              </div>
              <button
                className="btn btnText"
                onClick={() => this.setState({ showIdeaDetail: true })}
              >
                view idea detail
              </button>
            </div>
          )}
        </div>
        <IdeaSteps
          ideaSteps={this.state.basicData.breadcrumbs}
          currentView={this.state.currentView}
        />
        <div className="createIdeaOuter-cont">
          <div
            className={`createIdeaOuter ${
              this.state.currentView === 5 ? "evaluate-flow-outer" : ""
            }`}
          >
            {this.state.showOverallScore &&
              this.state.currentView !== 5 &&
              this.state.currentView !== 6 && (
                <div className="scoring-div">
                  <div className="Ideascore">Overall Score</div>
                  <div className="OverallScore">{this.state.score}/20</div>
                </div>
              )}
            <div className="createIdeaMain evaluate-flow">
              {this.state.currentView === 1 &&
                Object.keys(this.state.basicData).length > 0 && (
                  // Relevance
                  <ApprovalStep
                    updateQuestionArr={this.handleQuestionSelection.bind(this)}
                    onScoreReset={this.onScoreReset.bind(this)}
                    onHandleOtherArea={this.onHandleOtherArea.bind(this)}
                    stepDetails={this.state.basicData.reviewSteps[0]}
                    onScoreChange={this.onScoreChange.bind(this)}
                    saveSubQDetails={this.saveSubQDetails.bind(this)}
                    handleInputUpdate={this.handleInputUpdate.bind(this)}
                  />
                )}
              {this.state.currentView === 2 && (
                // Feasibility
                <ApprovalStep
                  updateQuestionArr={this.handleQuestionSelection.bind(this)}
                  stepDetails={this.state.basicData.reviewSteps[1]}
                  onScoreChange={this.onScoreChange.bind(this)}
                  customClass={"feasibility"}
                  questionAnswerDetails={this.questionAnswerDetails.bind(this)}
                />
              )}
              {this.state.currentView === 3 && (
                // Investment
                <ApprovalStep
                  updateQuestionArr={this.handleQuestionSelection.bind(this)}
                  stepDetails={this.state.basicData.reviewSteps[2]}
                  onScoreChange={this.onScoreChange.bind(this)}
                  customClass={"investment"}
                  questionAnswerDetails={this.questionAnswerDetails.bind(this)}
                />
              )}
              {this.state.currentView === 4 && (
                // Impact
                <ApprovalStep
                  updateQuestionArr={this.handleQuestionSelection.bind(this)}
                  stepDetails={this.state.basicData.reviewSteps[3]}
                  onScoreChange={this.onScoreChange.bind(this)}
                  questionAnswerDetails={this.questionAnswerDetails.bind(this)}
                />
              )}
              {this.state.currentView === 5 && (
                <>
                  <OverallSummary
                    updateQuestionArr={this.handleQuestionSelection.bind(this)}
                    isSubmitEnabled={this.state.isSubmitEnabled}
                    onScoreReset={this.onScoreReset.bind(this)}
                    score={this.state.score}
                    overAllScore={20}
                    onHandleOtherArea={this.onHandleOtherArea.bind(this)}
                    data={
                      Object.keys(this.state.basicData).length > 0
                        ? this.state.basicData.reviewSteps[4]
                        : {}
                    }
                    firstReviewer={this.state.basicData.firstReviewer}
                    submitEvaluation={this.handleSubmitEvaluation}
                    saveForLaterAction={this.saveForLaterAction}
                    handlePanelEmailUpdate={this.handlePanelEmailUpdate.bind(
                      this
                    )}
                    overallSummaryDetails={this.overallSummaryDetails.bind(
                      this
                    )}
                    userData={userData}
                  />
                </>
              )}
              {this.state.currentView === 6 && (
                <ApproveSuccess
                  title={title}
                  isOtherAreaSelected={this.state.isOtherAreaSelected}
                />
              )}
            </div>
          </div>
        </div>
        <div className="createIdeaOuter_btn-cont">
          <div className="createIdeaOuter_btn-cont_inner">
            {
              /* this.state.leftArrowVisible && */ <button
                className="btn secondary createIdeaPrevious"
                disabled={this.state.leftArrowDisable}
                onClick={this.handlePreviousClick}
              >
                <div className="arrow"></div>Back
              </button>
            }
            {
              /* this.state.rightArrowVisible && */ <button
                className="createIdeaNext"
                disabled={this.state.rightArrowDisable}
                onClick={this.handleNextClick}
              >
                <div className="arrow"></div>Next
              </button>
            }
          </div>
        </div>
        {this.state.currentView === 6 && (
          <div className="idea-explore-btn">
            <a href={"/ideaSubmission"} className="btn primary" role="button">
              Evaluate more ideas
            </a>
          </div>
        )}
        <Modal
          show={this.state.showSaveDraftModal}
          onHide={() => this.setState({ showSaveDraftModal: false })}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Close</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="confirmMessage">
              Are you sure you want to close? Your changes will not be saved if
              you close.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnText header-rm-20"
              onClick={() => this.saveforLaterSaveAction()}
            >
              Save Instead
            </button>
            <button
              className="btn primary"
              onClick={() => this.setState({ showSaveDraftModal: false })}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showIdeaDetail}
          onHide={() => this.setState({ showIdeaDetail: false })}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="idea-detail-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Idea Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="idea-detail-modal_body">
              <IdeaDetail ideaDetails={ideaDetails} userData={userData} />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_EVALUATE_DETAILS_STATUS:
      state.ideas.meta.FETCH_EVALUATE_DETAILS_STATUS,
    evaluateDetails: state.ideas.data.evaluateDetails,
    FETCH_IDEA_DETAILS_STATUS: state.ideas.meta.FETCH_IDEA_DETAILS_STATUS,
    ideaDetails: state.ideas.data.ideaDetails,
  };
};

const mapDispatchToProps = {
  getEvaluateDetails,
  fetchIdeaDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluateIdeaPanel);
