import React, { Component } from "react";
import footerLogo from '../../img/deloitte-whitelogo.png';
import { showLoader, hideLoading } from '../../helpers/utils';

class footer extends Component {
  contactLinkClickHandler = () => {
    showLoader();
    window.location = "mailto:USI Seed Core <USISeedCore@deloitte.com>?subject=SEED query/suggestion (please do not modify subject)";
    setTimeout(() => {
      hideLoading();
    }, 4000);
  }

  render() {    
      return (
        <div className="seed-footer">
          <div className="seed-footer_cont">
              <div className="seed-logo">
                  <img src={footerLogo} alt="footerLogo"/>
              </div>
              <ul>
                <li><a target="_blank" href="/faq">FAQ</a></li>
                <li><a target="_blank" href="/release-notes">Release Notes</a></li>
                <li><a href="javascript:void(0);" onClick={this.contactLinkClickHandler}>Contact Us</a></li>
                <li><a target="_blank" href="https://www2.deloitte.com/in/en/legal/legal.html">Terms of Use</a></li>
                <li><a target="_blank" href="https://cookienotice.deloitte.com">Cookies</a></li>
              </ul>
              {/* <div className="deloitte-logo"><p>A product of</p><img src={deloitteLogo} alt="DeloitteLogo" /></div> */}
            {/* <div className="seed-footer_cont-lower">
              <div>© 2023 Deloitte Touche Tohmatsu India LLP. See <a target="_blank" href="https://www2.deloitte.com/in/en/legal/legal.html">Terms of Use</a> for more information.</div>
              <div><a target="_blank" href="https://cookienotice.deloitte.com">Cookies</a></div>
            </div> */}
            </div>
          </div>
      )
  }
}

export default footer;