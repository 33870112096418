import { Component } from "react";
import Header from "../components/global/header";
import Breadcrumbs from "../components/global/breadcrumbs";
import "../css/pages/event.scss";
import { hideLoading, showLoader } from "../helpers/utils";
import { getEventDetailsLeaderboard, getEventList } from "../redux/actions/utilities";
import EachBoard from "../components/event/eachBoard";

class EventsLeaderboard extends Component {
    constructor(props) {
      super(props);
      this.state = {
        TTData: [],
        ChessData: [],
        OtherData: []
      }
    }
    
  fetchData = async (id) => {
    try {
      const res = await getEventDetailsLeaderboard(id)
      const {data} = await res.payload;
      return {success: true, data}
    } catch (err) {
      return {success: false, error: err}
    }
  }

  getEventList = async () => {
      showLoader();
      const res = await getEventList();
      const { data } = await res.payload;
      const eventIdList = data?.eventsList?.map(el => el.eventId)
      const promises = eventIdList.map(id => this.fetchData(id))
      const result = await Promise.all(promises)
      const TTData = result.filter(el => el.data?.eventName?.includes('TT - ') && el.data?.participantsList?.length)
      const ChessData = result.filter(el => el.data?.eventName?.includes('Chess - ') && el.data?.participantsList?.length)
      const OtherData = result.filter(el => !el.data?.eventName?.includes('TT - ') && !el.data?.eventName?.includes('Chess - ') && el.data?.participantsList?.length)
      this.setState({TTData, ChessData, OtherData})
      hideLoading();
  }

  componentDidMount() {
    this.getEventList()
  }

  render() {
    let breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "",
        text: "Commerce Day Leaderboard",
      },
    ];
    const { userData } = this.props;
    const { TTData, ChessData, OtherData } = this.state;
    return (
      <>
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"events-leaderboard"}
        />
        <>
          <Breadcrumbs breadcrumbs={breadcrumbJSON} />
            {TTData.length ? (<div className='board-container'><div className='board-title'>TT Leaderboard</div>{TTData.map((el, i) => <EachBoard key={i} eventDetails={el.data} />)}</div>): null }
            {ChessData.length ? (<div className='board-container'><div className='board-title'>Chess Leaderboard</div>{ChessData.map((el, i) => <EachBoard key={i} eventDetails={el.data} />)}</div>): null }
            {OtherData.length ? (<div className='board-container'><div className='board-title'>Other Events Leaderboard</div>{OtherData.map((el, i) => <EachBoard key={i} eventDetails={el.data} />)}</div>): null }
        </>
      </>
    );
  }
}

export default EventsLeaderboard;
