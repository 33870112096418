import React, { Component } from "react";
import Header from "../components/global/header";
import Breadcrumbs from "../components/global/breadcrumbs";
import "../css/pages/assignRole.scss";
import config from "../helpers/api-urls";
import successImage from "../img/successImage.png";
import axios from "axios";
import seedIcon from "../img/seedIcon.svg";
import closeIcon from "../img/close.svg";
import CreateChallengeIdeaSuccess from "../components/ideation/createChallengeIdeaSuccess";
import { hideLoading, showLoader } from "../helpers/utils";
import { _CONSTANTS } from "../helpers/constants";
import DOMPurify from "dompurify";

class AssignRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "SUBMITTER",
      showSuccessPage: false,
      addEmailText: "",
      checkEmailValid: false,
      showEmailDbError: false,
    };
  }

  //axios update call
  updateRole = () => {
    if (this.state.addEmailText === "") {
      return 0;
    }
    showLoader();
    const payload = {
      emailId: this.state.addEmailText,
      role: this.state.selectedOption,
    };
    axios
      .put(config.updateRoleConfig.requestPayload.url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`,
        },
      })
      .then((response) => {
        console.log("update rolllle", response.status);
        if (response.status === 200) {
          this.setState({ showSuccessPage: true });
          hideLoading();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Email Id not Found");
        hideLoading();
        // this.setState({ showEmailDbError: true });
      });
  };
  //Add Member Email
  handleAddEmailChange = (e) => {
    const value = DOMPurify.sanitize(e.target.value)
    this.setState({ addEmailText: value });
  };

  //Add Member Role
  handleAddRoleChange = (e) => {
    this.setState({ selectedOption: e.target.value });
    setTimeout(() => {
      this.setState({ selectedOption: e.target.value });
    }, 500);
  };

  // Handle changes in the dropdown
  handleDropdownChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  render = () => {
    const { userData } = this.props;
    const { showSuccessPage } = this.state;
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "",
        text: "Update Role",
      },
    ];
    return (
      <>
        {!showSuccessPage ? (
          <>
            <Header
              values={userData}
              showSearch={true}
              showUser={true}
              pageName={"assignRole"}
            />
            <Breadcrumbs breadcrumbs={breadcrumbJSON} />
            <div className="headerTitleContent section-container container-assign ">
              {/* <h4 className="subHeader">FOR YOU</h4> */}
              <h1 className="header-update">Update Role</h1>
            </div>
            <div className="roles-container">
              <div className="flex-assign">
                <div className="email-container add-team_section">
                  <div className="email-heading">Enter email ID</div>
                  <input
                    className="email-input"
                    type="email"
                    placeholder="Deloitte Email addess"
                    value={this.state.addEmailText}
                    onChange={(e) => this.handleAddEmailChange(e)}
                    required
                  />
                </div>
                <div className="add-team_section">
                  <div className="email-heading">Role</div>
                  <select
                    value={this.state.selectedOption} // Set the selected value
                    onChange={(e) => this.handleAddRoleChange(e)} // Handle changes
                    className="email-input roles-dropdown"
                  >
                    {/* <option value="">Select a Role</option> Default, unselected option */}
                    <option value="SUBMITTER">Submitter</option>
                    <option value="PANELIST">Panelist</option>
                    <option value="ADMIN">Admin</option>
                  </select>
                </div>
              </div>
              {!this.state.addEmailText && (
                <label className="validate-email-label">
                  Please enter a valid email address
                </label>
              )}
              {this.state.showEmailDbError && (
                <label className="validate-email-label">
                  Email Id not found
                </label>
              )}
            </div>
            {/* Submit button section */}
            <div className="submit-button-section">
              {this.state.addEmailText ? (
                <button
                  type="submit"
                  className="update-role-button"
                  onClick={this.updateRole}
                >
                  Update Role
                </button>
              ) : (
                <button
                  type="submit"
                  className="update-role-button-disable"
                  onClick={this.updateRole}
                >
                  Update Role
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <Header
              values={userData}
              showSearch={true}
              showUser={true}
              pageName={"assignRole"}
            />
            <Breadcrumbs breadcrumbs={breadcrumbJSON} />
            <div className="headerTitleContent section-container container-assign "></div>
            <div className="roles-container flex-assign">
              <img src={successImage} alt="success" />
              <div className="email-container add-team_section">
                <div className="success-heading">
                  Role updated successfully!
                </div>
                <p className="success-subheading">
                  The request is sent to respective recipients{" "}
                </p>
                <div className="assign-message">
                  “{this.state.addEmailText}” is assigned as “
                  {this.state.selectedOption}”
                </div>
              </div>
            </div>
            {/* <div className="explore-button">
              <button type="submit" className="update-role-button">
                Explore more Ideas
              </button>
            </div> */}
          </>
        )}
      </>
    );
  };
}

export default AssignRole;
