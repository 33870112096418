import React, { Component } from "react";
import logo from "../img/logo.svg";
import "../css/pages/amcPage.scss";
import Header from "../components/global/header";
import NoAccessSection from '../components/global/noAccess';

class NoAccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: this.props.showHeader ?? true,
      showTitle: this.props.showTitle ?? true,
      showInfo: this.props.showInfo ?? true,
      featureName: this.props.featureName,
      userData: this.props.userData
    };
  }

  render() {

    return (
      <>
        {/* Title */}
        {this.state.showHeader && (
          <section className="homeMain">
            <Header
              values={this.state.userData || {}}
              showSearch={false}
              showUser={true}
              pageName={this.state.featureName}
            />
          </section>
        )}
        {/* Content */}
        <section className="sorry-page">
        <div className="blur-bg"></div>
          <article className="seedLogo">
            {!this.state.showHeader && (<img src={logo} alt="Seed Logo" />)}
            {this.state.showTitle && <h1 className="heading">Sorry! Come back again!</h1>}
            <article className="sorry-page_content">
              <NoAccessSection htmlContent={
                // page specific messages
                (
                  (this.state.featureName === 'idea' || this.state.featureName === 'search')
                  && `Currently you don't have access to this view, as you are not part of any team. <div>Kindly reach out to <a href='mailto:uscmaianalyticscommunity@deloitte.com'>uscmaianalyticscommunity@deloitte.com</a> for more details.</div>`
                )

                // other pages
                // ...

                // fallback content
                || "Sorry for the inconvenience. Currently you don't have access to this view. Please keep checking the space for more."
              }></NoAccessSection>

              {this.state.showInfo && <p className="sorry-page_content-text">Keep Innovating!</p>}
              {this.state.showInfo && <p>-Team Seed</p>}

            </article>
          </article>
        </section >
      </>
    );
  }
}

export default NoAccessPage;
