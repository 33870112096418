import { Component } from "react";
import { connect } from "react-redux";
import TopIdeator from "../components/gamify/topIdeator";
import GamifiedIdea from "../components/gamify/gamifiedIdea";
import '../css/pages/leaderboard.scss';
import Ideator from "../components/gamify/ideator";
import CarousalArrow from "../components/global/carousalArrow";
import {
    getTopIdeators,
    getMostLikedIdeas,
    getMostInvestedIdeas
} from "../redux/actions/gamify";
import Slider from "react-slick";
import { _CONSTANTS } from "../helpers/constants";
import NoIdeas from "../components/gamify/noIdeas";

class leaderboard extends Component {

    constructor(props) {
        super(props);
        this.gotoIdeaDetails = this.gotoIdeaDetails.bind(this);
        this.state = {
            topTeamIdeators: [],
            mostLikedIdeas: [],
            mostInvestedIdeas: []
        };
    }

    componentDidMount() {
        this.props.getTopIdeators(8);
        this.props.getMostLikedIdeas(8);
        this.props.getMostInvestedIdeas(8);
    }

    componentDidUpdate(prevProps) {
        const { topTeamIdeators = [], mostLikedIdeas = [], mostInvestedIdeas = [] } = prevProps;
        if (topTeamIdeators !== this.props.topTeamIdeators ||
            mostLikedIdeas !== this.props.mostLikedIdeas ||
            mostInvestedIdeas !== this.props.mostInvestedIdeas) {
            this.setState({
                topTeamIdeators: this.props.topTeamIdeators,
                mostLikedIdeas: this.props.mostLikedIdeas,
                mostInvestedIdeas: this.props.mostInvestedIdeas
            });
        }

    }

    gotoIdeaDetails(ideaId) {
        window.location.href = `/idea/${ideaId}`;
    }

    render() {
        const { topTeamIdeators = [], mostLikedIdeas = [], mostInvestedIdeas = [] } = this.props;
        const noLikedIdeasMessage1 = _CONSTANTS.NOCONTENTMESSAGES.NOLIKEDIDEAS1;
        const noLikedIdeasMessage2 = _CONSTANTS.NOCONTENTMESSAGES.NOLIKEDIDEAS2;
        const noInvestedIdeasMessage1 = _CONSTANTS.NOCONTENTMESSAGES.NOINVESTEDIDEAS1;
        const noInvestedIdeasMessage2 = _CONSTANTS.NOCONTENTMESSAGES.NOINVESTEDIDEAS2;
        const settings = {
            dots: false,
            className: "center",
            centerMode: false,
            infinite: false,
            slidesToShow: 2,
            speed: 500,
            rows: 1,
            slidesPerRow: 1,
            nextArrow: <CarousalArrow />,
            prevArrow: <CarousalArrow />,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2.5,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2.1,
                    },
                },
            ],
        };

        return <section className="leaderboard">
            <h1>Leaderboard</h1>
            
            <article className="article-1">
                {this.state.topTeamIdeators?.length > 0 &&
                <section>
                    <div className="topper">
                        <div className="circular-dot"></div>
                    </div>
                    <ul className="top-ideators">
                        {topTeamIdeators?.slice(0, 3)?.map((ideator, index) => {
                            return <TopIdeator ideator={ideator} index={index + 1} key={ideator.userId} />
                        })}
                    </ul>
                </section>
                }   
                {this.state.topTeamIdeators?.length > 0 &&
                <section>
                    <ul className="ideators">
                        {topTeamIdeators?.slice(3, 8)?.map((ideator, index) => {
                            return <Ideator ideator={ideator} index={index + 4} key={ideator.userId} />
                        })}
                    </ul>
                </section>
                }
                {(this.state.topTeamIdeators === null || this.state.topTeamIdeators?.length === 0) &&
                <section>
                    <ul className="ideators-empty">
                        <p className="ideators-empty-header">No Ideas Submitted yet!</p>
                        <label className="leaderboard-empty-body">Go through our C&M AI Innovation Challenge and start submitting ideas 
                            for it from 9/25 to get featured on our leaderboard!</label>
                    </ul>
                </section>
                }

            </article>
            <article className="article-2">
    
                <section className="topicsMain leaderboard-ideas">
                    <div className="topicSlider">
                        <h2>Most Liked</h2>
                        {mostLikedIdeas?.length > 0 && <Slider {...settings}>
                            {mostLikedIdeas?.map(idea => {
                                return <GamifiedIdea idea={idea} gotoIdeaDetails={this.gotoIdeaDetails} key={idea.ideaID} />
                            })}
                        </Slider>}
                        {mostLikedIdeas?.length === 0 &&
                            <NoIdeas message1={noLikedIdeasMessage1}
                                message2={noLikedIdeasMessage2} />}
                    </div>
                </section>
                
                <section className="topicsMain leaderboard-ideas">
                    <div className="topicSlider">
                        <h2>Most Invested</h2>
                        {mostInvestedIdeas?.length > 0 && <Slider {...settings}>
                            {mostInvestedIdeas?.map(idea => {
                                return <GamifiedIdea idea={idea} gotoIdeaDetails={this.gotoIdeaDetails} key={idea.ideaID} />
                            })}
                        </Slider>}
                        {mostInvestedIdeas?.length === 0 &&
                            <NoIdeas message1={noInvestedIdeasMessage1}
                                message2={noInvestedIdeasMessage2} />}
                    </div>
                </section>
            </article>
        </section>;
    }
}

const mapStateToProps = (state) => {
    return {
        GET_TOP_IDEATORS: state.gamify.meta.GET_TOP_IDEATORS,
        topTeamIdeators: state.gamify.data.topTeamIdeators,
        GET_MOST_LIKED_IDEAS: state.gamify.meta.GET_MOST_LIKED_IDEAS,
        mostLikedIdeas: state.gamify.data.mostLikedIdeas,
        GET_MOST_INVESTED_IDEAS: state.gamify.meta.GET_MOST_INVESTED_IDEAS,
        mostInvestedIdeas: state.gamify.data.mostInvestedIdeas
    };
};

const mapDispatchToProps = {
    getTopIdeators,
    getMostLikedIdeas,
    getMostInvestedIdeas
};

export default connect(mapStateToProps, mapDispatchToProps)(leaderboard);