import { Component } from "react";

class NoIdeas extends Component {

    render() {
        const { message1, message2 } = this.props;
        return <div className="no-ideas">
            <p>{message1}</p>
            <p>{message2}</p>
            <a href="/idealist">Explore Ideas</a>
        </div>
    }
}

export default NoIdeas;
