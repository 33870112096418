import { combineReducers } from "redux";
import {
  FETCH_USER_PROFILE_PENDING,
  FETCH_USER_PROFILE_FULFILLED,
  FETCH_USER_PROFILE_REJECTED,
  FETCH_USER_PENDING,
  FETCH_USER_FULFILLED,
  FETCH_USER_REJECTED,
  FETCH_PERSONAL_CORNER_PENDING,
  FETCH_PERSONAL_CORNER_FULFILLED,
  FETCH_PERSONAL_CORNER_REJECTED,
  FETCH_PROFILE_CURRENCY_PENDING,
  FETCH_PROFILE_CURRENCY_FULFILLED,
  FETCH_PROFILE_CURRENCY_REJECTED,
  FETCH_PROFILE_TRANSACTION_PENDING,
  FETCH_PROFILE_TRANSACTION_FULFILLED,
  FETCH_PROFILE_TRANSACTION_REJECTED,
  FETCH_INVESTED_IDEA_PENDING,
  FETCH_INVESTED_IDEA_FULFILLED,
  FETCH_INVESTED_IDEA_REJECTED,
  FETCH_PROFILE_INVESTED_IDEA_PENDING,
  FETCH_PROFILE_INVESTED_IDEA_FULFILLED,
  FETCH_PROFILE_INVESTED_IDEA_REJECTED,
  SET_USER_EMAIL
} from "../action-types/user-profile";
import { PENDING, FULFILLED, FAILED } from "../../helpers/constants";

const initialMetaState = {
  FETCH_USER_PROFILE_STATUS: "DEFAULT",
  FETCH_USER_INFO: "DEFAULT",
  FETCH_PERSONAL_CORNER_STATUS: "DEFAULT",
  FETCH_PROFILE_CURRENCY_STATUS: "DEFAULT",
  FETCH_PROFILE_TRANSACTION_STATUS: "DEFAULT",
  FETCH_INVESTED_IDEA_STATUS: "DEFAULT",
  FETCH_PROFILE_INVESTED_IDEA_STATUS: "DEFAULT",
  SET_USER_EMAIL: "DEFAULT"
};

const initialDataState = {
  userJson: {
    userName: "",
    userEmail: "",
    userImage: "",
    sessionEndDate: "",
    sessionEndTime: "",
  },
  userDetails: {},
  profileCorner: {},
  currency: {},
  profileTransaction: {},
  investedIdea: {},
  profileInvestedIdeas: {},
  userEmail: '',
  isAuthComplete: false
};

function metaReducer(state = initialMetaState, action) {
  switch (action.type) {
    case FETCH_USER_PROFILE_PENDING:
      return { ...state, FETCH_USER_PROFILE_STATUS: PENDING };
    case FETCH_USER_PROFILE_FULFILLED:
      return { ...state, FETCH_USER_PROFILE_STATUS: FULFILLED };
    case FETCH_USER_PROFILE_REJECTED:
      return { ...state, FETCH_USER_PROFILE_STATUS: FAILED };
    case FETCH_USER_PENDING:
      return { ...state, FETCH_USER_INFO: PENDING };
    case FETCH_USER_FULFILLED:
      return { ...state, FETCH_USER_INFO: FULFILLED };
    case FETCH_USER_REJECTED:
      return { ...state, FETCH_USER_INFO: FAILED };
    case FETCH_PERSONAL_CORNER_PENDING:
      return { ...state, FETCH_PERSONAL_CORNER_STATUS: PENDING };
    case FETCH_PERSONAL_CORNER_FULFILLED:
      return { ...state, FETCH_PERSONAL_CORNER_STATUS: FULFILLED };
    case FETCH_PERSONAL_CORNER_REJECTED:
      return { ...state, FETCH_PERSONAL_CORNER_STATUS: FAILED };
    case FETCH_PROFILE_CURRENCY_PENDING:
      return { ...state, FETCH_PROFILE_CURRENCY_STATUS: PENDING };
    case FETCH_PROFILE_CURRENCY_FULFILLED:
      return { ...state, FETCH_PROFILE_CURRENCY_STATUS: FULFILLED };
    case FETCH_PROFILE_CURRENCY_REJECTED:
      return { ...state, FETCH_PROFILE_CURRENCY_STATUS: FAILED };
    case FETCH_PROFILE_TRANSACTION_PENDING:
      return { ...state, FETCH_PROFILE_TRANSACTION_STATUS: PENDING };
    case FETCH_PROFILE_TRANSACTION_FULFILLED:
      return { ...state, FETCH_PROFILE_TRANSACTION_STATUS: FULFILLED };
    case FETCH_PROFILE_TRANSACTION_REJECTED:
      return { ...state, FETCH_PROFILE_TRANSACTION_STATUS: FAILED };
    case FETCH_INVESTED_IDEA_PENDING:
      return { ...state, FETCH_INVESTED_IDEA_STATUS: PENDING };
    case FETCH_INVESTED_IDEA_FULFILLED:
      return { ...state, FETCH_INVESTED_IDEA_STATUS: FULFILLED };
    case FETCH_INVESTED_IDEA_REJECTED:
      return { ...state, FETCH_INVESTED_IDEA_STATUS: FAILED };
    case FETCH_PROFILE_INVESTED_IDEA_PENDING:
      return { ...state, FETCH_PROFILE_INVESTED_IDEA_STATUS: PENDING };
    case FETCH_PROFILE_INVESTED_IDEA_FULFILLED:
      return { ...state, FETCH_PROFILE_INVESTED_IDEA_STATUS: FULFILLED };
    case FETCH_PROFILE_INVESTED_IDEA_REJECTED:
      return { ...state, FETCH_PROFILE_INVESTED_IDEA_STATUS: FAILED };
    default:
      return state;
  }
}

function dataReducer(state = initialDataState, action) {
  switch (action.type) {
    case FETCH_USER_FULFILLED:
      return {
        ...state,
        userDetails: action.payload.data,
      };
    case FETCH_PERSONAL_CORNER_FULFILLED:
      return {
        ...state,
        profileCorner: action.payload,
      };
    case FETCH_PROFILE_CURRENCY_FULFILLED:
      return {
        ...state,
        currency: action.payload.data,
      };
    case FETCH_PROFILE_TRANSACTION_FULFILLED:
      return {
        ...state,
        profileTransaction: action.payload,
      };
    case FETCH_USER_PROFILE_FULFILLED:
      return {
        ...state,
        userJson: action.payload,
      };
    case FETCH_INVESTED_IDEA_FULFILLED:
      return {
        ...state,
        investedIdea: action.payload,
      };
    case FETCH_PROFILE_INVESTED_IDEA_FULFILLED:
      return {
        ...state,
        profileInvestedIdeas: action.payload.data,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
        isAuthComplete: true
      };
    default:
      return state;
  }
}

export default combineReducers({
  meta: metaReducer,
  data: dataReducer,
});
