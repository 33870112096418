import { Component } from "react";
import { generateRandomKey } from "../../helpers/utils";

class PanelLeadEvaluateIdea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otherComment: '',
            otherCommentCount: 0,
            maxCharCount: 255,
            disableApproveBtn: true
        };
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.updateOtherComment = this.updateOtherComment.bind(this);
        this.overrideRecommendation = this.overrideRecommendation.bind(this);
    }

    handleRadioChange(e) {
        this.setState({ disableApproveBtn: false });
        this.props.handleRadioChange(e);
    }

    updateOtherComment(e) {
        const descp = e.target.value;
        if (descp?.length <= this.state.maxCharCount) {
            this.setState({ otherComment: descp, otherCommentCount: descp.length });
        }
    }

    overrideRecommendation() {
        this.props.overrideRecommendation(this.state.otherComment);
    }

    render() {
        const { avgScoreSummaryPojo, progressArr, panelReviewList = null } = this.props;

        setTimeout(() => {
            const completedPanels = panelReviewList?.filter(panelReview => (panelReview?.panelStatus === 'Completed')) || [];
            if (avgScoreSummaryPojo && completedPanels?.length > 0) {
                this.props.addProgressClasses(avgScoreSummaryPojo.avgOverallScore, '-overall');
            }
        }, 50);

        return (
            <div className="detail-container panel-lead-review">
                <div className="detail-container_review-detail">
                    <div className="review-detail_lt panel-lead-left">
                        <p>Overall confidence</p>
                        <div className="progress-bar-cont">
                            {progressArr.map(() => {
                                return <div className={`dot dot-overall`} key={generateRandomKey()}></div>
                            })}
                        </div>
                        <p className="overall-score">Overall Score<span className={`review-score ${avgScoreSummaryPojo.avgOverallScore <= 30 && avgScoreSummaryPojo.avgOverallScore >= 15 ? 'color-yellow' : (avgScoreSummaryPojo.avgOverallScore < 15 ? 'color-red' : '')}`}>{avgScoreSummaryPojo.avgOverallScore}</span><span className="max-score">/20</span></p>
                        <ul>
                            <li>Originality <span>{avgScoreSummaryPojo.avgRelevanceScore}</span></li>
                            <li>Market Demand <span>{avgScoreSummaryPojo.avgInvestmentScore}</span></li>
                            <li>Feasibility​ <span>{avgScoreSummaryPojo.avgFesibilityScore}</span></li>
                            <li>Business Value <span>{avgScoreSummaryPojo.avgImpactScore}</span></li>
                        </ul>
                    </div>
                    <div className="review-detail_rt panel-lead-right">
                        <div className="panel-recommendation-detail">
                            <h2>Select next steps for this idea</h2>
                            <div>
                                <label className="radio-label">
                                    Send to backlog, not an immediate priority
                                    <input type="radio" name="panel-recommendation" className="" id="1"
                                        value="Send to backlog, not an immediate priority" onChange={this.handleRadioChange} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <label className="radio-label">
                                    Select for Pitch/Presentation
                                    <input type="radio" name="panel-recommendation" className="" id="2" value="Select for pitch /presentation"
                                        onChange={this.handleRadioChange} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <label className="radio-label">
                                    Select for Prototype
                                    <input type="radio" name="panel-recommendation" className="" id="3" value="Select for prototype"
                                        onChange={this.handleRadioChange} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <label className="radio-label">
                                    Select for Pilot
                                    <input type="radio" name="panel-recommendation" className="" id="4" value="Select for Pilot"
                                        onChange={this.handleRadioChange} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <label className="radio-label">
                                    Selected For Launch
                                    <input type="radio" name="panel-recommendation" className="" id="5" value="Selected For Launch"
                                        onChange={this.handleRadioChange} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="comment-btn-section">
                                <div className="comment-btn-section_lt">
                                    <label className="textarea-label">Add comment</label>
                                    <textarea value={this.state.otherComment} onChange={this.updateOtherComment} type="text" placeholder="Add your recommendation" className="other-textarea" />
                                    <span className="charcter-counter">{this.state.otherCommentCount}/{this.state.maxCharCount}</span>
                                </div>
                                <div className="comment-btn-section_rt">
                                    <button className="btn primary" disabled={this.state.disableApproveBtn || !this.state.otherComment} onClick={() => { this.overrideRecommendation('') }}>Complete Evaluation</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default PanelLeadEvaluateIdea;
