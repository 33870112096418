import React, { Component } from "react";
import classNames from "classnames";
import downArr from "../../img/down-arr.svg";

class ideaType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideaType: "",
      selectedOption: null,
      isListOpen: false,
    };
    this.ideaTypeWrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    let arrowDisable = true;
    this.props.ideaTypes !== null &&
      this.props.ideaTypes.length > 0 &&
      this.props.ideaTypes.forEach((ideaType) => {
        if (arrowDisable && ideaType.selected) {
          this.props.checkForValidType();
          arrowDisable = false;
        }
        if(ideaType.selected) {
          this.setState({ideaType: ideaType.text, selectedOption: ideaType.text})
        }
      });
    this.props.isNotAddDetail();
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.ideaTypeWrapperRef &&
      !this.ideaTypeWrapperRef.current.contains(event.target) &&
      this.state.isListOpen
    ) {
      this.setState({ isListOpen: false });
    }
  }

  onToggleList() {
    this.setState({ isListOpen: !this.state.isListOpen });
  }

  /**
   * handles the idea type change on click
   * @param {Event} e event
   */
  handleChange = (e) => {
    const { id, text } = e.target;
    this.setState({
      ideaType: id,
      selectedOption: text,
    });
    this.props.onDataChange(id, text);
    this.setState({ isListOpen: false });
  };

  render() {
    return (
      <>
        <div className="addDetailSubSection">
          <div className="addDetailsTalentGrp">
            <div className="addDetailsTalentGrp_sub-heading">
            What type of idea is this?
            </div>
            <div className="dropdown-wrapper" ref={this.ideaTypeWrapperRef}>
              <div className="dropdown selected">
                <button
                  aria-expanded="false"
                  type="button"
                  className={classNames(
                    "dropdown-select",
                    this.state.isListOpen ? "is-open" : ""
                  )}
                  onClick={() => this.onToggleList()}
                  id="ideaTypeGroup"
                >
                  {this.state.selectedOption || "Choose an option"}
                  <img src={downArr} alt="down-arrow" />
                </button>
                <ul
                  role="menu"
                  className={classNames(
                    "dropdown-menu",
                    this.state.isListOpen ? "show" : "d-none"
                  )}
                  aria-labelledby="ideaTypeGroup"
                >
                  <div className="list-container">
                    {this.props.ideaTypes.map((option, index) => {
                        return (
                          <li key={`option-ideaTypeGroup-${index}`}>
                            <a
                              role="menuitem"
                              className={classNames(
                                "dropdown-item",
                                option.text === this.state.ideaType
                                  ? "selected"
                                  : ""
                              )}
                              disabled={["All", "default"].includes(
                                option.text
                              )}
                              onClick={(e) => this.handleChange(e)}
                              id={index + 1}
                            >
                              {option.text}
                            </a>
                          </li>
                        );
                    })}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ideaType;
