import React, { Component } from "react";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DropDown from "../../components/global/dropdown";
import config from "../../helpers/api-urls";
import axios from "axios";
import {
  showLoader,
  hideLoading,
  getBaseUrl,
  getTextFromHtmlString,
} from "../../helpers/utils";
import RichTextEditor from "../global/richTextEditor";
import ProblemType from "./problemType";
import IdeaType from "./ideaType";
import DOMPurify from "dompurify";
import downArr from "../../img/down-arr.svg";

let newArr = [];
let s3FileName = "";
let s3FileUrl = "";
const baseUrl = getBaseUrl();
const seedSharepointUrl =
  "https://amedeloitte.sharepoint.com/:f:/s/SEEDCoreOperations/EplK-tLU8OhEje-_O-lB3sMB0XqNnOhVGBNf9TRinIdrPA?e=4S1nFP";
class addDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: new FormData(),
      attachmentFormData: new FormData(),
      file: [],
      disabled: true,
      fileArrSimplified: [],
      // fileArrSimplified: [],
      hideAttachmentBtn: false,
      ideaName: this.props.renderJSON.ideaDescription.ideaName || "",
      problemStatement: this.props.renderJSON.ideaDescription.problemStateMent,
      ideaSolution: this.props.renderJSON.ideaDescription.solution,
      potentialValue:
        this.props.renderJSON.ideaDescription.potentialValue || "",
      tools: this.props.renderJSON.tools || "",
      teamName: this.props.teamName || "",
      userBase: this.props.renderJSON.marketResearch.userBase,
      competitors: this.props.renderJSON.marketResearch.competitors,
      userBaseFinal: this.props.renderJSON.marketResearch.userBase,
      competitorsFinal: this.props.renderJSON.marketResearch.competitors,
      fileAttachmentUrl: this.props.renderJSON.marketResearch.attachmentURL,
      fileAttachmentUrlFinal:
        this.props.renderJSON.marketResearch.attachmentURL,
      fileAttachmentName: this.props.renderJSON.marketResearch.fileName,
      fileAttachmentNameFinal: this.props.renderJSON.marketResearch.fileName,
      marketResearch: {},
      keywords: this.props.renderJSON.keywords.userSelectedTags,
      selectedKeywords: [],
      tempArr: [],
      isSaveDetailsClicked: false,
      isSaveTagsClicked: false,
      renderJSON: this.props.renderJSON,
      offeringPortfolios: this.props.renderJSON.offeringPortfolios,
      talentOfferings: this.props.renderJSON.talentOfferings,
      talentGrp:
        this.props.renderJSON.talentGroups?.length > 1
          ? [...this.props.renderJSON.talentGroups]
          : [],
      archetypes:
        this.props.renderJSON.archeTypes?.length > 1
          ? [...this.props.renderJSON.archeTypes]
          : [],
      industryGroup: this.props.renderJSON.industries,
      domainGroup: this.props.renderJSON.domains,
      sectors:
        this.props.renderJSON.sectors?.length > 1
          ? [...this.props.renderJSON.sectors]
          : [],
      showTagsModal: false,
      showMRModal: false,
      show: true,
      isValid: false,
      selectedOfferingPortfolio: "N/A",
      selectedTalentOffering: "N/A",
      selectedItem: "N/A",
      selectedArchetype: {},
      selectedIndustryItem: "N/A",
      selectedDomainItem: "N/A",
      selectedSector: "N/A",
      maxCharCountBsOp: 1000,
      maxCharCount: 2000,
      maxCharCountSolution: 3000,
      maxCharCountQuan: 1000,
      maxCharCountUser: 255,
      tagsBtnDisabled:
        this.props.renderJSON.keywords.userSelectedTags.length >= 1
          ? false
          : true,
      showErrorOnPage: false,
      errorMessage: "",
      membersDetails: [],
      showAddTeamMemberModal: false,
      showConfirmationModal: false,
      addNameText: "",
      addEmailText: "",
      addRoleText: "",
      addLocationText: "",
      isEmailValid: "",
      disableAddBtn: true,
      isAddDetail: true,
      showDeleteTeamMemberModal: false,
      showDeleteTeamMemberErrorModal: false,
      solutionCharCount:
        this.props.renderJSON &&
        this.props.renderJSON.ideaDescription &&
        this.props.renderJSON.ideaDescription.solution
          ? this.props.renderJSON.ideaDescription.solution.length
          : 0,
      problemStatementCharCount:
        this.props.renderJSON?.ideaDescription?.problemStateMent?.length ?? 0,
      // this.props.renderJSON &&
      //   this.props.renderJSON.ideaDescription &&
      //   this.props.renderJSON.ideaDescription.problemStateMent
      //   ? this.props.renderJSON.ideaDescription.problemStateMent.length
      //   : 0,
      //getTextFromHtmlString(this.props.renderJSON?.ideaDescription?.problemStateMent).length,
      toolsCharCount: this.props.renderJSON?.tools?.length ?? 0,
      potentialValueCharCount:
        this.props.renderJSON?.ideaDescription?.potentialValue?.length ?? 0,
      files: [],
      fileName: "",
      fileInput: {},
      challengeID: this.props.challengeID,
      email: this.props.email,
      isAlreadyRegistered: this.props.isAlreadyRegistered,
      teamMembersIds: this.props.teamMembersIds,
      allianceVal: this.props.renderJSON.allainces,
      alliance: "N/A",
      allianceOthers: null,
      useCase: "N/A",
      priority: "N/A",
      problemTypeValue: this.props.problemTypeValue,
      ideaTypeValue: this.props.ideaTypeValue,
      basicIdeaData: this.props.basicIdeaData,

      isEnabled: {
        tools: !!this.props.renderJSON?.ideaFields?.includes(
          "Tool(s) needed to bring idea to life"
        ),
        potentialValue: !!this.props.renderJSON?.ideaFields?.includes(
          "Potential value delivered"
        ),
        selectedIndustryItem:
          !!this.props.renderJSON?.ideaFields?.includes("Industry"),
        selectedSector: !!this.props.renderJSON?.ideaFields?.includes("Sector"),
        selectedDomainItem:
          !!this.props.renderJSON?.ideaFields?.includes("Domain"),
        selectedTalentOffering:
          !!this.props.renderJSON?.ideaFields?.includes("Talent Offering"),
        selectedItem:
          !!this.props.renderJSON?.ideaFields?.includes("Market Offering"),
        selectedOfferingPortfolio:
          !!this.props.renderJSON?.ideaFields?.includes("Offering Portfolio"),
        alliance: !!this.props.renderJSON?.ideaFields?.includes("Alliance"),
        priority: !!this.props.renderJSON?.ideaFields?.includes("Priority"),
        allianceOthers: false,
        useCase: !!this.props.renderJSON?.ideaFields?.includes("Use Case"),
      },
      isListOpen: {
        yourTeam: false,
        alliance: false,
        priority: false,
        useCase: false
      },
    };
    this.yourTeamWrapperRef = React.createRef();
    this.allianceWrapperRef = React.createRef();
    this.priorityWrapperRef = React.createRef();
    this.useCaseWrapperRef = React.createRef();
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.removeSelectedFile = this.removeSelectedFile.bind(this);
    this.HideMRModal = this.HideMRModal.bind(this);
    this.saveMarketDetail = this.saveMarketDetail.bind(this);
    this.getTeamMembersDetailsForDraft =
      this.getTeamMembersDetailsForDraft.bind(this);
    this.handleTools = this.handleTools.bind(this);
    this.updateTools = this.updateTools.bind(this);
    this.updateTeamName = this.updateTeamName.bind(this);
    this.handlePotentialValueChange =
      this.handlePotentialValueChange.bind(this);
    this.updatePotentialValue = this.updatePotentialValue.bind(this);
    this.mapToOfferingPortfolioOptions =
      this.mapToOfferingPortfolioOptions.bind(this);
    this.mapToTalentOfferingOptions =
      this.mapToTalentOfferingOptions.bind(this);
    this.mapToTalentGroupOptions = this.mapToTalentGroupOptions.bind(this);
    this.mapToArchetypeOptions = this.mapToArchetypeOptions.bind(this);
    this.mapToIndustryOptions = this.mapToIndustryOptions.bind(this);
    this.mapToDomainOptions = this.mapToDomainOptions.bind(this);
    this.mapToSectorOptions = this.mapToSectorOptions.bind(this);
    this.findSelectedDropdown = this.findSelectedDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  /**
   * checks and removes file
   * @param {number} id
   */
  checkAndRemoveFile = (id) => {
    let tempArr = [];
    tempArr = this.state.formData.getAll("files").filter(function (file) {
      return file.id !== id;
    });
    return tempArr;
  };

  /**
   * removes file from the file list
   * @param {object} file removed file object
   */
  removeSelectedFile = (file) => {
    // let formFile = this.state.file;
    let fileArrSimplified = this.state.fileArrSimplified;
    // const id = formFile.findIndex((item) => item.id === file.fileID);
    // formFile.splice(id, 1);
    const idSimplified = fileArrSimplified.findIndex(
      (item) => item.fileID === file.fileID
    );
    fileArrSimplified.splice(idSimplified, 1);
    // this.state.formData.delete();
    // let formData = new FormData();
    this.setState({ formData: new FormData(), isSaveDetailsClicked: false });
    const revisedFileArr = this.checkAndRemoveFile(file.fileID);
    setTimeout(() => {
      revisedFileArr.forEach((file) => {
        this.state.formData.append("files", file);
      });
    }, 100);
    this.setState({
      file: fileArrSimplified,
      hideAttachmentBtn: false,
      fileArrSimplified: fileArrSimplified,
    });
    // this.composeFormData(formData);
    this.props.deleteFile(file.fileText);
  };

  /**
   * makes an object of file
   * @param {object} basicData raw file data
   */
  constructFileItemsArray = (basicData) => {
    let itemArr = {};
    // itemArr.fileID = basicData.id;
    itemArr.fileUrl = URL.createObjectURL(basicData);
    itemArr.fileText = basicData.name;
    return itemArr;
  };

  /**
   * check for dupliacte files while uploading
   * @param {string} fileName name of the file
   */
  checkForDuplicateFiles = (fileName) => {
    return this.state.fileArrSimplified.find((element) => {
      return element.fileText === fileName ? true : false;
    });
  };

  composeFormDataAttachment(file) {
    this.state.attachmentFormData.append("files", file);
  }

  // constructFileItemsArray = (basicData) => {
  //   let itemArr = {};
  //   itemArr.fileID = basicData.id;
  //   itemArr.fileUrl = URL.createObjectURL(basicData);
  //   itemArr.fileText = basicData.name;
  //   return itemArr;
  // };

  showSelectedFiles = (event) => {
    // if (event.target.files.length > 3) {
    //   return alert("You can upload max 3 files");
    // } else {
    //   const files = event.target.files;
    //   const fileArrSimplified = this.state.files;
    //   if(this.state.files.length > 0) {
    //     let count = this.state.files.length;
    //     for (const [id, data] of Object.entries(files)) {
    //       const isDuplicate = this.checkForDuplicateFiles(data.name);
    //       if (isDuplicate) { return }
    //       if (data.size / 1024 / 1024 > 3) {
    //         alert(data.name + ": Please upload file not more than 3MB.");
    //       } else if (!(/\.(pdf|xls|doc)$/i.test(data.name))) {
    //         alert("Please upload only .pdf or .xls or .doc");
    //       } else {
    //         count = count + 1;
    //         data.id = count;
    //         const simplifiedArr = this.constructFileItemsArray(data);
    //         // fileArr.push(data);
    //         this.composeFormDataAttachment(data);
    //         fileArrSimplified.push(simplifiedArr);
    //         this.uploadS3Files(data.name, data, files);
    //       }
    //     }
    //   } else {
    //     for (const [id, data] of Object.entries(files)) {
    //       const isDuplicate = this.checkForDuplicateFiles(data.name);
    //       if (isDuplicate) { return }
    //       if (data.size / 1024 / 1024 > 3) {
    //         alert(data.name + ": Please upload file not more than 3MB.");
    //       } else if (!(/\.(pdf|xls|doc)$/i.test(data.name)) ) {
    //         alert("Please upload only .pdf or .xls or .doc");
    //       } else {
    //         data.id = parseInt(id);
    //         const simplifiedArr = this.constructFileItemsArray(data);
    //         // fileArr.push(data);
    //         this.composeFormDataAttachment(data);
    //         fileArrSimplified.push(simplifiedArr);
    //         this.uploadS3Files(data.name, data, fileArrSimplified);
    //       }
    //     }
    //   }
    //   // if (files.length > 0) {
    //   //   this.setState({
    //   //     fileName: files[0].name,
    //   //     fileInput: files[0],
    //   //   });
    //   //   let fileArr = this.state.fileArrSimplified;
    //   //   const file = files[0];
    //   //   if (file.size / 1024 / 1024 > 3) {
    //   //     alert(file.name + ": Please upload file not more than 3 MB.");
    //   //   } else if (
    //   //     !(
    //   //       file.name.indexOf(".pdf") > -1 ||
    //   //       file.name.indexOf(".xls") > -1 ||
    //   //       file.name.indexOf(".docx") > -1 ||
    //   //       file.name.indexOf(".doc") > -1
    //   //     )
    //   //   ) {
    //   //     alert("Please upload only .pdf or .xls or .doc.");
    //   //   } else {
    //   //     const simplifiedArr = this.constructFileItemsArray(files[0]);
    //   //     fileArr = [simplifiedArr];
    //   //     s3FileName = files[0].name;
    //   //     // this.setState({ hideAttachmentBtn: fileArr.length === 1 });
    //   //     // this.setState({
    //   //     //   file: fileArr,
    //   //     //   fileArrSimplified: fileArr,
    //   //     // });
    //   //     this.uploadS3Files(files[0].name, files[0], fileArr);
    //   //     // document.getElementById('upload-btn').classList.remove('hide');
    //   //   }
    //   // }
    // }
    var fileInput = event.target;
    if (fileInput.files.length > 0) {
      this.setState({
        fileName: fileInput.files[0].name,
        fileInput: fileInput.files[0],
      });
      let fileArr = this.state.fileArrSimplified;
      const file = fileInput.files[0];
      if (file.size / 1024 / 1024 > 10) {
        alert(file.name + ": Please upload file not more than 10 MB.");
      } else if (
        !(
          file.name.indexOf(".pdf") > -1 ||
          file.name.indexOf(".xls") > -1 ||
          file.name.indexOf(".doc") > -1 ||
          file.name.indexOf(".ppt") > -1
        )
      ) {
        alert("Please upload only .pdf or .xls or .doc. or .ppt");
      } else {
        const simplifiedArr = this.constructFileItemsArray(fileInput.files[0]);
        fileArr = [simplifiedArr];
        s3FileName = fileInput.files[0].name;
        this.uploadS3Files(
          fileInput.files[0].name,
          fileInput.files[0],
          fileArr
        );
      }
    }
  };

  /**
   * on file selection, add to file list
   * @param {event} event event
   */
  onSelectFile = (event) => {
    const formFile = event.target.files;
    // let fileArr = this.state.file;
    let fileArrSimplified = this.state.fileArrSimplified;
    if (
      fileArrSimplified.length + formFile.length > 3 ||
      fileArrSimplified.length > 2 ||
      formFile.length > 3
    ) {
      alert("Maximum of 3 files allowed");
      return false;
    }
    // if (this.state.file.length > 0) {
    if (this.state.fileArrSimplified.length > 0) {
      let count = this.state.fileArrSimplified.length;
      for (const [id, data] of Object.entries(formFile)) {
        const isDuplicate = this.checkForDuplicateFiles(data.name);
        if (isDuplicate) {
          return;
        }
        if (data.size / 1024 / 1024 > 2) {
          alert(data.name + ": Please upload file not more than 2Mb.");
        } else if (
          !(data.name.indexOf(".pdf") > -1 || data.name.indexOf(".xls") > -1)
        ) {
          alert("Please upload only .pdf or .xls.");
        } else {
          count = count + 1;
          data.id = count;
          const simplifiedArr = this.constructFileItemsArray(data);
          // fileArr.push(data);
          this.composeFormData(data);
          fileArrSimplified.push(simplifiedArr);
        }
      }
    } else {
      for (const [id, data] of Object.entries(formFile)) {
        const isDuplicate = this.checkForDuplicateFiles(data.name);
        if (isDuplicate) {
          return;
        }
        if (data.size / 1024 / 1024 > 2) {
          alert(data.name + ": Please upload file not more than 2Mb.");
        } else if (
          !(data.name.indexOf(".pdf") > -1 || data.name.indexOf(".xls") > -1)
        ) {
          alert("Please upload only .pdf or .xls.");
        } else {
          data.id = parseInt(id);
          const simplifiedArr = this.constructFileItemsArray(data);
          // fileArr.push(data);
          this.composeFormData(data);
          fileArrSimplified.push(simplifiedArr);
        }
      }
    }

    this.setState({ hideAttachmentBtn: fileArrSimplified.length === 3 });
    this.setState({
      file: fileArrSimplified,
      fileArrSimplified: fileArrSimplified,
    });
    event.target.value = "";
    // this.props.uploadFile(this.state.formData);
  };

  /**
   * add file data in formdata
   * @param {object} file file object array
   */
  composeFormData(file) {
    this.state.formData.append("files", file);
  }

  /**
   * handle idea name change
   * @param {event} e event
   */
  handleIdeaNameChange = (e) => {
    const name = DOMPurify.sanitize(e.target.value).substring(
      0,
      Math.min(
        DOMPurify.sanitize(e.target.value).length,
        this.state.maxCharCountSolution
      )
    );
    this.setState({ ideaName: name });
  };

  /**
   * handle idea problem statement change
   * @param {event} e event
   */
  handleProblemStatementChange = (e) => {
    const statement = e;
    // e.target.value.substring(0, Math.min(e.target.value.length, this.state.maxCharCount));
    // if (e.target.value.length <= this.state.maxCharCount) {
    this.setState({
      problemStatement: statement,
      problemStatementCharCount: getTextFromHtmlString(statement).length,
    });
    // }
  };

  /**
   * handle idea solution change
   * @param {event} e event
   */
  handleIdeaSolutionChange = (e) => {
    const solution = e.substring(
      0,
      Math.min(e.length, this.state.maxCharCountSolution)
    );
    // if (e.target.value.length <= this.state.maxCharCountSolution) {
    this.setState({
      ideaSolution: solution,
      solutionCharCount: getTextFromHtmlString(solution).length,
    });
    // }
  };

  /**
   * handle idea solution change
   * @param {event} e event
   */
  handlePotentialValueChange(e) {
    const potentialValue = e.substring(
      0,
      Math.min(e.length, this.state.maxCharCount)
    );
    this.setState({
      potentialValue,
      potentialValueCharCount: getTextFromHtmlString(potentialValue).length,
    });
  }

  /**
   * handle tools change
   * @param {event} e event
   */
  handleTools(e) {
    const tools = e.substring(
      0,
      Math.min(e.length, this.state.maxCharCountQuan)
    );
    this.setState({
      tools,
      toolsCharCount: getTextFromHtmlString(tools).length,
    });
  }

  /**
   * handle market user base change
   * @param {event} e event
   */
  handleUserBaseChange = (e) => {
    const userText = DOMPurify.sanitize(e.target.value).substring(
      0,
      Math.min(
        DOMPurify.sanitize(e.target.value).length,
        this.state.maxCharCountUser
      )
    );
    this.setState({ userBase: userText });
  };

  /**
   * handle market competitor change
   * @param {event} e event
   */
  handleCompetitorChange = (e) => {
    const competitorsText = DOMPurify.sanitize(e.target.value).substring(
      0,
      Math.min(
        DOMPurify.sanitize(e.target.value).length,
        this.state.maxCharCountUser
      )
    );
    this.setState({ competitors: competitorsText });
  };

  /**
   * handle market competitor change
   * @param {event} e event
   */
  handleAttachmentUrlChange = (e) => {
    const urlText = DOMPurify.sanitize(e.target.value).substring(
      0,
      Math.min(
        DOMPurify.sanitize(e.target.value).length,
        this.state.maxCharCountSolution
      )
    );
    this.setState({ fileAttachmentUrl: urlText });
  };

  handleProblemType = (selectedValue, value, typeId) => {
    let renderJSONTemp = this.state.basicIdeaData;
    renderJSONTemp.problemTypes.forEach((ideaType) => {
      ideaType.selected = false;
      if (ideaType.text + ideaType.problemTypeID === selectedValue) {
        ideaType.selected = true;
      }
    });
    this.setState({
      problemTypeValue: selectedValue,
      basicIdeaData: renderJSONTemp,
      // rightArrowDisable:
      //   this.state.draftId === 0 || this.state.challengeID !== 0
      //     ? selectedValue === "" || this.state.ideaTypeValue === ""
      //     : selectedValue === "" && this.state.ideaTypeValue === "",
    });
    this.props.updateProblemType(
      selectedValue,
      value,
      this.state.basicIdeaData.ideaStatus,
      this.props.draftId,
      typeId,
      this.props.userData?.email || "",
      this.state.basicIdeaData.teamName
    );
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
    this.props.saveDraftDetails();
  };

  handleIdeaType = (selectedValue, value) => {
    let renderJSONTemp = this.state.basicIdeaData;
    renderJSONTemp.ideaTypes.forEach((ideaType) => {
      ideaType.selected = false;
      if (ideaType.ideaTypeID.toString() === selectedValue) {
        ideaType.selected = true;
      }
    });
    this.setState({
      ideaTypeValue: selectedValue,
      basicIdeaData: renderJSONTemp,
      // rightArrowDisable:
      //   this.state.draftId === 0 || this.state.challengeID !== 0
      //     ? selectedValue === "" || this.state.problemTypeValue === ""
      //     : selectedValue === "" && this.state.problemTypeValue === "",
    });
    this.props.updateIdeaType(
      selectedValue,
      value,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData?.email || "",
      this.state.basicIdeaData.teamName
    );
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
    this.props.saveDraftDetails();
  };

  /**
   * handle market competitor change
   * @param {event} e event
   */
  handleAttachmentNameChange = (e) => {
    const nameText = e.target.value.substring(
      0,
      Math.min(e.target.value.length, this.state.maxCharCountSolution)
    );
    this.setState({ fileAttachmentName: nameText });
  };
  /**
   * validates the page for all required fields
   */
  validatePage = () => {
    const valArr = {
      problemTypeValue:
        "Please select type of problem your idea is aiming to solve",
      ideaTypeValue: "Please select type of your idea",
      // ideaName: "Please enter Idea Name",
      // problemStatement: "Please enter Idea Problem Statement",
      ideaSolution: "Please enter Idea Solution",
      tools: "Please enter Tools Required",
      potentialValue: "Please enter Potential Value",

      selectedOfferingPortfolio: "Please select Offering Portfolio",
      selectedIndustryItem: "Please select Industry",
      selectedSector: "Please select Sector",
      selectedDomainItem: "Please select Domain",
      selectedTalentOffering: "Please select offering",
      selectedItem: "Please select Talent Group",

      alliance: "Please select alliance",
      allianceOthers: "Please enter alliance others",
      priority: "Please select priority",
      useCase: "Please select use case",
    };

    const checkArr = ["", null, "<p></p>\n", "N/A", "Choose an option"];
    const checkVal1 = [
      "problemTypeValue",
      "ideaTypeValue",
      "ideaName",
      "problemStatement",
      "ideaSolution",
    ];
    const checkVal2 = [
      "selectedIndustryItem",
      "selectedSector",
      "selectedDomainItem",
      "selectedTalentOffering",
      "selectedItem",
      "selectedOfferingPortfolio",
      "potentialValue",
      "tools",
      "alliance",
      "priority",
      "useCase",
      "allianceOthers",
    ];

    let notValidated = checkVal1.some((v) => checkArr.includes(this.state[v]));

    let variableFieldsNotValid = checkVal2.some((v) => {
      const isEnabled = this.state.isEnabled[v];
      const value =
            this.state[v] !== null && typeof this.state[v] === "object" && "name" in this.state[v]
          ? this.state[v].name
          : this.state[v] !== null && typeof this.state[v] === "object" && "allianceName" in this.state[v]
          ? this.state[v].allianceName
          : this.state[v];
      return (
        isEnabled &&
        (checkArr.includes(value) || Object.keys(value).length === 0)
      );
    });

    for (let val of Object.keys(valArr)) {
      if (notValidated && checkArr.includes(this.state[val])) {
        this.setState({ showErrorOnPage: true, errorMessage: valArr[val] });
        break;
      } else if (variableFieldsNotValid && this.state.isEnabled[val]) {
        const isObj = [
          "selectedIndustryItem",
          "selectedSector",
          "selectedDomainItem",
          "selectedTalentOffering",
          "selectedItem",
          "selectedOfferingPortfolio",
          "alliance"
        ].includes(val);
        const checkVal =
              isObj && typeof this.state[val] === "object" && "name" in this.state[val]
            ? checkArr.includes(this.state[val].name)
            : isObj && typeof this.state[val] === "object" && "allianceName" in this.state[val]
            ? checkArr.includes(this.state[val].allianceName)
            : checkArr.includes(this.state[val]);
        if (checkVal) {
          this.setState({ showErrorOnPage: true, errorMessage: valArr[val] });
          break;
        }
      } else if (!notValidated && !variableFieldsNotValid) {
        this.setState({ showErrorOnPage: false, errorMessage: "" });
        break;
      }
    }

    this.setState({ isValid: !notValidated && !variableFieldsNotValid });
    return !notValidated && !variableFieldsNotValid;
  };

  enableSubmitButton = () => {
    const isValid = this.validatePage();
    if (!isValid) {
      this.props.rightArrowDisable();
    } else {
      this.props.rightArrowEnable();
    }
  };

  /**
   * handle idea name update on blur
   * @param {event} e event
   */
  updateIdeaName = (e) => {
    // const value= e?.target?.value || this.state.ideaName;
    // let temp = {};
    // temp.ideaName = value;
    // temp.teamName = this.state.teamName;
    // temp.problemStateMent = this.state.problemStatement;
    // temp.solution = this.state.ideaSolution;
    // temp.potentialValue = this.state.potentialValue;
    // temp.tools = this.state.tools;
  };

  /**
   * handle idea problem statement update on blur
   * @param {event} e event
   */
  updateProblemStateMent = (e) => {
    // const textContent = e?.target?.textContent || this.state.problemStatement;
    // let temp = {};
    // temp.ideaName = this.state.ideaName;
    // temp.teamName = this.state.teamName;
    // temp.problemStateMent = textContent;
    // temp.solution = this.state.ideaSolution;
    // temp.potentialValue = this.state.potentialValue;
    // temp.tools = this.state.tools;
  };

  /**
   * handle idea solution update on blur
   * @param {event} e event
   */
  updateSolution = (e) => {
    // const textContent = e?.target?.textContent || this.state.ideaSolution;
    // let temp = {};
    // temp.ideaName = this.state.ideaName;
    // temp.teamName = this.state.teamName;
    // temp.problemStateMent = this.state.problemStatement;
    // temp.solution = textContent;
    // temp.potentialValue = this.state.potentialValue;
    // temp.tools = this.state.tools;
  };

  /**
   * handle idea solution update on blur
   * @param {event} e event
   */
  updatePotentialValue(e) {
    // const textContent = e.target?.textContent || this.state.potentialValue;
    // let temp = {};
    // temp.ideaName = this.state.ideaName;
    // temp.teamName = this.state.teamName;
    // temp.problemStateMent = this.state.problemStatement;
    // temp.solution = this.state.ideaSolution;
    // temp.tools = this.state.tools;
    // temp.potentialValue = textContent;
  }
  /**
   * handle tools update on blur
   * @param {event} e event
   */
  updateTools(e) {
    // const textContent = e?.target?.textContent || this.state.tools;
    // let temp = {};
    // temp.ideaName = this.state.ideaName;
    // temp.teamName = this.state.teamName;
    // temp.problemStateMent = this.state.problemStatement;
    // temp.solution = this.state.ideaSolution;
    // temp.tools = this.state.tools;
    // temp.tools = textContent;
  }

  updateAllIdeaVal() {
    let temp = {};
    temp.ideaName = this.state.ideaName;
    temp.teamName = this.state.teamName;
    temp.problemStateMent = this.state.problemStatement;
    temp.solution = this.state.ideaSolution;
    temp.tools = this.state.tools;
    temp.potentialValue = this.state.potentialValue;
    this.props.updateIdeaDetail(temp, true);
    this.enableSubmitButton();
  }

  /**
   * handle tools update on blur
   * @param {event} e event
   */
  updateTeamName() {
    let temp = {};
    temp.teamName = this.state.teamName;
    this.props.updateTeamName(temp);
  }

  /**
   * display tags detail modal
   */
  displayTagsModal = () => {
    this.setState({
      showTagsModal: true,
      tagsBtnDisabled: this.state.tempArr.length >= 1 ? false : true,
    });
  };

  /**
   * display market research detail modal
   */
  displayMRModal = () => {
    this.setState({ showMRModal: true });
  };

  /**
   * remove common array comparing 2 arrays
   * @param {Array} first
   * @param {Array} second
   * @returns {Array}
   */
  removeCommon = (first, second) => {
    var unique = [];
    var matches = false;

    for (var i = 0; i < first.length; i++) {
      matches = false;
      for (var e = 0; e < second.length; e++) {
        if (first[i].tagID === second[e].tagID) matches = true;
      }
      if (!matches) unique.push(first[i]);
    }
    return unique;
  };

  /**
   * hides tag detail modal
   */
  HideTagsModal = () => {
    const result = this.removeCommon(this.state.tempArr, newArr);
    this.setState({ tempArr: result });
    this.setState({ showTagsModal: false });
    newArr = [];
    setTimeout(() => {
      if (result.length === 0) {
        this.setState({
          tempArr: this.checkForSelectedKeywords(
            this.state.renderJSON.keywords.userSelectedTags
          ),
        });
        this.setState({ selectedKeywords: this.state.tempArr });
        this.props.updateSelectedKeywords(this.state.selectedKeywords);
      }
    }, 100);
  };

  /**
   * handles saving tags
   */
  handleSaveTagsClick = () => {
    this.setState({
      selectedKeywords: this.state.tempArr,
      showTagsModal: false,
      isSaveTagsClicked: true,
    });
    this.props.updateSelectedKeywords(this.state.tempArr);
    newArr = [];
  };

  uploadS3Files = (name, file, fileArr) => {
    showLoader();
    axios({
      method: "post",
      url: baseUrl.uploadUrl + name,
      data: file,
      headers: { "Content-Type": file.type },
    })
      .then((response) => {
        s3FileUrl = response.data;
        this.setState({ hideAttachmentBtn: fileArr.length === 1 });
        this.setState({
          file: fileArr,
          fileArrSimplified: fileArr,
        });
        alert("File Uploaded Successfully");
        // document.getElementById('upload-btn').classList.add('hide');
        // this.setState({fileAttachmentUrl: response.data, fileAttachmentName: this.state.fileName});
        hideLoading();
      })
      .catch((response) => {
        console.log("Error: " + response);
        alert("Error occurred! Please try again.");
        hideLoading();
      });
  };

  /**
   * saves market detail data to state
   */
  saveMarketDetail = () => {
    this.setState({
      showMRModal: false,
      isSaveDetailsClicked: true,
      userBaseFinal: this.state.userBase,
      competitorsFinal: this.state.competitors,
      fileAttachmentUrlFinal: s3FileUrl,
      fileAttachmentNameFinal: s3FileName,
    });
    let marketItem = {};
    marketItem.ideaID = null;
    marketItem.userBase = this.state.userBase;
    marketItem.competitors = this.state.competitors;
    marketItem.attachmentURL = s3FileUrl;
    marketItem.fileName = s3FileName;
    // marketItem.file = this.state.fileArrSimplified;
    // this.props.uploadFile(this.state.formData);
    this.props.updateMarketdetails(marketItem);
  };

  /**
   * hides market research detail modal
   */
  HideMRModal = () => {
    this.setState({
      showMRModal: false,
      userBase: this.state.userBaseFinal,
      competitors: this.state.competitorsFinal,
      fileAttachmentUrl: this.state.fileAttachmentUrlFinal,
      fileAttachmentName: this.state.fileAttachmentNameFinal,
    });
  };

  findSelectedDropdown(dropdownItem) {
    if (dropdownItem === "problemTypeValue") {
      let selIndex = this.state.renderJSON.problemTypes.findIndex((el) => el.selected);
      selIndex = selIndex === -1 ? 0 : selIndex
      const selValue = this.state.renderJSON.problemTypes[selIndex].text + this.state.renderJSON.problemTypes[selIndex].problemTypeID
      this.setState({problemTypeValue: selValue})
    }
    if (dropdownItem === "ideaTypeValue") {
      let selIndex = this.state.renderJSON.ideaTypes.findIndex((el) => el.selected);
      selIndex = selIndex === -1 ? 0 : selIndex
      const selValue = this.state.renderJSON.ideaTypes[selIndex].ideaTypeID
      this.setState({ideaTypeValue: selValue})
    }
    if (dropdownItem === "offeringPortfolios") {
      const item = {};
      const selIndex = this.state.offeringPortfolios.findIndex((el) => el.selected);
      // const selIndexUpdated = selIndex !== -1 ? selIndex : 0;
      // const offeringPortfolio = this.state.offeringPortfolios[selIndexUpdated];
      // item.value = offeringPortfolio?.offeringPortfolioId;
      // item.name = offeringPortfolio?.offeringPortfolioName;
      // this.setSelectedOfferingPortfolio(null, item);
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "talentOfferings") {
      const item = {};
      const selIndex = this.state.talentOfferings.findIndex((el) => el.selected);
      // const selIndexUpdated = selIndex !== -1 ? selIndex : 4;
      // const talentOffering = this.state.talentOfferings[selIndexUpdated];
      // item.value = talentOffering?.talentOfferingId;
      // item.name = talentOffering?.talentOfferingName;
      // this.setSelectedTalentOffering(null, item);
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "talentGroups") {
      const item = {};
      const selIndex = this.state.talentGrp.findIndex((el) => el.selected);
      // const selIndexUpdated = selIndex !== -1 ? selIndex : 4;
      // const talentGroup = this.state.talentGrp[selIndexUpdated];
      // item.value = talentGroup?.talentGroupId;
      // item.name = talentGroup?.talentGroupName;
      // this.setSelectedDropdownItem(null, item);
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "archeTypes") {
      const item = {};
      const selIndex = this.state.archetypes.findIndex((el) => el.selected);
      const selIndexUpdated = selIndex !== -1 ? selIndex : 6;
      const archeType = this.state.archetypes[selIndexUpdated];
      item.value = archeType?.archetypesId;
      item.name = archeType?.archetypesName;
      this.setSelectedArchetype(null, item);
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "industries") {
      const item = {};
      const selIndex = this.state.industryGroup.findIndex((el) => el.selected);
      const selIndexUpdated = selIndex !== -1 ? selIndex : 6;
      const industry = this.state.industryGroup[selIndexUpdated];
      item.value = industry?.industryId;
      item.name = industry?.industryName;
      this.setSelectedIndustryDropdownItem(null, item, false);
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "domains") {
      const item = {};
      const selIndex = this.state.domainGroup.findIndex((el) => el.selected);
      const selIndexUpdated = selIndex !== -1 ? selIndex : 0;
      const domain = this.state.domainGroup[selIndexUpdated];
      item.value = domain?.domainId;
      item.name = domain?.domainName;
      this.setSelectedDomainDropdownItem(null, item, false);
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "sectors") {
      const item = {};
      const selIndex = this.state.sectors.findIndex((el) => el.selected);
      const selIndexUpdated = selIndex !== -1 ? selIndex : 23;
      const sector = this.state.sectors[selIndexUpdated];
      item.value = sector?.sectorId;
      item.name = sector?.sectorName;
      this.setSelectedSector(null, item, false);
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "allainces") {
      const item = {};
      const selIndex = this.state.allianceVal.findIndex((el) => el.selected);
      const selIndexUpdated = selIndex !== -1 ? selIndex : 27;
      // const filArr = this.state.sectors.filter(el => el.selected)
      const allaince = this.state.allianceVal[selIndex];
      item.allianceId = allaince?.allianceId;
      item.allianceName = allaince?.allianceName;
      this.setState({ allaince: allaince });
      return selIndex === -1 ? null : this.props.renderJSON[dropdownItem]?.[selIndex];
    }
    if (dropdownItem === "priority") {
      return this.props.renderJSON[dropdownItem]
    }
    if (dropdownItem === "useCase") {
      return this.props.renderJSON[dropdownItem]
    }
    if(dropdownItem === "allianceOthers") {
      return this.props.renderJSON[dropdownItem]
    }
  }

  /**
   * finds selected talent grp
   */
  findSelectedTalentGrp = () => {
    return this.props.renderJSON.talentGroups.find((element) => {
      return element.selected === true;
    });
  };

  /**
   * finds selected industry grp
   */
  findSelectedIndustryGrp = () => {
    return this.props.renderJSON.industries.find((element) => {
      return element.selected === true;
    });
  };

  /**
   * finds selected industry grp
   */
  findSelectedDomainGrp = () => {
    return this.props.renderJSON.domain.find((element) => {
      return element.selected === true;
    });
  };

  /**
   * checks for the selected keywords
   * @param {Array} data
   */
  checkForSelectedKeywords = (data) => {
    let resultArr = [];
    data.forEach((keyword) => {
      resultArr.push({ tagID: parseInt(keyword.tagID), tag: keyword.tagName });
    });
    return resultArr;
  };

  getTeamMembersDetailsForDraft(ideaId) {
    showLoader();
    let draftIdeaMembersDetails = [];
    axios
      .get(
        config.getTeamMemberConfig.requestPayload.url.replace(
          "{ideaId}",
          ideaId
        ),
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        response?.data?.members?.map((member) => {
          let temp = {};
          temp.ideaId = ideaId;
          temp.memberRole = member.memberRole;
          temp.memberName = member.memberName;
          temp.memberEmail = member.userEmail;
          temp.memberLocation = member.memberLocation;
          draftIdeaMembersDetails.push(temp);
        });
        this.setState({ membersDetails: draftIdeaMembersDetails });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateTeamNameVal(val) {
    this.setState({ teamName: val });
    let temp = {};
    temp.teamName = val;
    this.props.updateTeamName(temp, true);
  }

  componentDidMount = () => {
    const selectedOfferingPortfolio =
      this.findSelectedDropdown("offeringPortfolios");
    const selectedTalentOffering = this.findSelectedDropdown("talentOfferings");
    const selectedTalentGroup = this.findSelectedDropdown("talentGroups");
    const selectedArchetype = this.findSelectedDropdown("archeTypes");
    const selectedIndustry = this.findSelectedDropdown("industries");
    const selectedDomain = this.findSelectedDropdown("domains");
    const selectedSector = this.findSelectedDropdown("sectors");
    const selectedAlliance = this.findSelectedDropdown("allainces");
    const selectedPriority = this.findSelectedDropdown("priority");
    const selectedUseCase = this.findSelectedDropdown("useCase");
    const selectedAllianceOthers = this.findSelectedDropdown("allianceOthers");
    this.findSelectedDropdown("problemTypeValue");
    this.findSelectedDropdown("ideaTypeValue");

    const defOption = {
      value: "00",
      name: "Choose an option",
      selected: true,
      visible: true,
    };
    this.setState({
      // selectedKeywords: [...this.checkForSelectedKeywords(this.state.renderJSON.keywords.userSelectedTags)],//this.state.renderJSON.keywords.userSelectedTags,
      selectedSector: selectedSector && Object.keys(selectedSector).length
        ? {
            value: selectedSector.sectorId,
            name: selectedSector.sectorName,
            selected: true,
            visible: true,
            parentId: selectedSector.industryId,
          }
        : this.state.isEnabled.selectedSector
        ? defOption
        : {},

      selectedItem: selectedTalentGroup && Object.keys(selectedTalentGroup).length
        ? {
            value: selectedTalentGroup.talentGroupId,
            name: selectedTalentGroup.talentGroupName,
            selected: true,
            visible: true,
            parentId: selectedTalentGroup.talentOfferingId,
          }
        : this.state.isEnabled.selectedItem
        ? defOption
        : {},

      selectedIndustryItem: selectedIndustry && Object.keys(selectedIndustry).length
        ? {
            value: selectedIndustry.industryId,
            name: selectedIndustry.industryName,
            selected: true,
            visible: true,
          }
        : this.state.isEnabled.selectedIndustryItem
        ? defOption
        : {},

      selectedDomainItem: selectedDomain && Object.keys(selectedDomain).length
        ? {
            value: selectedDomain.domainId,
            name: selectedDomain.domainName,
            selected: true,
            visible: true,
          }
        : this.state.isEnabled.selectedDomainItem
        ? defOption
        : {},

      selectedOfferingPortfolio: selectedOfferingPortfolio && Object.keys(selectedOfferingPortfolio).length
        ? {
            value: selectedOfferingPortfolio.offeringPortfolioId,
            name: selectedOfferingPortfolio.offeringPortfolioName,
            selected: true,
            visible: true,
          }
        : this.state.isEnabled.selectedOfferingPortfolio
        ? defOption
        : {},

      selectedTalentOffering: selectedTalentOffering && Object.keys(selectedTalentOffering).length
        ? {
            value: selectedTalentOffering.talentOfferingId,
            name: selectedTalentOffering.talentOfferingName,
            selected: true,
            visible: true,
            parentId: selectedTalentOffering.offeringportfolioId,
          }
        : this.state.isEnabled.selectedTalentOffering
        ? defOption
        : {},

      selectedArchetype: selectedArchetype && Object.keys(selectedArchetype).length
        ? {
            value: selectedArchetype.archetypesId,
            name: selectedArchetype.archetypesName,
            selected: true,
            visible: true,
            parentId: selectedArchetype.talentGroupId,
          }
        : {},

      priority: !(['Choose an option', 'N/A'].includes(selectedPriority)) ? selectedPriority : this.state.isEnabled.priority ? "Choose an option" : "N/A",
      useCase: !(['Choose an option', 'N/A'].includes(selectedUseCase)) ? selectedUseCase : this.state.isEnabled.useCase ? "Choose an option" : "N/A",

      alliance: selectedAlliance && Object.keys(selectedAlliance).length && selectedAlliance.allianceName !== 'N/A'
        ? {...selectedAlliance, selected: true}
        : this.state.isEnabled.alliance
        ? {allianceName: "Choose an option", allianceId: "00"}
        : "N/A",

      isEnabled: {
        ...this.state.isEnabled,
        allianceOthers: selectedAlliance && selectedAlliance.allianceName === 'Other'
      },

      allianceOthers: selectedAlliance && selectedAlliance.allianceName === 'Other' ? selectedAllianceOthers : null,

        // item.allianceId = allaince?.allianceId;
        // item.allianceName = allaince?.allianceName;

      teamMembersIds: this.props?.teamMembersIds?.length
        ? this.props.teamMembersIds
        : this.state.teamMembersIds.length
        ? this.state.teamMembersIds
        : [],
    });
    if (this.state.renderJSON.keywords.userSelectedTags !== undefined) {
      this.state.selectedKeywords = this.checkForSelectedKeywords(
        this.state.renderJSON.keywords.userSelectedTags
      );
      this.state.tempArr = this.checkForSelectedKeywords(
        this.state.renderJSON.keywords.userSelectedTags
      );
      this.props.updateSelectedKeywords(this.state.selectedKeywords);
      if (window.location.href.includes("draft")) {
        this.getTeamMembersDetailsForDraft(this.props.draftId);
      }
    }
    if (this.props.isChallengeIdea && this.props.ideaStatus === "New") {
      this.state.problemStatement = "";
      this.state.problemStatementCharCount = 0;
    }
    this.updateTeamName();
    this.props.isAddDetail();
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const makeFileArray = this.makeFileArray(
      this.props.renderJSON.marketResearch
    );
    // if (prevProps.marketFileList !== this.props.marketFileList) {
    //   this.setState({ fileArrSimplified: makeFileArray });
    // }
    if (prevState.showMRModal !== this.state.showMRModal) {
      if (!this.state.isSaveDetailsClicked) {
        if (this.state.file || this.state.fileArrSimplified) {
          this.setState({
            file: [],
            fileArrSimplified: [],
          });
        }
      }
    }
    if (prevState.tempArr !== this.state.tempArr) {
      this.setState({
        tagsBtnDisabled: this.state.tempArr.length >= 1 ? false : true,
      });
    }

    if (prevState.selectedKeywords !== this.state.selectedKeywords) {
      setTimeout(() => {
        this.enableSubmitButton();
      }, 250);
    }
    if (prevProps.renderJSON !== this.props.renderJSON) {
      let temp = {};
      temp.ideaName = this.props.renderJSON.ideaDescription.ideaName;
      temp.problemStateMent =
        this.props.renderJSON.ideaDescription.problemStateMent;
      temp.solution = this.props.renderJSON.ideaDescription.solution;
      this.props.updateIdeaDetail(temp, true);
      this.setState({ fileArrSimplified: makeFileArray });
    }
    if (prevState.addEmailText !== this.state.addEmailText) {
      if (
        this.state.addEmailText.includes("@") &&
        this.state.addEmailText.includes(".com")
      ) {
        const validateEmail = setTimeout(() => {
          this.onInputBlur();
        }, 2000);
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      (this.allianceWrapperRef &&
        !this.allianceWrapperRef.current?.contains(event.target) &&
        this.state.isListOpen.alliance) ||
      (this.priorityWrapperRef &&
        !this.priorityWrapperRef.current?.contains(event.target) &&
        this.state.isListOpen.priority) ||
      (this.yourTeamWrapperRef &&
        !this.yourTeamWrapperRef.current?.contains(event.target) &&
        this.state.isListOpen.yourTeam) ||
      (this.useCaseWrapperRef &&
        !this.useCaseWrapperRef.current?.contains(event.target) &&
        this.state.isListOpen.useCase)
    ) {
      for (let val of Object.keys(this.state.isListOpen)) {
        this.setState((prev) => ({
          isListOpen: {
            [val]: false,
          },
        }));
      }
    }
  }

  onToggleList(val) {
    this.setState((prev) => ({
      isListOpen: {
        ...prev.isListOpen,
        [val]: !prev.isListOpen[val],
      },
    }));
  }

  // Checks for member email validity
  onInputBlur = () => {
    if (this.state.addEmailText === "") {
      this.setState({ isEmailValid: false, errorClass: "invalidInputField" });
    } else if (this.userAlreadyExistsInTeam(this.state.addEmailText)) {
      this.setState({ isEmailValid: false, errorClass: "invalidInputField" });
    } else {
      const payload = {
        memberName: "",
        memberRole: "",
        panelist: false,
        userEmail: this.state.addEmailText,
      };
      const url = config.validateEmailConfig.requestPayload.url;
      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        })
        .then((response) => {
          this.setState({
            isEmailValid: response.data,
            errorClass: response.data ? "" : "invalidInputField",
            showErrorText: !response.data,
          });
          setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isEmailValid: false,
            errorClass: "invalidInputField",
            showErrorText: false,
          });
          setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
          }, 500);
        });
    }
  };

  /**
   * checks for the exising email
   * @param {String} Email
   */
  userAlreadyExistsInTeam = (userEmail) => {
    let isAlreadyExists = false;
    for (var i = 0; i < this.state.membersDetails.length; i++) {
      if (this.state.membersDetails[i].memberEmail === userEmail) {
        isAlreadyExists = true;
        break;
      }
    }
    return isAlreadyExists;
  };

  /**
   * Add member to idea
   * @param {Number} ideaId
   * @param {Boolean} isSubmitter
   */

  addMember = (ideaId, isSubmitter) => {
    showLoader();
    const payload = {
      ideaId: ideaId,
      memberEmail: this.state.addEmailText,
      memberName: this.state.addNameText,
      memberRole: this.state.addRoleText,
      memberLocation: this.state.addLocationText,
      submitter: isSubmitter,
      requesterComment: "",
      submitterComment: "",
    };
    axios
      .post(config.addTeamMemberConfig.requestPayload.url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`,
        },
      })
      .then((response) => {
        this.setState({
          showConfirmationModal: true,
          showAddTeamMemberModal: false,
        });
        this.setState((prevState) => ({
          membersDetails: [...prevState.membersDetails, payload],
        }));
        setTimeout(() => {
          this.setState({
            showConfirmationModal: false,
            errorClass: "invalidInputField",
            addEmailText: "",
            addNameText: "",
            addRoleText: "",
            roleText: "",
            addLocationText: "",
          });
        }, 3000);
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: error.response.data.errorMessage,
          showErrorModal: true,
          showAddTeamMemberModal: false,
        });
        setTimeout(() => {
          this.setState({ showErrorModal: false, errorMessage: "" });
        }, 3000);
        hideLoading();
      });
  };

  /**
   * Delete team member from Idea
   * @param {Number} ideaId
   */

  deleteMember = (ideaId) => {
    showLoader();
    let payload = {
      memberEmail: this.state.selectedDeleteTeamMember.memberEmail,
      ideaId: ideaId,
    };
    const deleteMemberUrl = config.deleteTeamMemberConfig.requestPayLoad.url;
    var data = payload;
    var deleteMemberRequest = {
      method: "delete",
      url: deleteMemberUrl,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        // idToken: `${localStorage.getItem("id_token")}`
      },
      data: data,
    };
    axios(deleteMemberRequest)
      .then((response) => {
        let newMemberDetails = this.state.membersDetails.filter((member) => {
          if (member.memberEmail !== payload.memberEmail) {
            return member;
          }
        });
        this.setState({
          showDeleteTeamMemberModal: false,
        });
        this.setState((prevState) => ({
          membersDetails: [...newMemberDetails],
        }));
        // this.props.getTeamMembersDetails(this.props.ideaDetails.ideaId);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  };

  //Add Member Name
  handleAddNameChange = (e) => {
    this.setState({ addNameText: e.target.value });
    setTimeout(() => {
      const isValid = this.validateModalFields();
      this.setState({ disableAddBtn: !isValid });
    }, 500);
  };

  //Add Member Email
  handleAddEmailChange = (e) => {
    this.setState({ addEmailText: e.target.value });
  };

  //Add Member Role
  handleAddRoleChange = (e) => {
    this.setState({ addRoleText: e.target.value });
    setTimeout(() => {
      const isValid = this.validateModalFields();
      this.setState({ disableAddBtn: !isValid });
    }, 500);
  };

  //Add Member Location
  handleAddLocationChange = (e) => {
    this.setState({ addLocationText: e.target.value });
    setTimeout(() => {
      const isValid = this.validateModalFields();
      this.setState({ disableAddBtn: !isValid });
    }, 500);
  };

  //Validate Member Info
  validateModalFields = () => {
    let validated =
      this.state.addNameText !== "" &&
      this.state.addEmailText !== "" &&
      this.state.addRoleText !== "" &&
      this.state.addLocationText !== "" &&
      this.state.isEmailValid;
    return validated;
  };

  makeFileArray = (arr) => {
    const fileList = [];
    if (arr !== undefined && Object.keys(arr).length > 0) {
      const file = {
        fileID: arr.ideaID,
        fileUrl: arr.attachmentURL,
        fileText: arr.fileName,
      };
      fileList.push(file);
    }
    return fileList;
  };

  checkForExistingDefaultSelection = (id) => {
    const checkIndex = this.state.selectedKeywords.findIndex(
      (tag) => tag.tagID === parseInt(id)
    );
    if (checkIndex > -1) {
      this.state.selectedKeywords.splice(checkIndex, 1);
    }
  };

  /**
   * handles keyword selection ad update
   * @param {event} e event
   */
  handleCheck = (e) => {
    const { id, checked, value } = e.target;
    e.target.attributes[checked] = checked;
    if (checked && this.state.tempArr.indexOf(id) === -1) {
      this.setState({
        tempArr: [
          ...this.state.tempArr,
          ...[{ tagID: parseInt(id), tag: value }],
        ],
      });
      newArr.push({ tagID: parseInt(id), tag: value });
    } else if (!checked) {
      // this.checkForExistingDefaultSelection(id);
      const checkIndex = this.state.tempArr.findIndex(
        (tag) => tag.tagID === parseInt(id)
      );
      this.state.tempArr.splice(checkIndex, 1);
      newArr.splice(checkIndex, 1);
    }
    this.setState({
      tagsBtnDisabled: this.state.tempArr.length >= 1 ? false : true,
    });
    this.enableSubmitButton();
  };

  setSelectedOfferingPortfolio(event, item) {
    this.setState({ selectedOfferingPortfolio: item });
    this.props.saveOfferingPortfolio(item, true);
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
  }

  setSelectedTalentOffering(event, item) {
    this.props.saveTalentOffering(item, true);
    const filteredTalentGroups = this.props.renderJSON.talentGroups.filter(
      (talentGroup) => {
        return talentGroup.talentOfferingId === item.value;
      }
    );
    this.setState({
      selectedTalentOffering: item,
      selectedArchetype: {},
      talentGrp: filteredTalentGroups,
    });
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
  }

  setSelectedDropdownItem(event, item) {
    const filteredArchetypes = this.props.renderJSON.archeTypes.filter(
      (archetype) => {
        return archetype.talentGroupId === item.value;
      }
    );
    this.setState({
      selectedItem: item,
      selectedArchetype: {},
      archetypes: filteredArchetypes,
    });
    this.props.saveTalentGrp(item, true);
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
  }

  setSelectedArchetype(event, item) {
    this.setState({ selectedArchetype: item });
    this.props.saveArchetype(item);
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
  }

  setSelectedIndustryDropdownItem(event, item, callSave=true) {
    const filteredSectors = this.props.renderJSON.sectors.filter((sector) => {
      return sector.industryId === item.value;
    });
    this.setState({
      selectedIndustryItem: item,
      sectors: filteredSectors,
    });
    if(callSave) this.props.saveIndustryGrp(item, true);
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
  }

  setSelectedDomainDropdownItem(event, item, callSave=true) {
    const filteredSectors = this.props.renderJSON.sectors.filter((domain) => {
      return domain.domainId === item.value;
    });
    this.setState({
      selectedDomainItem: item,
      sectors: filteredSectors,
    });
    if(callSave) this.props.saveDomainGrp(item, true);
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
  }

  setSelectedSector(event, item, callSave=true) {
    this.setState({ selectedSector: item });
    if(callSave) this.props.saveSector(item, true);
    setTimeout(() => {
      this.enableSubmitButton();
    }, 250);
  }

  mapToOfferingPortfolioOptions(list) {
    if (list?.length < 1) {
      return [];
    }
    return list?.map((element) => {
      const { offeringPortfolioId, offeringPortfolioName } = element;
      return {
        value: offeringPortfolioId,
        name: offeringPortfolioName,
        selected: false,
        visible: true,
        parentId: null,
      };
    });
  }

  mapToTalentOfferingOptions(list) {
    if (list?.length < 1) {
      return [];
    }
    return list?.map((element) => {
      const { talentOfferingId, talentOfferingName, offeringportfolioId } =
        element;
      return {
        value: talentOfferingId,
        name: talentOfferingName,
        selected: false,
        visible: true,
        parentId: offeringportfolioId,
      };
    });
  }

  mapToTalentGroupOptions(list, selectedOffering) {
    if (list?.length < 1 || !selectedOffering?.selected) {
      return [];
    }
    const filteredList = [];
    list?.forEach((element) => {
      const { talentGroupId, talentGroupName, talentOfferingId } = element;
      if (talentOfferingId === selectedOffering.value) {
        const item = {
          value: talentGroupId,
          name: talentGroupName,
          selected: false,
          visible: true,
          parentId: talentOfferingId,
        };
        filteredList.push(item);
      }
    });
    return filteredList;
  }

  mapToArchetypeOptions(list, selectedTalentGroup) {
    if (list?.length < 1 || !selectedTalentGroup?.selected) {
      return [];
    }
    const filteredList = [];
    list?.forEach((element) => {
      const { archetypesId, archetypesName, talentGroupId } = element;
      if (talentGroupId === selectedTalentGroup.value) {
        const item = {
          value: archetypesId,
          name: archetypesName,
          selected: false,
          visible: true,
          parentId: talentGroupId,
        };
        filteredList.push(item);
      }
    });
    return filteredList;
  }

  mapToSectorOptions(list, selectedIndustry) {
    if (list?.length < 1 || !selectedIndustry?.selected) {
      return [];
    }
    const filteredList = [];
    list?.forEach((element) => {
      const { sectorId, sectorName, industryId } = element;
      if (industryId === selectedIndustry.value) {
        const item = {
          value: sectorId,
          name: sectorName,
          selected: false,
          visible: true,
          parentId: industryId,
        };
        filteredList.push(item);
      }
    });
    return filteredList;
  }

  mapToIndustryOptions(list) {
    if (list?.length < 1) {
      return [];
    }
    return list?.map((element) => {
      const { industryId, industryName } = element;
      return {
        value: industryId,
        name: industryName,
        selected: false,
        visible: true,
      };
    });
  }

  mapToDomainOptions(list) {
    if (list?.length < 1) {
      return [];
    }
    return list?.map((element) => {
      const { domainId, domainName } = element;
      return {
        value: domainId,
        name: domainName,
        selected: false,
        visible: true,
      };
    });
  }

  render() {
    const offeringPortfolioOptions = this.mapToOfferingPortfolioOptions(
      this.state.offeringPortfolios
    );
    const talentOfferingOptions = this.mapToTalentOfferingOptions(
      this.state.talentOfferings
    );
    const dropdownOptions = this.mapToTalentGroupOptions(
      this.state.talentGrp,
      this.state.selectedTalentOffering
    );
    const archtypeOptions = this.mapToArchetypeOptions(
      this.state.archetypes,
      this.state.selectedItem
    );
    const industryDropdownOptions = this.mapToIndustryOptions(
      this.state.industryGroup
    );
    const domainDropdownOptions = this.mapToDomainOptions(
      this.state.domainGroup
    );
    const sectorOptions = this.mapToSectorOptions(
      this.state.sectors,
      this.state.selectedIndustryItem
    );

    let self = this,
      tags = [],
      selectedTags = [];
    this.state.renderJSON.keywords.suggestedTags.forEach((tag) => {
      if (
        this.state.tempArr.findIndex(
          (item) => item.tagID === parseInt(tag.tagID)
        ) >= 0
      ) {
        selectedTags.push(
          <label htmlFor={tag.tagID} className="checkLabel">
            {tag.tagName}
          </label>
        );
        tags.push(
          <input
            type="checkbox"
            onChange={self.handleCheck}
            className="optionCheck"
            name="tags"
            id={tag.tagID}
            value={tag.tagName}
            checked
          />
        );
      } else {
        tags.push(
          <input
            type="checkbox"
            onChange={self.handleCheck}
            className="optionCheck"
            name="tags"
            id={tag.tagID}
            value={tag.tagName}
          />
        );
      }
      tags.push(
        <label htmlFor={tag.tagID} className="checkLabel">
          {tag.tagName}
        </label>
      );
    });

    const priorityVal = ["Now", "Next", "Later"];
    const useCaseVal = ["Payment Inaccuracies", "Early Learning / Grants", "Staff Recommendations", "Next Best Action", "Eligibility Explainer", "Task Prioritization", "Policy Engine", "Snap Error Rate", "Genogram", "Policy Gap Analysis", "Efficiencies in CMS-64 Reporting"];

    const handleAllianceOtherChange = (e) => {
      const value = DOMPurify.sanitize(e.target.value);
      this.setState({ allianceOthers: value });
      this.props.handleAllianceOthers(value);
      setTimeout(() => {
        this.enableSubmitButton();
      }, 250);
    };

    const handleAllianceChange = (e) => {
      const { id, text } = e.target;
      this.setState({ alliance: { allianceId: id, allianceName: text } });
      if (text === "Other") {
        this.state.isEnabled.allianceOthers = true;
      } else {
        this.state.isEnabled.allianceOthers = false;
      }
      this.props.handleAllianceType(id, text);
      this.props.handleAllianceOthers(null);
      setTimeout(() => {
        this.enableSubmitButton();
      }, 250);
      // this.setState({ isListOpen: false });
      this.setState((prev) => ({
        isListOpen: {
          ...prev.isListOpen,
          alliance: false,
        },
      }));
    };

    const handlePriorityChange = (e) => {
      const { text } = e.target;
      this.setState({ priority: text });
      this.props.handlePriorityType(text);
      setTimeout(() => {
        this.enableSubmitButton();
      }, 250);
      this.updateAllIdeaVal();
      this.setState((prev) => ({
        isListOpen: {
          ...prev.isListOpen,
          priority: false,
        },
      }));
    };

    const handleUseCaseChange = (e) => {
      const { text } = e.target;
      this.setState({ useCase: text });
      this.props.handleUseCaseType(text);
      setTimeout(() => {
        this.enableSubmitButton();
      }, 250);
      this.updateAllIdeaVal();
      this.setState((prev) => ({
        isListOpen: {
          ...prev.isListOpen,
          useCase: false,
        },
      }));
    };

    return (
      <>
        {/* <Modal
                show={this.state.show}
                onHide={this.hideModal}
                class = "modal-AI"
                style = {{'width': '50%', 'margin-left': '25%'}}
                centered
      >
        <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="bodyHeader">
                    <div className="bodyHeaderHeading">
                    How can AI/Gen AI be leveraged to improve a challenge our clients are currently facing in the customer domain
                    </div> 
                    </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btnText"
                    onClick={this.hideModal}
                  >
                    OK
                  </button>
                </Modal.Footer>
      </Modal> */}
        {/* {this.state.showErrorOnPage && (
          <p className="addDetail-validate-email-label">
            {this.state.errorMessage}
          </p>
        )} */}
        <div className="addDetailsOuter">
          {
            //!this.props.isChallengeIdea &&
            <div className="addDetailsHeader">
              How can Deloitte bring innovative solutions to markets?
              {/* <br></br>
              <br></br>
              <span style={{ color: "red" }}>
                Note: Submission content cannot contain client names, client
                confidential information or any other reference identifying a
                client.
              </span> */}
            </div>
          }
          <div className="addDetailSection">
            <div className="addDetailSubSection">
              {this.state.teamMembersIds.length ? (
                <>
                  <div className="addDetailsTalentGrp">
                    <div className="addDetailsTalentGrp_sub-heading">
                      Your Team
                    </div>
                    <div
                      className="dropdown-wrapper"
                      ref={this.yourTeamWrapperRef}
                    >
                      <div className="dropdown selected">
                        <button
                          aria-expanded="false"
                          type="button"
                          className={classNames(
                            "dropdown-select",
                            this.state.isListOpen.yourTeam ? "is-open" : ""
                          )}
                          onClick={() => this.onToggleList("yourTeam")}
                          id="yourTeam"
                        >
                          {this.state.teamName || "Choose your team"}
                          <img src={downArr} alt="down-arrow" />
                        </button>
                        <ul
                          role="menu"
                          className={classNames(
                            "dropdown-menu",
                            this.state.isListOpen.yourTeam ? "show" : "d-none"
                          )}
                          aria-labelledby="yourTeam"
                        >
                          <div className="list-container">
                            {this.state.teamMembersIds.map((option, index) => {
                              return (
                                <li key={`option-yourTeam-${option}`}>
                                  <a
                                    role="menuitem"
                                    className={classNames(
                                      "dropdown-item",
                                      option === this.state.teamName
                                        ? "selected"
                                        : ""
                                    )}
                                    disabled={["All", "default"].includes(
                                      option
                                    )}
                                    onClick={(e) =>
                                      this.updateTeamNameVal(e.target.text)
                                    }
                                    data-index={index + 1}
                                    id={option}
                                  >
                                    {option}
                                  </a>
                                </li>
                              );
                            })}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            {/* <ProblemType
              onProblemTypeChange={this.handleProblemType}
              problemTypes={this.props.problemTypes}
              checkForValidType={this.props.checkForValidType}
              draftId={this.state.draftId}
              updateTeamName={(obj, savedraft) =>
                this.updateTeamName(obj, savedraft)
              }
              isNotAddDetail={this.props.isNotAddDetail}
            /> */}
            {/* <IdeaType
              onDataChange={this.handleIdeaType}
              ideaTypes={this.props.ideaTypes}
              checkForValidType={this.props.checkForValidType}
              isNotAddDetail={this.props.isNotAddDetail}
            /> */}

            <div className="addDetailSection">
              <label htmlFor="txtIdeaName" className="lblTextDesc">
                Name your Idea
              </label>
              <div className="addDetailSubSection">
                <input
                  type="text"
                  name=""
                  id="txtIdeaName"
                  placeholder="Enter Name"
                  className="txtBoxWithBorder"
                  maxLength="50"
                  value={this.state.ideaName}
                  onChange={(e) => this.handleIdeaNameChange(e)}
                  onBlur={() => {
                    this.updateAllIdeaVal();
                  }}
                />
              </div>
              <div className="addDetailNameHelpText addDetailSubSection">
                Let’s keep it short and precise
              </div>
            </div>
          </div>


          {this.state.isEnabled.useCase && (
                <>
                  <div className="addDetailSubSection">
                    <div className="addDetailsTalentGrp">
                      <div className="addDetailsTalentGrp_sub-heading">
                        Select Use Case
                      </div>
                      <div
                        className="dropdown-wrapper"
                        ref={this.useCaseWrapperRef}
                      >
                        <div className="dropdown selected">
                          <button
                            aria-expanded="false"
                            type="button"
                            className={classNames(
                              "dropdown-select",
                              this.state.isListOpen.useCase ? "is-open" : ""
                            )}
                            onClick={() => this.onToggleList("useCase")}
                            id="useCaseGroup"
                          >
                            {this.state.useCase || "Choose an option"}
                            <img src={downArr} alt="down-arrow" />
                          </button>
                          <ul
                            role="menu"
                            className={classNames(
                              "dropdown-menu",
                              this.state.isListOpen.useCase ? "show" : "d-none"
                            )}
                            aria-labelledby="useCaseGroup"
                          >
                            <div className="list-container">
                              {useCaseVal.map((option, index) => {
                                if (option !== "N/A") {
                                  return (
                                    <li key={`option-useCaseGroup-${index}`}>
                                      <a
                                        role="menuitem"
                                        className={classNames(
                                          "dropdown-item",
                                          option === this.state.useCase
                                            ? "selected"
                                            : ""
                                        )}
                                        disabled={["All", "default"].includes(
                                          option
                                        )}
                                        onClick={(e) => handleUseCaseChange(e)}
                                        data-index={index + 1}
                                      >
                                        {option}
                                      </a>
                                    </li>
                                  );
                                }
                              })}
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
          )}

          <div className="addDetailSection">
            <label htmlFor="txtProblem" className="lblTextDesc">
              What is the business opportunity that you are addressing through
              this idea?
              <span className="charcter-counter">
                {this.state.problemStatementCharCount}/
                {this.state.maxCharCountBsOp}
              </span>
            </label>
            <div className="addDetailSubSection">
              <RichTextEditor
                onRichTextEditorChange={(e) =>
                  this.handleProblemStatementChange(e)
                }
                currentValue={this.state.problemStatement}
                maxTextLength={1000}
                placeholder="Share little information about your idea."
                onBlur={() => {
                  this.updateAllIdeaVal();
                }}
              />
            </div>
          </div>
          <div className="addDetailSection">
            <label htmlFor="txtSolution" className="lblTextDesc">
              Please enter high level overview of the solution.
              <br></br>
              In your response, please address the market need, feasibility to
              bring to market, and how this solution is differentiated.
              <span className="charcter-counter">
                {this.state.solutionCharCount}/{this.state.maxCharCountSolution}
              </span>
            </label>
            <div className="addDetailSubSection">
              <RichTextEditor
                onRichTextEditorChange={(e) => this.handleIdeaSolutionChange(e)}
                currentValue={this.state.ideaSolution}
                maxTextLength={3000}
                placeholder={
                  this.props.isChallengeIdea
                    ? "How did you solve the challenge?"
                    : "What do you think is the solution to your problem statement?"
                }
                onBlur={() => {
                  this.updateAllIdeaVal();
                }}
              />
            </div>
          </div>
          {this.state.isEnabled.tools && (
            <div className="addDetailSection">
              <label htmlFor="txtTools" className="lblTextDesc">
                What tool(s) would be needed to bring this solution to life?
                <span className="charcter-counter">
                  {this.state.toolsCharCount}/{this.state.maxCharCountQuan}
                </span>
              </label>
              <div className="addDetailSubSection">
                <RichTextEditor
                  onRichTextEditorChange={this.handleTools}
                  currentValue={this.state.tools}
                  maxTextLength={2000}
                  placeholder="Brief about tool(s) required"
                  onBlur={() => {
                    this.updateAllIdeaVal();
                  }}
                />
              </div>
            </div>
          )}

          {this.state.isEnabled.potentialValue && (
            <div className="addDetailSection">
              <label htmlFor="potentialValue" className="lblTextDesc">
                <span>Please outline the potential value this solution will deliver e.g. ROI, Feasibility, Market Demand etc. (Qualitative and Quantitative)</span>
                <span className="charcter-counter">
                  {this.state.potentialValueCharCount}/{this.state.maxCharCount}
                </span>
              </label>
              <div className="addDetailSubSection">
                <RichTextEditor
                  onRichTextEditorChange={this.handlePotentialValueChange}
                  currentValue={this.state.potentialValue}
                  maxTextLength={2000}
                  placeholder="Share potential value for this idea."
                  onBlur={() => {
                    this.updateAllIdeaVal();
                  }}
                />
              </div>
            </div>
          )}

          <div className="addDetailSection">
            <div className="addDetailSectionHeader">
              {/* Supporting Documents (optional) */}
              Market Research/Supporting documents (optional)
            </div>
            <div className="addDetailSubSection">
              <div className="market-selectedTags">
                {(this.state.userBaseFinal ||
                  this.state.competitorsFinal ||
                  this.state.fileAttachmentNameFinal) && (
                  <ul>
                    {this.state.userBaseFinal && (
                      <li>
                        <p className="title">User Base (optional)</p>
                        <div className="detail">{this.state.userBaseFinal}</div>
                      </li>
                    )}
                    {this.state.competitorsFinal && (
                      <li>
                        <p className="title">Competitors (optional)</p>
                        <div className="detail">
                          {this.state.competitorsFinal}
                        </div>
                      </li>
                    )}
                    {this.state.fileAttachmentNameFinal && (
                      <li>
                        <p className="title">Uploaded Document</p>
                        <div className="detail">
                          {this.state.fileAttachmentNameFinal}
                        </div>
                      </li>
                    )}
                  </ul>
                )}
              </div>

              <button className="btn btnText" onClick={this.displayMRModal}>
                <span>+ </span>
                {this.state.userBaseFinal ||
                this.state.competitorsFinal ||
                this.state.fileAttachmentNameFinal
                  ? "Edit details"
                  : "Add details"}
              </button>

              <Modal
                show={this.state.showMRModal}
                onHide={this.HideMRModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Supporting Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="addDetailSection">
                    <label htmlFor="txtUserBase" className="lblTextDesc">
                      User base (optional)
                    </label>
                    <div className="addDetailSubSection">
                      <input
                        type="text"
                        id="txtUserBase"
                        className="txtBoxWithBorder"
                        placeholder="Share details about your target audience"
                        value={this.state.userBase}
                        onChange={(e) => this.handleUserBaseChange(e)}
                      />
                    </div>
                  </div>
                  <div className="addDetailSection">
                    <label htmlFor="txtCompetitors" className="lblTextDesc">
                      Competitors (optional)
                    </label>
                    <div className="addDetailSubSection">
                      <input
                        type="text"
                        id="txtCompetitors"
                        className="txtBoxWithBorder"
                        placeholder="Who are the competitors in the market?"
                        value={this.state.competitors}
                        onChange={(e) => this.handleCompetitorChange(e)}
                      />
                    </div>
                  </div>
                  <div className="addDetailSection">
                    <label htmlFor="btnAttachFile" className="lblTextDesc">
                      Attach research documents you made
                    </label>
                    <div className="addDetailSubSection">
                      <>
                        <button className="btn btnText" id="btnAttachFile">
                          Attach File
                        </button>
                        <input
                          type="file"
                          name="mrAttachments"
                          id="mrAttachments"
                          className="mrFiles"
                          accept=".pdf, .xls, .xlsx, .doc, .ppt, .docx, .pptx"
                          file={this.state.file}
                          onChange={(e) => this.showSelectedFiles(e)}
                          // multiple
                        />
                      </>
                      {this.state.fileArrSimplified !== undefined &&
                        this.state.fileArrSimplified.length > 0 && (
                          <ul className="attachment-list">
                            {this.state.fileArrSimplified?.map(
                              (file, index) => {
                                return (
                                  <li key={file.fileText + index}>
                                    <span>{file.fileText}</span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        )}
                    </div>
                    <div className="helpSection">
                      <div className="helpSectionHeader">Note</div>
                      <div className="helpSectionList">
                        <ul>
                          <li>
                            Save the Document name same as
                            IdeaName_FirstNameLastName
                          </li>
                          <li>Upload upto 1 file</li>
                          <li>
                            .pdf, .xls, .doc, .ppt files not more than 10 MB per
                            file
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="save-details"
                    onClick={this.saveMarketDetail}
                    disabled={!(this.state.fileArrSimplified.length > 0)}
                  >
                    Save details
                  </Button>
                </Modal.Footer>
              </Modal>
              {this.state.isEnabled.selectedOfferingPortfolio && (
                <div className="addDetailsTalentGrp">
                  {/* <div className="addDetailsTalentGrp_heading">
                    Which Offering Portfolio is this idea applicable to?
                  </div> */}
                  <div className="addDetailsTalentGrp_sub-heading">
                    Select Offering Portfolio
                  </div>
                  <DropDown
                    listItemSelected={this.setSelectedOfferingPortfolio.bind(
                      this
                    )}
                    options={offeringPortfolioOptions}
                    placeholderText="Choose an offering portfolio"
                    dropDownName="offeringPortfolio"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedOfferingPortfolio}
                  />
                </div>
              )}

              {this.state.isEnabled.selectedTalentOffering && (
                <div className="addDetailsTalentGrp">
                  {/* <div className="addDetailsTalentGrp_heading">
                    Which Offering is this idea applicable to?
                  </div> */}
                  <div className="addDetailsTalentGrp_sub-heading">
                    Select Offering
                  </div>
                  <DropDown
                    listItemSelected={this.setSelectedTalentOffering.bind(this)}
                    options={talentOfferingOptions}
                    placeholderText="Choose an offering"
                    dropDownName="talentOffering"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedTalentOffering}
                  />
                </div>
              )}

              {this.state.isEnabled.selectedItem && (
                <div className="addDetailsTalentGrp">
                  {/* <div className="addDetailsTalentGrp_heading">
                    Which Market Offering/Talent Group is this idea applicable
                    to?
                  </div> */}
                  <div className="addDetailsTalentGrp_sub-heading">
                    Select Talent Group
                  </div>
                  <DropDown
                    listItemSelected={this.setSelectedDropdownItem.bind(this)}
                    options={dropdownOptions}
                    placeholderText="Choose a Market Offering/Talent Group"
                    dropDownName="talentGroup"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedItem}
                  />
                </div>
              )}
              {this.state.isEnabled.selectedIndustryItem && (
                <div className="addDetailsTalentGrp">
                  {/* <div className="addDetailsTalentGrp_heading">
                    Which industry is this idea part of?
                  </div> */}
                  <div className="addDetailsTalentGrp_sub-heading">
                    Select Industry
                  </div>
                  <DropDown
                    listItemSelected={this.setSelectedIndustryDropdownItem.bind(
                      this
                    )}
                    options={industryDropdownOptions}
                    placeholderText="Choose an industry"
                    dropDownName="industryGroup"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedIndustryItem}
                  />
                </div>
              )}
              {this.state.isEnabled.selectedSector && (
                <div className="addDetailsTalentGrp">
                  {/* <div className="addDetailsTalentGrp_heading">
                    Which sector is this idea part of?
                  </div> */}
                  <div className="addDetailsTalentGrp_sub-heading">Select Sector</div>
                  <DropDown
                    listItemSelected={this.setSelectedSector.bind(this)}
                    options={sectorOptions}
                    placeholderText="Choose an option"
                    dropDownName="sector"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedSector}
                  />
                </div>
              )}

              {this.state.isEnabled.selectedDomainItem && (
                <div className="addDetailsTalentGrp">
                  {/* <div className="addDetailsTalentGrp_heading">
                    In what domain does this idea fall?
                  </div> */}
                  <div className="addDetailsTalentGrp_sub-heading">Select Domain</div>
                  <DropDown
                    listItemSelected={this.setSelectedDomainDropdownItem.bind(
                      this
                    )}
                    options={domainDropdownOptions}
                    placeholderText="Choose an domain"
                    dropDownName="domainGroup"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedDomainItem}
                  />
                </div>
              )}
              {this.state.isEnabled.alliance && (
                <>
                  <div className="addDetailsTalentGrp">
                    <div className="addDetailsTalentGrp_sub-heading">
                      Select Alliance
                    </div>
                    <div
                      className="dropdown-wrapper"
                      ref={this.allianceWrapperRef}
                    >
                      <div className="dropdown selected">
                        <button
                          aria-expanded="false"
                          type="button"
                          className={classNames(
                            "dropdown-select",
                            this.state.isListOpen.alliance ? "is-open" : ""
                          )}
                          onClick={() => this.onToggleList("alliance")}
                          id="allianceGroup"
                        >
                          {this.state.alliance.allianceName ||
                            "Choose an option"}
                          <img src={downArr} alt="down-arrow" />
                        </button>
                        <ul
                          role="menu"
                          className={classNames(
                            "dropdown-menu",
                            this.state.isListOpen.alliance ? "show" : "d-none"
                          )}
                          aria-labelledby="allianceGroup"
                        >
                          <div className="list-container">
                            {this.state.allianceVal.map((option, index) => {
                              if (![27, 28].includes(option.allianceId)) {
                                return (
                                  <li
                                    key={`option-allianceGroup-${option.allianceId}`}
                                  >
                                    <a
                                      role="menuitem"
                                      className={classNames(
                                        "dropdown-item",
                                        option.allianceName ===
                                          this.state.alliance.allianceName
                                          ? "selected"
                                          : ""
                                      )}
                                      disabled={["All", "default"].includes(
                                        option.allianceName
                                      )}
                                      onClick={(e) => handleAllianceChange(e)}
                                      data-index={index + 1}
                                      id={option.allianceId}
                                    >
                                      {option.allianceName}
                                    </a>
                                  </li>
                                );
                              }
                            })}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {this.state.isEnabled.allianceOthers && (
                <div className="addDetailSection">
                  <input
                    type="text"
                    name="allianceOthers"
                    placeholder="Please enter other alliance value"
                    onBlur={handleAllianceOtherChange}
                  />
                </div>
              )}

              {this.state.isEnabled.priority && (
                <>
                  <div className="addDetailsTalentGrp">
                    <div className="addDetailsTalentGrp_sub-heading">
                      Select Priority
                    </div>
                    <div
                      className="dropdown-wrapper"
                      ref={this.priorityWrapperRef}
                    >
                      <div className="dropdown selected">
                        <button
                          aria-expanded="false"
                          type="button"
                          className={classNames(
                            "dropdown-select",
                            this.state.isListOpen.priority ? "is-open" : ""
                          )}
                          onClick={() => this.onToggleList("priority")}
                          id="priorityGroup"
                        >
                          {this.state.priority || "Choose an option"}
                          <img src={downArr} alt="down-arrow" />
                        </button>
                        <ul
                          role="menu"
                          className={classNames(
                            "dropdown-menu",
                            this.state.isListOpen.priority ? "show" : "d-none"
                          )}
                          aria-labelledby="priorityGroup"
                        >
                          <div className="list-container">
                            {priorityVal.map((option, index) => {
                              if (option !== "N/A") {
                                return (
                                  <li key={`option-priorityGroup-${index}`}>
                                    <a
                                      role="menuitem"
                                      className={classNames(
                                        "dropdown-item",
                                        option === this.state.priority
                                          ? "selected"
                                          : ""
                                      )}
                                      disabled={["All", "default"].includes(
                                        option
                                      )}
                                      onClick={(e) => handlePriorityChange(e)}
                                      data-index={index + 1}
                                    >
                                      {option}
                                    </a>
                                  </li>
                                );
                              }
                            })}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default addDetails;
