import React, { Component } from "react";
import { connect } from "react-redux";
import "../../css/pages/draftSubmitted.scss";
import {
  doFileDownload,
  fetchIdeaAttachmentsList,
} from "../../redux/actions/utilities";
// import placeholderImg from "../../img/placeholder.png";
import downloadIcon from "../../img/downloadIcon.svg";
import LinkImg from "../../img/external-link.svg";
import { generateRandomKey, getBaseUrl } from "../../helpers/utils";
import axios from "axios";

let s3FileUrl = "";
const baseUrl = getBaseUrl();
class IdeaDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadedFileName: "",
      downloadedFile: {
        ref: React.createRef(),
        url: null,
        fileName: "",
      },
      userId: this.props.userData && this.props.userData.id,
      attachFileUrl: this.props.attachmentList
        ? this.props.attachmentList.attachments
        : [],
    };
  }

  componentDidMount() {
    const pageUrl = window.location.href;
    const ideaId = pageUrl.substring(pageUrl.lastIndexOf("/") + 1);
    // this.props.fetchIdeaAttachmentsList(parseInt(this.props.ideaDetails.ideaId), 'IDEA');
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.FILE_DOWNLOAD_STATUS !== "SUCCESS" &&
      this.props.FILE_DOWNLOAD_STATUS === "SUCCESS"
    ) {
      const url = window.URL.createObjectURL(new Blob([this.props.file]));
      this.setState(
        {
          downloadedFile: {
            ...this.state.downloadedFile,
            url: url,
            fileName: this.state.downloadedFileName,
          },
        },
        () => this.state.downloadedFile.ref.current.click()
      );
    }
    if (prevProps.attachmentList !== this.props.attachmentList) {
      this.setState({
        attachFileUrl: this.props.attachmentList.attachments,
      });
    }
    if (prevProps.ideaDetails !== this.props.ideaDetails) {
      this.getUploadedS3FileUrl(this.props.ideaDetails.fileName);
    }
  }

  getUploadedS3FileUrl = (fileName) => {
    axios
      .get(baseUrl.getS3FileUrl + fileName)
      .then((response) => {
        s3FileUrl = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * user click on download file
   * @param {object} file file object to be downloaded
   */
  downloadFile(file) {
    this.props.doFileDownload(file.requestId, file.fileName);
    this.setState({ downloadedFileName: file.fileName });
  }

  render() {
    const ideaDetails = this.props.ideaDetails || {};

    return (
      <>
        <section className="homeMain ideaMain approve-flow-detail">
          <div className="ideaHeader">
            {typeof ideaDetails !== undefined ? ideaDetails.ideaName : ""}
          </div>
          <div className="ideaLeftSection">
            <div className="idea-details_panelView-evaluate">
              <div className="panelView-item">
                <span className="ideaDesc-title"> Code: </span>
                {ideaDetails.ideaId}
              </div>
              <div className="panelView-item">
                <span className="ideaDesc-title"> Category: </span>
                {ideaDetails.ideaType ? ideaDetails.ideaType : ""}
              </div>
              <div className="panelView-item">
                <span className="ideaDesc-title"> Industry: </span>
                {ideaDetails.industry ? ideaDetails.industry : ""}
              </div>
              <div className="panelView-item">
                <span className="ideaDesc-title"> Sector: </span>
                {ideaDetails.sector ? ideaDetails.sector : ""}
              </div>
              <div className="panelView-item">
                <span className="ideaDesc-title"> Domain: </span>
                {ideaDetails.domain ? ideaDetails.domain : ""}
              </div>
              <div className="panelView-item">
                <span className="ideaDesc-title"> Alliance: </span>
                {ideaDetails.alliance ? ideaDetails.alliance : ""}
              </div>
              { ideaDetails.allainceOther && (
                <div className="panelView-item">
                  <span className="ideaDesc-title"> Alliance Other: </span>{ideaDetails.allainceOther}
                </div>
              )}
              <div className="panelView-item">
                <span className="ideaDesc-title"> Priority: </span>
                {ideaDetails.priority ? ideaDetails.priority : ""}
              </div>
            </div>
            {/* <div className="ideaPeople">
              <div className="idea-people-mentor">
                <div className="description">
                  <p>
                    Creator : {typeof ideaDetails !== undefined && ideaDetails.peopleData
                      ? ideaDetails.peopleData.ideaBy.name
                      : ""}
                  </p>
                </div>
              </div>
            </div> */}
            <div className="ideaDesc">
              <div className="ideaDesc-title">Business opportunity:</div>
              <p
                className="ideaDesc-detail"
                dangerouslySetInnerHTML={{
                  __html:
                    typeof ideaDetails !== undefined
                      ? ideaDetails.description
                      : "",
                }}
              ></p>
              <div className="ideaDesc-title">
                High level overview of solution:
              </div>
              <p
                className="ideaDesc-detail"
                dangerouslySetInnerHTML={{
                  __html:
                    typeof ideaDetails !== undefined
                      ? ideaDetails.ideaSolution
                      : "",
                }}
              ></p>

              <div className="ideaDesc-title">
                Tool(s) needed to bring solution to life:
              </div>
              <p
                className="ideaDesc-detail"
                dangerouslySetInnerHTML={{
                  __html:
                    typeof ideaDetails !== undefined ? ideaDetails.tools : "",
                }}
              ></p>

              <div className="ideaDesc-title">
                Potential value delivered (Qualitative and Quantitative):
              </div>
              <p
                className="ideaDesc-detail"
                dangerouslySetInnerHTML={{
                  __html:
                    typeof ideaDetails !== undefined
                      ? ideaDetails.potentialValue
                      : "",
                }}
              >
                {}
              </p>
            </div>
          </div>
          <div className="ideaRightSection">
            {/* {this.state.attachFileUrl && this.state.attachFileUrl.length > 0 && ( */}
            {ideaDetails.fileName !== null &&
              ideaDetails.attachmentURL !== null &&
              ideaDetails.attachmentURL !== "" && (
                <div className="ideaSection-detail attachments">
                  <div className="ideaDesc-title">
                    Market research/Supporting document
                  </div>
                  <ul className="attachment-detail">
                    {/* {this.state.attachFileUrl.map((file, index) => {
                    return (
                      <> */}
                    <li key={generateRandomKey()}>
                      <div>{ideaDetails.fileName}</div>
                      <a
                        target="_blank"
                        className="btn btnText attachment-detail_link"
                        href={s3FileUrl || ideaDetails.attachmentURL}
                      >
                        View <img src={LinkImg} alt="link" />
                      </a>
                    </li>
                    {/* <a style={{ display: 'none' }} href={this.state.downloadedFile.url} download={this.state.downloadedFile.fileName} ref={this.state.downloadedFile.ref}>DOWNLOAD</a> */}
                    {/* </>
                    );
                  })} */}
                  </ul>
                </div>
              )}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FILE_DOWNLOAD_STATUS: state.utilities.meta.FILE_DOWNLOAD_STATUS,
    file: state.utilities.data.file,
    ATTACHMENT_LIST_STATUS: state.utilities.meta.ATTACHMENT_LIST_STATUS,
    attachmentList: state.utilities.data.attachmentList,
  };
};

const mapDispatchToProps = {
  doFileDownload,
  fetchIdeaAttachmentsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeaDetail);
