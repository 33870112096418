import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/global/header";
import Modal from "react-bootstrap/Modal";
import "../css/pages/teamFormation.scss";
import axios from "axios";
import config from "../helpers/api-urls";
import { getTeamMembersDetails, fetchIdeaDetails } from "../redux/actions/ideas";
import { showLoader, hideLoading } from "../helpers/utils";
import actionIcon from '../img/action-icon.svg';
import DOMPurify from "dompurify";

class TeamRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddTeamMemberModal: false,
            disableAddBtn: true,
            addRoleText: '',
            addLocationText : "",
            addEmailText: '',
            addNameText: '',
            isEmailValid: false,
            membersDetails: {},
            requestDetails: [],
            showConfirmationModal: false,
            errorClass: '',
            ideaId: 0,
            errorMessage: '',
            showErrorModal: false
        };
    }

    componentDidMount() {
        showLoader();
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("id")) {
            this.setState({ ideaId: parseInt(urlParams.get("id")) });
            this.props.getTeamMembersDetails(parseInt(urlParams.get("id")));
            this.props.fetchIdeaDetails(parseInt(urlParams.get("id")), this.props.userData.id);
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if (prevProps.membersDetails !== this.props.membersDetails) {
            const tempArr = this.props.membersDetails.requesters.filter(function (item) {
                return item.approveStatus !== 'REJECTED';
            });
            this.setState({ membersDetails: this.props.membersDetails, requestDetails: tempArr });
        }
        setTimeout(() => {
            hideLoading();
        }, 1000);

        if (prevState.addEmailText !== this.state.addEmailText) {
            if (
                this.state.addEmailText.includes("@") &&
                this.state.addEmailText.includes(".com")
            ) {
                const validateEmail = setTimeout(() => {
                    this.onInputBlur();
                }, 2000);
            }
        }
    }

    handleAddRoleChange = (e) => {
        const val = DOMPurify.sanitize(e.target.value)
        this.setState({ addRoleText: val});
        setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
        }, 500);
    }

    handleAddEmailChange = (e) => {
        const val = DOMPurify.sanitize(e.target.value)
        this.setState({ addEmailText: val });
    }

    onInputBlur = () => {
        if (this.state.addEmailText === '') {
            this.setState({ isEmailValid: false, errorClass: 'invalidInputField' });
        } else {
            const payload = {
                "memberName": "",
                "memberRole": "",
                "panelist": false,
                "userEmail": this.state.addEmailText
            };
            const url = config.validateEmailConfig.requestPayload.url;
            axios.post(url, payload, {
                headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
            })
                .then((response) => {
                    this.setState({ isEmailValid: response.data, errorClass: response.data ? '' : 'invalidInputField' });
                }).catch((error) => {
                    console.log(error);
                    this.setState({ isEmailValid: false, errorClass: 'invalidInputField' });
                });
        }
        setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
        }, 1000);
    }

    handleAddNameChange = (e) => {
        const val = DOMPurify.sanitize(e.target.value)
        this.setState({ addNameText: val });
        setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
        }, 500);
    }

    //Add Member Location
    handleAddLocationChange = (e) => {
        const val = DOMPurify.sanitize(e.target.value)
        this.setState({ addLocationText: val });
        setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
        }, 500);
    }

    validateModalFields = () => {
        let validated =
            this.state.addNameText !== "" &&
            this.state.addEmailText !== "" &&
            this.state.addRoleText !== "" &&
            this.state.addLocationText !== "" &&
            this.state.isEmailValid;
        return validated;
    }

    addMember = () => {
        showLoader();
        const payload = {
            "ideaId": this.state.ideaId,
            "memberEmail": this.state.addEmailText,
            "memberName": this.state.addNameText,
            "memberRole": this.state.addRoleText,
            "submitter": true,
            "memberLocation": this.state.addLocationText,
            "requesterComment": "",
            "submitterComment": ""
        };
        axios.post(config.addTeamMemberConfig.requestPayload.url, payload, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
            .then((response) => {
                this.setState({ showConfirmationModal: true, showAddTeamMemberModal: false });
                setTimeout(() => {
                    this.setState({
                        showConfirmationModal: false,
                        errorClass: "invalidInputField",
                        addEmailText: "",
                        addNameText: "",
                        addRoleText: "",
                        addLocationText : ""
                      });
                    this.props.getTeamMembersDetails(this.state.ideaId);
                }, 4000);
                hideLoading();
            })
            .catch((error) => {
                console.log(error);
                this.setState({ errorMessage: error.response.data.errorMessage, showErrorModal: true, showJoinContributorModal: false, showAddTeamMemberModal: false });
                setTimeout(() => {
                    this.setState({ showErrorModal: false, errorMessage: '' });
                }, 3000);
                hideLoading();
            });
    }

    rejectRequest = (email) => {
        showLoader();
        const payload = {
            "ideaId": this.state.ideaId,
            "memberEmail": email,
            "submitterComment": ""
        };
        axios.post(config.rejectTeamMemberConfig.requestPayload.url, payload, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
            .then((response) => {
                this.props.getTeamMembersDetails(this.state.ideaId);
                hideLoading();
            })
            .catch((error) => { console.log(error); hideLoading(); });
    }

    acceptRequest = (email) => {
        showLoader();
        const payload = {
            "ideaId": this.state.ideaId,
            "memberEmail": email,
            "submitterComment": ""
        };
        axios.post(config.approveTeamMemberConfig.requestPayload.url, payload, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
            .then((response) => {
                this.props.getTeamMembersDetails(this.state.ideaId);
                hideLoading();
            })
            .catch((error) => { console.log(error); hideLoading(); });
    }

    render() {
        const userData = this.props.userData;
        const ideaDetails = this.props.ideaDetails || {};
        return (
            <>
                <Header
                    values={userData}
                    showSearch={true}
                    showUser={true}
                    pageName={"teamRequests"}
                />
                <div className="teamFormation">
                    <div className="heading">
                        Requests
                    </div>
                    <div className="teamFormation_list-cont">
                        <div className="teamFormation_list-cont_lt">
                            <div className="ideaSection-detail">
                                <div className="ideaSection-detail_subsection">
                                    <div className="subsection-header">{typeof ideaDetails !== undefined ? ideaDetails.ideaName : ""}</div>
                                    {Object.keys(this.state.membersDetails).length > 0 && this.state.requestDetails.length > 0 && (
                                        <>
                                            <div className="flex-header">
                                                <p>Name</p><p>Email</p><p>Date</p>
                                            </div>
                                            <ul className="request-list">
                                                {this.state.requestDetails.map((request) => {
                                                    return <>
                                                        <li>
                                                            <div className="flex-header list-item">
                                                                <p>{request.memberName}</p><p>{request.memberEmail}</p><p>{request.appliedDate}</p>
                                                            </div>
                                                            <div className="role">{request.memberRole}</div>
                                                            <div className="action-btn">
                                                                <button className="btn btnText" onClick={() => this.rejectRequest(request.memberEmail)}>Reject Request</button>
                                                                <button className="btn primary" onClick={() => this.acceptRequest(request.memberEmail)}>Accept Request</button>
                                                            </div>
                                                            {/* <img src={actionIcon} className="action-icon" /> */}
                                                        </li>
                                                    </>
                                                })}
                                            </ul>
                                        </>
                                    )}
                                    {Object.keys(this.state.membersDetails).length > 0 && this.state.requestDetails.length === 0 && (
                                        <div className="no-results">
                                            No New Requests!
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="teamFormation_list-cont_rt">
                            <div className="ideaSection-detail">
                                <div className="ideaSection-detail_subsection">
                                    <div className="subsection-header">
                                        <p>The Team</p>
                                        {Object.keys(this.state.membersDetails).length > 0 && this.state.membersDetails.members.length < 8 && (
                                            <button className="btn btnText" onClick={() => this.setState({ showAddTeamMemberModal: true })}><span>+</span>Add Team Members</button>
                                        )}
                                    </div>
                                    <ul className="team-list">
                                        <li>
                                            <div className="section-left">
                                            <p className="section-left__name" title={Object.keys(this.state.membersDetails).length > 0 ? this.state.membersDetails.ideaSubmitter : ""}>{Object.keys(this.state.membersDetails).length > 0 ? this.state.membersDetails.ideaSubmitter : ""}</p>
                                            </div>
                                            <div className="section-right is-not-creator"><p>Submitter</p></div>
                                        </li>
                                        {Object.keys(this.state.membersDetails).length > 0 && this.state.membersDetails.members.map((member) => {
                                            return (
                                            <>
                                                <li>
                                                <div className="section-left">
                                                    <p className="section-left__name" title={member.memberName}>{member.memberName}</p>
                                                    <p title={member.userEmail}>@{member.userEmail.split('@')[0]}</p>
                                                    <p title={member.memberLocation}>({member.memberLocation})</p>
                                                </div>
                                                <div className="section-right is-not-creator">
                                                    <p>{member.memberRole}</p>
                                                </div>                              
                                                </li>
                                            </>
                                            );
                                        })}
                                    </ul>
                                    {Object.keys(this.state.membersDetails).length > 0 && this.state.membersDetails.members.length >= 8 && (
                                        <em className="other-note-text">Maximum of 9 Team Members</em>
                                    )}
                                </div>
                                <Modal
                                    show={this.state.showAddTeamMemberModal}
                                    onHide={() => this.setState({
                                        showAddTeamMemberModal: false,
                                        errorClass: "invalidInputField",
                                        addEmailText: "",
                                        addNameText: "",
                                        addRoleText: "",
                                        addLocationText : "",
                                      })}
                                    backdrop="static"
                                    keyboard={false}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    dialogClassName="team-formation-modal"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Team Members</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="bodyHeader">
                                            <div className="bodyHeaderHeading">
                                                Name
                                            </div>
                                            <input type="text" placeholder="Full Name of the Person" value={this.state.addNameText} onChange={(e) => this.handleAddNameChange(e)} />
                                            <div className="bodyHeaderHeading">
                                                Email
                                            </div>
                                            <input className={this.state.errorClass} type="text" placeholder="Deloitte Email addess" value={this.state.addEmailText} onChange={(e) => this.handleAddEmailChange(e)} />
                                            <div className="bodyHeaderHeading">
                                                Role
                                            </div>
                                            <input placeholder="Describe Role" type="text" value={this.state.addRoleText} onChange={(e) => this.handleAddRoleChange(e)} />
                                            <div className="bodyHeaderHeading">Location</div>
                                            <input
                                                placeholder="Member Location"
                                                type="text"
                                                value={this.state.addLocationText}
                                                onChange={(e) => this.handleAddLocationChange(e)}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            className="btn primary"
                                            disabled={this.state.disableAddBtn}
                                            onClick={() => this.addMember()}>
                                            Add as Team Member
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal
                                    show={this.state.showConfirmationModal}
                                    onHide={() => this.setState({ showConfirmationModal: false })}
                                    backdrop="static"
                                    keyboard={false}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    dialogClassName="team-formation-modal"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Success!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="bodyHeader">
                                            <div className="bodyHeaderHeading">
                                                {this.state.addEmailText} Added Successfully!
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal
                                    show={this.state.showErrorModal}
                                    onHide={() => this.setState({ showErrorModal: false })}
                                    backdrop="static"
                                    keyboard={false}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    dialogClassName=""
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Error!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="bodyHeader">
                                            <div className="bodyHeaderHeading">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        GET_MEMBER_DETAILS_STATUS: state.ideas.meta.GET_MEMBER_DETAILS_STATUS,
        membersDetails: state.ideas.data.membersDetails,
        FETCH_IDEA_DETAILS_STATUS: state.ideas.meta.FETCH_IDEA_DETAILS_STATUS,
        ideaDetails: state.ideas.data.ideaDetails
    };
};

const mapDispatchToProps = {
    getTeamMembersDetails,
    fetchIdeaDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamRequests);