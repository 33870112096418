import React, { Component } from "react";
import MediaQuery from "react-responsive";
import Header from "../components/global/header";
import { connect } from "react-redux";
import FilterAccordion from "../components/search/filterAccordion";
import GlobalSearchNoResults from "../components/search/globalSearchNoResults";
import Pagination from "../components/global/pagination";
import { getGlobalResults } from "../redux/actions/ideas";
import "../css/pages/search-results.scss";
import SearchItem from "../components/search/searchItem";
import closeGreyIcon from "../img/close-grey.svg";
import { hideLoading, showLoader } from "../helpers/utils";
import ContextMenuWithHook from "../components/global/contextMenu";

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label
  }

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    let content;
    let buttons = [];
    return (
      <div className="search-filter_modal-tabs">
        {React.Children.map(this.props.children, child => {
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}
        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} selectedTalentGrpList={this.props.selectedTalentGrpList} selectedTagsList={this.props.selectedTagsList} selectedCategoryList={this.props.selectedCategoryList} />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab, selectedTagsList, selectedTalentGrpList, selectedCategoryList }) => {
  return (
    <div className="tab-buttons">
      {buttons.map(button => {
        return <button key={button} className={button === activeTab ? 'active' : ''} onClick={() => changeTab(button)}>
          {button}
          {button.toLowerCase().indexOf('categories') > -1 && selectedCategoryList.length > 0 && (
            <span>{selectedCategoryList.length}</span>
          )}
          {button.toLowerCase().indexOf('tags') > -1 && selectedTagsList.length > 0 && (
            <span>{selectedTagsList.length}</span>
          )}
          {button.toLowerCase().indexOf('capability') > -1 && selectedTalentGrpList.length > 0 && (
            <span>{selectedTalentGrpList.length}</span>
          )}
        </button>
      })}
    </div>
  )
}

const Tab = props => {
  alert(props);
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideaItems: this.props.searchResults ? this.props.searchResults.ideasList : [],
      searchTerm: "",
      totalRecords: this.props.searchResults ? this.props.searchResults.totalRecords : 0,
      searchFilters: this.props.searchResults ? this.props.searchResults.filtersBySearchResults : {},
      loading: false,
      perPageRows: 8,
      pageIndex: 0,
      currentPage: 1,
      options: [1, 2, 3],
      minCharactersRequired: 2,
      categorySelectedArr: [],
      tagsSelectedArr: [],
      talentSelectedGrp: [],
      combinedSelectedArr: [],
      selectedItem: {
        value: "Ideas",
        name: "Ideas",
        selected: true,
        visible: true,
      },
      clicked: 0,
      showFilterModal: false,
      contextLink: '',
    };
    this.setContextLink = this.setContextLink.bind(this);
  }

  componentDidMount() {
    showLoader();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("term")) {
      const term = urlParams.get("term");
      this.setState({ searchTerm: term });
      const requestPayload = {
        "advancedSearchFilters": {
          "categoryIds": [],
          "tagIds": [],
          "talentGroupIds": []
        },
        "currentPaginationLevel": this.state.pageIndex,
        "pageSize": this.state.perPageRows,
        "searchTerm": term
      }
      this.props.getGlobalResults(requestPayload);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchResults !== this.props.searchResults) {
      this.setState({
        ideaItems: this.props.searchResults.ideasList,
        totalRecords: this.props.searchResults.totalRecords,
        pageIndex: this.props.searchResults.currentPaginationLevel,
        searchFilters: this.props.searchResults.filtersBySearchResults
      });
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  handleToggle = (index) => {
    if (this.state.clicked === index) {
      return this.setState({ clicked: null });
    } else {
      this.setState({ clicked: index });
    }
  };

  updateSelectedFilter = (item, title, isRemoved) => {
    showLoader();
    const itemId = parseInt(item.id);
    if (title.toLowerCase().indexOf('categories') > -1) {
      let idArray = this.state.categorySelectedArr;
      if (isRemoved) {
        const index = idArray.indexOf(itemId);
        if (index !== -1) {
          idArray.splice(index, 1);
        }
        this.setState({ categorySelectedArr: idArray });
      } else {
        this.state.categorySelectedArr.push(itemId);
      }
    } else if (title.toLowerCase().indexOf('tags') > -1) {
      let idArray = this.state.tagsSelectedArr;
      if (isRemoved) {
        const index = idArray.indexOf(itemId);
        if (index !== -1) {
          idArray.splice(index, 1);
        }
        this.setState({ tagsSelectedArr: idArray });
      } else {
        this.state.tagsSelectedArr.push(itemId);
      }
    } else if (title.toLowerCase().indexOf('capability') > -1) {
      let idArray = this.state.talentSelectedGrp;
      if (isRemoved) {
        const index = idArray.indexOf(itemId);
        if (index !== -1) {
          idArray.splice(index, 1);
        }
        this.setState({ talentSelectedGrp: idArray });
      } else {
        this.state.talentSelectedGrp.push(itemId);
      }
    }
    let combinedArr = this.state.combinedSelectedArr;
    if (isRemoved) {
      const index = combinedArr.findIndex((arr) => arr.id === itemId && arr.type === title.toLowerCase());
      if (index !== -1) {
        combinedArr.splice(index, 1);
      }
      this.setState({ combinedSelectedArr: combinedArr });
    } else {
      const arr = [{
        id: itemId,
        text: item.text,
        type: title.toLowerCase()
      }];
      this.setState({ combinedSelectedArr: [...this.state.combinedSelectedArr, ...arr] });
    }

    const requestPayload = {
      "advancedSearchFilters": {
        "categoryIds": this.state.categorySelectedArr,
        "tagIds": this.state.tagsSelectedArr,
        "talentGroupIds": this.state.talentSelectedGrp
      },
      "currentPaginationLevel": 0,
      "pageSize": this.state.perPageRows,
      "searchTerm": this.state.searchTerm
    }
    this.setState({ pageIndex: 0, currentPage: 1 });
    this.clearSelectedSort();
    this.props.getGlobalResults(requestPayload);
  }

  removeSelectedFilter = (item) => {
    showLoader();
    let combinedArr = this.state.combinedSelectedArr;
    const index = combinedArr.findIndex((arr) => arr.id === item.id && arr.type === item.type.toLowerCase());
    if (index !== -1) {
      combinedArr.splice(index, 1);
    }
    this.setState({ combinedSelectedArr: combinedArr });
    if (item.type.toLowerCase().indexOf('categories') > -1) {
      let idArray = this.state.categorySelectedArr;
      const idIndex = idArray.indexOf(item.id);
      if (idIndex !== -1) {
        idArray.splice(idIndex, 1);
      }
      this.setState({ categorySelectedArr: idArray });
    } else if (item.type.toLowerCase().indexOf('tags') > -1) {
      let idArray = this.state.tagsSelectedArr;
      const idIndex = idArray.indexOf(item.id);
      if (idIndex !== -1) {
        idArray.splice(idIndex, 1);
      }
      this.setState({ tagsSelectedArr: idArray });
    } else if (item.type.toLowerCase().indexOf('capability') > -1) {
      let idArray = this.state.talentSelectedGrp;
      const idIndex = idArray.indexOf(item.id);
      if (idIndex !== -1) {
        idArray.splice(idIndex, 1);
      }
      this.setState({ talentSelectedGrp: idArray });
    }

    const requestPayload = {
      "advancedSearchFilters": {
        "categoryIds": this.state.categorySelectedArr,
        "tagIds": this.state.tagsSelectedArr,
        "talentGroupIds": this.state.talentSelectedGrp
      },
      "currentPaginationLevel": 0,
      "pageSize": this.state.perPageRows,
      "searchTerm": this.state.searchTerm
    }
    this.setState({ pageIndex: 0, currentPage: 1 });
    this.clearSelectedSort();
    this.props.getGlobalResults(requestPayload);
  };

  resetAllFilter = () => {
    showLoader();
    this.setState({ combinedSelectedArr: [], categorySelectedArr: [], tagsSelectedArr: [], talentSelectedGrp: [] });
    const requestPayload = {
      "advancedSearchFilters": {
        "categoryIds": [],
        "tagIds": [],
        "talentGroupIds": []
      },
      "currentPaginationLevel": 0,
      "pageSize": this.state.perPageRows,
      "searchTerm": this.state.searchTerm
    }
    this.setState({ pageIndex: 0, currentPage: 1 });
    this.clearSelectedSort();
    this.props.getGlobalResults(requestPayload);
  };

  /**
 * change the page number from pagination
 * @param {Number} page_number current page number
 */
  pageChangeHandler = page_number => {
    showLoader();
    this.setState({ currentPage: page_number, pageIndex: Number(page_number - 1) });
    const requestPayload = {
      "advancedSearchFilters": {
        "categoryIds": this.state.categorySelectedArr,
        "tagIds": this.state.tagsSelectedArr,
        "talentGroupIds": this.state.talentSelectedGrp
      },
      "currentPaginationLevel": Number(page_number - 1),
      "pageSize": this.state.perPageRows,
      "searchTerm": this.state.searchTerm
    }
    this.props.getGlobalResults(requestPayload);
  };

  /**
 * change the page number list based on start and end parameters
 * @param {Number} start Start page number
 * @param {Number} end End page number
 */
  sliceChangeHandler = (start, end) => {
    this.createSlice(start, end);
  };

  /**
 * Creates page number list
 * @param {Number} start Start Page number
 * @param {Number} end End page number
 */
  createSlice = (start = 1, end = 4) => {
    let temp = [];
    for (let i = start; i < end; i++) {
      temp.push(i);
    }
    this.setState({ options: temp });
  };

  clearSelectedSort = () => {
    const allUpArr = document.querySelectorAll(".arrow-up");
    const allDownArr = document.querySelectorAll(".arrow-down");
    [].forEach.call(allUpArr, function (el) {
      el.classList.remove("is-active");
    });
    [].forEach.call(allDownArr, function (el) {
      el.classList.remove("is-active");
    });
  };

  handleUpArrowClick(e, option) {
    this.clearSelectedSort();
    e.target.classList.add("is-active");
    const fieldType = option.field;
    let sortedResults = this.state.ideaItems;
    sortedResults = sortedResults.sort(function (a, b) {
      const itemA = fieldType === 'ideaType' ? a[fieldType].text.toLowerCase() : fieldType === 'createdBy' ? a[fieldType].name.toLowerCase() : a[fieldType].toLowerCase();
      const itemB = fieldType === 'ideaType' ? b[fieldType].text.toLowerCase() : fieldType === 'createdBy' ? b[fieldType].name.toLowerCase() : b[fieldType].toLowerCase();
      return (itemA < itemB) ? -1 : (itemA > itemB) ? 1 : 0;
    });
    this.setState({ ideaItems: sortedResults });
  };

  handleDownArrowClick(e, option) {
    this.clearSelectedSort();
    e.target.classList.add("is-active");
    const fieldType = option.field;
    let sortedResults = this.state.ideaItems;
    sortedResults = sortedResults.sort(function (a, b) {
      const itemA = fieldType === 'ideaType' ? a[fieldType].text.toLowerCase() : fieldType === 'createdBy' ? a[fieldType].name.toLowerCase() : a[fieldType].toLowerCase();
      const itemB = fieldType === 'ideaType' ? b[fieldType].text.toLowerCase() : fieldType === 'createdBy' ? b[fieldType].name.toLowerCase() : b[fieldType].toLowerCase();
      return (itemA > itemB) ? -1 : (itemA < itemB) ? 1 : 0;
    });
    this.setState({ ideaItems: sortedResults });
  };

  setSelectedItem = (item, title, isRemoved) => {
    const itemId = parseInt(item.id);
    if (title.toLowerCase().indexOf('categories') > -1) {
      let idArray = this.state.categorySelectedArr;
      if (isRemoved) {
        const index = idArray.indexOf(itemId);
        if (index !== -1) {
          idArray.splice(index, 1);
        }
        this.setState({ categorySelectedArr: idArray });
      } else {
        this.state.categorySelectedArr.push(itemId);
      }
    } else if (title.toLowerCase().indexOf('tags') > -1) {
      let idArray = this.state.tagsSelectedArr;
      if (isRemoved) {
        const index = idArray.indexOf(itemId);
        if (index !== -1) {
          idArray.splice(index, 1);
        }
        this.setState({ tagsSelectedArr: idArray });
      } else {
        this.state.tagsSelectedArr.push(itemId);
      }
    } else if (title.toLowerCase().indexOf('capability') > -1) {
      let idArray = this.state.talentSelectedGrp;
      if (isRemoved) {
        const index = idArray.indexOf(itemId);
        if (index !== -1) {
          idArray.splice(index, 1);
        }
        this.setState({ talentSelectedGrp: idArray });
      } else {
        this.state.talentSelectedGrp.push(itemId);
      }
    }
    let combinedArr = this.state.combinedSelectedArr;
    if (isRemoved) {
      const index = combinedArr.findIndex((arr) => arr.id === itemId && arr.type === title.toLowerCase());
      if (index !== -1) {
        combinedArr.splice(index, 1);
      }
      this.setState({ combinedSelectedArr: combinedArr });
    } else {
      const arr = [{
        id: itemId,
        text: item.text,
        type: title.toLowerCase()
      }];
      this.setState({ combinedSelectedArr: [...this.state.combinedSelectedArr, ...arr] });
    }
  };

  openFilterModal = () => { this.setState({ showFilterModal: true }); document.querySelector('body').classList.add("modal-open"); };
  closeFilterModal = () => { this.setState({ showFilterModal: false }); document.querySelector('body').classList.remove("modal-open") };

  applyFilters = () => {
    showLoader();
    const requestPayload = {
      "advancedSearchFilters": {
        "categoryIds": this.state.categorySelectedArr,
        "tagIds": this.state.tagsSelectedArr,
        "talentGroupIds": this.state.talentSelectedGrp
      },
      "currentPaginationLevel": 0,
      "pageSize": this.state.perPageRows,
      "searchTerm": this.state.searchTerm
    }
    this.setState({ pageIndex: 0, currentPage: 1 });
    this.closeFilterModal();
    this.props.getGlobalResults(requestPayload);
  }

  setContextLink(ideaId){
    const link = `/idea/${ideaId}`;
    this.setState({contextLink: link});
  }

  render() {
    const { userData } = this.props;
    const filterItems = [
      {
        title: "CATEGORIES",
        data: this.state.searchFilters ? this.state.searchFilters.categories : {}
      },
      {
        title: "TAGS",
        data: this.state.searchFilters ? this.state.searchFilters.tags : {}
      },
      {
        title: "CAPABILITY",
        data: this.state.searchFilters ? this.state.searchFilters.talentGroups : {}
      }
    ];
    const sortOptions = [
      {
        name: "Idea",
        field: "ideaName",
      },
      {
        name: "Category",
        field: "ideaType",
      },
      {
        name: "Submitter",
        field: "createdBy",
      }
    ];
    return (
      <>
        <Header values={userData} showSearch={true} showUser={true} pageName={"search-results"} />
        <div className="search-container section-container">
          <div className="search-filter">
            <MediaQuery minWidth={768}>
              <div className="search-filter_title">Filters</div>
              <div className="filter">
                {filterItems.map((item, index) => {
                  return (
                    <FilterAccordion selectedTalentGrpList={this.state.talentSelectedGrp} selectedTagsList={this.state.tagsSelectedArr} selectedCategoryList={this.state.categorySelectedArr} setSelectedFilter={this.updateSelectedFilter.bind(this)} key={item.title + index} item={item} active={this.state.clicked === index} onToggle={() => this.handleToggle(index)} />
                  );
                })}
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              {this.state.ideaItems && this.state.ideaItems.length > 0 && (
                <div className="search-filter_title" onClick={() => this.openFilterModal()}>Filters {this.state.combinedSelectedArr.length > 0 && (<span>{this.state.combinedSelectedArr.length}</span>)}</div>
              )}
              <div className={`${this.state.showFilterModal ? '' : 'close'} search-filter_modal`}>
                <div className="search-filter_modal-title">Filters<span onClick={() => this.resetAllFilter()}>Reset All</span></div>
                <Tabs selectedTalentGrpList={this.state.talentSelectedGrp} selectedTagsList={this.state.tagsSelectedArr} selectedCategoryList={this.state.categorySelectedArr}>
                  {filterItems.map((item, index) => {
                    const dataArray = [];
                    for (const [id, data] of Object.entries(item.data)) {
                      dataArray.push({ id: id, text: data });
                    }
                    return (
                      <Tab label={item.title} item={item} key={item.title + index}>
                        <div>
                          <ul>
                            {dataArray.map((data, i) => {
                              let selectedArr = [];
                              let isSelected = false;
                              if (item.title.toLowerCase().indexOf('categories') > -1) {
                                selectedArr = this.state.categorySelectedArr;
                              } else if (item.title.toLowerCase().indexOf('tags') > -1) {
                                selectedArr = this.state.tagsSelectedArr;
                              } else if (item.title.toLowerCase().indexOf('capability') > -1) {
                                selectedArr = this.state.talentSelectedGrp;
                              }
                              isSelected = selectedArr.length && selectedArr.find(id => id === parseInt(data.id)) ? true : false;
                              return (
                                <li key={data.text + i} className={isSelected ? 'is-selected' : ''} onClick={() => this.setSelectedItem(data, item.title, isSelected)}><span>{data.text}</span></li>
                              )
                            })}
                          </ul>
                        </div>
                      </Tab>
                    );
                  })}
                </Tabs>
                <div className="search-filter_modal-buttons">
                  <button onClick={() => this.closeFilterModal()}>Close</button>
                  <button onClick={() => this.applyFilters()}>Apply</button>
                </div>
              </div>
            </MediaQuery>
          </div>
          <div className="search-results-ideas">
            {this.state.ideaItems && this.state.ideaItems.length > 0 && (
              <p className="search-results-summary">Showing {this.state.ideaItems && this.state.ideaItems.length > 8 ? this.state.perPageRows : this.state.ideaItems.length} Results <q>{this.state.searchTerm}</q></p>
            )}
            {this.state.ideaItems && this.state.ideaItems.length === 0 && (
              <p className="search-results-summary">No Results Found for <q>{this.state.searchTerm}</q></p>
            )}
            {this.state.combinedSelectedArr.length > 0 && (
              <ul className="selected-tags-list">
                {this.state.combinedSelectedArr.map((selectedItem, index) => {
                  return (
                    <li key={selectedItem.id + index}>{selectedItem.text}<span onClick={() => this.removeSelectedFilter(selectedItem)}><img src={closeGreyIcon} alt="remove" /></span></li>
                  );
                })}
                {this.state.combinedSelectedArr.length > 0 && (
                  <li><button onClick={() => this.resetAllFilter()}>Reset All</button></li>
                )}
              </ul>
            )}
            {this.state.ideaItems && this.state.ideaItems.length > 0 && (
              <div className="search-results-sort">
                <ul>
                  {sortOptions.length && sortOptions.map((option, index) => {
                    return (
                      <li key={option.name + index}>{option.name}<span className="arrow-up" onClick={(e) => this.handleUpArrowClick(e, option)}></span><span className="arrow-down" onClick={(e) => this.handleDownArrowClick(e, option)}></span></li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="ideasContainer">
              <ContextMenuWithHook className="ideas" contextLink={this.state.contextLink}>
                {this.state.ideaItems && this.state.ideaItems.length === 0 && (
                  <div className="no-results">
                    <GlobalSearchNoResults term={this.state.searchTerm} />
                  </div>
                )}
                {this.state.ideaItems && this.state.ideaItems.length > 0 &&
                  this.state.ideaItems.map((idea, index) => {
                    return (
                      <SearchItem idea={idea} key={idea.ideaName + index} userData={userData} setContextIdeaId={this.setContextLink}/>
                    );
                  })}
              </ContextMenuWithHook>
            </div>
            {this.state.ideaItems && this.state.ideaItems.length > 0 && (
              <Pagination
                rowCount={this.state.perPageRows}
                startCount={this.state.pageIndex}
                productListCount={Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows))}
                totalRecordsCount={this.state.totalRecords}
                onPageChange={this.pageChangeHandler.bind(this)}
                page={this.state.currentPage}
                slice={this.state.options}
                onSliceChange={this.sliceChangeHandler.bind(this)}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_GLOBAL_RESULTS_STATUS: state.ideas.meta.FETCH_GLOBAL_RESULTS_STATUS,
    searchResults: state.ideas.data.searchResults,
  };
};

const mapDispatchToProps = {
  getGlobalResults
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
