import React, { Component } from "react";
import challengeGameIcon from "../../img/challengeGame.svg";
import { _CONSTANTS } from "../../helpers/constants";

class CreateChallengeIdeaSuccess extends Component {
  render() {
    // const ideaStepsJSON = this.props.ideaSteps;

    return (
      <>
        <div className="ideaSuccessMain">
          <div className="submittedImage">
            <img src={challengeGameIcon} alt={_CONSTANTS.IDEASTATUS.SUBMITTED} />
          </div>
          <div className="submittedDetails challengeGame">
            <div className="submittedDetails_header">Game On!</div>
            <div className="submittedDetails_subheader">
              Your idea for the Challenge is submitted
            </div>
            <div className="submittedDetails_statusBubble">
              <div className="submittedDetails_statusBubble_header">
                Your idea is under review
              </div>
              <div className="submittedDetails_statusBubble_text">
                You will soon hear from us...
              </div>
            </div>
            {/* <div className="submittedDetails_ideaLifeCycle">
              <div className="submittedDetails_ideaLifeCycle_header">
                Here’s how the idea lifecycle looks like
              </div>
              <div className="submittedDetails_ideaLifeCycle_steps">
                <div className="ideaStep">Review</div>
                <div className="ideaStep">Showcase</div>
                <div className="ideaStep">Approval</div>
              </div>
            </div> */}
            {/* <div className="submittedDetails_prepare">
              <label htmlFor="" className="lblPrepare">
                Let’s prepare for the showcase
              </label>
              <ul>
                <li>Keep all your files ready!</li>
                <li>Make sure you know what you’ve submitted.</li>
                <li>Practice a dry run with your team. </li>
              </ul>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default CreateChallengeIdeaSuccess;
