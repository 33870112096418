import React, { Component } from "react";
// import React, {  } from "react";
import logo from "../img/logo.svg";
import "../css/pages/amcPage.scss";

class notamc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAMCModal: localStorage.getItem("amcUser") === null,
      isAMCUser: localStorage.getItem("amcUser") !== null && localStorage.getItem('amcUser') === 'true' ? true : false
    };
  }

  render() {

    return (
      <>
        <section className="sorry-page">
        <div className="blur-bg"></div>
          <article className="seedLogo">
            <img src={logo} alt="Seed Logo" />
            <h1 className="heading">Sorry! Come back again!</h1>
            <article className="sorry-page_content">
              Sorry for the inconvenience. Currently this is a pilot run which is taking place within AM&C. Will be launching this portal soon for C&M. Please keep checking the space for more.
              <p className="sorry-page_content-text">Keep Innovating!</p>
              <p>-Team SEED</p>
            </article>
          </article>
        </section >
      </>
    );
  }
}

export default notamc;
