import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarousalArrow from "../global/carousalArrow";

class exploreTopics extends Component {
  render() {
    const dashboardDetails = this.props.dashboardDetails;

    let topicList = [];
    if (dashboardDetails.keywords) {
      dashboardDetails.keywords.forEach((topic) => {
        topicList.push(
          <div
            className="topic"
            key={topic.keywordValue + topic.id}
            onClick={() =>
              (window.location.href = "/ideaList?tagId=" + topic.id)
            }
          >
            <div className="innerTopic">{topic.keywordValue}</div>
          </div>
        );
      });
    }

    const settings = {
      dots: false,
      className: "center",
      centerMode: false,
      infinite: false,
      centerPadding: "22px",
      slidesToShow: 5.5,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      nextArrow: <CarousalArrow />,
      prevArrow: <CarousalArrow />,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2.5,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2.1,
          },
        },
      ],
    };

    return (
      <>
        <article className="topicsMain">
          <div className="headerRow">
            <div className="topicHeader">Explore by topics</div>
          </div>
          <div className="topicSlider">
            <Slider {...settings}>{topicList}</Slider>
          </div>
        </article>
      </>
    );
  }
}

export default exploreTopics;
