export const FETCH_USER_PROFILE_PENDING = "FETCH_USER_PROFILE_PENDING";
export const FETCH_USER_PROFILE_FULFILLED = "FETCH_USER_PROFILE_FULFILLED";
export const FETCH_USER_PROFILE_REJECTED = "FETCH_USER_PROFILE_REJECTED";

export const FETCH_USER_PENDING = "FETCH_USER_PENDING";
export const FETCH_USER_FULFILLED = "FETCH_USER_FULFILLED";
export const FETCH_USER_REJECTED = "FETCH_USER_REJECTED";

export const FETCH_PERSONAL_CORNER_PENDING = "FETCH_PERSONAL_CORNER_PENDING";
export const FETCH_PERSONAL_CORNER_FULFILLED =
  "FETCH_PERSONAL_CORNER_FULFILLED";
export const FETCH_PERSONAL_CORNER_REJECTED = "FETCH_PERSONAL_CORNER_REJECTED";

export const FETCH_PROFILE_CURRENCY_PENDING = "FETCH_PROFILE_CURRENCY_PENDING";
export const FETCH_PROFILE_CURRENCY_FULFILLED =
  "FETCH_PROFILE_CURRENCY_FULFILLED";
export const FETCH_PROFILE_CURRENCY_REJECTED =
  "FETCH_PROFILE_CURRENCY_REJECTED";

export const FETCH_PROFILE_TRANSACTION_PENDING =
  "FETCH_PROFILE_TRANSACTION_PENDING";
export const FETCH_PROFILE_TRANSACTION_FULFILLED =
  "FETCH_PROFILE_TRANSACTION_FULFILLED";
export const FETCH_PROFILE_TRANSACTION_REJECTED =
  "FETCH_PROFILE_TRANSACTION_REJECTED";

export const FETCH_INVESTED_IDEA_PENDING = "FETCH_INVESTED_IDEA_PENDING";
export const FETCH_INVESTED_IDEA_FULFILLED = "FETCH_INVESTED_IDEA_FULFILLED";
export const FETCH_INVESTED_IDEA_REJECTED = "FETCH_INVESTED_IDEA_REJECTED";

export const FETCH_PROFILE_INVESTED_IDEA_PENDING = "FETCH_PROFILE_INVESTED_IDEA_PENDING";
export const FETCH_PROFILE_INVESTED_IDEA_FULFILLED = "FETCH_PROFILE_INVESTED_IDEA_FULFILLED";
export const FETCH_PROFILE_INVESTED_IDEA_REJECTED = "FETCH_PROFILE_INVESTED_IDEA_REJECTED";

export const SET_USER_EMAIL = "SET_USER_EMAIL";