import React, { Component } from "react";
import { Switch, Route, Redirect, BrowserRouter  } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProfile, setEmail } from "./redux/actions/user-profile";
import axios from "axios";
import { getDashboardDetails } from "./redux/actions/dashboard";
import { attemptTokenRefreshOrLogin, showLoader, hideLoading, getBaseUrl, deleteAllCookies } from "./helpers/utils";
import Idea from "./pages/Idea";
import Home from "./pages/Home";
import CreateIdea from "./pages/createIdea";
import DraftIdea from "./pages/draftIdea";
import SubmittedIdea from "./pages/submittedIdea";
import ChallengeDetail from "./components/challenge/challegeDetail";
import ChallengeEdit from "./components/challenge/challengeEdit";
import ChallengeCreationFlow from "./components/challenge/challengeCreationFlow";
import ChallengeList from "./components/challenge/challengeList";
import ChallengeListAll from "./components/challenge/challengeListAll";
import IdeaList from "./pages/ideaList";
import UserProfile from "./pages/userProfile";
import SearchResults from "./pages/searchResults";
import IdeaSubmissions from "./pages/ideaSubmissions";
import LeaderReview from "./pages/leaderReview";
import EvaluateIdeaPanel from "./pages/evaluateIdea";
import EvaluateDetail from "./pages/evaluateDetail";
import MakeItRain from "./pages/makeItRain";
import TeamRequests from "./pages/teamRequests";
import NotAMC from "./pages/notamc";
import loadingIcon from "./img/spinner.gif";
import SiteDown from "./pages/siteDown";
import Footer from "./components/global/footer";
import ImportExportData from "./pages/importExportData";
import ChallengeIdeaList from "./pages/challengeIdeaList";
import AssignRole from "./pages/assignRole";
import ReleaseNotes from "./pages/releaseNotes";
import SeedFAQs from "./pages/seedFAQs";
import BannerDetails from "./pages/bannerDetails";
import Cookies from 'js-cookie';
import { hasIdeaAccess } from "./helpers/ideaAuth";
import NoAccessPage from "./pages/noAccessPage";
import { _CONSTANTS } from "./helpers/constants";
import Events from "./pages/events";
import EventsLeaderboard from "./pages/eventsLeaderboard";

const baseUrl = getBaseUrl();
const YOUR_CLIENT_ID = baseUrl.clientId;
const redirectUri = baseUrl.redirectUrl;

class App extends Component {
  fetchingLoginRedirectToken = false; // local variable to track the flag for login-redirect token fetch - state not req. [[NOTE: workaround]]

  constructor(props) {
    super(props);
    this.state = {
      userEmail: '',
      isAuthComplete: false,
      userDetails: this.props.userDetails,
      dashboardDetails: this.props.dashboard,
      stopLoader: true
    };
  }

  componentDidMount () {
    showLoader();

    // get user-auth info only if web-app is not already fetching the token in login flow
    if (!this.fetchingLoginRedirectToken) {
      this.getUserAuthInfo();
    }
    // const payload = {
    //   email: "amandeepsingh2@deloitte.com",
    //   firstName: "Amandeep",
    //   lastName: "Singh"
    // };
    // this.props.fetchProfile(payload);
    // setTimeout(() => {
    //   this.props.getDashboardDetails(this.props.userDetails.id);
    // }, 500);
  }

  componentWillMount() {
    sessionStorage.setItem(
      "redirectURI", window.location.href
    );
    const checkCookie = Cookies.get('initialHitURL');
    if(!checkCookie){
     // Get the initial hit URL
     const initialHitURL = window.location.href;

     // Set a cookie with the initial hit URL
     Cookies.set('initialHitURL', initialHitURL);
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.dashboard !== this.props.dashboard) {
      this.setState({ dashboardDetails: this.props.dashboard });
    }
    if (prevProps.userDetails !== this.props.userDetails) {
      this.setState({ userDetails: this.props.userDetails });
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  };

  getUserAuthInfo = () => {
    axios
      .get(baseUrl.basicCognitoUrl + 'oauth2/userInfo', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`
        }
      })
      .then((response) => {
        this.props.setEmail(response.data.email);
        const payload = {
          email: response.data.email,
          firstName: response.data.given_name,
          lastName: response.data.family_name
        };
        this.props.fetchProfile(payload);
        setTimeout(() => {
          this.props.getDashboardDetails(this.props.userDetails.id);
        }, 500);
        hideLoading();
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
        if (err.response) {
          attemptTokenRefreshOrLogin(err.response);
        }
      });
  };

  /**
   * checks if access token localstorage item is there
   * @returns {boolean}
   */
  isAccessTokenPresent = () => {
    // localStorage.setItem('access_token', true);
    // const isTokenExpired = new Date() > new Date(localStorage.getItem('expirationdate'));
    // if (isTokenExpired) {
    //   localStorage.clear();
    // }
    if (localStorage.getItem('access_token')) {
      return true;
    }
    return false;
  };

  render () {
    const values = this.state.userDetails?.userType;
    // console.log("ssss",values);
    return (
      <>
        <div className={`loader ${this.state.stopLoader ? 'hide' : ''}`}>
          <div className="loader__content">
            <div className="loader-container py-3 py-lg-4">
              <img src={loadingIcon} alt="loading" />
            </div>
          </div>
        </div>
        <BrowserRouter >
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <Redirect to="/dashboard" />
            }>
          </Route>
          <Route
            exact
            path="/login"
            render={() =>
              this.isAccessTokenPresent() ? ( Cookies.get('initialHitURL')?(<Redirect to={Cookies.get('initialHitURL').split('com')[1]}/>):
                (<Redirect to="/dashboard" />)
              ) : (
                window.open(
                  baseUrl.basicCognitoUrl + `login?client_id=${YOUR_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}/loginredirect`,
                  '_self'
                )
              )
            }
          />
          <Route
            path="/loginredirect"
            render={() => {
              // deleteAllCookies();
              if (window.location.search.includes('code')) {
                this.fetchingLoginRedirectToken = true; // track if get token API for login-redirect flow is in progress
                showLoader();
                const code = window.location.search.substring(6);
                const url = baseUrl.basicCognitoUrl + `oauth2/token?grant_type=authorization_code&code=${code}&client_id=${YOUR_CLIENT_ID}&redirect_uri=${redirectUri}/loginredirect`;
                axios
                  .post(
                    url,
                    {},
                    {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
                    }
                  )
                  .then((response) => {
                    if (response.data || response.data.access_token) {
                      const tokenexpiration = new Date();
                      tokenexpiration.setSeconds(new Date().getSeconds() + parseInt(response.data.expires_in));
                      localStorage.setItem('expirationdate', tokenexpiration);
                      localStorage.setItem('access_token', response.data.access_token);
                      localStorage.setItem('refresh_token', response.data.refresh_token);
                      localStorage.setItem('id_token', response.data.id_token);
                      this.getUserAuthInfo();
                    }
                    hideLoading();
                  })
                  .catch((e) => {
                    console.log(e);
                    hideLoading();
                  }).finally(() => {
                    this.fetchingLoginRedirectToken = false;  // clear the tracking flag
                  });
                  if (this.props.isAuthComplete) {
                    const initialHitURL = Cookies.get('initialHitURL').split('com')[1];
                    // if(initialHitURL.split("?")[0] =='/challenge'){
                    //   return  <ChallengeDetail userData={this.state.userDetails} />
                    // }
                    // console.log('Initial Hit URL:', initialHitURL);
                    <Redirect to={initialHitURL}/>
                    return <Redirect to={initialHitURL}/>}
              }
              }
            }
          />
          <Route
            path="/dashboard"
            render={() =>
              !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <Home userData={this.state.userDetails} dashboard={this.state.dashboardDetails} />
            }
          />
          <Route path="/idea" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && (
              hasIdeaAccess(this.state.userDetails)
              ? <Idea userData={this.state.userDetails} />
              : <NoAccessPage featureName="idea" userData={this.state.userDetails}/>
            )
          }>
          </Route>

          <Route path="/createidea" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && (
              hasIdeaAccess(this.state.userDetails)
              ? <CreateIdea userData={this.state.userDetails} />
              : <NoAccessPage featureName="idea" userData={this.state.userDetails}/>
            )
          }>
          </Route>

          <Route exact path="/draftidea" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && (
              hasIdeaAccess(this.state.userDetails)
              ? <DraftIdea userData={this.state.userDetails} />
              : <NoAccessPage featureName="idea" userData={this.state.userDetails}/>
            )
          }>
          </Route>

	<Route
            path="/assignRole"
            render={() =>
              !this.isAccessTokenPresent() ? (
                <Redirect to="/login" />
              ) : (values==='ADMIN'?
                this.state.userDetails?.id && <AssignRole userData={this.state.userDetails} />
                :<Redirect to="/dashboard" />  
              )
            }
          ></Route>

          <Route exact path="/submittedidea" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && (
              hasIdeaAccess(this.state.userDetails)
              ? <SubmittedIdea userData={this.state.userDetails} />
              : <NoAccessPage featureName="idea" userData={this.state.userDetails}/>
            )
          }>
          </Route>

          <Route path="/idealist" render={() =>
            !this.isAccessTokenPresent()
              ? <Redirect to="/login" />
              : this.state.userDetails?.id && (
                hasIdeaAccess(this.state.userDetails)
                  ? <IdeaList userData={this.state.userDetails} />
                  : <NoAccessPage featureName="idea" userData={this.state.userDetails}/>
              )
          }>

          </Route>
          <Route path="/user-profile" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <UserProfile userData={this.state.userDetails} />
          }>
          </Route>
          <Route path="/challenge" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && <ChallengeDetail userData={this.state.userDetails} />
          }>
          </Route>
          <Route path="/challengeEdit" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && <ChallengeEdit userData={this.state.userDetails} />
          }>
          </Route>
          <Route path="/challengecreationflow" render={() =>
            !this.isAccessTokenPresent()
              ? <Redirect to="/login" />
              : (values === _CONSTANTS.USER.ADMIN)
                ? this.state.userDetails?.id && <ChallengeCreationFlow userData={this.state.userDetails} />
                : <Redirect to="/dashboard"/>
          }>
          </Route>
          <Route path="/challengeList/admin" render={() =>
            !this.isAccessTokenPresent()
              ? <Redirect to="/login" />
              : (values === 'ADMIN'
                ? this.state.userDetails?.id && <ChallengeList userData={this.state.userDetails}/>
                : <Redirect to="/dashboard"/>)
          }>
          </Route>
          <Route path="/challengeList/user" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && <ChallengeListAll userData={this.state.userDetails} />
          }>
          </Route>
          <Route path="/search-results" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && (
              hasIdeaAccess(this.state.userDetails)
              ? <SearchResults userData={this.state.userDetails} />
              : <NoAccessPage featureName="search" userData={this.state.userDetails}/>
            )
          }>
          </Route>
          <Route path="/ideaSubmission" render={() =>
            !this.isAccessTokenPresent()
              ? <Redirect to="/login" />
              : (values === 'PANELLEAD' || values === 'PANELIST' || values === 'ADMIN')
                ? this.state.userDetails?.id && <IdeaSubmissions userData={this.state.userDetails} />
                : <Redirect to="/dashboard"/>
          }>
          </Route>
          <Route path="/talentReview" render={() =>
            !this.isAccessTokenPresent()
              ? <Redirect to="/login" />
              : (values === _CONSTANTS.USER.ADMIN)
                ? this.state.userDetails?.id && <LeaderReview userData={this.state.userDetails} />
                : <Redirect to="/dashboard"/>
          }>
          </Route>
          <Route path="/evaluateIdea" render={() =>
            !this.isAccessTokenPresent()
              ? <Redirect to="/login" />
              : (values === _CONSTANTS.USER.PANELIST)
                ? this.state.userDetails?.id && <EvaluateIdeaPanel userData={this.state.userDetails} />
                : <Redirect to="/dashboard"/>
          }>
          </Route>
          <Route path="/evaluateDetail" render={() =>
            !this.isAccessTokenPresent()
              ? <Redirect to="/login" />
              : (values === _CONSTANTS.USER.ADMIN)
                ? this.state.userDetails?.id && <EvaluateDetail userData={this.state.userDetails} />
                : <Redirect to="/dashboard"/>
          }>
          </Route>
          <Route path="/faq" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <SeedFAQs userData={this.state.userDetails} />
          }>
          </Route>
          <Route path="/teamRequests" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && <TeamRequests userData={this.state.userDetails} />
          }>
          </Route>
          <Route path="/challengeIdeaList" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : this.state.userDetails?.id && (
              hasIdeaAccess(this.state.userDetails)
                ? <ChallengeIdeaList userData={this.state.userDetails} />
                : <NoAccessPage featureName="idea" userData={this.state.userDetails}/>
            )
          }>
          </Route>
          <Route path="/sorrycomebackagain" render={() => <NotAMC userData={this.state.userDetails} />}></Route>
          <Route path="/sitemaintenance" render={() =>
            !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <SiteDown/>
          }></Route>
          <Route
            path="/release-notes"
            render={() =>
              !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <ReleaseNotes userData={this.state.userDetails} />
            }
          ></Route>
          <Route
            path="/importexportdata"
            render={() => <ImportExportData />}
          ></Route>
          <Route
            path="/banner-details"
            render={() =>
              !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <BannerDetails userData={this.state.userDetails} />
            }
          ></Route>
          <Route
            path="/events"
            render={() =>
              !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <Events userData={this.state.userDetails} />
            }
          ></Route>
          <Route
            path="/events-leaderboard"
            render={() =>
              !this.isAccessTokenPresent() ? <Redirect to="/login" /> : <EventsLeaderboard userData={this.state.userDetails} />
            }
          ></Route>
        </Switch>
        </BrowserRouter>
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_USER_INFO: state.userProfile.meta.FETCH_USER_INFO,
    userDetails: state.userProfile.data.userDetails,
    dashboard: state.dashboard.data.dashboard,
    userEmail: state.userProfile.data.userEmail,
    isAuthComplete: state.userProfile.data.isAuthComplete
  };
};

const mapDispatchToProps = {
  fetchProfile,
  getDashboardDetails,
  setEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(App);