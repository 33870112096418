import React, { Component } from "react";
import Header from "../global/header";
import Breadcrumbs from "../global/breadcrumbs";
import "../../css/pages/challengeCreationFlow.scss";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { doFileUpload } from "../../redux/actions/utilities";
import { _CONSTANTS } from "../../helpers/constants";
import {
  fetchChallengeDetail,
  setSuccessMessage,
} from "../../redux/actions/challenges";
import {
  generateRandomKey,
  hideLoading,
  showLoader,
  getBaseUrl,
  getTextFromHtmlString,
} from "../../helpers/utils";
import moment from "moment";
// import fileUploadIcon from "../../img/file_upload.svg";
import closeBtn from "../../img/close.svg";
import calendar from "../../img/calendar.svg";
import DropDown from "../../components/global/dropdown";
import RichTextEditor from "../global/richTextEditor";
import ChallengeEnableOptions from "./challengeEnableOptions"
import DOMPurify from "dompurify";

const baseUrl = getBaseUrl();
const setDatePickerDate = new Date();
setDatePickerDate.setDate(setDatePickerDate.getDate() + 29);
let dropdownOptions = [];
class ChallengeCreationFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challenge: this.props.challenge,
      keywords: [],
      topicKeywords: [],
      showTagsModal: false,
      showSaveModal: false,
      startDate: setDatePickerDate,
      formData: new FormData(),
      attachmentFormData: new FormData(),
      updatedTopics: true,
      file: null,
      challengeID: "",
      challengeDescription: "",
      saveChallengeJson: {
        challengeID: "",
        challengeName: "",
        tags: [],
        topics: [6],
        description: "",
        creatorID: this.props.userData.id,
        challengeDate: setDatePickerDate.toISOString(),
        rulesOfTheRoad: "",
        fileNames: [],
        isRegistrationRequired: false,
        templateFields: ""
      },
      maxCharCount: 2000,
      descriptionCharCount: 0,
      isBtnDisabled: true,
      challengeTitle: "",
      // isDateSelected: false,
      hideAttachmentBtn: false,
      attachfile: [],
      file: [],
      fileArrSimplified: [],
      datePickerIsOpen: false,
      selectedItem: {},
      tagsBtnDisabled: true,
      showErrorOnPage: false,
      errorMessage: "",
      rulesOfTheRoad: "",
      rulesOfTheRoadCharCount: 0,
      rulesOfRoadCharLimit: 2000,
      radioBtnVal: false,

      optionalFields: [
        // {field: 'Type of idea', type: 'Dropdown', checked: true, state: 'typeOfIdea', disabled: true},
        {field: 'Idea Name', type: 'Text', checked: true, state: 'ideaName', disabled: true},
        {field: 'Use Case', type: 'Dropdown', checked: true, state: 'useCase'},
        {field: 'Business Opportunity', type: 'Rich Text', checked: true, state: 'businessOpportunity', disabled: true},
        {field: 'High level overview', type: 'Rich Text', checked: true, state: 'highLevelOverview', disabled: true},
        {field: 'Tool(s) needed to bring idea to life', type: 'Rich Text', checked: true, state: 'ideaTool'},
        {field: 'Potential value delivered', type: 'Rich Text', checked: true, state: 'potentialVal'},
        {field: 'Offering Portfolio', type: 'Dropdown', checked: true, state: 'offeringPortfolio'},
        {field: 'Talent Offering', type: 'Dropdown', checked: true, state: 'talentOffering'},
        {field: 'Market Offering', type: 'Dropdown', checked: true, state: 'marketOffering'},
        {field: 'Industry', type: 'Dropdown', checked: true, state: 'industry'},
        {field: 'Sector', type: 'Dropdown', checked: true, state: 'sector'},
        {field: 'Domain', type: 'Dropdown', checked: true, state: 'domain'},
        {field: 'Alliance', type: 'Dropdown', checked: true, state: 'alliance'},
        {field: 'Priority', type: 'Dropdown', checked: true, state: 'priority'}
    ],

    };
    this.fileUpload = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.updateRulesOfTheRoad = this.updateRulesOfTheRoad.bind(this);
    this.uploadS3Files = this.uploadS3Files.bind(this);
  }

  componentDidMount() {
    showLoader();
    this.props.fetchChallengeDetail();
    this.setState({
      targetBtnDisabled: this.state.keywords.length >= 1 ? false : true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.challenge !== this.props.challenge) {
      this.setState({ challenge: this.props.challenge });
      this.props.challenge.topics.forEach((topic) => {
        const option = {
          value: topic.topicID,
          name: topic.topicText,
          id: topic.topicID,
          selected: false,
          visible: true,
        };
        dropdownOptions.push(option);
      });
    }
    if (
      prevProps.FILE_UPLOAD_STATUS !== "SUCCESS" &&
      this.props.FILE_UPLOAD_STATUS === "SUCCESS"
    ) {
      const messageData = {
        type: "success",
        title: "WoHooo! Bravo",
        detailText:
          "Your challenge is live. Copy URL and share with your collegues.",
      };
      hideLoading();
      this.props.setSuccessMessage(messageData);
      window.location.href = "/challenge?id=" + this.state.challengeID;
    }
    // if (prevProps.attachmentList !== this.props.attachmentList && this.state.basicIdeaData.ideaStatus !== 'New') {
    //   this.setState({
    //     attachFileUrl: this.props.attachmentList
    //   });
    // }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  setSelectedDropdownItem(event, item) {
    this.setState({ selectedItem: item });
    this.state.updatedTopics = true;
    const topicValue = item.name;
    this.state.saveChallengeJson.topics = [];
    this.state.challenge.topics.forEach((topic) => {
      if (topic.topicText === topicValue) {
        const id = topic.topicID;
        this.state.saveChallengeJson.topics.push(id);
      }
    });
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  }

  validateFields = () => {
    let validated =
      this.state.challengeTitle !== "" &&
      this.state.keywords.length >= 1 &&
      !(['<p></p>\n', '', ''].includes(this.state.challengeDescription)) &&
      this.state.updatedTopics === true;

    if (!validated && this.state.challengeTitle === "") {
      this.setState({
        showErrorOnPage: true,
        errorMessage: "Please enter Challenge Title",
      });
    } else if (!validated && this.state.keywords.length < 1) {
      this.setState({
        showErrorOnPage: true,
        errorMessage: "Please select atleast 1 Keyword",
      });
    } else if (!validated && (['<p></p>', ''].includes(this.state.challengeDescription))) {
      this.setState({
        showErrorOnPage: true,
        errorMessage: "Please enter Challenge Description",
      });
    } else if (!validated && !this.state.updatedTopics) {
      this.setState({
        showErrorOnPage: true,
        errorMessage: "Please select Challenge Topic",
      });
    } else if (validated) {
      this.setState({ showErrorOnPage: false, errorMessage: "" });
    }

    return validated;
  };

  setOptionalFields() {
    const tempArr = []
    for (let val of this.state.optionalFields) {
      if(val.checked) {
        tempArr.push(val.field)
      }
    }
    const templateFields = tempArr.join();
    this.state.saveChallengeJson.templateFields = templateFields;
  }

  saveChallenge() {
    this.setOptionalFields()
    this.hideSaveModal();
    let saveChallengeJson = [];
    let self = this;
    saveChallengeJson.push(self.state.saveChallengeJson);
    showLoader();
    axios
      .post(
        baseUrl.ipAddress + "api/v1/challenges",
        self.state.saveChallengeJson,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        this.setState({ challengeID: response.data });
        // if (document.getElementById('file-upload').value !== '') {
        //   this.props.doFileUpload(
        //     response.data,
        //     _CONSTANTS.CHALLENGESTATUS.CREATE_CHALLENGE,
        //     this.state.formData,
        //     _CONSTANTS.CHALLENGESTATUS.UPLOAD,
        //     "CHALLENGE_PROFILE"
        //   );
        // }
        if (document.getElementById("mrAttachments").value !== "") {
          this.props.doFileUpload(
            response.data,
            _CONSTANTS.CHALLENGESTATUS.CREATE_CHALLENGE,
            this.state.attachmentFormData,
            _CONSTANTS.CHALLENGESTATUS.UPLOAD,
            _CONSTANTS.CHALLENGESTATUS.CHALLENGE_ATTACHMENT
          );
        }
        // if (document.getElementById('file-upload').value === '' || document.getElementById('mrAttachments').value === '') {
        if (document.getElementById("mrAttachments").value === "") {
          const messageData = {
            type: "success",
            title: "WoHooo! Bravo",
            detailText: "Your challenge is live.",
          };
          setTimeout(() => {
            this.props.setSuccessMessage(messageData);
            window.location.href = "/challenge?id=" + this.state.challengeID;
          }, 500);
        }
        // hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  }

  updateTitle(e) {
    const val = DOMPurify.sanitize(e.target.value).substring(
      0,
      Math.min(DOMPurify.sanitize(e.target.value).length, this.state.maxCharCount)
    );
    this.setState({ challengeTitle: val });
    this.state.saveChallengeJson.challengeName = val;
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  }

  updateTopic = (e) => {
    this.state.updatedTopics = true;
    const topicValue = DOMPurify.sanitize(e.target.value);
    this.state.saveChallengeJson.topics = [];
    this.state.challenge.topics.forEach((topic) => {
      if (topic.topicText === topicValue) {
        const id = topic.topicID;
        this.state.saveChallengeJson.topics.push(id);
      }
    });
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  };

  updateDescription = (value) => {
    const descp = value;
    this.state.saveChallengeJson.description = descp;
    this.setState({
      challengeDescription: descp,
      descriptionCharCount: getTextFromHtmlString(descp).length,
    });
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  };

  updateRulesOfTheRoad(value) {
    const rulesOfTheRoad =
      value /* value?.substring(0, Math.min(value.length, this.state.rulesOfRoadCharLimit)) */ ||
      "";
    this.state.saveChallengeJson.rulesOfTheRoad = rulesOfTheRoad;
    this.setState({
      rulesOfTheRoad,
      rulesOfTheRoadCharCount: getTextFromHtmlString(rulesOfTheRoad).length,
    });
  }

  updateSelectedKeywords = (keywords) => {
    let renderJSONTemp = this.state.challenge;
    renderJSONTemp.selectedTags = keywords;
    this.setState({ challenge: renderJSONTemp });
  };

  displayTagsModal = () => {
    this.setState({ showTagsModal: true });
  };

  HideTagsModal = () => {
    this.setState({ showTagsModal: false });
    this.updateSelectedKeywords(this.state.keywords);
  };

  displaySaveModal = () => {
    this.setState({ showSaveModal: true });
  };

  hideSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  handleChange(date) {
    const setDate = date;
    if (date !== this.state.startDate) {
      this.state.saveChallengeJson.challengeDate = new Date(
        setDate
      ).toISOString();
    }
    this.setState({
      startDate: date,
      // isDateSelected: true,
    });
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  handleCheck = (e) => {
    const { id, checked } = e.target;
    e.target.attributes[checked] = checked;
    if (checked && this.state.keywords.indexOf(id) === -1) {
      this.state.keywords.push(id);
      this.state.saveChallengeJson.tags.push(parseInt(id));
    } else if (!checked && this.state.keywords.indexOf(id) !== -1) {
      const checkIndex = this.state.keywords.indexOf(id);
      this.state.keywords.splice(checkIndex, 1);
      this.state.saveChallengeJson.tags.splice(checkIndex, 1);
    }
    this.setState({
      tagsBtnDisabled: this.state.keywords.length >= 1 ? false : true,
    });
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  };

  removeSelectedImage = (event) => {
    this.setState({ file: [] });
  };

  onSelectFile = (event) => {
    var formFile = event.target.files[0];
    if (
      !(
        formFile.name.indexOf(".jpg") > -1 ||
        formFile.name.indexOf(".png") > -1 ||
        formFile.name.indexOf(".jpeg") > -1
      )
    ) {
      alert("Please upload only .jpg or .jpeg or .png.");
      return;
    } else if (formFile.size / 1024 / 1024 > 2) {
      alert(formFile.name + ": Please upload file not more than 2Mb.");
      return;
    }
    var file = URL.createObjectURL(event.target.files[0]);
    this.setState({ file });
    this.composeFormData(formFile);
  };

  upload() {
    this.fileUpload.current.click();
  }

  dragOver = (e) => {
    e.preventDefault();
  };

  dragEnter = (e) => {
    e.preventDefault();
  };

  dragLeave = (e) => {
    e.preventDefault();
  };

  fileDrop = (e) => {
    e.preventDefault();
    var files = e.dataTransfer.files[0];
    if (
      !(
        files.name.indexOf(".jpg") > -1 ||
        files.name.indexOf(".png") > -1 ||
        files.name.indexOf(".jpeg") > -1
      )
    ) {
      alert("Please upload only .jpg or .jpeg or .png.");
      return;
    } else if (files.size / 1024 / 1024 > 2) {
      alert(files.name + ": Please upload file not more than 2Mb.");
      return;
    }
    const file = URL.createObjectURL(files);
    this.setState({ file });
    this.composeFormData(files);
  };

  /**
   * checks and removes file
   * @param {number} id
   */
  checkAndRemoveFile = (id) => {
    let tempArr = [];
    tempArr = this.state.attachmentFormData
      .getAll("files")
      .filter(function (file) {
        return file.id !== id;
      });
    return tempArr;
  };

  /**
   * removes file from the file list
   * @param {object} file removed file object
   */
  removeSelectedFile = (file) => {
    // let formFile = this.state.attachfile;
    let fileArrSimplified = this.state.fileArrSimplified;
    // const id = formFile.findIndex((item) => item.id === file.fileID);
    // formFile.splice(id, 1);
    const idSimplified = fileArrSimplified.findIndex(
      (item) => item.fileID === file.fileID
    );
    fileArrSimplified.splice(idSimplified, 1);
    this.setState({ attachmentFormData: new FormData() });
    const revisedFileArr = this.checkAndRemoveFile(file.fileID);
    setTimeout(() => {
      revisedFileArr.forEach((file) => {
        this.state.attachmentFormData.append("files", file);
      });
    }, 100);
    this.setState({
      attachfile: fileArrSimplified,
      hideAttachmentBtn: false,
      fileArrSimplified: fileArrSimplified,
    });
    // this.composeFormDataAttachment(formFile);
    // this.props.deleteFile(file.fileText);
    // this.props.doFileDelete(this.state.draftId, fileName);
  };

  /**
   * makes an object of file
   * @param {object} basicData raw file data
   */
  constructFileItemsArray = (basicData) => {
    let itemArr = {};
    itemArr.fileID = basicData.id;
    itemArr.fileUrl = URL.createObjectURL(basicData);
    itemArr.fileText = basicData.name;
    return itemArr;
  };

  /**
   * check for dupliacte files while uploading
   * @param {string} fileName name of the file
   */
  checkForDuplicateFiles = (fileName) => {
    return this.state.fileArrSimplified.find((element) => {
      return element.fileText === fileName ? true : false;
    });
  };

  /**
   * on file selection, add to file list
   * @param {event} event event
   */
  onAttachmentSelectFile = (event) => {
    const formFile = event.target.files;
    // let fileArr = this.state.attachfile;
    let fileArrSimplified = this.state.fileArrSimplified;
    // if (fileArrSimplified.length > 2) {
    if (
      fileArrSimplified.length + formFile.length > 3 ||
      fileArrSimplified.length > 2 ||
      formFile.length > 3
    ) {
      alert("Maximum of 3 files allowed");
      return false;
    }
    if (this.state.fileArrSimplified.length > 0) {
      let count = this.state.fileArrSimplified.length;
      for (const [id, data] of Object.entries(formFile)) {
        const isDuplicate = this.checkForDuplicateFiles(data.name);
        if (isDuplicate) {
          return;
        }
        if (data.size / 1024 / 1024 > 10) {
          alert(data.name + ": Please upload file not more than 10 MB.");
        } else if (
          !(
            data.name.indexOf(".pdf") > -1 ||
            data.name.indexOf(".xls") > -1 ||
            data.name.indexOf(".doc") > -1 ||
            data.name.indexOf(".ppt") > -1
          )
        ) {
          alert("Please upload only .pdf or .xls or .doc or .ppt");
        } else {
          count = count + 1;
          data.id = count;
          const simplifiedArr = this.constructFileItemsArray(data);
          // fileArr.push(data);
          this.composeFormDataAttachment(data);
          fileArrSimplified.push(simplifiedArr);
          this.uploadS3Files(data.name, data, fileArrSimplified);
        }
      }
    } else {
      for (const [id, data] of Object.entries(formFile)) {
        const isDuplicate = this.checkForDuplicateFiles(data.name);
        if (isDuplicate) {
          return;
        }
        if (data.size / 1024 / 1024 > 10) {
          alert(data.name + ": Please upload file not more than 10 MB.");
        } else if (
          !(
            data.name.indexOf(".pdf") > -1 ||
            data.name.indexOf(".xls") > -1 ||
            data.name.indexOf(".doc") > -1 ||
            data.name.indexOf(".ppt") > -1
          )
        ) {
          alert("Please upload only .pdf or .xls or .doc or .ppt");
        } else {
          data.id = parseInt(id);
          const simplifiedArr = this.constructFileItemsArray(data);
          // fileArr.push(data);
          this.composeFormDataAttachment(data);
          fileArrSimplified.push(simplifiedArr);
          this.uploadS3Files(data.name, data, fileArrSimplified);
        }
      }
    }
    // this.props.uploadFile(this.state.formData);
    event.target.value = "";
  };

  uploadS3Files = (name, file, fileArr) => {
    showLoader();
    axios({
      method: "post",
      url: baseUrl.uploadUrl + name,
      data: file,
      headers: { "Content-Type": file.type },
    })
      .then((response) => {
        this.setState({ hideAttachmentBtn: fileArr.length === 3 });
        this.setState({
          attachfile: fileArr,
          fileArrSimplified: fileArr,
        });
        this.state.saveChallengeJson.fileNames.push(name);
        alert("File Uploaded Successfully");
        // document.getElementById('upload-btn').classList.add('hide');
        // this.setState({fileAttachmentUrl: response.data, fileAttachmentName: this.state.fileName});
        hideLoading();
      })
      .catch((response) => {
        console.log("Error: " + response);
        alert("Error occurred! Please try again.");
        hideLoading();
      });
  };
  /**
   * add file data in formdata
   * @param {object} file file object array
   */
  composeFormData(file) {
    this.state.formData.append("files", file);
  }

  /**
   * add file data in formdata
   * @param {object} file file object array
   */
  composeFormDataAttachment(file) {
    this.state.attachmentFormData.append("files", file);
  }

  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  openDatePickerKeyEventHandler(e) {
    if (e.key === "Enter") {
      this.setState({
        datePickerIsOpen: !this.state.datePickerIsOpen,
      });
    }
  }

  handleSaveTagsClick = () => {
    this.setState({ showTagsModal: false, isSaveTagsClicked: true });
    this.updateSelectedKeywords(this.state.keywords);
  };

  handleOptionalFields = (e) => {
    const {name, checked} = e.target;
    const updatedOptionFields = this.state.optionalFields.map(item => {
      if(item.field === name) {
        return {
          ...item,
          checked: checked
        }
      }
      return item
    })
    this.setState({optionalFields: updatedOptionFields})
  }

  render() {
    const { userData } = this.props;
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "/challengeList/admin",
        text: "Challenge",
      },
      {
        url: "",
        text: "Create",
      },
    ];
    let tags = [];
    let selectedTags = [];
    let self = this;
    // const dropdownOptions = [];
    if (
      this.state.challenge !== undefined &&
      this.state.challenge !== null &&
      Object.keys(this.state.challenge).length > 0
    ) {
      this.state.challenge.tags.forEach((tag) => {
        if (self.state.keywords.indexOf(tag.tagID.toString()) >= 0) {
          selectedTags.push(
            <label
              htmlFor={tag.tagID}
              key={generateRandomKey()}
              className="checkLabel"
            >
              {tag.tag}
            </label>
          );
          tags.push(
            <input
              type="checkbox"
              onChange={self.handleCheck}
              className="optionCheck"
              name="tags"
              id={tag.tagID}
              value={tag.tag}
              checked
              key={generateRandomKey()}
            />
          );
        } else {
          tags.push(
            <input
              type="checkbox"
              onChange={self.handleCheck}
              className="optionCheck"
              name="tags"
              id={tag.tagID}
              value={tag.tag}
              key={generateRandomKey()}
            />
          );
        }
        tags.push(
          <label
            htmlFor={tag.tagID}
            className="checkLabel"
            key={generateRandomKey()}
          >
            {tag.tag}
          </label>
        );
      });
    }

    const handleRadioChange = (val) => {
      this.state.saveChallengeJson.isRegistrationRequired = val;
      this.setState({ radioBtnVal: val });
    };

    return (
      <>
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"ChallengeCreationFlow"}
        />
        <Breadcrumbs breadcrumbs={breadcrumbJSON} />
        <section className="challengeCreationFlow">
          {this.state.showErrorOnPage && (
            <p className="validate-email-label">{this.state.errorMessage}</p>
          )}
          <input
            type="text"
            className="challengeCreationTitle"
            placeholder="Title"
            value={this.state.challengeTitle}
            onChange={(e) => this.updateTitle(e)}
          ></input>
          <div className="MainSection">
            <div className="challengeSection">
              <div className="addDetailSubSection">
                {selectedTags.length !== 0 &&
                  !this.state.showTagsModal &&
                  !this.state.tagsBtnDisabled && (
                    <div className="selectedTags">{selectedTags}</div>
                  )}
                <button className="btn btnText" onClick={this.displayTagsModal}>
                  {selectedTags.length === 0 ? "Add tags" : "Edit tags"}
                </button>

                <Modal
                  show={this.state.showTagsModal}
                  onHide={this.HideTagsModal}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add keywords/Tags</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="bodyHeader">
                      <div className="bodyHeaderHeading"></div>
                      {/* <div className="bodyHeaderSubHeading"></div> */}
                      <div className="bodyHeaderSubHeading">
                        Select atleast 1
                      </div>
                    </div>
                    <div className="bodyTags">{tags}</div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={this.handleSaveTagsClick}
                      disabled={this.state.tagsBtnDisabled}
                    >
                      Save tags
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              {/* <div className="TopicDeadline">
                <div className="Topic">Topic</div>
                <div className="Deadline">Deadline</div>
              </div> */}
              <div className="challengeCreationMiddle">
                {/* <div className="challengeCreationMiddle_fields"> */}
                  {/* <div className="fieldHeading">Category</div>  */}
                  {/* <select
                    className="topicList"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    // aria-haspopup="true"
                    aria-expanded="false"
                    onChange={this.updateTopic}
                  >
                    <option value="" disabled selected>
                      Choose from our list
                    </option>
                    {topics}
                  </select> */}
                  {/* <DropDown
                    listItemSelected={this.setSelectedDropdownItem.bind(this)}
                    options={dropdownOptions}
                    placeholderText="Choose from our list"
                    dropDownName="challengeTopics"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedItem}
                  />
                </div> */}
                <div className="YearMonth">
                  <div className="fieldHeading">Deadline</div>
                  <img
                    src={calendar}
                    onClick={this.openDatePicker}
                    onKeyDown={(event) =>
                      this.openDatePickerKeyEventHandler(event)
                    }
                    alt="calendar"
                    className="YearMonth_calendar"
                    tabIndex={0}
                  />
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    // minDate={moment().toDate()}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    onClickOutside={this.openDatePicker}
                    open={this.state.datePickerIsOpen}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    tabIndex={-1}
                  />
                </div>
              </div>
              <div className="challengeCreationRegistration">
                <div className="fieldHeading">
                  Is registration required for this challenge?
                </div>
                <div className="radioBtnGroup">
                  <div>
                    <input
                      type="radio"
                      id="yes"
                      value="Yes"
                      checked={this.state.radioBtnVal === true}
                      onChange={() => handleRadioChange(true)}
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="no"
                      value="No"
                      checked={this.state.radioBtnVal === false}
                      onChange={() => handleRadioChange(false)}
                    />
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              </div>
              <div className="challengeCreationDescription">Description</div>
              <div className="challengeCreationBottom">
                {/* <textarea
                  onChange={(e) => this.updateDescription(e.target.value)}
                  className="Description"
                  value={this.state.challengeDescription}
                  placeholder="what is the challenge about?"
                ></textarea> */}
                <RichTextEditor
                  onRichTextEditorChange={this.updateDescription}
                  currentValue={this.state.challengeDescription}
                  maxTextLength={this.state.maxCharCount}
                  placeholder="what is the challenge about?"
                />
                <span className="charcter-counter">
                  {this.state.descriptionCharCount}/{this.state.maxCharCount}
                </span>
              </div>
              <div className="challengeCreationDescription">
                Rules of the Road (optional)
              </div>
              <div className="challengeCreationBottom">
                {/* <textarea
                  onChange={this.updateRulesOfTheRoad}
                  className="Description"
                  value={this.state.rulesOfTheRoad}
                  placeholder="Add any rules that apply specifically for this challenge"
                ></textarea> */}
                <RichTextEditor
                  onRichTextEditorChange={this.updateRulesOfTheRoad}
                  currentValue={this.state.rulesOfTheRoad}
                  maxTextLength={this.state.rulesOfRoadCharLimit}
                  placeholder="Add any rules that apply specifically for this challenge"
                />
                <span className="charcter-counter">
                  {this.state.rulesOfTheRoadCharCount}/
                  {this.state.rulesOfRoadCharLimit}
                </span>
              </div>
              <div className="attachment-section">
                <p>Attach supporting documents</p>
                <div className="helpSection">
                  <div className="helpSectionHeader">Note</div>
                  <div className="helpSectionList">
                    <ul>
                      <li>Upload upto 3 files</li>
                      <li>
                        .pdf, .xls, .doc, .ppt files not more than 10 MB per file
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btnText"
                  id="btnAttachFile"
                  disabled={this.state.hideAttachmentBtn}
                >
                  Attach File
                </button>
                <input
                  type="file"
                  name="mrAttachments"
                  id="mrAttachments"
                  className="mrFiles"
                  accept=".pdf, .xls, .xlsx, .doc, .ppt, .docx, .pptx"
                  file={this.state.file}
                  disabled={this.state.hideAttachmentBtn}
                  onChange={(e) => this.onAttachmentSelectFile(e)}
                  multiple
                />
                {this.state.fileArrSimplified !== undefined &&
                  this.state.fileArrSimplified.length > 0 && (
                    <ul className="attachment-list">
                      {this.state.fileArrSimplified?.map((file, index) => {
                        return (
                          <li key={file.fileText + index}>
                            <span>
                              {file.fileText}
                              {/* <button
                                onClick={() => this.removeSelectedFile(file)}
                              >
                                <img src={closeBtn} alt="removeFile" />
                              </button> */}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
              </div>
              <ChallengeEnableOptions optionalFields={this.state.optionalFields} handleOptionalFields={this.handleOptionalFields}/>
            </div>

            <div className="challengeCreationleft">
              {/* {this.state.file.length > 0 && (
                <span className="remove-icon" onClick={() => this.removeSelectedImage()}>Remove</span>
              )}
              <input
                ref={this.fileUpload}
                type="file"
                style={{
                  display: "none",
                  width: "10px",
                  height: "10px",
                  margin: 0,
                  padding: 0,
                }}
                id="file-upload"
                accept="image/x-png,image/jpg,image/jpeg"
                onChange={this.onSelectFile}
                onDragOver={this.dragOver}
                onDragEnter={this.dragEnter}
                onDragLeave={this.dragLeave}
                onDrop={this.fileDrop}
              />
              <img
                className="challengeFileUpload"
                onClick={() => this.upload()}
                onChange={this.onSelectFile}
                onDragOver={this.dragOver}
                onDragEnter={this.dragEnter}
                onDragLeave={this.dragLeave}
                onDrop={this.fileDrop}
                src={this.state.file.length ? this.state.file : challengeNewUser}
                alt=""
              />
              {this.state.file.length == 0 && (
              <div className="UploadimageText">
                <span>Drag and drop image or </span>
                <button
                  onClick={() => this.upload()}
                  id="btnAttachFile"
                  className="Upload"
                >
                  upload
                </button>{" "}
                from a file
              </div>
              )}
              <div className="NoteText">
                Note
                <br />
                .jpeg & .png file not more than 2mb
              </div> */}
              <Modal
                show={this.state.showSaveModal}
                onHide={this.hideSaveModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Challenge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="bodyHeader">
                    <div className="bodyHeaderHeading">
                      Are you sure you want to create this Challenge?
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btnText header-lm-20"
                    onClick={this.hideSaveModal}
                  >
                    Cancel
                  </button>
                  <Button
                    variant="primary"
                    onClick={() => this.saveChallenge()}
                  >
                    Create Challenge
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="btn-container align-right">
            <button
              className="btn primary saveChallengeButton"
              disabled={this.state.isBtnDisabled}
              onClick={this.displaySaveModal}
            >
              Create Challenge
            </button>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FILE_DOWNLOAD_STATUS: state.utilities.meta.FILE_DOWNLOAD_STATUS,
    file: state.utilities.data.file,
    FETCH_CHALLENGE_DETAILS_STATUS:
      state.challenges.meta.FETCH_CHALLENGE_DETAILS_STATUS,
    challenge: state.challenges.data.challenge,
    SET_SUCCESS_MESSAGE_STATUS:
      state.challenges.meta.SET_SUCCESS_MESSAGE_STATUS,
    messageData: state.challenges.data.messageData,
    FILE_UPLOAD_STATUS: state.utilities.meta.FILE_UPLOAD_STATUS,
  };
};

const mapDispatchToProps = {
  doFileUpload,
  fetchChallengeDetail,
  setSuccessMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeCreationFlow);
