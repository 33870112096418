import React, { Component } from "react";
import Slider from "react-slick";
import "../../css/partials/hero-slider/heroSlider.scss";
import marketing from "../../img/hero-slider/Marketing.png";
import cmAI from "../../img/hero-slider/cmAI.jpg";
import commerce from "../../img/hero-slider/Commerce.png";
import lightnathon from "../../img/hero-slider/lightnathon.png";
import SSTBanner from "../../img/hero-slider/ai_hackathon.png";
import { getBaseUrl } from "../../helpers/utils";

const baseUrl = getBaseUrl();
class HeroSlider extends Component {

    constructor(props){
        super(props);
        this.goToChallengeDetails = this.goToChallengeDetails.bind(this);
    }

    goToChallengeDetails(e, challengeId){
        e.preventDefault();
        window.location.href = "/challenge?id=" + challengeId;
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000
        };
        return <div className="hero-slider">
            <Slider {...settings}>
            {/* <div className="marketing" onClick={(e) => this.goToChallengeDetails(e, baseUrl.lightnathonChallengeId)}>
                    <img src={lightnathon} alt="challenge-banner-imgae1"/>
                </div>
                <div className="marketing" onClick={(e) => this.goToChallengeDetails(e, baseUrl.marketingChallengeId)}>
                    <img src={marketing} alt="challenge-banner-imgae2"/>
                </div> */}
                {/*<div className="genAI" onClick={(e) => this.goToChallengeDetails(e, baseUrl.cmAIChallengeId)}>
                    <img src={cmAI} alt="challenge-banner-image3"/>
                </div>*/}
                {/* <div className="commerce" onClick={(e) => this.goToChallengeDetails(e, baseUrl.commerceChallengeId)}>
                    <img src={commerce} alt="challenge-banner-imgae4"/>
                </div> */}
                {<div className="SST" onClick={(e) => this.goToChallengeDetails(e, baseUrl.SSTChallengeId)}>
                    <img src={SSTBanner} alt="challenge-banner-imgae"/>
                </div>}
            </Slider>
        </div>
    }
}

export default HeroSlider;
