import React, { Component } from "react";
import QuestionLayerView from "./questionLayerView";
import { generateRandomKey } from "../../helpers/utils";

class ApprovalStepView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepDetails: this.props.stepDetails || {},
      customClass: this.props.customClass || ''
    };
  }

  render() {
    return (
      <>
        <div className="ideaL">
          <div className="question-type">{this.state.stepDetails.stepName}</div>
          <div className="question-cont">
            {this.state.stepDetails.stepHeader !== '' && (
              <div className="question-title">{this.state.stepDetails.stepHeader}</div>
            )}
            {this.state.stepDetails.stepSubHeader !== '' && (
              <div className="question-subtitle">{this.state.stepDetails.stepSubHeader}</div>
            )}
            {this.state.stepDetails.questions.map((question) => {
              return (
                <QuestionLayerView
                  stepID={this.state.stepDetails.stepID}
                  questionDetail={question}
                  key={generateRandomKey()}
                  customClass={this.state.customClass}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
export default ApprovalStepView; 