import React, { Component } from "react";

class breadcrumbs extends Component {
  render() {
    const breadcrumbJSON = this.props.breadcrumbs;
    const breadcrumbList = breadcrumbJSON.map((breadcrumb, index) => (
      <div className="breadcrumb" key={breadcrumb.text + index}>
        {index === breadcrumbJSON.length - 1 ? (
          breadcrumb.text
        ) : (
          <a href={breadcrumb.url}>{breadcrumb.text}</a>
        )}
      </div>
    ));
    return (
      <>
        <div className="breadcrumbsMain">{breadcrumbList}</div>
      </>
    );
  }
}

export default breadcrumbs;
