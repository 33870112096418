import React, { Component } from "react";
import Header from "../components/global/header";
import Breadcrumbs from "../components/global/breadcrumbs";
import ProfileInfo from "../components/userProfile/profileInfo";
// import InvestedIdea from "../components/userProfile/investedIdea";
import "../css/pages/userProfile.scss";
import ProfilePersonalCorner from "../components/userProfile/profilePersonalCorner";
class UserProfile extends Component {
  render() {
    const { userData } = this.props;
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "",
        text: "Profile",
      },
    ];
    // const mentorBanner = {
    //   img: bannerImg,
    //   title: "Do you want to be a mentor?",
    //   description: "Help your colleagues build their ideas",
    //   ctaIcon: false,
    //   ctaText: "Be a Mentor",
    //   customClass: "mentor-banner",
    // };
    return (
      <>
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"userProfile"}
        />
        <Breadcrumbs breadcrumbs={breadcrumbJSON} />
        <div className="profile-container">
          <ProfileInfo userInfo={userData} />
        </div>
        <div className="section-container">
          <ProfilePersonalCorner userData={userData} />
          {/* <ProfileInfoBanner data={mentorBanner} bannerImg={bannerImg} /> */}
        </div>
        {/* <div className="section-slider-container">
          <InvestedIdea />
        </div> */}
      </>
    );
  }
}

export default UserProfile;
