export const FETCH_TOP_CHALLENGES_PENDING = "FETCH_TOP_CHALLENGES_PENDING";
export const FETCH_TOP_CHALLENGES_FULFILLED = "FETCH_TOP_CHALLENGES_FULFILLED";
export const FETCH_TOP_CHALLENGES_REJECTED = "FETCH_TOP_CHALLENGES_REJECTED";

export const FETCH_CHALLENGE_DETAILS_PENDING =
  "FETCH_CHALLENGE_DETAILS_PENDING";
export const FETCH_CHALLENGE_DETAILS_FULFILLED =
  "FETCH_CHALLENGE_DETAILS_FULFILLED";
export const FETCH_CHALLENGE_DETAILS_REJECTED =
  "FETCH_CHALLENGE_DETAILS_REJECTED";

// export const ACCEPT_CHALLENGE_PENDING = "ACCEPT_CHALLENGE_PENDING,";
// export const ACCEPT_CHALLENGE_FULFILLED = "ACCEPT_CHALLENGE_FULFILLED";
// export const ACCEPT_CHALLENGE_REJECTED = "ACCEPT_CHALLENGE_REJECTED";

export const FETCH_CHALLENGE_USER_PENDING = "FETCH_CHALLENGE_USER_PENDING,";
export const FETCH_CHALLENGE_USER_FULFILLED = "FETCH_CHALLENGE_USER_FULFILLED";
export const FETCH_CHALLENGE_USER_REJECTED = "FETCH_CHALLENGE_USER_REJECTED";

export const FETCH_SAVED_CHALLENGE_PENDING = "FETCH_SAVED_CHALLENGE_PENDING,";
export const FETCH_SAVED_CHALLENGE_FULFILLED =
  "FETCH_SAVED_CHALLENGE_FULFILLED";
export const FETCH_SAVED_CHALLENGE_REJECTED = "FETCH_SAVED_CHALLENGE_REJECTED";

export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
