import { Component } from "react";
import { getNameInitials } from "../../helpers/utils";

class TopIdeator extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
       // const { ideator: { firstName, lastName, email, numberOfIdeas }, index } = this.props;
       const { ideator: { teamName, numberOfIdeas }, index } = this.props;
        const order = index === 2 ? 0 : 1;
        const footerHeight = 70 / index + '%';
        const footerBackgroundColor = index === 1 ? 'rgb(0 185 128)' : (index === 2 ? 'rgb(0 47 128)' : 'rgb(0 68 102)');
        const boxShadow = `4px 4px 12px ${footerBackgroundColor}`;
        const suffix = index === 1 ? 'st' : index == 2 ? 'nd' : 'rd';


        return <li className="top-ideator" style={{ 'order': order }}>
            <header>
                {/* <p className="userCreds">{getNameInitials(`${firstName} ${lastName}`)}</p>
                <p className="top-ideator-name">{firstName} {lastName}</p> */}
                <p className="top-ideator-name">{teamName}</p>
                <p className="top-ideator-count">{numberOfIdeas} {numberOfIdeas>1 ? "ideas" : "idea"}</p>
            </header>
            <footer style={{ 'height': footerHeight, 'background': footerBackgroundColor, 'boxShadow': boxShadow}} >
                <span className="rank">{index}</span><span className="suffix">{suffix}</span>
            </footer>
        </li>
    };
}

export default TopIdeator;
