import React, { Component } from "react";
import "../../css/pages/challengeCreationFlow.scss";
import User from "../../img/ideation/challenge2.jpg";
import Header from "../global/header";
import Breadcrumbs from "../global/breadcrumbs";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import axios from "axios";
import {
  generateRandomKey,
  hideLoading,
  showLoader,
  getBaseUrl,
  getTextFromHtmlString
} from "../../helpers/utils";
import { fetchFileList } from "../../redux/actions/utilities";
import { _CONSTANTS } from "../../helpers/constants";
import moment from "moment";
import closeBtn from "../../img/close.svg";
import calendar from "../../img/calendar.svg";
import { doFileUpload, doFileDelete } from "../../redux/actions/utilities";
import DropDown from "../global/dropdown";
import challengeNewUser from "../../img/ideation/challengenewuser.png";
import RichTextEditor from "../global/richTextEditor";
import DOMPurify from "dompurify";

const baseUrl = getBaseUrl();
let dropdownOptions = [];
class challengeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: [],
      showTagsModal: false,
      renderJSON: props.renderJSON,
      challengeJson: props.renderJSON,
      showMRModal: false,
      file: "",
      formData: new FormData(),
      attachmentFormData: new FormData(),
      initialDate: new Date(),
      updateTitle: '',
      updateDescription: '',
      updateTopics: false,
      updateDate: false,
      updateTags: false,
      showErrorOnPage: false,
      errorMessage: '',
      editChallengeJson: {
        challengeID: "",
        challengeName: "",
        tags: [],
        topics: [],
        description: "",
        creatorID: this.props.userData.id,
        challengeDate: new Date(),
        rulesOfTheRoad: '',
        fileNames: [],
        isRegistrationRequired: false,
        templateFields: ''
      },
      maxCharCount: 2000,
      descriptionCharCount: 0,
      isBtnDisabled: false,
      updatedTopics: true,
      datePickerIsOpen: false,
      hideAttachmentBtn: false,
      attachfile: [],
      file: [],
      selectedItem: {},
      fileArrSimplified: [],
      // attachFileUrl: []
      tagsBtnDisabled: true,
      rulesOfTheRoad: '',
      rulesOfTheRoadCharCount: 0,
      rulesOfRoadCharLimit: 2000
    };
    this.fileUpload = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.updateRulesOfTheRoad = this.updateRulesOfTheRoad.bind(this);
    this.uploadS3Files = this.uploadS3Files.bind(this);
    this.getUploadedS3FileUrl = this.getUploadedS3FileUrl.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    let challengeId = "";
    if (urlParams.has("id")) {
      challengeId = urlParams.get("id");
    }
    this.setState({
      editChallengeJson: {
        ...this.state.editChallengeJson,
        challengeID: challengeId,
      },
    });
    showLoader();
    axios
      .get(
        baseUrl.ipAddress +
        "api/v1/challenges/" +
        parseInt(challengeId) +
        "?userId=" +
        this.state.editChallengeJson.creatorID,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        }
      )
      .then((response) => {
        const data = response.data;
        const { challengeID,
          challengeName,
          challengeDescription: description,
          rulesOfTheRoad,
          challengeDate
        } = data;
        const selectedTopic = data.topics[0];
        const keywords = data.tags?.map(tag => tag.tagID.toString()) || [];
        
        this.setState({
          renderJSON: data,
          updateDescription: data.challengeDescription,
          descriptionCharCount: getTextFromHtmlString(data.challengeDescription).length,
          updateTitle: data.challengeName,
          rulesOfTheRoad: data.rulesOfTheRoad,
          rulesOfTheRoadCharCount: getTextFromHtmlString(data.rulesOfTheRoad).length ?? 0,
          selectedItem: {
            value: selectedTopic.topicID,
            name: selectedTopic.topicText,
            selected: true,
            visible: true,
            id: selectedTopic.topicID
          },
          keywords,
          tagsBtnDisabled: keywords.length >= 1,
          editChallengeJson: {
            ...this.state.editChallengeJson,
            challengeID,
            challengeName,
            description,
            rulesOfTheRoad,
            challengeDate,
            topics: [...this.state.editChallengeJson.topics, selectedTopic.topicID],
            tags: [...this.state.editChallengeJson.tags, ...keywords],
            isRegistrationRequired: data?.registrationRequired,
            templateFields: data?.ideaTemplateFields
          }
        });
        
        if(data.fileNames?.length > 0){
          this.getUploadedS3FileUrl(data.fileNames);
        }
        
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });

    axios
      .get(baseUrl.ipAddress + "api/v1/challenges", {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      })
      .then((data) => {
        this.setState({ challengeJson: data.data });
        data.data.topics.forEach((topic) => {
          const option = {
            value: topic.topicID,
            name: topic.topicText,
            id: topic.topicID,
            selected: false,
            visible: true,
          };
          dropdownOptions.push(option);
        });
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  }

  componentDidUpdate(prevProps) {

  }

  getUploadedS3FileUrl(fileNames) {
    showLoader();
    fileNames?.forEach((fileName, index) => {
      this.state.editChallengeJson.fileNames.push(fileName);
      axios
        .get(baseUrl.getS3FileUrl + fileName)
        .then((response) => {
          const file = {fileText: fileName, fileUrl: response.data, fileID: index};
          this.setState({
            fileArrSimplified: [...this.state.fileArrSimplified, file]
          });
          hideLoading();
        })
        .catch((err) => {
          console.log(err);
          hideLoading();
        });
    })
  }

  updateRulesOfTheRoad(value) {
    const rulesOfTheRoad = value /* value.substring(0, Math.min(value.length, this.state.rulesOfRoadCharLimit)) */;
    this.state.editChallengeJson.rulesOfTheRoad = rulesOfTheRoad;
    this.setState({
      rulesOfTheRoad,
      rulesOfTheRoadCharCount: getTextFromHtmlString(rulesOfTheRoad).length,
    });
  }


  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  editChallenge() {
    let self = this;
    showLoader();
    axios
      .post(
        baseUrl.ipAddress + "api/v1/challenges",
        self.state.editChallengeJson,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        }
      )
      .then((response) => {
        this.setState({
          challengeCreated: response.data,
        });
        // if (document.getElementById('mrAttachments').value !== '') {
        //   this.props.doFileUpload(
        //     response.data,
        //     _CONSTANTS.CHALLENGESTATUS.CREATE_CHALLENGE,
        //     this.state.attachmentFormData,
        //     _CONSTANTS.CHALLENGESTATUS.UPLOAD,
        //     _CONSTANTS.CHALLENGESTATUS.CHALLENGE_ATTACHMENT
        //   );
        // }
        setTimeout(() => {
          window.location.href =
            "/challenge?id=" +
            this.state.challengeCreated;
        }, 1000);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
    if (this.state.challengeCreated !== undefined) {
      window.location.href = "/challenge?id=" + this.state.challengeCreated;
    }
  }

  updateTitle(e) {
    const val = DOMPurify.sanitize(e.target.value);
    this.setState({ updateTitle: val })
    this.state.editChallengeJson.challengeName = val;
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  }

  setSelectedDropdownItem(event, item) {
    this.setState({ selectedItem: item });
    this.state.updatedTopics = true;
    const topicValue = item.name;
    this.state.editChallengeJson.topics = [];
    this.state.challengeJson.topics.forEach((topic) => {
      if (topic.topicText === topicValue) {
        const id = topic.topicID;
        this.state.editChallengeJson.topics.push(id);
      }
    });
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  }

  updateDescription = (value) => {
    const descp = value /* value?.substring(0, Math.min(value.length, this.state.maxCharCount)) */ || '';
    this.state.editChallengeJson.description = descp;
    this.setState({ descriptionCharCount: getTextFromHtmlString(descp).length, updateDescription: descp })
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 1000);
  };

  validateFields = () => {
    let validated =
      this.state.updateTitle !== "" &&
      this.state.keywords.length >= 1 &&
      this.state.updateDescription !== "" &&
      this.state.updatedTopics === true;

    if (!validated && this.state.updateTitle === "") {
      this.setState({ showErrorOnPage: true, errorMessage: 'Please enter Challenge Title' });
    } else if (!validated && this.state.keywords.length < 1) {
      this.setState({ showErrorOnPage: true, errorMessage: 'Please select atleast 1 Keyword' });
    } else if (!validated && this.state.updateDescription === "") {
      this.setState({ showErrorOnPage: true, errorMessage: 'Please enter Challenge Description' });
    } else if (!validated && !this.state.updatedTopics) {
      this.setState({ showErrorOnPage: true, errorMessage: 'Please select Challenge Topic' });
    } else if (validated) {
      this.setState({ showErrorOnPage: false, errorMessage: '' });
    }

    return validated;
  };

  updateSelectedKeywords = (keywords) => {
    let renderJSONTemp = this.state.renderJSON;
    renderJSONTemp.selectedTags = keywords;
    this.setState({ renderJSON: renderJSONTemp });
  };

  displayTagsModal = () => {
    this.setState({ showTagsModal: true });
  };

  HideTagsModal = () => {
    this.setState({ showTagsModal: false });
    this.updateSelectedKeywords(this.state.keywords);
  };

  handleChange(date) {
    this.state.updateDate = true;
    this.state.editChallengeJson.challengeDate = new Date(date).toISOString();
    this.setState({
      initialDate: date,
    });
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  handleCheck = (e) => {
    const { id, checked } = e.target;
    e.target.attributes[checked] = checked;
    if (checked && this.state.keywords.indexOf(id) === -1) {
      this.state.keywords.push(id);
      this.state.editChallengeJson.tags.push(parseInt(id));
    } else if (!checked && this.state.keywords.indexOf(id) !== -1) {
      const checkIndex = this.state.keywords.indexOf(id);
      this.state.keywords.splice(checkIndex, 1);
      this.state.editChallengeJson.tags.splice(checkIndex, 1);
    }
    this.setState({
      tagsBtnDisabled: this.state.keywords.length >= 1 ? false : true,
    });
    setTimeout(() => {
      const isValid = this.validateFields();
      this.setState({ isBtnDisabled: !isValid });
    }, 100);
  };

  removeSelectedImage = (event) => {
    this.setState({file:[]});
  }

  onSelectFile = (event) => {
    let existingFile = this.props.existingFileList;
    var formFile = event.target.files[0];
    if (
      !(formFile.name.indexOf(".jpg") > -1 || formFile.name.indexOf(".png") > -1 || formFile.name.indexOf(".jpeg") > -1)
    ) {
      alert("Please upload only .jpg or .jpeg or .png.");
      return;
    } else if (formFile.size / 1024 / 1024 > 2) {
      alert(formFile.name + ": Please upload file not more than 2Mb.");
      return;
    }
    if (
      existingFile.profile !== null &&
      Object.keys(existingFile.profile).length > 0
    ) {
      this.props.doFileDelete(
        this.state.editChallengeJson.challengeID,
        existingFile.profile.fileName
      );
    }
    var file = URL.createObjectURL(event.target.files[0]);
    this.setState({ file });
    this.composeFormData(formFile);
  };

  upload() {
    this.fileUpload.current.click();
  }

  dragOver = (e) => {
    e.preventDefault();
  };

  dragEnter = (e) => {
    e.preventDefault();
  };

  dragLeave = (e) => {
    e.preventDefault();
  };

  fileDrop = (e) => {
    e.preventDefault();
    var files = e.dataTransfer.files[0];
    if (!(files.name.indexOf(".jpg") > -1 || files.name.indexOf(".png") > -1 || files.name.indexOf(".jpeg") > -1)) {
      alert("Please upload only .jpg or .jpeg or .png.");
      return;
    } else if (files.size / 1024 / 1024 > 2) {
      alert(files.name + ": Please upload file not more than 2Mb.");
      return;
    }
    const file = URL.createObjectURL(files);
    this.setState({ file });
    this.composeFormData(files);
  };

  /**
   * checks and removes file
   * @param {number} id
   */
  checkAndRemoveFile = (id) => {
    let tempArr = [];
    tempArr = this.state.attachmentFormData.getAll('files').filter(function (file) {
      return file.id !== id;
    })
    return tempArr;
  };

  /**
   * removes file from the file list
   * @param {object} file removed file object
   */
  removeSelectedFile = (file) => {
    // let formFile = this.state.attachfile;
    let fileArrSimplified = this.state.fileArrSimplified;
    // const id = formFile.findIndex((item) => item.id === file.fileID);
    // formFile.splice(id, 1);
    const idSimplified = fileArrSimplified.findIndex(
      (item) => item.fileID === file.fileID
    );
    fileArrSimplified.splice(idSimplified, 1);
    this.setState({ attachmentFormData: new FormData() });
    const revisedFileArr = this.checkAndRemoveFile(file.fileID);
    setTimeout(() => {
      revisedFileArr.forEach((file) => {
        this.state.attachmentFormData.append("files", file);
      });
    }, 100);
    this.setState({
      attachfile: fileArrSimplified,
      hideAttachmentBtn: false,
      fileArrSimplified: fileArrSimplified,
    });
    // this.composeFormDataAttachment(formData);
    this.props.doFileDelete(
      this.state.editChallengeJson.challengeID,
      file.fileText
    );
    const foundIdex = this.state.editChallengeJson.fileNames.findIndex((fileName => fileName === file.fileText));
    if(foundIdex > -1){
      this.state.editChallengeJson.fileNames.splice(foundIdex, 1);
    }
  };

  /**
   * makes an object of file
   * @param {object} basicData raw file data
   */
  constructFileItemsArray = (basicData) => {
    let itemArr = {};
    itemArr.fileID = basicData.id;
    itemArr.fileUrl = URL.createObjectURL(basicData);
    itemArr.fileText = basicData.name;
    return itemArr;
  };

  /**
   * check for dupliacte files while uploading
   * @param {string} fileName name of the file
   */
  checkForDuplicateFiles = (fileName) => {
    return this.state.fileArrSimplified.find((element) => {
      return element.fileText === fileName ? true : false;
    });
  };

  /**
   * on file selection, add to file list
   * @param {event} event event
   */
  onAttachmentSelectFile = (event) => {
    const formFile = event.target.files;
    // let fileArr = this.state.attachfile;
    let fileArrSimplified = this.state.fileArrSimplified;
    if ((fileArrSimplified.length + formFile.length) > 3 || fileArrSimplified.length > 2 || formFile.length > 3) {
      alert("Maximum of 3 files allowed");
      return false;
    }
    if (this.state.fileArrSimplified.length > 0) {
      let count = this.state.fileArrSimplified.length;
      for (const [id, data] of Object.entries(formFile)) {
        const isDuplicate = this.checkForDuplicateFiles(data.name);
        if (isDuplicate) {
          return;
        }
        if (data.size / 1024 / 1024 > 10) {
          alert(data.name + ": Please upload file not more than 10 MB.");
        } else if (
          !(data.name.indexOf(".pdf") > -1 || data.name.indexOf(".xls") > -1 || data.name.indexOf(".doc") > -1 || data.name.indexOf(".ppt") > -1)
        ) {
          alert("Please upload only .pdf or .xls or .doc or .ppt");
        } else {
          count = count + 1;
          data.id = count;
          const simplifiedArr = this.constructFileItemsArray(data);
          // fileArr.push(data);
          this.composeFormDataAttachment(data);
          fileArrSimplified.push(simplifiedArr);
          this.uploadS3Files(data.name, data, fileArrSimplified);
        }
      }
    } else {
      for (const [id, data] of Object.entries(formFile)) {
        const isDuplicate = this.checkForDuplicateFiles(data.name);
        if (isDuplicate) {
          return;
        }
        if (data.size / 1024 / 1024 > 10) {
          alert(data.name + ": Please upload file not more than 10 MB.");
        } else if (
          !(data.name.indexOf(".pdf") > -1 || data.name.indexOf(".xls") > -1 || data.name.indexOf(".doc") > -1 || data.name.indexOf(".ppt") > -1)
        ) {
          alert("Please upload only .pdf or .xls or .doc or .ppt");
        } else {
          data.id = parseInt(id);
          const simplifiedArr = this.constructFileItemsArray(data);
          // fileArr.push(data);
          this.composeFormDataAttachment(data);
          fileArrSimplified.push(simplifiedArr);
          this.uploadS3Files(data.name, data, fileArrSimplified);
        }
      }
    }
    // this.props.uploadFile(this.state.formData);
    event.target.value = '';
  };

  uploadS3Files(name, file, fileArr) {
    showLoader();
    axios({
      method: "post",
      url: baseUrl.uploadUrl + name,
      data: file,
      headers: { "Content-Type": file.type },
    })
      .then((response) => {
        this.setState({ hideAttachmentBtn: fileArr.length === 3 });
        this.setState({
          attachfile: fileArr,
          fileArrSimplified: fileArr,
        });
        this.state.editChallengeJson.fileNames.push(name);
        alert('File Uploaded Successfully');
        // document.getElementById('upload-btn').classList.add('hide');
        // this.setState({fileAttachmentUrl: response.data, fileAttachmentName: this.state.fileName});
        hideLoading();
      })
      .catch((response) => {
        console.log('Error: ' + response);
        alert('Error occurred! Please try again.');
        hideLoading();
      });
  }

  /**
   * add file data in formdata
   * @param {object} file file object array
   */
  composeFormData(file) {
    this.state.formData.append("files", file);
  }

  /**
   * add file data in formdata
   * @param {object} file file object array
   */
  composeFormDataAttachment(file) {
    this.state.attachmentFormData.append("files", file);
  }

  handleSaveTagsClick = () => {
    this.setState({ showTagsModal: false, isSaveTagsClicked: true });
    this.updateSelectedKeywords(this.state.keywords);
  }

  render() {
    const { userData } = this.props;
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        // url: "/challengeList/admin",
        url: `${(userData.userRole === _CONSTANTS.USER.PANELLEAD || userData.userRole === 'ADMIN')
          ? "/challengeList/admin"
          : "/challengeList/user"
          }`,
        text: "Challenge",
      },
      {
        url: "",
        text: "Edit",
      },
    ];
    let tags = [];
    let selectedTags = [];
    let self = this;
    let editChallengeDetail = [];
    if (
      this.state.renderJSON !== undefined &&
      this.state.challengeJson !== undefined
    ) {
      //Challenge Tags and Topics
      this.state.challengeJson.tags.forEach((tag) => {
        if (self.state.keywords.indexOf(tag.tagID.toString()) >= 0) {
          selectedTags.push(
            <label
              htmlFor={tag.tagID}
              key={generateRandomKey()}
              className="checkLabel"
            >
              {tag.tag}
            </label>
          );
          tags.push(
            <input
              type="checkbox"
              onChange={self.handleCheck}
              className="optionCheck"
              name="tags"
              id={tag.tagID}
              value={tag.tag}
              checked
              key={generateRandomKey()}
            />
          );
        } else {
          tags.push(
            <input
              type="checkbox"
              onChange={self.handleCheck}
              className="optionCheck"
              name="tags"
              id={tag.tagID}
              value={tag.tag}
              key={generateRandomKey()}
            />
          );
        }
        tags.push(
          <label
            htmlFor={tag.tagID}
            className="checkLabel"
            key={generateRandomKey()}
          >
            {tag.tag}
          </label>
        );
      });

      //Challenge Detail
      editChallengeDetail = this.state.renderJSON;
      if (!this.state.updateDate) {
        this.state.initialDate = new Date(editChallengeDetail.challengeDate);
      }
    }
    return (
      <>
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"challengeEdit"}
        />
        <Breadcrumbs breadcrumbs={breadcrumbJSON} />
        <div className="challengeCreationFlow">
          {this.state.showErrorOnPage && (
            <p className="validate-email-label">{this.state.errorMessage}</p>
          )}
          <input
            type="text"
            className="challengeCreationTitle"
            placeholder="Title"
            value={this.state.updateTitle}
            onChange={(e) => this.updateTitle(e)}
          />
          <div className="MainSection">
            <div className="challengeSection">
              <div className="addDetailSubSection">
                <div className="EditTag">
                  {selectedTags.length !== 0 && (
                    <div className="selectedTags">{selectedTags}</div>
                  )}
                  <button
                    className="btn btnText"
                    onClick={this.displayTagsModal}
                  >
                    {selectedTags.length === 0 ? "Add tags" : "Edit tags"}
                  </button>

                  <Modal
                    show={this.state.showTagsModal}
                    onHide={this.HideTagsModal}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add keywords/Tags</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="bodyHeader">
                        <div className="bodyHeaderHeading"></div>
                        <div className="bodyHeaderSubHeading"></div>
                      </div>

                      <div className="bodyTags">{tags}</div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={this.handleSaveTagsClick} disabled={this.state.tagsBtnDisabled}>
                        Save tags
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              <div className="challengeCreationMiddle">
                <div className="challengeCreationMiddle_fields">
                  <div className="fieldHeading">Category</div>
                  <DropDown
                    listItemSelected={this.setSelectedDropdownItem.bind(this)}
                    options={dropdownOptions}
                    placeholderText="Choose from our list"
                    dropDownName="challengeTopics"
                    isDropDownNative={false}
                    selectedItem={this.state.selectedItem}
                  />
                  {/* <HiOutlineChevronDown className="downwardArrow" /> */}
                </div>
                <div className="YearMonth">
                  <div className="fieldHeading">Deadline</div>
                  <img
                    src={calendar}
                    onClick={this.openDatePicker}
                    alt="calendar"
                    className="YearMonth_calendar"
                  />
                  <DatePicker
                    selected={this.state.initialDate}
                    onChange={this.handleChange}
                    minDate={moment().toDate()}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    onClickOutside={this.openDatePicker}
                    open={this.state.datePickerIsOpen}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              </div>
              <div className="challengeCreationDescription">Description</div>
              <div className="challengeCreationBottom">
                {/* <textarea
                  onChange={(e) => this.updateDescription(e)}
                  className="Description"
                  placeholder="what is the challenge about?"
                  value={this.state.updateDescription}
                ></textarea> */}
                <RichTextEditor 
                  onRichTextEditorChange={this.updateDescription} 
                  currentValue={this.state.updateDescription}
                  maxTextLength={this.state.maxCharCount}
                  placeholder="what is the challenge about?"/>
                <span className="charcter-counter">
                  {this.state.descriptionCharCount}/{this.state.maxCharCount}
                </span>
              </div>
              <div className="challengeCreationDescription">Rules of the Road (optional)</div>
              <div className="challengeCreationBottom">
                {/* <textarea
                  onChange={this.updateRulesOfTheRoad}
                  className="Description"
                  value={this.state.rulesOfTheRoad}
                  placeholder="Add any rules that apply specifically for this challenge"
                ></textarea> */}
                <RichTextEditor 
                  onRichTextEditorChange={this.updateRulesOfTheRoad} 
                  currentValue={this.state.rulesOfTheRoad}
                  maxTextLength={this.state.rulesOfRoadCharLimit}
                  placeholder="Add any rules that apply specifically for this challenge"/>
                <span className="charcter-counter">
                  {this.state.rulesOfTheRoadCharCount}/{this.state.rulesOfRoadCharLimit}
                </span>
              </div>
              <div className="attachment-section">
                <p>Attach supporting documents</p>
                {!this.state.hideAttachmentBtn && (
                  <>
                    <button className="btn btnText" id="btnAttachFile">
                      Attach File
                    </button>
                    <input
                      type="file"
                      name="mrAttachments"
                      id="mrAttachments"
                      className="mrFiles"
                      accept=".pdf, .xls, .xlsx, .doc, .ppt, .docx, .pptx"
                      file={this.state.file}
                      onChange={(e) => this.onAttachmentSelectFile(e)}
                      multiple
                    />
                  </>
                )}
                {this.state.fileArrSimplified !== undefined &&
                  this.state.fileArrSimplified.length > 0 && (
                    <ul className="attachment-list">
                      {this.state.fileArrSimplified?.map((file, index) => {
                        return (
                          <li key={file.fileText + index}>
                            <span>
                              {file.fileText}
                              {/* <button
                                onClick={() => this.removeSelectedFile(file)}
                              >
                                <img src={closeBtn} alt="removeFile" />
                              </button> */}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
              </div>
            </div>
            <div className="challengeCreationleft">
              {/* {this.state.file.length > 0 && (
                <span className="remove-icon" onClick={() => this.removeSelectedImage()}>Remove</span>
              )} */}
              {/* <input
                ref={this.fileUpload}
                type="file"
                style={{
                  display: "none",
                  width: "10px",
                  height: "10px",
                  margin: 0,
                  padding: 0,
                }}
                id="file-upload"
                accept=".jpeg,.png"
                onChange={this.onSelectFile}
                onDragOver={this.dragOver}
                onDragEnter={this.dragEnter}
                onDragLeave={this.dragLeave}
                onDrop={this.fileDrop}
              />
              <img
                className="challengeFileUpload"
                onClick={() => this.upload()}
                onChange={this.onSelectFile}
                onDragOver={this.dragOver}
                onDragEnter={this.dragEnter}
                onDragLeave={this.dragLeave}
                onDrop={this.fileDrop}
                src={this.state.file}
                alt=""
              />
              <div className="UploadimageText">
                <span>Drag and drop image or </span>
                <button
                  onClick={() => this.upload()}
                  id="btnAttachFile"
                  className="Upload"
                >
                  upload
                </button>
                from a file
              </div>
              <div className="NoteText">
                Note
                <br />
                .jpeg & .png file not more than 2mb
              </div> */}
            </div>
          </div>
          <div className="btn-container align-right">
            <button
              className="btn primary saveChallengeButton"
              onClick={() => this.editChallenge()}
              disabled={this.state.isBtnDisabled}
            >
              Save Challenge
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FILE_DOWNLOAD_STATUS: state.utilities.meta.FILE_DOWNLOAD_STATUS,
    file: state.utilities.data.file,

    FILE_LIST_STATUS: state.utilities.meta.FILE_LIST_STATUS,
    existingFileList: state.utilities.data.existingFileList,

    FETCH_CHALLENGE_DETAILS_STATUS:
      state.challenges.meta.FETCH_CHALLENGE_DETAILS_STATUS,
    challenge: state.challenges.data.challenge,
  };
};

const mapDispatchToProps = {
  fetchFileList,
  doFileDelete,
  doFileUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(challengeEdit);
