import React, { Component } from "react";
import RadioTextDescription from "./radioTextDescription";
import RatingSubtext from "./ratingSubtext";
import { generateRandomKey } from "../../helpers/utils";

class SubQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subQuestion: this.props.subQuestion || {},
            stepID: this.props.stepID,
            questionArr: []
        };
    }

    /**
   * handles the radio type change on click
   * @param {Number} selectedValue selected radio type
   * @param {String} value selected radio text
   */
    onOptionValueChange = (selectedValue, value, score, questionId, stepID) => {
        let renderJSONTemp = this.state.subQuestion;
        renderJSONTemp.options.forEach((option) => {
            option.selected = false;
            if (option.text === value) {
                option.selected = true;
                this.props.selectedAnswerDetails(renderJSONTemp.questionId, option.id, "", score);
            }
        });
        this.setState({ subQuestion: renderJSONTemp });
        // if (this.state.subQuestion.questionType === "ratingSubtext") {
        this.props.onScoreChange(score, questionId);
        // }
        this.state.questionArr.push({ stepID: stepID, question: questionId });
        this.props.updateQuestionArr(this.state.questionArr);
    };

    updateOtherComment = (selectedId, stepId, subCustomText) => {
        this.props.selectedAnswerDetails(this.state.subQuestion.questionId, selectedId, "", 0, subCustomText);
    }

    render() {
        return (
            <>
                <div className="question-cont">
                    {this.state.subQuestion.questionText !== '' && (
                        <div className="question-title">{this.state.subQuestion.questionText}</div>
                    )}
                    {this.state.subQuestion.statementText !== '' && (
                        <div className="question-subtitle">{this.state.subQuestion.statementText}</div>
                    )}
                    {this.state.subQuestion.questionType === 'radioTextDescription' && (
                        <div className="RatingWholeScetion">
                            {this.state.subQuestion.options.length > 0 && this.state.subQuestion.options.map((radioVal) => {
                                return (
                                    <RadioTextDescription
                                        stepID={this.state.stepID}
                                        key={generateRandomKey()}
                                        questionId={this.state.subQuestion.questionId}
                                        radioValues={radioVal}
                                        onRadioValueChange={this.onOptionValueChange}
                                        updateOtherComment={this.updateOtherComment.bind(this)}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {this.state.subQuestion.questionType === 'ratingSubtext' && (
                        <RatingSubtext
                            stepID={this.state.stepID}
                            question={this.state.subQuestion}
                            onRatingValueChange={this.onOptionValueChange}
                        />
                    )}
                </div>
            </>
        )
    }
}

export default SubQuestion;