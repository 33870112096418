import React, { Component } from 'react';

class PaginationCount extends Component {
    render() {
        let productListCount = this.props.productListCount;
        if (this.props.productListCount > this.props.totalRecordsCount) {
            productListCount = this.props.totalRecordsCount;
        }
        return (
            <div className="page-count-data">
                Showing {(this.props.startCount * this.props.rowCount) + 1} - {productListCount}/{this.props.totalRecordsCount}
            </div>
        )
    }
}

export default PaginationCount;
