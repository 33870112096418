import React, { Component } from "react";
import arrowRight from "../../img/arrow-blue-right.svg";
import challengeImage from "../../img/ideation/challenge2.jpg";
import { getNameInitials } from "../../helpers/utils";

class ChallengeItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userData && this.props.userData.id
        };
    }

    navigateToChallenge = (id) => {
        window.location.href = "/challenge?id=" + id;
    };

    render() {
        const { challenge = {} } = this.props;
        let color = "#f7bf46";
        if (Object.keys(challenge).length > 0) {
            if (challenge.timeRemaining && challenge.timeRemaining.days > 1) {
                color = "#077F5A";
            } else if (challenge.timeRemaining && challenge.timeRemaining.days < 1) {
                if (challenge.timeRemaining.hours >= 15) {
                    color = "#077F5A";
                } else if (challenge.timeRemaining.hours >= 10) {
                    let color = "#f7bf46";
                } else if (challenge.timeRemaining.hours < 10) {
                    color = '#A5143A';
                }
            }
        }

        return (
            <div className="challenge-cont" onClick={() => {
                this.navigateToChallenge(challenge.challengeID);
            }}>
                <div className="challenge-cont_image">
                    <img
                        src={challengeImage}
                        alt="challenge"
                    />
                </div>
                <div className="challenge-cont_detail">
                    <div className="challengeHeading">
                        {Object.keys(challenge).length > 0 && challenge.challengeName !== undefined && challenge.challengeName.length > 45
                            ? `${challenge.challengeName.substring(0, 40)} ...`
                            : challenge.challengeName}
                    </div>
                    <div className="challengeStatus" style={{ "color": color }}>
                        {Object.keys(challenge).length > 0 && challenge.timeRemaining && challenge.timeRemaining.days + (challenge.timeRemaining.days > 1 ? " days " : " day ") + challenge.timeRemaining.hours + (challenge.timeRemaining.hours > 1 ? " hrs" : " hr")}<span> till deadline</span>
                    </div>
                    <div className="challengeDescription">
                        {/* <div className="challengeDescription_description">
                            {Object.keys(challenge).length > 0 && challenge.challengeDescription && challenge.challengeDescription.length > 55
                                ? `${challenge.challengeDescription.substring(0, 55)} ...`
                                : challenge.challengeDescription}
                        </div> */}
                        <div className="challengeDescription_creator">
                            <div className="challengeDescription_creator-image">
                                <div className="challengeDescription_creator-image-cont">
                                    {/* <img
                                        src={Object.keys(challenge).length > 0 && challenge.organiser.imageUrl}
                                        alt={Object.keys(challenge).length > 0 && challenge.organiser.name}
                                    /> */}
                                    <div className="userCreds">{Object.keys(challenge).length > 0 && challenge.organiser ? getNameInitials(challenge.organiser.name) : ''}</div>
                                </div>
                                <p>{Object.keys(challenge).length > 0 && challenge.organiser && challenge.organiser.name}</p>
                            </div>
                            <div className="challengeDescription_creator-invested">
                                <span>{Object.keys(challenge).length > 0 && challenge.tags && challenge.tags.length > 0 && challenge.tags[0].tag}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="challengelink"
                        onClick={() => {
                            this.navigateToChallenge(challenge.challengeID);
                        }}
                    >
                        <img src={arrowRight} alt="arrow-right" />
                    </div>
                </div>
            </div>
        );
    }
}
export default ChallengeItem;
