import React, { Component } from "react";
import userIcon from "../../img/user.png";
import seedIcon from "../../img/seedIcon.svg";
import IdeaListImage from "../../img/ideaPlaceholder.svg";
import arrowRight from "../../img/arrow-blue-right.svg";
import { checkForStatusColor } from "../../helpers/utils";
import { getNameInitials } from "../../helpers/utils";
import ChallengeIcon from "./challengeIcon";

class IdeaItem extends Component {
  navigateToIdea = (id) => {
    window.location.href = "/idea/" + parseInt(id);
  };

  contextHandler = () => {
    this.props.setContextIdeaId(this.props.idea.ideaID)
  }

  render() {
    const { idea, userData, teamName, userName = '' } = this.props;
    const byValue = (idea.teamName !== '' && idea.teamName !== null) ? idea.teamName : idea?.createdBy?.name ? idea?.createdBy?.name : 'SEED user'
    return (
      <div className="idea-cont" onClick={() => {
        this.navigateToIdea(idea.ideaID);
      }} onMouseOver={(e) => {
        this.contextHandler(idea.ideaID);
      }}>
        {idea.challengeID && <ChallengeIcon />}
        <div className="idea-cont_image">
          <img
            src={IdeaListImage}
            alt="idea"
          />
        </div>
        <div className="idea-cont_detail">
          <div className="ideaHeadCont">
            <div className="ideaHeading ideaHeadAlign">
              {idea.ideaName && idea.ideaName.length > 45
                ? `${idea.ideaName.substring(0, 40)} ...`
                : idea.ideaName}
            </div>
          </div>
          <div className={checkForStatusColor(idea.ideaStatus, "ideaStatus")}>
            {idea.ideaStatus}
          </div>
          <div className="ideaDescription">
            <div
              className="ideaDescription_description"
              dangerouslySetInnerHTML={{ __html: idea.ideaDescription.length > 55
                ? `${idea.ideaDescription.substring(0, 55)} ...`
                : idea.ideaDescription }}
            >
            </div>
            <div className="ideaDescription_creator">
            {(byValue) && <div className="ideaDescription_creator-image">
                <p >By: {byValue}</p>
              </div>}
              {idea.teamName === null && idea.userName && <div className="ideaDescription_creator-image">
                {<div className="ideaDescription_creator-image-cont">
                  {/* {idea.collaborator.length > 0 && idea.collaborator.map((user, index) => {
                    return (
                      <img
                        key={user.name + index}
                        src={user.imageUrl ? user.imageUrl : userIcon}
                        alt={user.name}
                      />
                    );
                  })} */}
                  {/* {idea.collaborator.length === 0 && ( */}
                  {/* <img
                    src={idea.createdBy.imageUrl}
                    alt={idea.createdBy.name}
                  /> */}
                  {idea.createdBy && (
                    <div className="userCreds">
                      {getNameInitials(idea.createdBy && idea.createdBy.name ? idea.createdBy.name : '')}
                    </div>
                  )}
                  {/* )} */}
                </div>}
                {/* {idea.collaborator.length > 0 && (
                  <p>
                    {idea.collaborator.length > 0 ? idea.collaborator[0].name : ''}
                    {idea.collaborator.length > 1 && (
                      <span>+{idea.collaborator.length - 1} more</span>
                    )}
                  </p>
                )} */}
                {/* {idea.collaborator.length === 0 && ( */}
                <p>{idea.createdBy ? idea.createdBy.name : ''}</p>
                {/* )} */}
              </div>}
              {/* {idea.currencyEarned && (
                <div className="ideaDescription_creator-invested">
                  <img src={seedIcon} alt="seed" />
                  <span>{idea.currencyEarned}</span>
                </div>
              )} */}
            </div>
          </div>
          <div
            className="idealink"
            onClick={() => {
              this.navigateToIdea(idea.ideaID);
            }}
          >
            <img src={arrowRight} alt="arrow-right" />
          </div>
        </div>
      </div>
    );
  }
}
export default IdeaItem;
