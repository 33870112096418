import React, { Component } from "react";

class ideaSteps extends Component {
  render() {
    const ideaStepsJSON = this.props.ideaSteps || [];
    const currentView = this.props.currentView;
    // let currentViewIndex = ideaStepsJSON[0].view === currentView ? 0 : -1;
    let currentViewIndex = 10;

    if (ideaStepsJSON.length > 0) {
      for (let i = 0; i < ideaStepsJSON.length; i++) {
        ideaStepsJSON[i].class = "ideaStep col";
        if (ideaStepsJSON[i].breadcrumbID === currentView) {
          currentViewIndex = i;
          ideaStepsJSON[i].class += " current";
        } else if (i <= currentViewIndex) {
          ideaStepsJSON[i].class += " complete";
        }
      }
    }
    const ideaStepsList = ideaStepsJSON.map((ideaStep, index) => (
      <div className={ideaStep.class} key={ideaStep.text + index}>
        {/* {ideaStep.breadcrumbID === currentView ? ideaStep.text : ""} */}
        {ideaStep.text}
      </div>
    ));
    const ideaStepsListMobile = ideaStepsJSON.map((ideaStep, index) => (
      <>
        {ideaStep.breadcrumbID === currentView ? (<div className="ideaStepsMain-mobile_left" key={ideaStep.text + index}>{ideaStep.text}</div>) : ''}
      </>
    ));
    return (
      <>
        <div className="ideaStepsMain">{ideaStepsList}</div>
        <div className="ideaStepsMain-mobile">{ideaStepsListMobile}<div className="ideaStepsMain-mobile_right"><span>{currentView}</span>/{ideaStepsJSON.length}</div></div>
      </>
    );
  }
}

export default ideaSteps;
