import axios from "axios";
import config from "../../helpers/api-urls";

const fileUploadUrl =
  "http://internal-Seed-Prod-LB-163434845.us-east-1.elb.amazonaws.com/api/v1/utils/file";

const FILE_UPLOAD_API_URL = (requestId, requestType, actionType, fileType) =>
  `${fileUploadUrl}/upload?requestId=${requestId}&requestType=${requestType}&actionType=${actionType}&fileType=${
    fileType ? fileType : ""
  }`;
const FILE_DOWNLOAD_API_URL = (requestId, fileName) =>
  `${config.utilConfig.requestPayload.url}/download?requestId=${requestId}&fileName=${fileName}`;
const FILE_DELETE_API_URL = (requestId, fileName) =>
  `${config.utilConfig.requestPayload.url}/delete?requestId=${requestId}&fileName=${fileName}`;
const FILE_LIST_API_URL = (requestId, feature) =>
  `${config.utilConfig.requestPayload.url}/list?requestId=${requestId}&featureType=${feature}`;

const downloadFile = (requestId, fileName) => {
  return axios.get(FILE_DOWNLOAD_API_URL(requestId, fileName), {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      idToken: `${localStorage.getItem("id_token")}`,
    },
  });
};

export const doFileDownload = (requestId, fileName) => {
  return (dispatch) =>
    dispatch({
      type: "FILE_DOWNLOAD",
      payload: downloadFile(requestId, fileName),
    });
};

const _ideaFileUpload = (requestId, requestType, formdata, actionType) => {
  return axios.post(
    `${fileUploadUrl}/upload?requestId=${requestId}&requestType=${requestType}&actionType=${actionType}`,
    formdata,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
    }
  );
};

export const ideaFileUpload = (
  requestId,
  requestType,
  formdata,
  actionType
) => {
  // console.log(formdata);
  return (dispatch) =>
    dispatch({
      type: "FILE_UPLOAD",
      payload: _ideaFileUpload(requestId, requestType, formdata, actionType),
    });
};

const fileUpload = (requestId, requestType, formdata, actionType, fileType) => {
  return axios.post(
    FILE_UPLOAD_API_URL(requestId, requestType, actionType, fileType),
    formdata,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
    }
  );
};

export const doFileUpload = (
  requestId,
  requestType,
  formdata,
  actionType,
  fileType
) => {
  // console.log(formdata);
  return (dispatch) =>
    dispatch({
      type: "FILE_UPLOAD",
      payload: fileUpload(
        requestId,
        requestType,
        formdata,
        actionType,
        fileType
      ),
    });
};

const deleteFile = (requestId, fileName) => {
  return axios.delete(FILE_DELETE_API_URL(requestId, fileName), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      idToken: `${localStorage.getItem("id_token")}`,
    },
  });
};

export const doFileDelete = (requestId, fileName) => {
  return (dispatch) =>
    dispatch({
      type: "FILE_DELETE",
      payload: deleteFile(requestId, fileName),
    });
};

const fileList = (requestId, feature) => {
  return axios.get(FILE_LIST_API_URL(requestId, feature), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      idToken: `${localStorage.getItem("id_token")}`,
    },
  });
};

export const fetchFileList = (requestId, feature) => {
  return (dispatch) =>
    dispatch({
      type: "FILE_LIST",
      payload: fileList(requestId, feature),
    });
};
export const fetchFileList1 = (requestId, feature) => {
  return (dispatch) =>
    dispatch({
      type: "FILE_LIST1",
      payload: fileList(requestId, feature),
    });
};
export const fetchFileList2 = (requestId, feature) => {
  return (dispatch) =>
    dispatch({
      type: "FILE_LIST2",
      payload: fileList(requestId, feature),
    });
};

const attachmentList = (id, feature) => {
  return axios.get(FILE_LIST_API_URL(id, feature), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      idToken: `${localStorage.getItem("id_token")}`,
    },
  });
};

export const fetchIdeaAttachmentsList = (id, feature) => {
  return (dispatch) =>
    dispatch({
      type: "ATTACHMENT_LIST",
      payload: attachmentList(id, feature),
    });
};

const _getRegisterTeamForChallenge = (challengeId, payload) => {
  return axios.post(
    config.registerTeamForChallenge.requestPayload.url.replace(
      "{challengeId}",
      challengeId
    ),
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
    }
  );
};

export const getRegisterTeamForChallenge = (challengeId, payload) => {
  return {
    type: "FETCH_REGISTER_TEAM",
    payload: _getRegisterTeamForChallenge(challengeId, payload),
  };
};

const _getTeamNameForChallenge = (challengeId, email) => {
  return axios.get(
    config.getTeamNameForChallenge.requestPayload.url
      .replace("{challengeId}", challengeId)
      .replace("{email}", email),
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
    }
  );
};

export const getTeamNameForChallenge = (challengeId, email) => {
  return {
    type: "FETCH_REGISTER_TEAM",
    payload: _getTeamNameForChallenge(challengeId, email),
  };
};

const _getEventList = () => {
  return axios.get(
    config.eventList.requestPayload.url,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
    }
  );
};

export const getEventList = () => {
  return {
    type: "FETCH_EVENT_LIST",
    payload: _getEventList(),
  };
};

const _getEventDetails = (eventId) => {
  return axios.get(
    config.eventDetails.requestPayload.url.replace("{eventId}", eventId),
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
    }
  );
};

export const getEventDetails = (eventId) => {
  return {
    type: "FETCH_EVENT_DETAILS",
    payload: _getEventDetails(eventId),
  };
};

const _getEventDetailsLeaderboard = (eventId) => {
  return axios.get(
    config.eventDetailsLeaderboard.requestPayload.url.replace("{eventId}", eventId),
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
    }
  );
};

export const getEventDetailsLeaderboard = (eventId) => {
  return {
    type: "FETCH_EVENT_DETAILS",
    payload: _getEventDetailsLeaderboard(eventId),
  };
};

const _registerEvent = (payload) => {
  return axios.post(
    config.registerEvent.requestPayload.url,
    payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`
        }
    })
};

export const registerEvent = (payload) => {
  return {
    type: "POST_EVENT",
    payload: _registerEvent(payload),
  };
};

const _updateEvent = ({eventId, eventStatus}) => {
  return axios.post(
    config.updateEvent.requestPayload.url.replace("{eventId}", eventId),
    {eventId, eventStatus},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`
        }
    })
};

export const updateEvent = ({eventId, eventStatus}) => {
  return {
    type: "POST_EVENT",
    payload: _updateEvent({eventId, eventStatus}),
  };
};

const _addParticipantsToEvent = (payload) => {
  return axios.post(
    config.addParticipantsToEvent.requestPayload.url,
    payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`
        }
    })
};

export const addParticipantsToEvent = (payload) => {
  return {
    type: "ADD_PARTICIPANTS",
    payload: _addParticipantsToEvent(payload),
  };
};

const _deleteParticipantsFromEvent = (payload) => {
  return axios.post(
    config.deleteParticipantsToEvent.requestPayload.url,
    payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`
        }
    })
};

export const deleteParticipantsFromEvent = (payload) => {
  return {
    type: "DELETE_PARTICIPANTS",
    payload: _deleteParticipantsFromEvent(payload),
  };
};

