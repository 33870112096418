import React, { Component } from "react";
import arrowRight from "../../img/arrow-blue-right.svg";

class ProfileInfoBanner extends Component {
  render() {
    const data = this.props.data;
    const bannerImg = this.props.bannerImg;
    const cls = `${data.customClass} profile-banner`;
    return (
      <div className={cls}>
        <img src={bannerImg} alt="banner-img" />
        <div className="profile-banner_intro">
          <h3>{data.title}</h3>
          <p>{data.description}</p>
        </div>
        <a className="profile-banner_cta" href={data.ctaHref}>
          {data.ctaIcon && <img src={arrowRight} alt="arrow-right" />}
          {!data.ctaIcon && data.ctaText !== "" && data.ctaText}
        </a>
      </div>
    );
  }
}
export default ProfileInfoBanner;
