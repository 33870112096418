import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Header from "../components/global/header";
import '../css/pages/draftSubmitted.scss';
import Breadcrumbs from "../components/global/breadcrumbs";
// import { fetchChallengesIdeas } from "../redux/actions/challenges";
import config from "../helpers/api-urls";
import axios from "axios";
import moment from "moment";
import { checkForStatusColor, showLoader, hideLoading } from "../helpers/utils";
import ChallengeIcon from "../components/global/challengeIcon";
import ContextMenuWithHook from "../components/global/contextMenu";

class ChallengeIdeaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            challengeIdeas: (this.props.challengeIdeas && this.props.challengeIdeas.ideasFromChallengePojo) || [],
            loading: false,
            perPageRows: 8,
            totalPages: (this.props.challengeIdeas && this.props.challengeIdeas.totalSize) || 0,
            pageIndex: 0,
            userData: this.props.userData,
            challengeId: this.props.challengeIdeas?.challengeId ?? '',
            challengeName: this.props.challengeIdeas?.challengeName ??  '',
            contextLink: ''
        };
        this.setContextLink = this.setContextLink.bind(this);
    }

    componentDidMount() {
        showLoader();
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("id")) {
            this.setState({ challengeId: urlParams.get("id") });
            axios.get(config.challengeIdeasConfig.requestPayload.url + "/" + urlParams.get("id") + "?pageNum=" + this.state.pageIndex + "&pageSize=" + this.state.perPageRows, {
                headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
              })
              .then((response) => {
                this.setState({ challengeIdeas: response.data.ideasFromChallengePojo, totalPages: response.data.totalSize, challengeName: response.data.challengeName });
                hideLoading();
              }).catch((error) => {
                console.log(error);
                hideLoading();
              });
            // this.props.fetchChallengesIdeas(this.state.pageIndex, this.state.perPageRows, urlParams.get("id"));
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.challengeIdeas !== this.props.challengeIdeas) {
    //         this.setState({ challengeIdeas: this.props.challengeIdeas.ideasFromChallengePojo, totalPages: this.props.challengeIdeas.totalSize, challengeName: this.props.challengeIdeas.challengeName });
    //     }
    //     setTimeout(() => {
    //         hideLoading();
    //     }, 3000);
    // }

    loadMoreItems = (pageIndex) => {
        showLoader();
        this.setState({
            loading: true,
            pageIndex: this.state.pageIndex + 1,
        });
        const payloadUrl = config.challengeIdeasConfig.requestPayload.url + "/" + this.state.challengeId + "?pageNum=" + (pageIndex + 1) + "&pageSize=" + this.state.perPageRows;
        axios
            .get(payloadUrl, {
                headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
            })
            .then((response) => {
                this.setState({
                    challengeIdeas: [...this.state.challengeIdeas, ...response.data.ideasFromChallengePojo],
                    loading: false,
                });
                hideLoading();
            })
            .catch((error) => { console.log(error); hideLoading(); });
    };

    navigateToIdea = (id) => {
        window.location.href = '/idea/' + id;
    }

    setContextLink(ideaId) {
        const link = `/idea/${ideaId}`;
        this.setState({ contextLink: link });
    }

    render() {
        const breadcrumbJSON = [
            {
                url: "/dashboard",
                text: "Home",
            },
            {
                url: `/challenge?id=${this.state.challengeId}`,
                text: "Challenge",
            },
            {
                url: "",
                text: "Submitted Ideas",
            }
        ];
        let ideaList = [];
        const userJson = this.props.userData;
        // let self = this;
        if (this.state.challengeIdeas) {
            ideaList = this.state.challengeIdeas.map((idea, index) =>
                <div key={idea.ideaName + index}>
                    <div className="ideaBubble challengeIdeas"
                        id={idea.ideaId}
                        onClick={() => this.navigateToIdea(idea.ideaId)}
                        onMouseOver={() => { this.setContextLink(idea.ideaId) }}
                    >
                        {/* <div className="ideaName">
                            {idea.ideaName}
                        </div> */}
                        <ChallengeIcon />
                        <div className="ideaName" title={idea.ideaName ? idea.ideaName : ''}>{idea.ideaName && idea.ideaName.length > 100 ? `${idea.ideaName.substring(0, 99)} ...` : idea.ideaName}</div>
                        <div className="ideaDetails">
                            <div className="ideaSolution" dangerouslySetInnerHTML={{ __html: idea.solution }}>
                            </div>
                            <div className="ideaDelete">
                                <label className={checkForStatusColor(idea.ideaStatus, "review")}>{idea.ideaStatus ? idea.ideaStatus : "-"}</label>
                            </div>
                            <div className="ideaDate">
                                {idea.createdDate ? moment(idea.createdDate).format("DD MMM YY") : '-'}
                            </div>
                        </div>
                    </div>
                    <div className="ideaBubble-mobile challengeIdeas" id={idea.ideaId} onClick={() => this.navigateToIdea(idea.ideaId)}>
                        <div className="name-container">
                            <div className="ideaName">
                                {idea.ideaName}
                            </div>
                            <div className="ideaDate">
                                {idea.createdDate ? moment(idea.createdDate).format("DD MMM YY") : '-'}
                            </div>
                        </div>
                        <div className="ideaDetails">
                            <div className="ideaDelete">
                                <label className={checkForStatusColor(idea.ideaStatus, "review")}>{idea.ideaStatus ? idea.ideaStatus : "-"}</label>
                            </div>
                            <div className="ideaSolution">
                                {idea.solution}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <>
            <Header
                values={userJson}
                showSearch={true}
                showUser={true}
                pageName={"challengeIdeaList"}
            />
            <section className="homeMain">
                <Breadcrumbs breadcrumbs={breadcrumbJSON} />
                <article className="draftHeader">
                    <div className="heading">
                        {this.state.challengeName}
                        <p className="subtitle_heading">Submitted ideas</p>
                    </div>
                    <div className="ideaCount">
                        {(this.state.challengeIdeas !== undefined && this.state.challengeIdeas !== null) && this.state.challengeIdeas.length > 0 && (
                            <>
                                Showing {this.state.challengeIdeas ? this.state.challengeIdeas.length : 0} of {this.state.totalPages || 0}
                            </>
                        )}
                    </div>
                </article>
                <article className="ideaBubbels">
                <ContextMenuWithHook className="ideaBubbels" contextLink={this.state.contextLink}>
                    {ideaList}
                </ContextMenuWithHook>
                </article>
                {this.state.challengeIdeas.length > 0 && (
                    <div className="load-more-btn-cont">
                        <div className={(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) ? 'load-more-cta-count-data load-more-hidden' : 'load-more-cta-count-data'}>Showing 1 - {Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows)) > this.state.totalPages ? this.state.totalPages : Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows))}/{this.state.totalPages}</div>
                        {!(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) && (
                            <button
                                type="button"
                                disabled={
                                    this.state.totalPages <=
                                    (this.state.pageIndex + 1) * this.state.perPageRows
                                }
                                className={classNames("btn btnText load-more-cta", {
                                    disabled:
                                        this.state.totalPages <=
                                        (this.state.pageIndex + 1) * this.state.perPageRows,
                                })}
                                onClick={() => this.loadMoreItems(this.state.pageIndex)}
                            >
                                {this.state.loading ? "Loading..." : "Load More"}
                            </button>
                        )}
                    </div>
                )}
                {(this.state.challengeIdeas !== undefined && this.state.challengeIdeas !== null) && this.state.challengeIdeas.length === 0 && (
                    <article className="ideaBubbels">
                        No Submitted Ideas!
                    </article>
                )}
            </section>
        </>

    }
}

// const mapStateToProps = (state) => {
//     return {
//         FETCH_CHALLENGE_IDEAS_STATUS: state.ideas.meta.FETCH_CHALLENGE_IDEAS_STATUS,
//         challengeIdeas: state.ideas.data.challengeIdeas,
//     };
// };

// const mapDispatchToProps = {
//     fetchChallengesIdeas
// };

export default ChallengeIdeaList;