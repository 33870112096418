import React, { Component } from "react";
import CircularProgressBar from "./circularProgressBar";
import MediaQuery from "react-responsive";
import QuestionLayer from "./questionLayer";
import { generateRandomKey } from "../../helpers/utils";
import axios from "axios";
import config from "../../helpers/api-urls";

class OverallSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || {},
      overAllScore: this.props.overAllScore || 20,
      score: this.props.score || 0,
      firstReviewer: this.props.firstReviewer,
      isSubmitEnabled: this.props.isSubmitEnabled || true,
      isEmailValid: true,
      isEmailRequired: false,
      userData: this.props.userData
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.score !== this.props.score) {
      this.setState({ score: this.props.score });
    }
    if (prevProps.isSubmitEnabled !== this.props.isSubmitEnabled) {
    this.setState({ isSubmitEnabled: this.props.isSubmitEnabled });
    }
  }

  handleOtherArea = (flag) => {
    this.props.onHandleOtherArea(flag);
  }

  handleScoreReset = () => {
    this.props.onScoreReset();
  };

  updateQuestionArr = (arr) => {
    this.props.updateQuestionArr(arr);
  };

  overallSummaryDetails = (questionId, selectedId, stepId, subCustomText) => {
    this.props.overallSummaryDetails(questionId, selectedId, stepId, subCustomText);
  }

  handlePanelEmailUpdate = (questionId, selectedId, customText, stepId, isValid) => {
    this.setState({ isEmailValid: isValid });
    if (isValid) {
      this.props.handlePanelEmailUpdate(questionId, selectedId, customText, stepId);
    }
  };

  isEmailRequired = (isRequired) => {
    this.setState({ isEmailRequired: isRequired, isEmailValid: !isRequired });
  };

  enableSignInBtn = (value) => {
    if (value === '' || value === this.state.userData.email) {
      this.setState({ isEmailValid: this.state.isEmailRequired ? false : true });
    } else {
      const payload = {
        "memberName": "",
        "memberRole": "",
        "panelist": true,
        "userEmail": value
      };
      const url = config.validateEmailConfig.requestPayload.url;
      axios.post(url, payload, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      })
        .then((response) => {
          this.setState({ isEmailValid: response.data });
        }).catch((error) => {
          console.log(error);
          this.setState({ isEmailValid: false });
        });
    }
  };

  handleSubmitEvaluation = (e) => {
    e && e.stopPropagation();
    this.props.submitEvaluation(this.state.data.stepID);
  };

  render() {
    const score = this.state.score,
      max_score = 20,
      scorePercent = Math.round((score / max_score) * 100);
    let color = '';
    if (score > 30) {
      color = '#077F5A';
    } else if (score <= 30 && score >= 15) {
      color = "#f7bf46";
    } else if (score < 15) {
      color = '#A5143A';
    }

    return (
      <>
        <div className="IdeaApprovalFlow">
          <div className="Idealeft">
            <div className="OverallScore">Overall Score</div>
            <div className="score">
              <span style={{ "color": color }}>{this.state.score}</span>
              <span className="totalScore">
                /{this.state.overAllScore}
              </span>
            </div>
            <div className="evaluator-chart">
              Overall Confidence of Evaluator
            </div>
            <div className="evaluator-chart-circle">
              <MediaQuery maxWidth={767}>
                <CircularProgressBar
                  selectedValue={score}
                  maxValue={20}
                  textColor={color}
                  activeStrokeColor={color}
                  strokeWidth={40}
                  radius={80}
                  valueFontSize={24}
                  inactiveStrokeColor="#e6e6e6"
                  initialAngularDisplacement={0}
                  label={scorePercent + "%"}
                />
              </MediaQuery>
              <MediaQuery minWidth={768}>
                <CircularProgressBar
                  selectedValue={score}
                  maxValue={20}
                  textColor={color}
                  activeStrokeColor={color}
                  strokeWidth={60}
                  radius={120}
                  valueFontSize={40}
                  inactiveStrokeColor="#e6e6e6"
                  initialAngularDisplacement={0}
                  label={scorePercent + "%"}
                />
              </MediaQuery>
            </div>
          </div>
          <div className="Idearight">
            <div className="question-type">{this.state.data.stepName}</div>
            <div className="question-cont">
              {this.state.data.stepHeader !== '' && (
                <div className="question-title">{this.state.data.stepHeader}</div>
              )}
              {this.state.data.stepSubHeader !== '' && (
                <div className="question-subtitle">{this.state.data.stepSubHeader}</div>
              )}
              {Object.keys(this.state.data).length > 0 && this.state.data.questions.length > 0 && this.state.data.questions.map((question) => {
                return (
                  <QuestionLayer
                    stepID={this.state.data.stepID}
                    updateQuestionArr={this.updateQuestionArr.bind(this)}
                    firstReviewer={this.state.firstReviewer}
                    handleScoreReset={this.handleScoreReset.bind(this)}
                    handleOtherAreaApproval={this.handleOtherArea.bind(this)}
                    questionDetail={question} key={question.questionId}
                    overallSummaryDetails={this.overallSummaryDetails.bind(this)}
                    handlePanelEmailUpdate={this.handlePanelEmailUpdate.bind(this)}
                    updateOtherComment={this.overallSummaryDetails.bind(this)}
                    isEmailRequired={this.isEmailRequired}
                    userData={this.state.userData}
                    showError={!this.state.isEmailValid}
                  />
                );
              })}
            </div>
            <div className="SubmitButton">
              <button className="btn secondary saveForLater" onClick={() => this.props.saveForLaterAction(this.state.data.stepID)}>Save for later</button>
              <button className="btn primary" disabled={this.state.isSubmitEnabled || !this.state.isEmailValid} onClick={(e) => this.handleSubmitEvaluation(e)}>Submit Evaluation</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OverallSummary;
