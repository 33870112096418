import React, { Component } from "react";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/partials/rich-text-editor/richTextEditor.scss';

class RichTextEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty()
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    componentDidMount() {
        const html = this.props.currentValue;
        if(html) {
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    editorState: EditorState.createWithContent(contentState)
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.currentValue && prevProps.currentValue !== this.props.currentValue) {
            const html = this.props.currentValue;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    editorState: EditorState.createWithContent(contentState)
                });
            }
        }
    }

    onEditorStateChange(editorState) {
        const currentContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({
            editorState
        });
        const currentTextCount = editorState?.getCurrentContent().getPlainText().length;
        this.props.onRichTextEditorChange(currentContent);
        this.props.hasOwnProperty('onCurrentTextCountChange') && this.props.onCurrentTextCountChange(currentTextCount);
    }

    /**
     * helper method to check and return `true` if passed max-length has been breached
     *
     * @param {*} newStringLength
     * @memberof RichTextEditor
     */
    isMaxTextLengthBreached = (newStringLength) => {
        // allow content text till `maxTextLength`, falsy `maxTextLength` prop would have no limit restriction.
        const existingTextLength = this.state.editorState.getCurrentContent().getPlainText().length;
        return this.props.maxTextLength && (existingTextLength + (newStringLength || 0)) >= this.props.maxTextLength;
    };

    /**
     * handler for package callback on before updating value in package
     *
     * @memberof RichTextEditor
     */
    handleBeforeInput = () => {
        if (this.isMaxTextLengthBreached()) {
            return 'handled';
        }
    };

    /**
     * handler for package callback on before enter key
     *
     * @memberof RichTextEditor
     */
    handleReturn = () => {
        if (this.isMaxTextLengthBreached()) {
            return 'handled';
        }
    };

    /**
     * handler for package callback on paste
     *
     * @param {*} pastedText
     * @memberof RichTextEditor
     */
    handlePastedText = (pastedText) => {
        return this.isMaxTextLengthBreached(pastedText?.length);
    };
   

    render() {
        const { editorState } = this.state;
        const toolbar = {
            options: ['inline', 'list'],
            inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough']
            },
            list: {
                options: ['unordered', 'ordered']
            }
        }
        return (
            <Editor
                toolbar={toolbar}
                placeholder={this.props.placeholder}
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange}
                handleBeforeInput={this.handleBeforeInput}
                handlePastedText={this.handlePastedText}
                handleReturn={this.handleReturn}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onBlur={this.props.onBlur || (() => {})}
            />
        );
    }
}

export default RichTextEditor;