import React, { Component } from "react";

class TextAreaOnly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textValue: this.props.inputVal,
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.inputVal !== this.props.inputVal) {
            this.setState({
                textValue: this.props.inputVal,
            });
        }
    }

    handleBlur(e) {
        this.props.handleBlur();
    }

    updateTextValue = (e) => {
        this.setState({ textValue: e.target.value });
        this.props.handleChange(e.target.value);
    };

    render() {
        const { placeHolder, id } = this.props;
        return (
            <textarea
                type="textarea"
                placeholder={placeHolder}
                onChange={(e) => this.updateTextValue(e)}
                id={id}
                value={this.state.textValue}
                autoComplete="off"
                onBlur={(e) => this.handleBlur(e)}
            ></textarea>
        );
    }
}
export default TextAreaOnly;