import React, { Component } from "react";
import FilterItem from "./filterItem";

class FilterAccordion extends Component {
  setSelectedFilter = (item, title, isRemoved) => {
    this.props.setSelectedFilter(item, title, isRemoved);
  };
  render() {
    const { item, onToggle, active, selectedTagsList, selectedCategoryList, selectedTalentGrpList } = this.props;
    const dataArray = [];

    for (const [id, data] of Object.entries(item.data)) {
      dataArray.push({ id: id, text: data });
    }

    return (
      <div className="filter__title">
        <button
          data-qa="filter__title-button"
          className={`filter__title-button ${active ? "is-active" : ""}`}
          onClick={onToggle}
        >
          {item.title}
          {item.title.toLowerCase().indexOf('categories') > -1 && selectedCategoryList.length > 0 && (
            <span>{selectedCategoryList.length}</span>
          )}
          {item.title.toLowerCase().indexOf('tags') > -1 && selectedTagsList.length > 0 && (
            <span>{selectedTagsList.length}</span>
          )}
          {item.title.toLowerCase().indexOf('capability') > -1 && selectedTalentGrpList.length > 0 && (
            <span>{selectedTalentGrpList.length}</span>
          )}
        </button>
        <div
          data-qa="filter__desc"
          className={`filter__desc ${active ? "open" : ""}`}
        >
          <ul>
            {dataArray.map((data, i) => {
              let selectedArr = [];
              let isSelected = false;
              if (item.title.toLowerCase().indexOf('categories') > -1) {
                selectedArr = selectedCategoryList;
              } else if (item.title.toLowerCase().indexOf('tags') > -1) {
                selectedArr = selectedTagsList;
              } else if (item.title.toLowerCase().indexOf('capability') > -1) {
                selectedArr = selectedTalentGrpList;
              }
              isSelected = selectedArr.length && selectedArr.find(id => id === parseInt(data.id)) ? true : false;
              return (
                <FilterItem isSelected={isSelected} title={item.title} data={data} key={data.text + i} setSelectedFilter={this.setSelectedFilter.bind(this)} />
              )
            })}
          </ul>
        </div>
      </div>
    );
  }

}
export default FilterAccordion;
