import useContextMenu from "./useContextMenu";

export const withHookHOC = (Component) => {

    return (props) => {
        const {
            clicked,
            setClicked,
            points,
            setPoints
        } = useContextMenu();

        return <Component
            clicked={clicked}
            setClicked={setClicked}
            points={points}
            setPoints={setPoints}
            {...props}
        />
    }
}
