import { Component } from "react";
import EventCard from "./eventCard";

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventList: this.props.eventList,
      tableTennisList: [],
      chessList: [],
      otherEventList: []
    };
  }

  setEventData = (eventList) => {
    const tableTennis = eventList.filter(el => el.eventName.includes('TT'))
    const chess = eventList.filter(el => el.eventName.includes('Chess -'))
    const others = eventList.filter(el => !(el.eventName.includes('TT -') || el.eventName.includes('Chess -')))
    this.setState({tableTennisList: tableTennis, chessList: chess, otherEventList: others})
  }

  componentDidMount() {
    const coll = document.getElementsByClassName('collapsible');
    let i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight){
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        } 
      });
    }
    this.setEventData(this.props.eventList)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.eventList !== this.props.eventList) {
      this.setEventData(this.props.eventList)
    }
  }

  render() {
    const {tableTennisList, chessList, otherEventList} = this.state
    return (
      <div>
        <button className="collapsible">Table Tennis</button>
        <div className="content">
          <EventCard dataList= {tableTennisList}/>
        </div>
        <button className="collapsible">Chess</button>
        <div className="content">
          <EventCard dataList= {chessList}/>
        </div>
        <EventCard dataList= {otherEventList}/>
      </div>
    );
  }
}

export default EventList;
