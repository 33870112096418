import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfileTransaction } from "../../redux/actions/user-profile";
import seedIcon from "../../img/seedIcon.svg";
import { checkForEventString, generateRandomKey } from "../../helpers/utils";

class ProfileTransactionHistory extends Component {
  componentDidMount() {
    this.props.fetchProfileTransaction(this.props.userInfo.id);
  }

  render() {
    const profileTransaction = this.props.profileTransaction.data || {};
    return (
      <>
        {profileTransaction.length > 0 && (
          <div className="profile-transaction-container">
            <p>Recent Transactions</p>
            <ul>
              {profileTransaction.map((transaction, index) => {
                if (index < 10) {
                  return (
                    <li key={generateRandomKey()}>
                      <span title={transaction.transactionComments!==null?transaction.transactionComments:''}>
                        {transaction.transactionComments!==null && transaction.transactionComments.length < 45 && (
                          <>
                            {checkForEventString(transaction.eventType) + ": " + transaction.transactionComments}
                          </>
                        )}
                        {transaction.transactionComments!==null && transaction.transactionComments.length > 45 && (
                          <>
                            {checkForEventString(transaction.eventType) + ": " + transaction.transactionComments.substring(0, 40)}...
                          </>
                        )}
                        {(transaction.transactionComments==null || transaction.transactionComments=='') && (
                          <>
                            {checkForEventString(transaction.eventType)}
                          </>
                        )}
                        {/* {transaction.transactionComments!==null && transaction.transactionComments.length > 45 ? `${checkForEventString(transaction.eventType) + ": " + transaction.transactionComments.substring(0, 40)} ...`: checkForEventString(transaction.eventType) + ": " + transaction.transactionComments} */}
                      </span>
                      <span className="profile-transaction-itemAmount">
                        {transaction.transactionType === "DEBIT" ? "-" : "+"}
                        <img
                          src={seedIcon}
                          alt="seed"
                        />
                        {transaction.transactionAmount}
                      </span>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    FETCH_PROFILE_TRANSACTION_STATUS:
      state.userProfile.meta.FETCH_PROFILE_TRANSACTION_STATUS,
    profileTransaction: state.userProfile.data.profileTransaction,
  };
};

const mapDispatchToProps = {
  fetchProfileTransaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileTransactionHistory);
