import DOMPurify from "dompurify";
import React, { Component } from "react";

class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textValue: this.props.inputVal,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.inputVal !== this.props.inputVal) {
      this.setState({
        textValue: this.props.inputVal,
      });
    }
  }
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.props.handleSubmit(e);
    }
  }
  handleBlur(e) {
    this.props.handleBlur();
  }
  updateTextValue = (e) => {
    const val = DOMPurify.sanitize(e.target.value)
    this.setState({ textValue: val });
    this.props.handleChange(val);
  };
  render() {
    const { type, placeHolder, id } = this.props;
    return (
      <>
        <input
          type={type}
          placeholder={placeHolder}
          onChange={(e) => this.updateTextValue(e)}
          data-testid="textbox"
          className="search-input"
          id={id}
          value={this.state.textValue}
          autoComplete="off"
          onKeyPress={(e) => this.handleKeypress(e)}
          onBlur={(e) => this.handleBlur(e)}
        />
      </>
    );
  }
}
export default InputField;
