import { Component } from "react";
import Header from '../components/global/header';
import "../css/pages/bannerDetails.scss"

class BannerDetails extends Component {

    render() {
        const {userData} = this.props;
        return <>
            <Header
                values={userData}
                showSearch={true}
                showUser={true}
                pageName={"bannerDetails"}
            />
            <section className="banner-details">
                <article>
                    <img src={""} alt="banner image" />
                </article>
                <article>
                    <header>
                        <h1></h1>
                        <button></button>
                    </header>
                    <p></p>
                    <p></p>
                </article>
                <article>
                </article>
            </section>
        </>
    }
}

export default BannerDetails;