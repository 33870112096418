import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../global/header";
import Breadcrumbs from "../global/breadcrumbs";
import GlobalMessagePopup from "../global/globalMessagePopup";
import "../../css/pages/challengeDetail.scss";
import challenge from "../../img/ideation/challenge2.jpg";
import challengeTopic from "../../img/challengeTopic.svg";
import dateIcon from "../../img/date.svg";
import closeIcon from "../../img/close.svg";
import challengeDeadline from "../../img/challengeDeadline.svg";
import challengeDeadlineGreen from "../../img/challengeDeadline-green.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import moment from "moment";
import {
  fetchFileList,
  doFileDownload,
  getRegisterTeamForChallenge,
  getTeamNameForChallenge,
} from "../../redux/actions/utilities";
import { fetchSavedChallenge } from "../../redux/actions/challenges";
import { _CONSTANTS } from "../../helpers/constants";
import {
  generateRandomKey,
  getNameInitials,
  hideLoading,
  showLoader,
  getBaseUrl,
} from "../../helpers/utils";
import config from "../../helpers/api-urls";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { hasIdeaAccess } from "../../helpers/ideaAuth";
import DOMPurify from "dompurify";

const baseUrl = getBaseUrl();
class ChallengeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessagePopup: false,
      messageData: this.props.messageData,
      challengeId: "",
      userId: this.props.userData && this.props.userData.id,
      accepted:
        this.props.challengeDetail.challengeAcceptedByCurrentUser || false,
      challengeDetail: this.props.challengeDetail,
      challengeFileUrl: this.props.existingFileList,
      showChallengeAcceptConfirmation: false,
      showRedirectModal: false,
      userRole: this.props.challengeDetail.userType,
      downloadedFileName: "",
      downloadedFile: {
        ref: React.createRef(),
        url: null,
        fileName: "",
      },
      attachFileUrl: this.props.existingFileList
        ? this.props.existingFileList.attachments
        : [],
      attachedFiles: [],
      showRegistraionModal: false,
      userEmail: this.props.userData.email,
      teamName: "",
      teamMembersIds: [],
      isRegisterBtnDisabled: false,
      isteamMembersIdsErr: false,
      isAlreadyRegistered: false,
    };
    this.onClickAcceptChallenge = this.onClickAcceptChallenge.bind(this);
    this.hideAcceptModal = this.hideAcceptModal.bind(this);
    this.getUploadedS3FileUrl = this.getUploadedS3FileUrl.bind(this);
  }

  componentDidMount() {
    showLoader();
    if (
      document.referrer.indexOf("challengecreationflow") > -1 &&
      window.performance &&
      performance.navigation.type == 0
    ) {
      this.setState({ showMessagePopup: true });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        this.setState({
          showMessagePopup: false,
        });
      }, 10000);
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("id")) {
      this.setState({ challengeId: urlParams.get("id") });
      this.props
        .getTeamNameForChallenge(urlParams.get("id"), this.props.userData.email)
        .then((res) => {
          this.setState({ isAlreadyRegistered: !!res?.value?.data?.length });
        })
        .catch((err) => {
          console.log("[Err]: ", err);
        });
    }
    if (this.state.userId) {
      this.props
        .fetchSavedChallenge(parseInt(urlParams.get("id")), this.state.userId)
        .catch((err) => {
          alert("Challenge Details not found");
          window.location.href = "/dashboard";
        });
    }
    this.props.fetchFileList(parseInt(urlParams.get("id")), "CHALLENGE");
    window.addEventListener("pageshow", function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        window.location.reload();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messageData !== this.props.messageData) {
      this.setState({ messageData: this.props.messageData });
    }
    if (prevProps.challengeDetail !== this.props.challengeDetail) {
      this.setState({
        challengeDetail: this.props.challengeDetail,
        accepted: this.props.challengeDetail.challengeAcceptedByCurrentUser,
        userRole: this.props.challengeDetail.userType,
      });
      if (this.props.challengeDetail.fileNames?.length > 0) {
        this.getUploadedS3FileUrl(this.props.challengeDetail.fileNames);
      }
    }
    if (prevProps.existingFileList !== this.props.existingFileList) {
      this.setState({
        challengeFileUrl: this.props.existingFileList,
        attachFileUrl: this.props.existingFileList.attachments,
      });
    }
    if (
      prevProps.FILE_DOWNLOAD_STATUS !== "SUCCESS" &&
      this.props.FILE_DOWNLOAD_STATUS === "SUCCESS"
    ) {
      const url = window.URL.createObjectURL(new Blob([this.props.file]));
      this.setState(
        {
          downloadedFile: {
            ...this.state.downloadedFile,
            url: url,
            fileName: this.state.downloadedFileName,
          },
        },
        () => this.state.downloadedFile.ref.current.click()
      );
    }

    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  /**
   * user click on download file
   * @param {object} file file object to be downloaded
   */
  downloadFile(file) {
    this.props.doFileDownload(file.requestId, file.fileName);
    this.setState({ downloadedFileName: file.fileName });
  }

  onClickAcceptChallenge(showAcceptChallengeBanner) {
    showLoader();
    axios
      .post(
        baseUrl.ipAddress +
          "api/v1/challenges/" +
          this.state.challengeId +
          "/accept/" +
          this.state.userId,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((data) => {
        this.setState({
          accepted: data.data,
          showChallengeAcceptConfirmation: showAcceptChallengeBanner,
        });
        setTimeout(() => {
          this.setState({
            showChallengeAcceptConfirmation: false,
          });
        }, 5000);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  }
  displayTagsModal = () => {
    this.setState({ showTagsModal: true });
  };
  HideTagsModal = (id) => {
    this.setState({ showTagsModal: false });
  };
  hideAcceptModal = () => {
    this.setState({ showChallengeAcceptConfirmation: false });
  };
  openChallengeEdit = () => {
    this.setState({ showTagsModal: false });
    window.location.href = "/challengeEdit?id=" + this.state.challengeId;
  };
  showAcceptModal = () => {
    this.setState({ showAcceptModal: true });
  };

  handleRedirectModal = () => {
    this.setState({ showRedirectModal: true });
  };

  hideRedirectModal = () => {
    this.setState({ showRedirectModal: false });
  };

  handleRedirectionToIdea = (id) => {
    this.setState({ showRedirectModal: false });
    window.location.href = "/createidea?challenge=" + id;
  };

  stopChallenge = (id) => {
    showLoader();
    axios
      .post(
        config.stopChallengeConfig.url + "?challengeId=" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((data) => {
        setTimeout(function () {
          window.location.reload(true);
        }, 1000);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
    this.HideTagsModal();
  };

  getUploadedS3FileUrl(fileNames) {
    showLoader();
    fileNames?.forEach((fileName) => {
      const fileNameNew = fileName === '' ? null : fileName
      axios
        .get(baseUrl.getS3FileUrl + fileNameNew)
        .then((response) => {
          const file = { fileNameNew, s3FileUrl: response.data };
          this.setState({
            attachedFiles: [...this.state.attachedFiles, file],
          });
          setTimeout(()=> { this.reRender() }, 1000)
          hideLoading();
        })
        .catch((err) => {
          console.log(err);
          setTimeout(()=> { this.reRender() }, 1000)
          hideLoading();
        });
    });
  }

  reRender = () => { this.forceUpdate(); }

  getHtmlContent(rawcontent) {
    let htmlContent = "";
    const contentBlock = htmlToDraft(rawcontent);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      htmlContent = draftToHtml(convertToRaw(contentState));
    }
    return htmlContent;
  }

  render() {
    let imageSrc = "";
    let challengeDetail = {};
    if (Object.keys(this.state.challengeFileUrl).length > 0) {
      imageSrc =
        this.state.challengeFileUrl.profile !== null
          ? this.state.challengeFileUrl.profile.fileUrl
          : challenge;
    }
    const { userData } = this.props;
    let timeRemaining = "";
    let organiserName = "";
    let organiserImg = "";
    let topic = "";
    let dateStr = "";
    if (
      this.state.challengeDetail !== undefined &&
      Object.keys(this.state.challengeDetail).length > 0
    ) {
      challengeDetail = this.state.challengeDetail;
      if (Object.keys(challengeDetail).length > 0) {
        organiserName = challengeDetail.organiser
          ? challengeDetail.organiser.name
          : "";
        organiserImg = challengeDetail.organiser
          ? challengeDetail.organiser.imageUrl
          : "";
        let hours = challengeDetail.timeRemaining.hours.toString();
        timeRemaining = hours.replace(/-/g, "") + " hrs ";
        challengeDetail.topics.forEach((e) => {
          topic = e.topicText;
        });
        dateStr = moment(challengeDetail.challengeDate).format(
          "dddd, MMMM DD YYYY, h:mm a"
        );
      }
    }
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        // url: "/challengeList/admin",
        url: `${
          this.state.userRole === _CONSTANTS.USER.PANELLEAD ||
          this.state.userRole === _CONSTANTS.USER.ADMIN
            ? "/challengeList/admin"
            : "/challengeList/user"
        }`,
        text: "Challenge",
      },
      {
        url: "",
        text: "Details",
      },
    ];

    let challengeDescriptionHtml = "";
    let rulesOfTheRoadHtml = "";
    if (challengeDetail.challengeDescription) {
      challengeDescriptionHtml = this.getHtmlContent(
        challengeDetail.challengeDescription
      );
    }
    if (challengeDetail.rulesOfTheRoad) {
      rulesOfTheRoadHtml = this.getHtmlContent(challengeDetail.rulesOfTheRoad);
    }

    const validateEmail = (val) => {
      const teamMemIds = val ? val : [];
      if (teamMemIds.length === 0) return {removeDup: [], errArr: []}
      let filterArr = (teamMemIds instanceof Array) ? teamMemIds : teamMemIds?.split(";");
      filterArr = filterArr?.map((e) => e.trim())?.filter((e) => e.length)?.map((e) => e.toLowerCase());

      const removeDup = filterArr.filter((val, idx) => filterArr.indexOf(val) === idx);

      const errArr = removeDup
        .map((e) => {
          if (
            e.indexOf("@deloitte.com", e.length - "@deloitte.com".length) !== -1
          ) {
            const emailWoDomain = e.split("@deloitte.com")[0];
            if (emailWoDomain.trim().length <= 0) {
              return e;
            }
          } else {
            return e;
          }
        })
        .filter((e) => e !== undefined);

        return {removeDup, errArr}
    };

    const checkRegisterInput = (val, field) => {
      if (field === "teamName") {
        const trimVal = val.trim();
        this.setState({ teamName: trimVal });
        const {errArr} = validateEmail(this.state.teamMembersIds)
        this.setState({isRegisterBtnDisabled: !!(trimVal && this.state.userEmail && !errArr.length)});
      } else {
        const {errArr, removeDup} = validateEmail(val)
        this.setState({ isteamMembersIdsErr: !!errArr.length });
        this.setState({ teamMembersIds: removeDup });
        this.setState({
          isRegisterBtnDisabled: !!(
            (this.state.teamName && this.state.userEmail && !errArr.length)
          ),
        });
      }
    };

    const handleRegisterTeam = async () => {
      // const removeuserEmail = this.state.teamMembersIds.filter(e => e !== this.state.userEmail);
      // register team logic here
      showLoader();
      const payload = {
        teamName: this.state.teamName,
        userEmail: this.state.userEmail,
        teamMembersIds: this.state.teamMembersIds,
      };
      try {
        const res = await this.props.getRegisterTeamForChallenge(
          this.state.challengeDetail.challengeID,
          payload
        );
        this.setState({
          showRegistraionModal: false,
          isAlreadyRegistered: true,
        });
        this.onClickAcceptChallenge(false);
        hideLoading();
      } catch (err) {
        console.log("[Err]: ", err);
        if (err?.response?.data?.errorMessage) {
          alert(err.response.data.errorMessage);
        } else {
          alert(
            "Something went wrong. Please make sure all fields are correctly entered"
          );
        }
        // this.setState({ showRegistraionModal: false });
        hideLoading();
      }
    };

    const isUserOrganiser =
      "userData" in this.props &&
      "email" in this.props.userData &&
      "organiser" in challengeDetail &&
      challengeDetail.organiser.email === this.props?.userData?.email;
    const isUserPanelLeadOrAdmin =
      this.state.userRole === _CONSTANTS.USER.PANELLEAD ||
      this.state.userRole === _CONSTANTS.USER.ADMIN;
    const isChallengeActive =
      challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.ACTIVE;
    const isChallengeAccepted = this.state.accepted;
    const isregistrationRequired =
      this.state.challengeDetail.registrationRequired;
    const isAlreadyRegistered = this.state.isAlreadyRegistered;
    const isChallengeInactive =
      challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.INACTIVE;
    const isChallengeExpired =
      challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.EXPIRED;
    return (
      <>
        {this.state.showMessagePopup &&
          Object.keys(this.props.messageData).length > 0 && (
            <GlobalMessagePopup
              showMessagePopup={this.state.showMessagePopup}
              messageData={this.state.messageData}
            />
          )}
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"challengeDetail"}
        />
        <Breadcrumbs breadcrumbs={breadcrumbJSON} />
        <section className="challengeDetailContainer">
          <div className="challengeDetail_lt">
            <div className="heading">
              {Object.keys(challengeDetail).length > 0
                ? this.state.challengeDetail.challengeName
                : ""}
            </div>
            <div className="selectedTags">
              {typeof challengeDetail !== undefined && challengeDetail.tags
                ? challengeDetail.tags.map((tag, index) => {
                    return (
                      <label className="checkLabel" key={generateRandomKey()}>
                        {tag.tag}
                      </label>
                    );
                  })
                : ""}
            </div>
            <div className="challenge-detail">
              <div className="challenge-detail_lt">
                {/* <div className="topic">
                  <img src={challengeTopic} alt="topic" />
                  <div>{topic}</div>
                </div> */}
                <div className="organizer-cont">
                  <div className="organizer_heading">Organizer</div>
                  <div className="organizer">
                    {/* <img src={organiserImg} alt="user" /> */}
                    <div className="userCreds">
                      {organiserName ? getNameInitials(organiserName) : ""}
                    </div>
                    <div>{organiserName}</div>
                  </div>
                </div>
              </div>
              <div className="challenge-detail_rt">
                <div
                  className={`date-time-cont ${
                    Object.keys(challengeDetail).length > 0 &&
                    challengeDetail.timeRemaining.days > 1
                      ? ""
                      : "reaching-dealine"
                  }`}
                >
                  <div className="date">
                    <img src={dateIcon} alt="date" />
                    <div>{dateStr} IST</div>
                  </div>
                  {challengeDetail.status ===
                    _CONSTANTS.CHALLENGESTATUS.ACTIVE && (
                    <div className="timeline">
                      <img
                        src={challengeDeadlineGreen}
                        alt="deadline"
                        className="green"
                      />
                      <img
                        src={challengeDeadline}
                        alt="deadline"
                        className="yellow"
                      />
                      <div>
                        {challengeDetail.timeRemaining.days +
                          (challengeDetail.timeRemaining.days > 1
                            ? " days "
                            : " day ") +
                          Math.abs(challengeDetail.timeRemaining.hours) +
                          (challengeDetail.timeRemaining.hours > 1
                            ? " hrs"
                            : " hr")}
                        <p>till deadline</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="challenge_description">Details</div>
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: challengeDescriptionHtml }}
            ></p>
            {challengeDetail.rulesOfTheRoad && (
              <>
                <div className="challenge_description">Rules of the Road</div>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: rulesOfTheRoadHtml }}
                ></p>
              </>
            )}
          </div>
          <div className="challengeDetail_rt">
            <div className="button-section">
              <div className="btn-container">
                {this.state.showChallengeAcceptConfirmation && (
                  <div className="accepted-modal">
                    <button onClick={() => this.hideAcceptModal()}>
                      <img src={closeIcon} alt="close" />
                    </button>
                    {/* <img src={challengeAccept} alt="close" /> */}
                    <div className="accepted-modal_title">
                      Challenge Accepted!
                    </div>
                    <p className="accepted-modal_detail">
                      Gear-up and send your best solution to this challenge
                    </p>
                  </div>
                )}

                {isChallengeActive ? (
                  !isUserOrganiser ? (
                    isregistrationRequired ? (
                      !isAlreadyRegistered ? (
                        // <button
                        //   className="btn primary"
                        //   onClick={() =>
                        //     this.setState({ showRegistraionModal: true })
                        //   }
                        // >
                        //   Accept &amp; Register
                        // </button>
                        <h2>Registration are closed for this challenge</h2>
                      ) : (
                        <button
                          className="btn primary"
                          disabled={!hasIdeaAccess(this.props.userData)}
                          onClick={() =>
                            hasIdeaAccess(this.props.userData) &&
                            this.handleRedirectModal()
                          }
                        >
                          Submit Your Idea
                        </button>
                      )
                    ) : !isChallengeAccepted ? (
                      <button
                        className="btn primary"
                        onClick={() => this.onClickAcceptChallenge(true)}
                      >
                        Accept challenge
                      </button>
                    ) : (
                      <button
                        className="btn primary"
                        disabled={!hasIdeaAccess(this.props.userData)}
                        onClick={() =>
                          hasIdeaAccess(this.props.userData) &&
                          this.handleRedirectModal()
                        }
                      >
                        Submit Your Idea
                      </button>
                    )
                  ) : (
                    <button
                      className="btn primary"
                      disabled={
                        userData.userType !== "ADMIN" &&
                        userData.userType !== "PANELLEAD"
                      }
                      onClick={this.displayTagsModal}
                    >
                      Stop Challenge
                    </button>
                  )
                ) : isChallengeInactive ? (
                  <span className="expiredChallenge">InActive</span>
                ) : isChallengeExpired ? (
                  <span className="expiredChallenge">Expired</span>
                ) : null}
              </div>
              <div className="align-center">
                {
                  //(challengeDetail.challengeID == 289 || challengeDetail.challengeID == 185) &&
                  this.props.userData?.id &&
                    hasIdeaAccess(this.props.userData) && (
                      //?
                      <a
                        className="btn btnText"
                        href={`/challengeIdeaList?id=${challengeDetail.challengeID}`}
                      >
                        View Submitted Ideas (
                        {this.state.challengeDetail.totalSolutions})
                      </a>
                    )
                  /* : 
                  <a className="btn btnText view-submitted-disabled" >
                    View Submitted Ideas </a> */
                }
              </div>
            </div>
            {/* <img src={imageSrc} className="challengeimg" alt="challenge"></img> */}
            {this.state.attachedFiles?.length > 0 && (
              <div className="attachments">
                <div className="attachmentHeader">Attachments</div>
                <ul>
                  {this.state.attachedFiles.map((file, index) => {
                    return (
                      <>
                        <li key={generateRandomKey()}>
                          <a target="_blank" href={file.s3FileUrl}>
                            {file.fileNameNew}
                          </a>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </section>
        <Modal
          show={this.state.showTagsModal}
          onHide={this.HideTagsModal}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Stop Challenge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bodyHeader">
              <div className="bodyHeaderHeading">
                Are you sure you want to stop this Challenge?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.openChallengeEdit(challengeDetail.challengeID)
              }
            >
              Edit Instead
            </Button>
            <Button
              variant="primary"
              onClick={() => this.stopChallenge(challengeDetail.challengeID)}
            >
              Stop Challenge
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showRedirectModal}
          onHide={this.hideRedirectModal}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Submit Your Idea</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bodyHeader">
              <div className="bodyHeaderHeading">
                I hereby acknowledge my full acceptance of the contest rules, as
                stipulated in the challenge documentation, prior to submitting
                my idea.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnText"
              onClick={() => this.hideRedirectModal()}
            >
              Cancel
            </button>
            <Button
              variant="primary"
              onClick={
                () => this.handleRedirectionToIdea(challengeDetail.challengeID)
                // this.handleRedirectionToIdea(this.state.challengeId)
              }
            >
              Submit Your Idea for this Challenge
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showRegistraionModal}
          onHide={() =>
            this.setState({
              showRegistraionModal: false,
              isteamMembersIdsErr: false,
              teamMembersIds: [],
              teamName: "",
            })
          }
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Register your team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bodyHeader">
              <div className="bodyHeaderHeading">
                <div className="regsiterTeamIndi">
                  <div className="regsiterTeamIndi_title">1. Team name: </div>
                  <div className="regsiterTeamIndi_val">
                    <input
                      type="text"
                      maxLength={50}
                      placeholder="Team name should not contain more than 50 characters"
                      onChange={(e) =>
                        checkRegisterInput(DOMPurify.sanitize(e.target.value), "teamName")
                      }
                    />
                  </div>
                </div>
                <div className="regsiterTeamIndi">
                  <div className="regsiterTeamIndi_title">2. Your email: </div>
                  <div className="regsiterTeamIndi_val">
                    <input type="text" value={this.state.userEmail} disabled />
                  </div>
                </div>
                <div className="regsiterTeamIndi">
                  <div className="regsiterTeamIndi_title">
                    3. Add team members email:{" "}
                  </div>
                  <div className="regsiterTeamIndi_val">
                    <input
                      type="text"
                      placeholder="emails should be semi-colon separated"
                      onChange={(e) =>
                        checkRegisterInput(DOMPurify.sanitize(e.target.value), "teamMembersIds")
                      }
                    />
                    {this.state.isteamMembersIdsErr && (
                      <span className="error">
                        Please provide valid Deloitte email id only
                      </span>
                    )}
                  </div>
                </div>
                <div className="regsiterTeamIndi_note">
                  <div className="regsiterTeamIndi_title">Note: </div>
                  <div className="regsiterTeamIndi_val">Only deloitte email addresses of those who have logged in to seed.deloitte.com at least once can be added.</div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnText"
              onClick={() =>
                this.setState({
                  showRegistraionModal: false,
                  isteamMembersIdsErr: false,
                  teamMembersIds: [],
                  teamName: "",
                })
              }
            >
              Cancel
            </button>
            <Button
              variant="primary"
              disabled={!this.state.isRegisterBtnDisabled}
              onClick={handleRegisterTeam}
            >
              Register
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FILE_DOWNLOAD_STATUS: state.utilities.meta.FILE_DOWNLOAD_STATUS,
    file: state.utilities.data.file,

    FILE_LIST_STATUS: state.utilities.meta.FILE_LIST_STATUS,
    existingFileList: state.utilities.data.existingFileList,

    FETCH_SAVED_CHALLENGE_STATUS:
      state.challenges.meta.FETCH_SAVED_CHALLENGE_STATUS,
    messageData: state.challenges.data.messageData,
    challengeDetail: state.challenges.data.challengeDetail,

    FILE_DOWNLOAD_STATUS: state.utilities.meta.FILE_DOWNLOAD_STATUS,
    file: state.utilities.data.file,
  };
};

const mapDispatchToProps = {
  fetchFileList,
  fetchSavedChallenge,
  doFileDownload,
  getRegisterTeamForChallenge,
  getTeamNameForChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetail);
