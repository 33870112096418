import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { fetchIdeasFrmArnd, searchIdeas } from "../../redux/actions/ideas";
import "../../css/pages/ideaList.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import config from "../../helpers/api-urls";
import IdeaItem from "../global/ideaItem";
import CarousalArrow from "../global/carousalArrow";
import { showLoader, hideLoading } from "../../helpers/utils";
import ContextMenuWithHook from "../global/contextMenu";

class IdeasFromAround extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      loading: false,
      perPageRows: 8,
      totalPages: parseInt(this.props.totalIdeas),
      pageIndex: 0,
      ideaItems: [],
      contextLink: '',
    };
    this.setContextLink = this.setContextLink.bind(this);
  }
  componentDidMount() {
    showLoader();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("tagId") && this.state.tags.length === 0) {
      this.state.tags.push(parseInt(urlParams.get("tagId")));
    }
    if (urlParams.has("tagId") && this.state.tags.length > 0) {
      this.props.searchIdeas({
        tagIds: this.state.tags,
        currentPaginationLevel: this.state.pageIndex,
        pageSize: this.state.perPageRows,
        userId: this.props.userData.id
      });
    } else {
      this.props.fetchIdeasFrmArnd(this.state.perPageRows, this.props.userData.id);
    }
  }

  componentDidUpdate(prevProps) {
    setTimeout(() => {
      hideLoading();
    }, 1000);
    if (prevProps.ideasFromAroundIdeas !== this.props.ideasFromAroundIdeas) {
      this.setState({
        totalPages: parseInt(this.props.totalIdeas),
        ideaItems: this.props.ideasFromAroundIdeas,
      });
    }
  }

  sendSearchTerms = (tagID, isSelected) => {
    showLoader();
    if (isSelected) {
      const id = this.state.tags.findIndex((tag) => tag === tagID);
      this.state.tags.splice(id, 1);
    } else {
      this.state.tags.push(tagID);
    }
    if (this.state.tags.length === 0) {
      window.history.pushState("", "", window.location.href.split("?")[0]);
      this.props.fetchIdeasFrmArnd(this.state.perPageRows, this.props.userData.id);
    } else {
      this.props.searchIdeas({
        tagIds: this.state.tags,
        currentPaginationLevel: this.state.pageIndex,
        pageSize: this.state.perPageRows,
        userId: this.props.userData.id
      });
    }
  };

  loadMoreItems = (pageIndex) => {
    showLoader();
    this.setState({
      loading: true,
      pageIndex: this.state.pageIndex + 1,
    });
    if(this.state.tags.length === 0){
      const payloadUrl =
        config.ideaListConfig.requestPayload.url +
        "/" +
        (pageIndex + 1) +
        "?pageSize=" +
        this.state.perPageRows +
        "&userId=" +
        this.props.userData.id;
      axios
        .get(payloadUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
        .then((response) => {
          this.setState({
            ideaItems: [...this.state.ideaItems, ...response.data],
            loading: false,
          });
          hideLoading();
        })
        .catch((error) => {
          console.log(error);
          hideLoading();
        });
    } else {
      const payload = {
        tagIds: this.state.tags,
        currentPaginationLevel: (pageIndex + 1) * this.state.perPageRows,
        pageSize: this.state.perPageRows,
        userId: this.props.userData.id
      };
      const payloadUrl =
        config.searchIdeaConfig.requestPayload.url
      axios
        .post(payloadUrl, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        })
        .then((response) => {
          if (response.data?.ideas?.length > 0) {
            this.setState({
              ideaItems: [...this.state.ideaItems, ...response.data.ideas],
              loading: false,
            });
          }
          hideLoading();
        })
        .catch((error) => {
          console.log(error);
          hideLoading();
        });
    }
  };

  setContextLink(ideaId){
    const link = `/idea/${ideaId}`;
    this.setState({contextLink: link});
  }

  render() {
    const ideasFromAroundTags = this.props.ideasFromAroundTags || [];
    const userData = this.props.userData;
    const settings = {
      dots: false,
      className: "center",
      centerMode: false,
      infinite: false,
      slidesToShow: 3.5,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      nextArrow: <CarousalArrow />,
      prevArrow: <CarousalArrow />,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2.5,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2.1,
          },
        },
      ],
    };
    return (
      <>
        <div className="section-slider-container">
          <div className="tagsContainer">
            <div className="tags">
              <Slider {...settings}>
                {ideasFromAroundTags.map((tagItem, index) => {
                  return (
                    <div className="tag" key={tagItem.tagID + index}>
                      <button
                        onClick={() =>
                          this.sendSearchTerms(tagItem.tagID, tagItem.select)
                        }
                        className={
                          tagItem.select === true
                            ? "btn tagsButton isSelected"
                            : "btn tagsButton"
                        }
                      >
                        {tagItem.tagName}
                      </button>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
        <div className="section-container">
          <div className="ideasContainer">
            <ContextMenuWithHook className="ideas" contextLink={this.state.contextLink}>
              {this.state.ideaItems.length === 0 && (
                <div className="no-results">
                  Oh no! We couldn’t find any match
                </div>
              )}
              {this.state.ideaItems.length > 0 &&
                this.state.ideaItems.map((idea, index) => {
                  return <IdeaItem idea={idea} key={idea.ideaName + index} userData={userData} setContextIdeaId={this.setContextLink}/>;
                })}
            </ContextMenuWithHook>
          </div>
          {this.state.ideaItems.length > 0 && (
            <div className="load-more-btn-cont">
              <div className={(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) ? 'load-more-cta-count-data load-more-hidden' : 'load-more-cta-count-data'}>Showing 1 - {Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows)) > this.state.totalPages ? this.state.totalPages : Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows))}/{this.state.totalPages}</div>
              {!(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) && (
                <button
                  type="button"
                  disabled={
                    this.state.totalPages <=
                    (this.state.pageIndex + 1) * this.state.perPageRows
                  }
                  className={classNames("btn btnText load-more-cta", {
                    disabled:
                      this.state.totalPages <=
                      (this.state.pageIndex + 1) * this.state.perPageRows,
                  })}
                  onClick={() => this.loadMoreItems(this.state.pageIndex)}
                >
                  {this.state.loading ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_IDEAS_FRM_ARND_STATUS: state.ideas.meta.FETCH_IDEAS_FRM_ARND_STATUS,
    ideasFromAroundTags: state.ideas.data.ideasFromAround.ideasFromAroundTags,
    ideasFromAroundIdeas: state.ideas.data.ideasFromAround.ideasFromAroundIdeas,
    totalIdeas: state.ideas.data.ideasFromAround.totalIdeas,
  };
};

const mapDispatchToProps = {
  fetchIdeasFrmArnd,
  searchIdeas,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeasFromAround);
