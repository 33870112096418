import React, { Component } from "react";
import classNames from "classnames";
import downArr from "../../img/down-arr.svg";

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: this.props.selectedItem || {},
      optionsData: this.props.options,
      isListOpen: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ optionsData: this.props.options });
    }
    if (prevProps.selectedItem?.name !== this.props.selectedItem?.name) {
      this.setState({ selectedItem: this.props.selectedItem });
    }
  }
  /**
   * Close if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isListOpen: false });
    }
  }

  onToggleList() {
    this.setState({ isListOpen: !this.state.isListOpen });
  }

  handleClick(event) {
    event.preventDefault();
    let index = this.props.isDropDownNative
      ? event.target.selectedIndex
      : event.target.dataset.index;
    index = parseInt(index, 0);
    index = this.props.placeholderText ? index - 1 : index;
    this.onSelect(event, index);
  }

  handleChallengeOption(event, optionId) {
    event.preventDefault();
    let index = this.props.isDropDownNative
      ? event.target.selectedIndex
      : event.target.dataset.index;
    index = parseInt(index, 0);
    index = this.props.placeholderText ? index - 1 : index;
    this.onSelectChallengeOption(event, index, optionId);
  }

  onSelectChallengeOption(event, index, optionId) {
    if (!this.props.isDropDownNative) {
      this.setState({ isListOpen: false });
    }
    if (this.state.selectedItem) {
      const item = this.state.selectedItem;
      item.selected = false;
      this.setState({ selectedItem: item });
    }
    const challengeOptions = this.props.placeholderText === 'Choose Challenge' ? this.props.options[0] : []
    const item = {}
    item.name = challengeOptions[optionId]
    item.id = optionId
    item.selected = true;
    this.setState({ selectedItem: item });
    this.props.listItemSelected(event, item);
  }

  onSelect(event, index) {
    if (!this.props.isDropDownNative) {
      this.setState({ isListOpen: false });
    }
    if (this.state.selectedItem) {
      const item = this.state.selectedItem === 'N/A' ? {} : this.state.selectedItem;
      item.selected = false;
      this.setState({ selectedItem: item });
    }
    const item = this.state.optionsData[index];
    item.selected = true;
    this.setState({ selectedItem: item });
    this.props.listItemSelected(event, item);
  }

  render() {
    const { dropDownName, placeholderText } = this.props;
    const { optionsData = undefined } = this.state;
    const challengeOptions = this.props.placeholderText === 'Choose Challenge' ? this.props.options[0] : []
    return this.props.placeholderText !== "Choose Challenge" ? (
      <div className="dropdown-wrapper">
        <div
          className={classNames("dropdown", {
            selected: Object.keys(this.state.selectedItem).length > 0,
            disabled: optionsData.length < 2,
          })}
          ref={this.wrapperRef}
        >
          <button
            aria-expanded="false"
            type="button"
            className={classNames(
              "dropdown-select",
              this.state.isListOpen ? "is-open" : ""
            )}
            onClick={() => this.onToggleList()}
            id={dropDownName}
          >
            {this.state.selectedItem.name || placeholderText}
            <img src={downArr} alt="down-arrow" />
          </button>
          <ul
            role="menu"
            className={classNames(
              "dropdown-menu",
              this.state.isListOpen ? "show" : "d-none"
            )}
            aria-labelledby={dropDownName}
          >
            <div className="list-container">
              {optionsData !== undefined &&
                optionsData !== null &&
                optionsData.map((option, index) => {
                  if (option.visible) {
                    return (
                      <li
                        key={`option-${this.props.dropDownName}-${option.name}`}
                      >
                        <a
                          role="menuitem"
                          className={classNames(
                            "dropdown-item",
                            this.state.selectedItem &&
                              option.value === this.state.selectedItem.value
                              ? "selected"
                              : ""
                          )}
                          disabled={["All", "default"].includes(option.value)}
                          onClick={(e) => this.handleClick(e)}
                          data-index={placeholderText ? index + 1 : index}
                        >
                          {option.name}
                        </a>
                      </li>
                    );
                  } else {
                    return <></>;
                  }
                })}
            </div>
          </ul>
        </div>
      </div>
    ) : (
      <div className="dropdown-wrapper">
      <div
        className={classNames("dropdown", {
          selected: Object.keys(this.state.selectedItem).length > 0,
          disabled: challengeOptions?.length < 2,
        })}
        ref={this.wrapperRef}
      >
        <button
          aria-expanded="false"
          type="button"
          className={classNames(
            "dropdown-select",
            this.state.isListOpen ? "is-open" : ""
          )}
          onClick={() => this.onToggleList()}
          id={dropDownName}
        >
          {this.state.selectedItem.name || placeholderText}
          <img src={downArr} alt="down-arrow" />
        </button>
        <ul
          role="menu"
          className={classNames(
            "dropdown-menu",
            this.state.isListOpen ? "show" : "d-none"
          )}
          aria-labelledby={dropDownName}
        >
          <div className="list-container">
            {challengeOptions !== undefined &&
              challengeOptions !== null &&
              Object.keys(challengeOptions).map((option, index) => {
                  return (
                    <li
                      key={`option-${this.props.dropDownName}-${option}`}
                    >
                      <a
                        role="menuitem"
                        className={classNames(
                          "dropdown-item",
                          this.state.selectedItem &&
                          challengeOptions[option] === this.state.selectedItem
                            ? "selected"
                            : ""
                        )}
                        disabled={["All", "default"].includes(option.value)}
                        onClick={(e) => this.handleChallengeOption(e, option)}
                        data-index={placeholderText ? index + 1 : index}
                      >
                        {challengeOptions[option]}
                      </a>
                    </li>
                  );   
              })}
          </div>
        </ul>
      </div>
    </div>
    );
  }
}

export default DropDown;
