import React, { Component } from "react";

class FilterItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: this.props.isSelected
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isSelected !== this.props.isSelected) {
      this.setState({
        isSelected: this.props.isSelected
      });
    }
  }
  setSelectedItem = (item, title) => {
    this.props.setSelectedFilter(item, title, this.state.isSelected);
  }
  render() {
    const { data, title } = this.props;
    return (
      <li className={`${this.state.isSelected ? 'is-selected' : ''}`} onClick={() => this.setSelectedItem(data, title)}>{data.text}</li>
    );
  }
}

export default FilterItem;
