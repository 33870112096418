import React, { Component } from "react";
import "../../css/pages/newUser.scss";
// import { connect } from "react-redux";
import Header from "../global/header";
import axios from "axios";
import challengeNewUser from "../../img/ideation/challengenewuser.png";
// import { challengeList } from "../../redux/actions/challenges";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import moment from "moment";
import classNames from "classnames";
import config from "../../helpers/api-urls";
import { hideLoading, showLoader, getBaseUrl } from "../../helpers/utils";
import { _CONSTANTS } from "../../helpers/constants";
import ContextMenuWithHook from "../global/contextMenu";

const baseUrl = getBaseUrl();
class ChallengeList extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: "challenges",
      perPageRows: 3,
      pageIndex: 0,
      totalRecords: 0,
      renderJSON: [],
      userId: this.props.userData && this.props.userData.id,
      challengeID: 0,
      contextLink: ''
    };
    this.setContextLink = this.setContextLink.bind(this);
  }

  componentDidMount() {
    showLoader();
    axios
      .get(
        baseUrl.ipAddress +
        "api/v1/challenges/" +
        this.state.pageIndex +
        "?pageSize=" +
        this.state.perPageRows +
        "&userID=" +
        this.state.userId,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        }
      )
      .then((data) => {
        this.setState({
          renderJSON: data.data.challenges,
          totalRecords: data.data.totalRecords,
        });
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  }

  loadMoreItems = (pageIndex) => {
    showLoader();
    this.setState({
      loading: true,
      pageIndex: this.state.pageIndex + 1,
    });

    axios
      .get(
        baseUrl.ipAddress +
        "api/v1/challenges/" +
        (pageIndex + 1) +
        "?pageSize=" +
        this.state.perPageRows +
        "&userID=" +
        this.state.userId,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
        }
      )
      .then((data) => {
        this.setState({
          renderJSON: [...this.state.renderJSON, ...data.data.challenges],
          loading: false,
          totalRecords: data.data.totalRecords,
        });
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  };

  displayTagsModal = () => {
    this.setState({ showTagsModal: true });
  };
  HideTagsModal = () => {
    this.setState({ showTagsModal: false });
  };

  openChallengeDetail = (id) => {
    window.location.href =
      "/challenge?id=" + id;
  };

  stopChallenge = (id) => {
    showLoader();
    axios
      .post(config.stopChallengeConfig.url + "?challengeId=" + id, {}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      })
      .then((data) => {
        // console.log(data.data);
        setTimeout(function () {
          window.location.reload(true);
        }, 1000);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
    this.HideTagsModal();
  };

  setContextLink(challengeId){
    this.setState({contextLink: `/challenge?id=${challengeId}`});
  }

  render() {
    const { userData } = this.props;
    let challengeDetail = [];
    // let status = [];
    // let time = [];
    if (this.state.renderJSON !== undefined) {
      challengeDetail = this.state.renderJSON;
      // challengeDetail.forEach(element => {
      // let now = new Date().toISOString();
      // const value = moment(new Date().toISOString()).isAfter(element.challengeEndTime);
      // })
    }
    return (
      <>
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"challengeList"}
        />
        <section className="newUseramin">
          <div className="topSection">
            <div className="imageDiv">
              <div>
                <img
                  src={challengeNewUser}
                  className="challengenewuserimg"
                  alt="challenge"
                ></img>
              </div>
            </div>
            <div className="colleagueDiv">
              <div className="giveYourColleague">
                Give your colleagues a way to flex their brain
                <p>Create a challenge under your specified category</p>
                <p>
                  Don’t forget to make it more competitive with a challenging
                  timeline!
                </p>
                <button
                  className="btn btn-primary createChallenge"
                  disabled={userData.userType !== 'ADMIN' && userData.userType !== 'PANELLEAD'}
                  onClick={(event) =>
                    (window.location.href = "/challengecreationflow")
                  }
                >
                  Create a challenge
                </button>
              </div>
            </div>
          </div>
          {challengeDetail.length > 0 && (
            <>
              <ContextMenuWithHook className="yourChallenge" contextLink={this.state.contextLink}>
                All Active Challenges
                <div className="challengeListTitle">
                  <div className="titleName">Name</div>
                  <div className="titleStatus">Status</div>
                  <div className="titleParticipants">Submitted Ideas</div>
                  <div className="titleTime">Time till expiry</div>
                </div>
                {challengeDetail.map((challengeDetail, index) => {
                  return (
                    <div className="yourChallengeList" key={index}>
                      {challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.ACTIVE ? (
                        <div
                          className="challengeName is-active"
                          onClick={() =>
                            this.openChallengeDetail(
                              challengeDetail.challengeID
                            )
                          }
                          onMouseOver={() => {
                            this.setContextLink(challengeDetail.challengeID);
                          }}
                        >
                          {challengeDetail.challengeName}
                        </div>
                      ) : (
                        <div className="challengeName">
                          {challengeDetail.challengeName}
                        </div>
                      )}
                      {/* <div className="challengeName" onClick={() => this.openChallengeDetail(challengeDetail.challengeID)}>{challengeDetail.challengeName}</div> */}
                      <div className="challengeStatus">
                        {(challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.INACTIVE ||
                          challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.EXPIRED) ? (
                          <span className="ended">Ended</span>
                        ) : (
                          <span>Live</span>
                        )}
                      </div>
                      <div className="challengeParticipants">
                        {challengeDetail.totalSolutions
                          ? challengeDetail.totalSolutions
                          : "0"}
                      </div>
                      <div className="timeTillExpiry">
                        {challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.ACTIVE
                          ? challengeDetail.timeRemaining.days +
                          " D " +
                          Math.abs(challengeDetail.timeRemaining.hours) +
                          " H " +
                          Math.abs(challengeDetail.timeRemaining.minutes) +
                          " M"
                          : "-"}
                      </div>
                      {challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.ACTIVE && (
                        <div className="stopChallenge">
                          <button
                            className="btn btnText"
                            disabled={(userData.userType !== 'ADMIN' && userData.userType !== 'PANELLEAD') || userData.email !== challengeDetail.organiser.email}
                            // disabled={!(moment(challengeDetail.challengeDate).isAfter(new Date().toISOString()))}
                            onClick={() =>
                              this.setState({
                                showTagsModal: true,
                                challengeID: challengeDetail.challengeID,
                              })
                            }
                          >
                            Stop Challenge
                          </button>
                        </div>
                      )}
                      {challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.INACTIVE && (
                        <span className="expiredChallenge">InActive</span>
                      )}

                      {challengeDetail.status === _CONSTANTS.CHALLENGESTATUS.EXPIRED && (
                        <span className="expiredChallenge">Expired</span>
                      )}
                    </div>
                  );
                })}
                <Modal
                  show={this.state.showTagsModal}
                  onHide={this.HideTagsModal}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Stop Challenge</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="bodyHeader">
                      <div className="bodyHeaderHeading">
                        Are you sure you want to stop this Challenge?
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => this.stopChallenge(this.state.challengeID)}
                    >
                      Stop Challenge
                    </Button>
                  </Modal.Footer>
                </Modal>
              </ContextMenuWithHook>
              <div className={(this.state.totalRecords <= (this.state.pageIndex + 1) * this.state.perPageRows) ? 'load-more-cta-count-data load-more-hidden' : 'load-more-cta-count-data'}>Showing 1 - {Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows)) > this.state.totalRecords ? this.state.totalRecords : Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows))}/{this.state.totalRecords}</div>
              {!(this.state.totalRecords <= (this.state.pageIndex + 1) * this.state.perPageRows) && (
                <button
                  type="button"
                  disabled={
                    this.state.totalRecords <=
                    (this.state.pageIndex + 1) * this.state.perPageRows
                  }
                  className={classNames("btn btnText load-more-cta", {
                    disabled:
                      this.state.totalRecords <=
                      (this.state.pageIndex + 1) * this.state.perPageRows,
                  })}
                  onClick={() => this.loadMoreItems(this.state.pageIndex)}
                >
                  {this.state.loading ? "Loading..." : "Load More"}
                </button>
              )}
            </>
          )}
        </section>
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     FETCH_CHALLENGE_LIST: state.challenges.meta.FETCH_CHALLENGE_LIST_STATUS,
//     challengeList: state.challenges.data.challengeTag,
//   };
// };

// const mapDispatchToProps = {
//   challengeList,
// };
export default ChallengeList;
// export default connect(mapStateToProps, mapDispatchToProps)(ChallengeList);
