import React, { Component } from "react";
import Header from "../components/global/header";
import "../css/pages/ideaList.scss";
import IdeasFromAround from "../components/IdeaList/ideasFromAround";

class IdeaList extends Component {
  render() {
    const { userData } = this.props;
    return (
      <>
        <Header values={userData} showSearch={true} showUser={true} pageName={"ideas"} />
        <section className="ideaList">
          <div className="headerTitleContent section-container">
            {/* <h4 className="subHeader">FOR YOU</h4> */}
            <h1 className="header">Ideas across the board</h1>
          </div>
          <IdeasFromAround userData={userData} />
        </section>
      </>
    );
  }
}

export default IdeaList;
