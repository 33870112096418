import React, { Component } from "react";
import { connect } from "react-redux";
import "../css/pages/ideaSubmissions.scss";
import classNames from "classnames";
import downArr from "../img/down-arr.svg";
import Header from "../components/global/header";
import Pagination from "../components/global/pagination";
import { getIdeaSubmissionList } from "../redux/actions/ideas";
import DropDown from "../components/global/dropdown";
import { checkForStatusColor, hideLoading, showLoader } from "../helpers/utils";
import GlobalMessagePopup from "../components/global/globalMessagePopup";
import { _CONSTANTS } from "../helpers/constants";
import ChallengeIcon from "../components/global/challengeIcon";
import ContextMenuWithHook from "../components/global/contextMenu";
import TooltipEx from "../components/UI/tooltip";
import axios from "axios";
import config from "../helpers/api-urls";

let statusOptions = [],
    categoryOptions = [],
    talentOffering = [],
    marketOffering = [],
    talentOptions = [],
    totalMarketOfferings = [],
    industryOptions = [],
    sectorOptions = [{
      value: 'default',
      name: 'Please select Industry first',
      id: 1111,
      selected: false,
      visible: true
    }],
    domainOptions = [],
    challengeOptions = [];

class IdeaSubmissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideaItems: this.props.ideaResults
        ? this.props.ideaResults.ideaDetailsResponsePojo
        : [],
      isListOpen: true,
      perPageRows: 8,
      pageIndex: 0,
      totalRecords: this.props.ideaResults
        ? this.props.ideaResults.totalResults
        : 0,
      currentPage: 1,
      options: [1, 2, 3],
      categoryNameSelected: "",
      categoryIdSelected: 0,
      statusSelected: "",
      talentIdSelected: 0,
      talentNameSelected: "",
      talentOfferingSelectedItem: {},
      marketOfferingSelectedItem: {},
      talentOfferingNameSelected: "",
      marketOfferingNameSelected: "",
      talentOfferingIdSelected: 0,
      marketOfferingIdSelected: 0,
      statusSelectedItem: {},
      categorySelectedItem: {},
      talentSelectedItem: {},
      userId: this.props.userData?.id,
      userType: "",
      showDraftPopup: false,
      contextLink: "",

      challenges: this.props.challenge,
      domains: this.props.domains,
      sectors: this.props.sectors,
      industries: this.props.industries,

      challengeIdSelected: 0,
      challengeNameSelected: '',
      challengeSelectedItem: {},

      industryIdSelected: 0,
      industryNameSelected: '',
      industrySelectedItem: {},

      sectorIdSelected: 0,
      sectorNameSelected: '',
      sectorSelectedItem: {},

      domainIdSelected: 0,
      domainNameSelected: '',
      domainSelectedItem: {}
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.contextHandler = this.contextHandler.bind(this);
  }

  updateIndustriesAndDomain (responseIndustries = [],responseDomains = []) {
    const industryArr = (this.state.industries?.length && industryOptions.length == 0) ?
                          this.state.industries :
                          responseIndustries?.length ? responseIndustries : []
      if(industryArr?.length) {
        industryArr.forEach(e => {
          const option = {
            value: e.industryName,
            name: e.industryName,
            id: e.industryId,
            selected: false,
            visible: true,
          };
          industryOptions.push(option)
        })
      }

    const domainArr = (this.state.domains?.length && domainOptions.length == 0) ?
                          this.state.domains :
                          responseDomains?.length ? responseDomains : []

    if(domainArr?.length) {
        domainArr.forEach(e => {
          const option = {
            value: e.domainName,
            name: e.domainName,
            id: e.domainId,
            selected: false,
            visible: true
          };
          domainOptions.push(option)
        })
      }
      challengeOptions.push(this.props.ideaResults.challenges)
  }

  componentDidMount() {
    showLoader();
    axios
      .get(config.createIdeaConfig.requestPayload.url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`,
        },
      })
      .then((response) => {
        totalMarketOfferings = response.data.talentGroups;
        this.setState({
          industries: response.data.industries,
          sectors: response.data.sectors,
          domains: response.data.domains,
        })
        this.updateIndustriesAndDomain(response.data.industries, response.data.domains)
      });

    document.addEventListener("mousedown", this.handleClickOutside);
    const urlParams = new URLSearchParams(window.location.search);
    if (this.state.userId) {
      const requestPayload = {
        category: this.state.categoryNameSelected,
        categoryId: this.state.categoryIdSelected,
        status: this.state.statusSelected,
        talentGroup: this.state.marketOfferingNameSelected,
        talentGroupId: this.state.marketOfferingIdSelected,
        talentOffering: this.state.talentOfferingNameSelected,
        talentOfferingId: this.state.talentOfferingIdSelected,
        currentPage: this.state.pageIndex,
        resultsPerPage: this.state.perPageRows,
        userId: this.state.userId,
        industryId: this.state.industryIdSelected,
        sectorId: this.state.sectorIdSelected,
        domainId: this.state.domainIdSelected,
        challengeId: this.state.challengeIdSelected
      };
      this.props.getIdeaSubmissionList(requestPayload);
    }
    if (
      urlParams.has("isDraft") &&
      document.referrer.indexOf("evaluateIdea") > -1 &&
      window.performance &&
      performance.navigation.type == 0
    ) {
      this.setState({ showDraftPopup: true });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        this.setState({
          showDraftPopup: false,
        });
      }, 10000);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ideaResults !== this.props.ideaResults) {
      this.setState({
        userType: this.props.ideaResults.userType,
        ideaItems: this.props.ideaResults.ideaDetailsResponsePojo,
        totalRecords: this.props.ideaResults.totalResults,
      });

      // set status dropdown
      if (statusOptions.length == 0) {
        this.props.ideaResults.status.forEach((item) => {
          const option = {
            value: item,
            name: item,
            id: item,
            selected: false,
            visible: true,
          };
          statusOptions.push(option);
        });
        const allStatus = {
          value: "All",
          name: "All",
          id: "All",
          selected: false,
          visible: true,
        };
        statusOptions.unshift(allStatus);
      }

      // set category dropdown
      if (categoryOptions.length == 0) {
        for (const [id, data] of Object.entries(
          this.props.ideaResults.categories
        )) {
          const option = {
            value: data,
            name: data,
            id: id,
            selected: false,
            visible: true,
          };
          categoryOptions.push(option);
        }
      }

      //set talentOffering dropdown
      if (talentOffering.length == 0) {
        for (const [id, data] of Object.entries(
          this.props.ideaResults.talentOfferings
        )) {
          const option = {
            value: data,
            name: data,
            id: id,
            selected: false,
            visible: true,
          };
          talentOffering.push(option);
        }
      }

      //set marketOffering dropdown

      // set talent dropdown
      if (talentOptions.length == 0) {
        for (const [id, data] of Object.entries(
          this.props.ideaResults.talentGroups
        )) {
          const option = {
            value: data,
            name: data,
            id: id,
            selected: false,
            visible: true,
          };
          talentOptions.push(option);
        }
      }

      this.updateIndustriesAndDomain()
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  navigateToIdea = (id) => {
    window.location.href = "/idea/" + parseInt(id);
  };

  /**
   * change the page number from pagination
   * @param {Number} page_number current page number
   */
  pageChangeHandler = (page_number) => {
    this.clearSelectedSort();
    showLoader();
    this.setState({
      currentPage: page_number,
      pageIndex: Number(page_number - 1),
    });
    const requestPayload = {
      category: this.state.categoryNameSelected,
      categoryId: this.state.categoryIdSelected,
      status: this.state.statusSelected,
      talentGroup: this.state.marketOfferingNameSelected,
      talentGroupId: this.state.marketOfferingIdSelected,
      talentOffering: this.state.talentOfferingNameSelected,
      talentOfferingId: this.state.talentOfferingIdSelected,
      currentPage: Number(page_number - 1),
      resultsPerPage: this.state.perPageRows,
      userId: this.state.userId,
      industryId: this.state.industryIdSelected,
      sectorId: this.state.sectorIdSelected,
      domainId: this.state.domainIdSelected,
      challengeId: this.state.challengeIdSelected
    };
    this.props.getIdeaSubmissionList(requestPayload);
  };

  /**
   * change the page number list based on start and end parameters
   * @param {Number} start Start page number
   * @param {Number} end End page number
   */
  sliceChangeHandler = (start, end) => {
    this.createSlice(start, end);
  };

  /**
   * Creates page number list
   * @param {Number} start Start Page number
   * @param {Number} end End page number
   */
  createSlice = (start = 1, end = 4) => {
    let temp = [];
    for (let i = start; i < end; i++) {
      temp.push(i);
    }
    this.setState({ options: temp });
  };

  /**
   * Close if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      // this.setState({ isListOpen: false });
    }
  }

  onToggleList() {
    // this.setState({ isListOpen: !this.state.isListOpen });
  }

  setSelectedStatusItem(event, item) {
    this.setState({ statusSelectedItem: item, statusSelected: item.name });
  }

  setSelectedTalentItem(event, item) {
    this.setState({
      talentSelectedItem: item,
      talentNameSelected: item.name,
      talentIdSelected: parseInt(item.id),
    });
  }

  setSelectedTalentOfferingItem(event, item) {
    marketOffering = [];
    this.setState({
      talentOfferingSelectedItem: item,
      talentOfferingNameSelected: item.name,
      talentOfferingIdSelected: parseInt(item.id),
      talentNameSelected: "",
      talentIdSelected: 0,
      marketOfferingSelectedItem: {},
      marketOfferingNameSelected: "",
      marketOfferingIdSelected: 0,
    });
    this.talentGroup = this.state.marketOfferingNameSelected;
    this.talentGroupId = this.state.marketOfferingIdSelected;
    var marketOfferingFilter = totalMarketOfferings.filter(function (el) {
      return el.talentOfferingId == parseInt(item.id);
    });

    for (const [talentGroupId, talentGroupName] of Object.entries(
      marketOfferingFilter
    )) {
      const option = {
        value: marketOfferingFilter[talentGroupId].talentGroupName,
        name: marketOfferingFilter[talentGroupId].talentGroupName,
        id: marketOfferingFilter[talentGroupId].talentGroupId,
        selected: false,
        visible: true,
      };
      marketOffering.push(option);
    }
  }

  setSelectedMarketOfferingItem(event, item) {
    this.setState({
      marketOfferingSelectedItem: item,
      marketOfferingNameSelected: item.name,
      marketOfferingIdSelected: parseInt(item.id),
    });
  }

  setSelectedCategoryItem(event, item) {
    this.setState({
      categorySelectedItem: item,
      categoryNameSelected: item.name,
      categoryIdSelected: parseInt(item.id),
    });
  }

  setSelectedIndustryItem(event, item) {
    this.setState({
      industrySelectedItem: item,
      industryNameSelected: item.name,
      industryIdSelected: parseInt(item.id),
    })

    const sectorArr = this.state.sectors.filter(e => e.industryId == item.id)

    if(sectorArr?.length) {
      sectorArr.forEach(e => {
        const option = {
          value: e.sectorName,
          name: e.sectorName,
          id: e.sectorId,
          selected: false,
          visible: true,
          industryId: e.industryId
        };
        sectorOptions.push(option)
      })
      sectorOptions = sectorOptions.filter(e => e.id !== 1111);
    }
  }

  setSelectedSectorItem(event, item) {
    this.setState({
      sectorSelectedItem: item,
      sectorNameSelected: item.name,
      sectorIdSelected: parseInt(item.id),
    })
  }

  setSelectedDomainItem(event, item) {
    this.setState({
      domainSelectedItem: item,
      domainNameSelected: item.name,
      domainIdSelected: parseInt(item.id),
    })
  }

  setSelectedChallengeItem(event, item) {
    this.setState({
      challengeSelectedItem: item,
      challengeNameSelected: item.name,
      challengeIdSelected: parseInt(item.id),
    })
  }

  applyFilters = () => {
    showLoader();
    const requestPayload = {
      category: this.state.categoryNameSelected,
      categoryId: this.state.categoryIdSelected,
      status: this.state.statusSelected,
      talentGroup: this.state.marketOfferingNameSelected,
      talentGroupId: this.state.marketOfferingIdSelected,
      talentOffering: this.state.talentOfferingNameSelected,
      talentOfferingId: this.state.talentOfferingIdSelected,
      currentPage: this.state.pageIndex,
      resultsPerPage: this.state.perPageRows,
      userId: this.state.userId,
      industryId: this.state.industryIdSelected,
      sectorId: this.state.sectorIdSelected,
      domainId: this.state.domainIdSelected,
      challengeId: this.state.challengeIdSelected
    };
    this.clearSelectedSort();
    this.props.getIdeaSubmissionList(requestPayload);
  };

  clearFilters = () => {
    showLoader();
    marketOffering = [];
    this.setState({
      statusSelectedItem: {},
      statusSelected: "",
      categorySelectedItem: {},
      categoryNameSelected: "",
      categoryIdSelected: 0,
      talentSelectedItem: {},
      talentNameSelected: "",
      talentIdSelected: 0,
      talentOfferingSelectedItem: {},
      marketOfferingSelectedItem: {},
      talentOfferingNameSelected: "",
      marketOfferingNameSelected: "",
      talentOfferingIdSelected: 0,
      marketOfferingIdSelected: 0,

      challengeSelectedItem: {},
      challengeNameSelected: '',
      challengeIdSelected: 0,

      domainSelectedItem: {},
      domainNameSelected: '',
      domainIdSelected: 0,

      sectorSelectedItem: {},
      sectorNameSelected: '',
      sectorIdSelected: 0,

      industrySelectedItem: {},
      industryNameSelected: '',
      industryIdSelected: 0,

    });

    const requestPayload = {
      category: "",
      categoryId: 0,
      status: "",
      talentGroup: "",
      talentGroupId: 0,
      talentOffering: "",
      talentOfferingId: 0,
      currentPage: 0,
      resultsPerPage: this.state.perPageRows,
      userId: this.state.userId,
      industryId: 0,
      sectorId: 0,
      domainId: 0,
      challengeId: 0
    };
    this.setState({ pageIndex: 0, currentPage: 1 });
    this.clearSelectedSort();
    this.props.getIdeaSubmissionList(requestPayload);
  };

  clearSelectedSort = () => {
    const allUpArr = document.querySelectorAll(".arrow-up");
    const allDownArr = document.querySelectorAll(".arrow-down");
    [].forEach.call(allUpArr, function (el) {
      el.classList.remove("is-active");
    });
    [].forEach.call(allDownArr, function (el) {
      el.classList.remove("is-active");
    });
  };

  handleUpArrowClick(e, option) {
    this.clearSelectedSort();
    e.target.classList.add("is-active");
    const fieldType = option.field;
    let sortedResults = this.state.ideaItems;
    sortedResults = sortedResults.sort(function (a, b) {
      let itemA = "";
      let itemB = "";
      if (fieldType === "ideaPanelists") {
        itemA =
          a[fieldType][0] === null || a[fieldType][0] === ""
            ? (a[fieldType][0] = { firstName: "", lastName: "" })
            : a[fieldType][0].firstName.toLowerCase();
        itemB =
          b[fieldType][0] === null || b[fieldType][0] === ""
            ? (b[fieldType][0] = { firstName: "", lastName: "" })
            : b[fieldType][0].firstName.toLowerCase();
      } else {
        itemA =
          a[fieldType] === null || a[fieldType] === ""
            ? (a[fieldType] = "")
            : a[fieldType].toLowerCase();
        itemB =
          b[fieldType] === null || b[fieldType] === ""
            ? (b[fieldType] = "")
            : b[fieldType].toLowerCase();
      }
      return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
    });
    this.setState({ ideaItems: sortedResults });
  }

  handleDownArrowClick(e, option) {
    this.clearSelectedSort();
    e.target.classList.add("is-active");
    const fieldType = option.field;
    let sortedResults = this.state.ideaItems;
    sortedResults = sortedResults.sort(function (a, b) {
      let itemA = "";
      let itemB = "";
      if (fieldType === "ideaPanelists") {
        itemA =
          a[fieldType][0] === null || a[fieldType][0] === ""
            ? (a[fieldType][0] = { firstName: "", lastName: "" })
            : a[fieldType][0].firstName.toLowerCase();
        itemB =
          b[fieldType][0] === null || b[fieldType][0] === ""
            ? (b[fieldType][0] = { firstName: "", lastName: "" })
            : b[fieldType][0].firstName.toLowerCase();
      } else {
        itemA =
          a[fieldType] === null || a[fieldType] === ""
            ? (a[fieldType] = "")
            : a[fieldType].toLowerCase();
        itemB =
          b[fieldType] === null || b[fieldType] === ""
            ? (b[fieldType] = "")
            : b[fieldType].toLowerCase();
      }
      return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
    });
    this.setState({ ideaItems: sortedResults });
  }

  contextHandler(ideaId) {
    const link = `/idea/${ideaId}`;
    this.setState({ contextLink: link });
  }

  render() {
    const { userData } = this.props;
    const messageData = {
      type: "success",
      detailText:
        "Your Evaluation assesment is saved. Come back and complete it later.",
    };
    let sortOptions = [];
    if (this.state.userType === _CONSTANTS.USER.PANELIST) {
      sortOptions = [
        {
          name: "Idea",
          field: "ideaName",
        },
        {
          name: "Due In",
          field: "dueIn",
        },
        {
          name: "Category",
          field: "category",
        },
        {
          name: "Status",
          field: "status",
        },
      ];
    } else if (
      this.state.userType === _CONSTANTS.USER.PANELLEAD ||
      this.state.userType === _CONSTANTS.USER.ADMIN
    ) {
      sortOptions = [
        {
          name: "Idea",
          field: "ideaName",
        },
        {
          name: "Panelist",
          field: "ideaPanelists",
        },
        {
          name: _CONSTANTS.IDEASTATUS.MENTOR,
          field: "mentorName",
        },
        {
          name: "Overall Status",
          field: "status",
        },
      ];
    }

    return (
      <>
        {this.state.showDraftPopup && (
          <GlobalMessagePopup
            showMessagePopup={this.state.showDraftPopup}
            messageData={messageData}
          />
        )}
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"idea-submission"}
        />
        <section className="ideaAssessmentmain section-container">
          <div className="ideaAssessmentHeader">
            All Submitted Ideas<span>(please use filters as appropriate)</span>
          </div>
          <div
            ref={this.wrapperRef}
            className={classNames(
              "HeaderPart",
              this.state.isListOpen ? "is-open" : ""
            )}
          >
            {/* <div className="FilterPart" onClick={() => this.onToggleList()}>
              Filter Ideas
              <img src={downArr} alt="downArrow" />
            </div> */}
            <div className="FilterPart">Filter Ideas</div>
            <div
              className={classNames(
                "IdeasTopSection",
                this.state.isListOpen ? "show" : "d-none"
              )}
            >
              <div className="filter-group">
                <div className="filter-cont">
                  {/* <div className="filter-item">
                  <div className="filter-item_heading">Offering Portfolio</div>
                  <DropDown
                    listItemSelected={this.setSelectedTalentItem.bind(this)}
                    options={talentOptions}
                    placeholderText="Choose an Offering"
                    dropDownName="IdeaTalent"
                    isDropDownNative={false}
                    selectedItem={this.state.talentSelectedItem}
                  />
                </div> */}

                  <div className="filter-item">
                    <div className="filter-item_heading">Offering </div>
                    <DropDown
                      listItemSelected={this.setSelectedTalentOfferingItem.bind(
                        this
                      )}
                      options={talentOffering}
                      placeholderText="Choose Talent Offering"
                      dropDownName="IdeaTalent"
                      isDropDownNative={false}
                      selectedItem={this.state.talentOfferingSelectedItem}
                    />
                  </div>
                  <div className="filter-item">
                    <div className="filter-item_heading">Market Offering</div>
                    <DropDown
                      listItemSelected={this.setSelectedMarketOfferingItem.bind(
                        this
                      )}
                      options={marketOffering}
                      placeholderText="Choose Market Offering"
                      dropDownName="IdeaMarket"
                      isDropDownNative={false}
                      selectedItem={this.state.marketOfferingSelectedItem}
                    />
                  </div>
                  <div className="filter-item">
                    <div className="filter-item_heading">Status</div>
                    <DropDown
                      listItemSelected={this.setSelectedStatusItem.bind(this)}
                      options={statusOptions}
                      placeholderText="All"
                      dropDownName="IdeaStatus"
                      isDropDownNative={false}
                      selectedItem={this.state.statusSelectedItem}
                    />
                  </div>
                  <div className="filter-item">
                    <div className="filter-item_heading">Category</div>
                    <DropDown
                      listItemSelected={this.setSelectedCategoryItem.bind(this)}
                      options={categoryOptions}
                      placeholderText="Choose Category"
                      dropDownName="IdeaCategory"
                      isDropDownNative={false}
                      selectedItem={this.state.categorySelectedItem}
                    />
                  </div>
                </div>
                <div className="filter-cont">
                  {/* <div className="filter-item">
                  <div className="filter-item_heading">Offering Portfolio</div>
                  <DropDown
                    listItemSelected={this.setSelectedTalentItem.bind(this)}
                    options={talentOptions}
                    placeholderText="Choose an Offering"
                    dropDownName="IdeaTalent"
                    isDropDownNative={false}
                    selectedItem={this.state.talentSelectedItem}
                  />
                </div> */}

                  <div className="filter-item">
                    <div className="filter-item_heading">Challenge </div>
                    <DropDown
                      listItemSelected={this.setSelectedChallengeItem.bind(this)}
                      options={challengeOptions}
                      placeholderText="Choose Challenge"
                      dropDownName="IdeaChallenge"
                      isDropDownNative={false}
                      selectedItem={this.state.challengeSelectedItem}
                    />
                  </div>
                  <div className="filter-item">
                    <div className="filter-item_heading">Industry</div>
                    <DropDown
                      listItemSelected={this.setSelectedIndustryItem.bind(this)}
                      options={industryOptions}
                      placeholderText="Choose Industry"
                      dropDownName="IdeaIndustry"
                      isDropDownNative={false}
                      selectedItem={this.state.industrySelectedItem}
                    />
                  </div>
                  <div className="filter-item">
                    <div className="filter-item_heading">Sector</div>
                    <DropDown
                      listItemSelected={this.setSelectedSectorItem.bind(this)}
                      options={sectorOptions}
                      placeholderText="Choose Sector"
                      dropDownName="IdeaSector"
                      isDropDownNative={false}
                      selectedItem={this.state.sectorSelectedItem}
                    />
                  </div>
                  <div className="filter-item">
                    <div className="filter-item_heading">Domain</div>
                    <DropDown
                      listItemSelected={this.setSelectedDomainItem.bind(this)}
                      options={domainOptions}
                      placeholderText="Choose Domain"
                      dropDownName="IdeaDomain"
                      isDropDownNative={false}
                      selectedItem={this.state.domainSelectedItem}
                    />
                  </div>
                </div>
              </div>
              <div className="IdeaButton">
                <button
                  className="btn primary ApplyButton"
                  onClick={() => this.applyFilters()}
                >
                  Apply Filter
                </button>
                <button
                  className="btn secondary clearButton"
                  onClick={() => this.clearFilters()}
                >
                  clear
                </button>
              </div>
            </div>
          </div>
          {/* class to be added for TG Leader view "tgleader-view" */}
          {this.state.ideaItems !== undefined &&
            this.state.ideaItems.length > 0 && (
              <div
                className={`idea-submission_sort ${
                  this.state.userType === _CONSTANTS.USER.PANELLEAD ||
                  this.state.userType === _CONSTANTS.USER.ADMIN
                    ? "tgleader-view"
                    : ""
                }`}
              >
                <ul>
                  {sortOptions.length &&
                    sortOptions.map((option, index) => {
                      return (
                        <li key={option.name + index}>
                          {option.name}
                          <span
                            className="arrow-up"
                            onClick={(e) => this.handleUpArrowClick(e, option)}
                          ></span>
                          <span
                            className="arrow-down"
                            onClick={(e) =>
                              this.handleDownArrowClick(e, option)
                            }
                          ></span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}

          <ContextMenuWithHook
            className="ideaList-cont"
            contextLink={this.state.contextLink}
          >
            {this.state.ideaItems !== undefined &&
              this.state.ideaItems.length > 0 &&
              this.state.ideaItems.map((idea, index) => {
                return (
                  <div
                    className="ideaList-section"
                    key={index}
                    onClick={() => {
                      this.navigateToIdea(idea.ideaId);
                    }}
                    onMouseOver={() => {
                      this.contextHandler(idea.ideaId);
                    }}
                  >
                    {/* <div className="ideaName">{idea.ideaName}</div> */}
                    {idea.challengeId && <ChallengeIcon />}
                    <div className="ideaDetails">
                      <div
                        className="ideaName"
                        title={idea.ideaName ? idea.ideaName : ""}
                      >
                        {idea.ideaName && idea.ideaName.length > 100
                          ? `${idea.ideaName.substring(0, 99)} ...`
                          : idea.ideaName}
                      </div>
                      {idea.solution && (
                        <div
                          className="ideaSolution"
                          dangerouslySetInnerHTML={{ __html: idea.solution }}
                        >
                        </div>
                      )}
                      {idea.userEmail && (
                        <p className="ideaCreator">{`@${
                          idea.userEmail.split("@")[0]
                        }`}</p>
                      )}
                    </div>
                    {this.state.userType === _CONSTANTS.USER.PANELIST && (
                      <div className="ideaDueDate">
                        {idea.dueIn ? idea.dueIn.replace(/-/g, "") : "-"}
                      </div>
                    )}
                    {(this.state.userType === _CONSTANTS.USER.PANELLEAD ||
                      this.state.userType === _CONSTANTS.USER.ADMIN) && (
                      <div className="ideaPanel">
                        {idea.ideaPanelists?.length > 0 &&
                          idea.ideaPanelists[0] !== null &&
                          idea.ideaPanelists.slice(0, 2).map((panel) => {
                            return (
                              <div>
                                {panel.firstName + " " + panel.lastName}
                                <span
                                  className={checkForStatusColor(
                                    panel.status,
                                    ""
                                  )}
                                >
                                  {panel.status}
                                </span>
                              </div>
                            );
                          })}
                        {idea.ideaPanelists?.slice(2)?.length > 0 && (
                          <TooltipEx panelists={idea.ideaPanelists.slice(2)} />
                        )}
                        {(idea.ideaPanelists.length == 0 ||
                          idea.ideaPanelists[0] == null) &&
                          "-"}
                      </div>
                    )}
                    <div className="ideaCategoryName">
                      {this.state.userType === _CONSTANTS.USER.PANELLEAD ||
                      this.state.userType === _CONSTANTS.USER.ADMIN
                        ? idea.mentorName
                          ? idea.mentorName
                          : "-"
                        : idea.category
                        ? idea.category
                        : "-"}
                    </div>
                    <div
                      className={checkForStatusColor(
                        idea.status,
                        "ideaFilestatus"
                      )}
                    >
                      {idea.status}
                    </div>
                  </div>
                );
              })}
            {this.state.ideaItems !== undefined &&
              this.state.ideaItems.length === 0 && (
                <div className="no-results">No Results</div>
              )}
          </ContextMenuWithHook>

          {this.state.ideaItems && this.state.ideaItems.length > 0 && (
            <Pagination
              rowCount={this.state.perPageRows}
              startCount={this.state.pageIndex}
              productListCount={Number(
                parseInt(this.state.pageIndex + 1) *
                  parseInt(this.state.perPageRows)
              )}
              totalRecordsCount={this.state.totalRecords}
              onPageChange={this.pageChangeHandler.bind(this)}
              page={this.state.currentPage}
              slice={this.state.options}
              onSliceChange={this.sliceChangeHandler.bind(this)}
            />
          )}
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_IDEA_SUBMISSION_STATUS: state.ideas.meta.FETCH_IDEA_SUBMISSION_STATUS,
    ideaResults: state.ideas.data.ideaResults,
  };
};

const mapDispatchToProps = {
  getIdeaSubmissionList,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeaSubmissions);
