import DOMPurify from "dompurify";
import React, { Component } from "react";
import InputField from "../global/inputField";

class InpTextBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioItem: this.props.radioItem || {},
            stepID: this.props.stepID,
            inpVal: this.props.radioItem.textBoxValue || ''
        };
    }

    onTextChange(value) {
        const val = DOMPurify.sanitize(value)
        this.setState({ inpVal: val });
        let renderJSONTemp = this.state.radioItem;
        renderJSONTemp.textBoxValue = val;
        this.setState({ radioItem: renderJSONTemp });
    }

    handleBlur() {
        this.props.updateQuestionArr([{ stepID: this.state.stepID, question: this.state.radioItem.id }]);
        this.props.handleInputUpdate(this.state.inpVal, this.state.stepID);
    }

    render() {
        return (
            <div className="IdeaForm">
                <label className="IdeaLabel">{this.state.radioItem.textBoxHeader}</label>
                <InputField
                    handleChange={this.onTextChange.bind(this)}
                    placeHolder={this.state.radioItem.textBoxPlaceholder}
                    id={this.state.radioItem.id}
                    inputVal={this.state.inpVal}
                    handleBlur={this.handleBlur.bind(this)}
                />
            </div>
        );
    }
}
export default InpTextBox;