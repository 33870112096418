import { Component } from "react";
import { getNameInitials } from "../../helpers/utils";

class Ideator extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        //const { ideator: { firstName, lastName, email, numberOfIdeas }, index } = this.props;
        const { ideator: { teamName, numberOfIdeas }, index } = this.props;
        return <li className="ideator" key={index}>
            <p>{index}</p>
            <p>
                {/* <span className="userCreds">
                    {getNameInitials(`${firstName} ${lastName}`)}
                </span> */}
                {/* <span>{firstName} {lastName}</span> */}
                <span>{teamName}</span>
            </p>
            <p>{numberOfIdeas} ideas</p>
        </li>
    };
}

export default Ideator;
