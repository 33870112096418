import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkForStatusColor } from "../../helpers/utils";
import { MdAdd, MdInfo } from "react-icons/md";

class TooltipEx extends Component {

    render() {
        const { panelists } = this.props;
        return <OverlayTrigger placement="bottom"
            overlay={
                <Tooltip {...this.props} bsClass="tooltip-ex">
                    <ul className="panelists">
                        {
                            panelists?.map(panelist => {
                                return <li >
                                    {panelist.firstName + " " + panelist.lastName}
                                    <span
                                        className={checkForStatusColor(
                                            panelist.status,
                                            ""
                                        )}
                                    >
                                        {panelist.status}
                                    </span>
                                </li>
                            })
                        }
                    </ul>
                </Tooltip>
            }>
            <p className="panelist-tooltip"><MdAdd /> {panelists.length} panelists <MdInfo /></p>
        </OverlayTrigger>
    }
}

export default TooltipEx;
