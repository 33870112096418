import React, { Component } from "react";

class Rating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingVal: this.props.ratingVal || {},
            radioName: this.props.radioName || '',
            radioClass: this.props.radioClass || '',
            questionId: this.props.questionId || 0
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.ratingVal !== this.props.ratingVal) {
            this.setState({ ratingVal: this.props.ratingVal });
        }
    };

    handleRatingChange = (e) => {
        const { id, value } = e.target;
        this.props.onRatingValueChange(id, value, this.state.ratingVal.score, this.state.questionId);
    };

    render() {
        return (
            <label className={this.state.radioClass}>
                {this.state.ratingVal.helpText && (
                    <em className="label-text">{this.state.ratingVal.helpText}</em>
                )}
                {this.state.ratingVal.text}
                <input
                    type="radio"
                    name={`radio ${this.state.radioName}`}
                    id={this.state.ratingVal.id}
                    value={this.state.ratingVal.text}
                    onChange={this.handleRatingChange}
                    checked={this.state.ratingVal.selected}
                />
                <span className="checkmark"></span>
            </label>
        )
    }
}

export default Rating;