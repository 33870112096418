import React, { Component } from "react";
import QuestionLayer from "./questionLayer";
import { generateRandomKey } from "../../helpers/utils";

class ApprovalStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepDetails: this.props.stepDetails || {},
      customClass: this.props.customClass || ''
    };
  }

  /**
     * handles score change, passing to parent
     * @param {number} score individual selected score
     * @param {Number} questionId question id
     */
  onScoreChange = (score, questionId) => {
    this.props.onScoreChange(score, questionId, this.state.stepDetails.stepID);
  };

  /**
     * handles the area radio change and pass to parent
     * @param {boolean} flag flag for other area selection
     * @param {Number} questionCount question count for ech selection
     */
  handleOtherArea = (flag, questionCount) => {
    this.props.onHandleOtherArea(flag, questionCount);
  }

  /**
     * handles score reset and pass to parent
     */
  handleScoreReset = () => {
    this.props.onScoreReset();
  };

  /**
   * handles question change and pass to parent
   * @param {array} arr score and question array
   */
  updateQuestionArr = (arr) => {
    this.props.updateQuestionArr(arr);
  };

  selectedAnswerDetails = (questionId, selectedId, score, stepId) => {
    this.props.questionAnswerDetails(questionId, selectedId, score, stepId);
  }

  saveSubQDetails = (questionId, selectedId, customText, score, parentQuestionId, parentSelectedId, stepId, subCustomText) => {
    this.props.saveSubQDetails(questionId, selectedId, customText, score, parentQuestionId, parentSelectedId, stepId, subCustomText);
  }

  handleInputUpdate = (value, stepId, questionId) => {
    this.props.handleInputUpdate(value, stepId, questionId);
  };

  render() {
    return (
      <>
        <div className="ideaL">
          <div className="question-type">{this.state.stepDetails.stepName}</div>
          <div className="question-cont">
            {this.state.stepDetails.stepHeader !== '' && (
              <div className="question-title">{this.state.stepDetails.stepHeader}</div>
            )}
            {this.state.stepDetails.stepSubHeader !== '' && (
              <div className="question-subtitle">{this.state.stepDetails.stepSubHeader}</div>
            )}
            {this.state.stepDetails.questions.map((question) => {
              return (
                <QuestionLayer
                  updateQuestionArr={this.updateQuestionArr.bind(this)}
                  stepID={this.state.stepDetails.stepID}
                  handleScoreReset={this.handleScoreReset.bind(this)}
                  handleOtherAreaApproval={this.handleOtherArea.bind(this)}
                  questionDetail={question}
                  key={generateRandomKey()}
                  onScoreChange={this.onScoreChange.bind(this)}
                  customClass={this.state.customClass}
                  selectedAnswerDetails={this.selectedAnswerDetails.bind(this)}
                  saveSubQDetails={this.saveSubQDetails.bind(this)}
                  handleInputUpdate={this.handleInputUpdate.bind(this)}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
export default ApprovalStep; 