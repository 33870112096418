import { combineReducers } from "redux";
import {
  FETCH_IDEAS_FRM_ARND_PENDING,
  FETCH_IDEAS_FRM_ARND_FULFILLED,
  FETCH_IDEAS_FRM_ARND_REJECTED,
  FETCH_IDEA_DETAILS_PENDING,
  FETCH_IDEA_DETAILS_FULFILLED,
  FETCH_IDEA_DETAILS_REJECTED,
  FETCH_GLOBAL_RESULTS_PENDING,
  FETCH_GLOBAL_RESULTS_FULFILLED,
  FETCH_GLOBAL_RESULTS_REJECTED,
  FETCH_AUTOCOMPLETE_RESULTS_PENDING,
  FETCH_AUTOCOMPLETE_RESULTS_FULFILLED,
  FETCH_AUTOCOMPLETE_RESULTS_REJECTED,
  INVEST_IN_IDEA_PENDING,
  INVEST_IN_IDEA_FULFILLED,
  INVEST_IN_IDEA_REJECTED,
  FETCH_IDEA_INVESTMENT_DETAILS_PENDING,
  FETCH_IDEA_INVESTMENT_DETAILS_FULFILLED,
  FETCH_IDEA_INVESTMENT_DETAILS_REJECTED,
  FETCH_CREATE_IDEA_PENDING,
  FETCH_CREATE_IDEA_FULFILLED,
  FETCH_CREATE_IDEA_REJECTED,
  FETCH_CHALLENGE_IDEA_PENDING,
  FETCH_CHALLENGE_IDEA_FULFILLED,
  FETCH_CHALLENGE_IDEA_REJECTED,
  FETCH_DRAFT_IDEA_PENDING,
  FETCH_DRAFT_IDEA_FULFILLED,
  FETCH_DRAFT_IDEA_REJECTED,
  ADD_IDEA_DETAILS,
  ADD_USER_IDEA_TAGS,
  ADD_MARKET_DETAILS,
  ADD_IDEA_TYPE,
  ADD_PROBLEM_TYPE,
  ADD_TOOLS,
  ADD_TEAM_NAME,
  ADD_DRAFT_ID,
  ADD_TALENT_DETAILS,
  ADD_INDUSTRY_DETAILS,
  ADD_DOMAIN_DETAILS,
  FETCH_SUBMITTED_IDEAS_PENDING,
  FETCH_SUBMITTED_IDEAS_FULFILLED,
  FETCH_SUBMITTED_IDEAS_REJECTED,
  FETCH_IDEA_SUBMISSION_PENDING,
  FETCH_IDEA_SUBMISSION_FULFILLED,
  FETCH_IDEA_SUBMISSION_REJECTED,
  FETCH_EVALUATE_DETAILS_PENDING,
  FETCH_EVALUATE_DETAILS_FULFILLED,
  FETCH_EVALUATE_DETAILS_REJECTED,
  FETCH_REVIEW_DETAILS_PENDING,
  FETCH_REVIEW_DETAILS_FULFILLED,
  FETCH_REVIEW_DETAILS_REJECTED,
  GET_MEMBER_DETAILS_PENDING,
  GET_MEMBER_DETAILS_FULFILLED,
  GET_MEMBER_DETAILS_REJECTED,
  ADD_OFFERING_PORTFOLIO,
  ADD_TALENT_OFFERING,
  ADD_ARCHETYPE,
  ADD_SECTOR,
} from "../action-types/ideas";
import { PENDING, FULFILLED, FAILED, DEFAULT } from "../../helpers/constants";

const initialMetaState = {
  FETCH_IDEAS_FRM_ARND_STATUS: "DEFAULT",
  FETCH_IDEA_DETAILS_STATUS: "DEFAULT",
  FETCH_GLOBAL_RESULTS_STATUS: "DEFAULT",
  FETCH_AUTOCOMPLETE_RESULTS_STATUS: "DEFAULT",
  INVEST_IN_IDEA_STATUS: "DEFAULT",
  FETCH_IDEA_INVESTMENT_DETAILS_STATUS: "DEFAULT",
  FETCH_CREATE_IDEA_STATUS: DEFAULT,
  FETCH_CHALLENGE_IDEA_STATUS: DEFAULT,
  FETCH_DRAFT_IDEA_STATUS: DEFAULT,
  FETCH_SUBMITTED_IDEAS_STATUS: DEFAULT,
  FETCH_IDEA_SUBMISSION_STATUS: DEFAULT,
  FETCH_EVALUATE_DETAILS_STATUS: DEFAULT,
  FETCH_REVIEW_DETAILS_STATUS: DEFAULT,
  GET_MEMBER_DETAILS_STATUS: DEFAULT,
};

const initialDataState = {
  ideasFromAround: {
    ideasFromAroundTags: [],
    ideasFromAroundIdeas: [],
    totalIdeas: [],
  },
  ideaDetails: {},
  globalAutoCompleteResults: [],
  investInIdeaStatus: "",
  investedIdeaSummaryDetails: {
    amountInvested: 0,
    ideaInvestedAmount: 0,
    balanceAmount: 0,
  },
  searchResults: {},
  autocompleteResults: [],
  createIdeaDetails: {},
  draftIdeas: {},
  submittedIdeas: {},
  ideaFromChallenge: {},
  ideaSavedDetails: {},
  ideaResults: {},
  evaluateDetails: {},
  reviewDetails: {},
  membersDetails: {},
};

function metaReducer(state = initialMetaState, action) {
  switch (action.type) {
    case FETCH_IDEAS_FRM_ARND_PENDING:
      return { ...state, FETCH_IDEAS_FRM_ARND_STATUS: PENDING };
    case FETCH_IDEAS_FRM_ARND_FULFILLED:
      return { ...state, FETCH_IDEAS_FRM_ARND_STATUS: FULFILLED };
    case FETCH_IDEAS_FRM_ARND_REJECTED:
      return { ...state, FETCH_IDEAS_FRM_ARND_STATUS: FAILED };
    case FETCH_IDEA_DETAILS_PENDING:
      return { ...state, FETCH_IDEA_DETAILS_STATUS: PENDING };
    case FETCH_IDEA_DETAILS_FULFILLED:
      return { ...state, FETCH_IDEA_DETAILS_STATUS: FULFILLED };
    case FETCH_IDEA_DETAILS_REJECTED:
      return { ...state, FETCH_IDEA_DETAILS_STATUS: FAILED };
    case FETCH_GLOBAL_RESULTS_PENDING:
      return { ...state, FETCH_GLOBAL_RESULTS_STATUS: PENDING };
    case FETCH_GLOBAL_RESULTS_FULFILLED:
      return { ...state, FETCH_GLOBAL_RESULTS_STATUS: FULFILLED };
    case FETCH_GLOBAL_RESULTS_REJECTED:
      return { ...state, FETCH_GLOBAL_RESULTS_STATUS: FAILED };
    case FETCH_AUTOCOMPLETE_RESULTS_PENDING:
      return { ...state, FETCH_AUTOCOMPLETE_RESULTS_STATUS: PENDING };
    case FETCH_AUTOCOMPLETE_RESULTS_FULFILLED:
      return { ...state, FETCH_AUTOCOMPLETE_RESULTS_STATUS: FULFILLED };
    case FETCH_AUTOCOMPLETE_RESULTS_REJECTED:
      return { ...state, FETCH_AUTOCOMPLETE_RESULTS_STATUS: FAILED };
    case INVEST_IN_IDEA_PENDING:
      return { ...state, INVEST_IN_IDEA_STATUS: PENDING };
    case INVEST_IN_IDEA_FULFILLED:
      return { ...state, INVEST_IN_IDEA_STATUS: FULFILLED };
    case INVEST_IN_IDEA_REJECTED:
      return { ...state, INVEST_IN_IDEA_STATUS: FAILED };
    case FETCH_IDEA_INVESTMENT_DETAILS_PENDING:
      return { ...state, FETCH_IDEA_INVESTMENT_DETAILS_STATUS: PENDING };
    case FETCH_IDEA_INVESTMENT_DETAILS_FULFILLED:
      return { ...state, FETCH_IDEA_INVESTMENT_DETAILS_STATUS: FULFILLED };
    case FETCH_IDEA_INVESTMENT_DETAILS_REJECTED:
      return { ...state, FETCH_IDEA_INVESTMENT_DETAILS_STATUS: FAILED };
    case FETCH_CREATE_IDEA_PENDING:
      return { ...state, FETCH_CREATE_IDEA_STATUS: PENDING };
    case FETCH_CREATE_IDEA_FULFILLED:
      return { ...state, FETCH_CREATE_IDEA_STATUS: FULFILLED };
    case FETCH_CREATE_IDEA_REJECTED:
      return { ...state, FETCH_CREATE_IDEA_STATUS: FAILED };
    case FETCH_DRAFT_IDEA_PENDING:
      return { ...state, FETCH_DRAFT_IDEA_STATUS: PENDING };
    case FETCH_DRAFT_IDEA_FULFILLED:
      return { ...state, FETCH_DRAFT_IDEA_STATUS: FULFILLED };
    case FETCH_DRAFT_IDEA_REJECTED:
      return { ...state, FETCH_DRAFT_IDEA_STATUS: FAILED };
    case FETCH_CHALLENGE_IDEA_PENDING:
      return { ...state, FETCH_CHALLENGE_IDEA_STATUS: PENDING };
    case FETCH_CHALLENGE_IDEA_FULFILLED:
      return { ...state, FETCH_CHALLENGE_IDEA_STATUS: FULFILLED };
    case FETCH_CHALLENGE_IDEA_REJECTED:
      return { ...state, FETCH_CHALLENGE_IDEA_STATUS: FAILED };
    case FETCH_SUBMITTED_IDEAS_PENDING:
      return { ...state, FETCH_SUBMITTED_IDEAS_STATUS: PENDING };
    case FETCH_SUBMITTED_IDEAS_FULFILLED:
      return { ...state, FETCH_SUBMITTED_IDEAS_STATUS: FULFILLED };
    case FETCH_SUBMITTED_IDEAS_REJECTED:
      return { ...state, FETCH_SUBMITTED_IDEAS_STATUS: FAILED };
    case FETCH_IDEA_SUBMISSION_PENDING:
      return { ...state, FETCH_IDEA_SUBMISSION_STATUS: PENDING };
    case FETCH_IDEA_SUBMISSION_FULFILLED:
      return { ...state, FETCH_IDEA_SUBMISSION_STATUS: FULFILLED };
    case FETCH_IDEA_SUBMISSION_REJECTED:
      return { ...state, FETCH_IDEA_SUBMISSION_STATUS: FAILED };
    case FETCH_EVALUATE_DETAILS_PENDING:
      return { ...state, FETCH_EVALUATE_DETAILS_STATUS: PENDING };
    case FETCH_EVALUATE_DETAILS_FULFILLED:
      return { ...state, FETCH_EVALUATE_DETAILS_STATUS: FULFILLED };
    case FETCH_EVALUATE_DETAILS_REJECTED:
      return { ...state, FETCH_EVALUATE_DETAILS_STATUS: FAILED };
    case FETCH_REVIEW_DETAILS_PENDING:
      return { ...state, FETCH_REVIEW_DETAILS_STATUS: PENDING };
    case FETCH_REVIEW_DETAILS_FULFILLED:
      return { ...state, FETCH_REVIEW_DETAILS_STATUS: FULFILLED };
    case FETCH_REVIEW_DETAILS_REJECTED:
      return { ...state, FETCH_REVIEW_DETAILS_STATUS: FAILED };
    case GET_MEMBER_DETAILS_PENDING:
      return { ...state, GET_MEMBER_DETAILS_STATUS: PENDING };
    case GET_MEMBER_DETAILS_FULFILLED:
      return { ...state, GET_MEMBER_DETAILS_STATUS: FULFILLED };
    case GET_MEMBER_DETAILS_REJECTED:
      return { ...state, GET_MEMBER_DETAILS_STATUS: FAILED };
    default:
      return state;
  }
}

const findSelected = (data, itemName) => {
  if(itemName === 'offeringPortfolios'){
    return data[itemName]?.[0];
  }
  return data[itemName]?.find(item => item?.selected === true);
}

const saveDraftDetails = (data) => {
  let savedItem = {};
  savedItem["challengeID"] = data.challengeID;
  savedItem["ideaDescription"] = data.ideaDescription;
  savedItem["talentGroup"] = {};
  const talentItem = data.talentGroups.find((element) => {
    return element.selected === true;
  });
  savedItem["talentGroup"]["talentGroupId"] = talentItem
    ? talentItem.talentGroupId
    : "";
  savedItem["talentGroup"]["talentGroupName"] = talentItem
    ? talentItem.talentGroupName
    : "";
  savedItem["marketResearch"] = data.marketResearch;
  const typeItem = data.ideaTypes.find((element) => {
    return element.selected === true;
  });
  savedItem["offeringPortfolio"] = findSelected(data, "offeringPortfolios") || {};
  savedItem["talentOffering"] = findSelected(data, "talentOfferings") || {};
  savedItem["archeType"] = findSelected(data, "archeTypes") || {};
  savedItem["industry"] = findSelected(data, "industries") || {};
  savedItem["domain"] = findSelected(data, "domains") || {};
  savedItem["sector"] = findSelected(data, "sectors") || {};
  savedItem["ideaTypeID"] = typeItem ? typeItem.ideaTypeID : "";
  savedItem["ideaType"] = {};
  savedItem["ideaType"]["defaultIdeaType"] = true;
  savedItem["ideaType"]["ideaTypeID"] = typeItem ? typeItem.ideaTypeID : "";
  savedItem["ideaType"]["selected"] = true;
  savedItem["ideaType"]["text"] = typeItem ? typeItem.text : "";
  savedItem["tools"] = data.tools;
  savedItem["teamName"] = data.teamName;

  const problemTypeItem = data.problemTypes.find((element) => {
    return element.selected === true;
  });
  savedItem["problemTypeID"] = problemTypeItem ? problemTypeItem.problemTypeID : '';
  savedItem["problemType"] = {};
  savedItem["problemType"]["defaultProblemType"] = problemTypeItem ? problemTypeItem.defaultProblemType : true;
  savedItem["problemType"]["problemTypeID"] = problemTypeItem ? problemTypeItem.problemTypeID : '';
  savedItem["problemType"]["selected"] = problemTypeItem ? problemTypeItem.selected : true;
  savedItem["problemType"]["text"] = problemTypeItem ? problemTypeItem.text : '';

  savedItem["keywords"] = {};
  savedItem["tags"] = [];
  savedItem["keywords"]["tagID"] = [];
  data.keywords.userSelectedTags.forEach((item) => {
    const option = {
      imageURL: "",
      select: true,
      tagID: item.tagID,
      tagName: item.tagName,
    };
    savedItem["tags"].push(option);
    savedItem["keywords"]["tagID"].push(item.tagID);
  });
  return savedItem;
};

const handleBasicDetailChange = (state, action) => {
  const { obj, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["ideaDescription"] = obj;
  return { ...state };
};

const handleOfferingPortfolioChange = (state, action) => {
  const { item, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus && ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  const offeringPortfolio = {};
  offeringPortfolio.offeringPortfolioId = item.value;
  offeringPortfolio.offeringPortfolioName = item.name;
  state.ideaSavedDetails["offeringPortfolio"] = offeringPortfolio;
  return { ...state };
};

const handleTalentOfferingChange = (state, action) => {
  const { item, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus && ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  const talentOffering = {};
  talentOffering.talentOfferingId = item.value;
  talentOffering.talentOfferingName = item.name;
  talentOffering.offeringportfolioId = item.parentId;
  state.ideaSavedDetails["talentOffering"] = talentOffering;
  return { ...state };
};

const handleTalentChange = (state, action) => {
  const { item, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["talentGroup"] = {};
  state.ideaSavedDetails["talentGroup"]["talentGroupId"] = item.value;
  state.ideaSavedDetails["talentGroup"]["talentGroupName"] = item.name;
  state.ideaSavedDetails["talentGroup"]["talentOfferingId"] = item.parentId;
  return { ...state };
};

const handleArchetypeChange = (state, action) => {
  const { item, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus && ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  const archeType = {};
  archeType.archetypesId = item.value;
  archeType.archetypesName = item.name;
  archeType.talentGroupId = item.parentId;
  state.ideaSavedDetails["archeType"] = archeType;
  return { ...state };
};

const handleIndustryChange = (state, action) => {
  const { item, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus && ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["industry"] = {};
  state.ideaSavedDetails["industry"]["industryId"] = item.value;
  state.ideaSavedDetails["industry"]["industryName"] = item.name;
  return { ...state };
};

const handleDomainChange = (state, action) => {
  const { item, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus && ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["domain"] = {};
  state.ideaSavedDetails["domain"]["domainId"] = item.value;
  state.ideaSavedDetails["domain"]["domainName"] = item.name;
  return { ...state };
};


const handleSectorChange = (state, action) => {
  const { item, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus && ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["sector"] = {};
  const sector = {};
  sector.industryId = item.parentId;
  sector.sectorId = item.value;
  sector.sectorName = item.name;
  state.ideaSavedDetails["sector"] = sector;
  return { ...state };
};

const handleTagsChange = (state, action) => {
  const { arr, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["keywords"] = {};
  state.ideaSavedDetails["tags"] = [];
  state.ideaSavedDetails["keywords"]["tagID"] = [];
  arr.forEach((item) => {
    const option = {
      imageURL: "",
      select: true,
      tagID: item.tagID,
      tagName: item.tag,
    };
    state.ideaSavedDetails["tags"].push(option);
    state.ideaSavedDetails["keywords"]["tagID"].push(item.tagID);
  });
  return { ...state };
};

const handleIdeaTypeChange = (state, action) => {
  const { id, value, ideaStatus, draftId, userEmail, teamName } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["ideaTypeID"] = parseInt(id);
  state.ideaSavedDetails["ideaType"] = {};
  state.ideaSavedDetails["ideaType"]["defaultIdeaType"] = true;
  state.ideaSavedDetails["ideaType"]["ideaTypeID"] = parseInt(id);
  state.ideaSavedDetails["ideaType"]["selected"] = true;
  state.ideaSavedDetails["ideaType"]["text"] = value;
  state.ideaSavedDetails["team"] = teamName;
  return { ...state };
};

const handleProblemTypeChange = (state, action) => {
  const { value, ideaStatus, draftId, typeId, userEmail, teamName } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["problemTypeID"] = parseInt(typeId);
  state.ideaSavedDetails["problemType"] = {};
  state.ideaSavedDetails["problemType"]["defaultProblemType"] = true;
  state.ideaSavedDetails["problemType"]["problemTypeID"] = parseInt(typeId);
  state.ideaSavedDetails["problemType"]["selected"] = true;
  state.ideaSavedDetails["problemType"]["text"] = value;
  state.ideaSavedDetails["team"] = teamName;
  return { ...state };
};

const handleTools = (state, action) => {
  const { value, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["tools"] = value;
  return { ...state };
}

const handleTeamName = (state, action) => {
  const { value, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["team"] = value;
  return { ...state };
}

const handleDraftId = (state, action) => {
  const { id, userEmail } = action;
  state.ideaSavedDetails["ideaID"] = id;
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  return { ...state };
};

const handleMarketChange = (state, action) => {
  const { arr, ideaStatus, draftId, userEmail } = action;
  if (ideaStatus.toLowerCase() === "draft") {
    state.ideaSavedDetails["ideaID"] = draftId;
  }
  state.ideaSavedDetails["createdBy"] = {};
  state.ideaSavedDetails["createdBy"]["email"] = userEmail;
  state.ideaSavedDetails["marketResearch"] = arr;
  return { ...state };
};

function dataReducer(state = initialDataState, action) {
  switch (action.type) {
    case FETCH_IDEAS_FRM_ARND_FULFILLED:
      return {
        ...state,
        ideasFromAround: {
          ideasFromAroundTags: action.payload.data.tags,
          ideasFromAroundIdeas: action.payload.data.ideas,
          totalIdeas: action.payload.data.totalIdeas,
        },
      };
    case FETCH_IDEA_DETAILS_FULFILLED:
      return {
        ...state,
        ideaDetails: action.payload.data,
      };
    case FETCH_IDEA_DETAILS_REJECTED:
      return {
        ...state,
        ideaDetails: {},
      };
    case FETCH_GLOBAL_RESULTS_FULFILLED:
      return {
        ...state,
        searchResults: action.payload.data,
      };
    case INVEST_IN_IDEA_FULFILLED:
      return {
        ...state,
        investInIdeaStatus: action.payload.data,
      };
    case FETCH_IDEA_INVESTMENT_DETAILS_FULFILLED:
      return {
        ...state,
        investedIdeaSummaryDetails: {
          balanceAmount: action.payload.data.balanceAmount,
          ideaInvestedAmount: action.payload.data.ideaInvestedAmount,
          amountInvested: action.payload.data.amountInvested,
        },
      };
    case FETCH_AUTOCOMPLETE_RESULTS_FULFILLED:
      return {
        ...state,
        autocompleteResults: action.payload.data,
      };
    case FETCH_CREATE_IDEA_FULFILLED:
      return {
        ...state,
        createIdeaDetails: action.payload.data,
        ideaSavedDetails:
          action.payload.data.ideaStatus !== null &&
            action.payload.data.ideaStatus.toLowerCase() === "draft"
            ? saveDraftDetails(action.payload.data)
            : {},
      };
    case FETCH_CHALLENGE_IDEA_FULFILLED:
      return {
        ...state,
        ideaFromChallenge: action.payload,
      };
    case FETCH_SUBMITTED_IDEAS_FULFILLED:
      return {
        ...state,
        submittedIdeas: action.payload.data,
      };
    case FETCH_DRAFT_IDEA_FULFILLED:
      return {
        ...state,
        draftIdeas: action.payload.data,
      };
    case FETCH_IDEA_SUBMISSION_FULFILLED:
      return {
        ...state,
        ideaResults: action.payload.data,
      };
    case FETCH_EVALUATE_DETAILS_FULFILLED:
      return {
        ...state,
        evaluateDetails: action.payload.data,
      };
    case FETCH_REVIEW_DETAILS_FULFILLED:
      return {
        ...state,
        reviewDetails: action.payload.data,
      };
    case GET_MEMBER_DETAILS_FULFILLED:
      return {
        ...state,
        membersDetails: action.payload.data,
      };
    case ADD_IDEA_DETAILS:
      return handleBasicDetailChange(state, action);
    case ADD_TALENT_DETAILS:
      return handleTalentChange(state, action);
    case ADD_INDUSTRY_DETAILS:
      return handleIndustryChange(state, action);
    case ADD_DOMAIN_DETAILS:
      return handleDomainChange(state, action);
    case ADD_USER_IDEA_TAGS:
      return handleTagsChange(state, action);
    case ADD_MARKET_DETAILS:
      return handleMarketChange(state, action);
    case ADD_IDEA_TYPE:
      return handleIdeaTypeChange(state, action);
    case ADD_PROBLEM_TYPE:
      return handleProblemTypeChange(state, action);
    case ADD_TOOLS:
      return handleTools(state, action);
    case ADD_TEAM_NAME:
      return handleTeamName(state, action);
    case ADD_DRAFT_ID:
      return handleDraftId(state, action);
    case ADD_OFFERING_PORTFOLIO:
      return handleOfferingPortfolioChange(state, action);
    case ADD_TALENT_OFFERING:
      return handleTalentOfferingChange(state, action);
    case ADD_ARCHETYPE:
      return handleArchetypeChange(state, action);
    case ADD_SECTOR:
      return handleSectorChange(state, action);
    default:
      return state;
  }
}

export default combineReducers({
  meta: metaReducer,
  data: dataReducer,
});
