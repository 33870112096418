import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from 'react-redux'
import { Route } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'
import { store, history, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'


const provider = (
    <Provider store={store}>

        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <Route path="/" component={App} />
            </ConnectedRouter>
        </PersistGate>

    </Provider>
);

ReactDOM.render(provider, document.getElementById('root'));
//registerServiceWorker();
document.addEventListener('click', () => {
    const elements = document.getElementsByClassName('context-menu');
    if(elements?.length > 0){
        const contextMenuElements = [...elements];
        contextMenuElements.forEach(ele => {ele.style.display = 'none';})
    }
});

console.log('running with env:', process.env.REACT_APP_ENV);