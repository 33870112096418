
export const GET_TOP_IDEATORS_PENDING = 'GET_TOP_IDEATORS_PENDING';
export const GET_TOP_IDEATORS_FULFILLED = 'GET_TOP_IDEATORS_FULFILLED';
export const GET_TOP_IDEATORS_REJECTED = 'GET_TOP_IDEATORS_REJECTED';

export const GET_MOST_LIKED_IDEAS_PENDING = 'GET_MOST_LIKED_IDEAS_PENDING';
export const GET_MOST_LIKED_IDEAS_FULFILLED = 'GET_MOST_LIKED_IDEAS_FULFILLED';
export const GET_MOST_LIKED_IDEAS_REJECTED = 'GET_MOST_LIKED_IDEAS_REJECTED';

export const GET_MOST_INVESTED_IDEAS_PENDING = 'GET_MOST_INVESTED_IDEAS_PENDING';
export const GET_MOST_INVESTED_IDEAS_FULFILLED = 'GET_MOST_INVESTED_IDEAS_FULFILLED';
export const GET_MOST_INVESTED_IDEAS_REJECTED = 'GET_MOST_INVESTED_IDEAS_REJECTED';