import React, { useEffect, useState } from 'react';
import PaginationCount from './paginationCount';
import rightArr from "../../img/right-arr-sm-white.svg";

const Pagination = ({
    rowCount,
    startCount,
    productListCount,
    totalRecordsCount,
    onPageChange,
    page,
    slice,
    onSliceChange
}) => {
    const [currentPage, setCurrentPage] = useState(page);
    let pageCount = Math.ceil(totalRecordsCount / rowCount);

    let options = [];
    if (pageCount <= 3 && pageCount !== 0) {
        for (let i = 1; i <= pageCount; i++) {
            options.push(i);
        }
    }

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    const changePageHandler = event => {
        event.preventDefault();
        const currentPageNumber = Number(event.target.textContent);
        setCurrentPage(currentPageNumber);
        onPageChange(currentPageNumber);
    };

    const previousPageHandler = event => {
        event.preventDefault();
        if (currentPage === slice[0]) {
            if (currentPage - 2 <= 0) {
                onSliceChange(1, 4);
            } else {
                onSliceChange(currentPage - 3, currentPage);
            }
        }
        setCurrentPage(prevState => prevState - 1);
        onPageChange(currentPage - 1);
    };

    const nextPageHandler = event => {
        event.preventDefault();
        if (currentPage + 2 > pageCount) {
            onSliceChange(pageCount, pageCount + 1);
        } else if (currentPage === slice[2] && currentPage < pageCount) {
            onSliceChange(currentPage + 1, currentPage + 4);
        }
        setCurrentPage(prevState => prevState + 1);
        onPageChange(currentPage + 1);
    };

    return (
        <div className="pagination-container">
            <PaginationCount
                startCount={startCount}
                productListCount={productListCount}
                totalRecordsCount={totalRecordsCount}
                rowCount={rowCount}
            />
            {pageCount > 3 && (
                <ul className="pagination">
                    {/* {currentPage > 1 && ( */}
                    <li className="prev-arrow">
                        <button
                            className="prev-enabled"
                            href="#"
                            tabIndex="-1"
                            aria-disabled="true"
                            onClick={previousPageHandler}
                            disabled={currentPage <= 1}
                        >
                            <img src={rightArr} alt="prev" />
                        </button>
                    </li>
                    {/* )} */}
                    {slice[2] !== undefined && slice[2] > 3 ? (
                        <li>
                            <span>&hellip;</span>
                        </li>
                    ) : (
                        ''
                    )}
                    {slice.map((id, index) => (
                        <li key={id + index}>
                            <a
                                className={`page-num ${currentPage === id ? 'active' : ''}`}
                                href=""
                                onClick={changePageHandler}
                            >
                                {id}
                            </a>
                        </li>
                    ))}
                    {slice[2] !== undefined && slice[2] !== pageCount ? (
                        <li>
                            <span>&hellip;</span>
                        </li>
                    ) : (
                        ''
                    )}
                    {/* {currentPage !== pageCount && ( */}
                    <li>
                        <button className="next-enabled" onClick={nextPageHandler} href="#" disabled={!(currentPage !== pageCount)}>
                            <img src={rightArr} alt="next" />
                        </button>
                    </li>
                    {/* )} */}
                </ul>
            )}
            {pageCount <= 3 && pageCount > 1 && (
                <ul className="pagination">
                    {/* {currentPage > 1 && ( */}
                    <li className="prev-arrow">
                        <button
                            className="prev-enabled"
                            href="#"
                            tabIndex="-1"
                            aria-disabled="true"
                            onClick={previousPageHandler}
                            disabled={currentPage <= 1}
                        >
                            <img src={rightArr} alt="prev" />
                        </button>
                    </li>
                    {/* )} */}
                    {options.map((id, index) => (
                        <li key={id + index}>
                            <a
                                className={`page-num ${currentPage === id ? 'active' : ''}`}
                                href=""
                                onClick={changePageHandler}
                            >
                                {id}
                            </a>
                        </li>
                    ))}
                    {/* {currentPage !== pageCount && ( */}
                    <li>
                        <button className="next-enabled" onClick={nextPageHandler} href="#" disabled={!(currentPage !== pageCount)}>
                            <img src={rightArr} alt="next" />
                        </button>
                    </li>
                    {/* )} */}
                </ul>
            )}
        </div>
    );
};

export default Pagination;
