import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Header from "../components/global/header";
import Breadcrumbs from "../components/global/breadcrumbs";
import AddDetails from "../components/ideation/addDetails";
import "../css/pages/createIdea.scss";
import {
  ideaFileUpload,
  doFileDelete,
  fetchIdeaAttachmentsList,
  getTeamNameForChallenge,
} from "../redux/actions/utilities";
import {
  getCreationIdeaDetails,
  fetchSavedDraft,
  fetchChallengeIdea,
  updateIdeaDetail,
  updateSelectedKeywords,
  updateMarketdetails,
  updateIdeaType,
  updateProblemType,
  updateTools,
  updateTeamName,
  setIdeaDraftId,
  saveOfferingPortfolio,
  saveTalentOffering,
  saveTalentGrp,
  saveArchetype,
  saveIndustryGrp,
  saveDomainGrp,
  saveSector,
} from "../redux/actions/ideas";
import axios from "axios";
import config from "../helpers/api-urls";
import seedIcon from "../img/seedIcon.svg";
import closeIcon from "../img/close.svg";
import { _CONSTANTS } from "../helpers/constants";
import {
  showLoader,
  hideLoading,
} from "../helpers/utils";

class CreateIdea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membersDetails: {},
      showRewardsScreen: false,
      draftId: 0,
      challengeID: 0,
      previousview: "",
      currentView: 1,
      leftArrowDisable: true,
      rightArrowDisable: true,
      // leftArrowVisible: false,
      // rightArrowVisible: true,
      disableSubmit: true,
      basicIdeaData: {},
      attachFileUrl: [],
      isChallengeIdea: false,
      ideaTypeValue: "",
      problemTypeValue: "",
      hideHeaderCrossBtn: true,
      ideaId: "",
      tools: "",
      isAddDetail: false,
      isAlreadyRegistered: false,
      teamMembersIds: [],
      teamName: "",
      getTeamNameCalled: false
    };
  }

  componentDidMount() {
    showLoader();
    this.setState({ basicIdeaData: {} });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("draft")) {
      this.setState({ draftId: parseInt(urlParams.get("draft")) });
      this.props.fetchSavedDraft(
        parseInt(urlParams.get("draft")),
        this.props.userData.email
      );
      this.props.fetchIdeaAttachmentsList(
        parseInt(urlParams.get("draft")),
        "IDEA"
      );
      setTimeout(() => {
        this.checkForValidType();
        //this.setState({ ideaTypeValue: "", problemTypeValue: "" });
      }, 800);
    } else if (urlParams.has("challenge")) {
      const payload = {
        challengeID: parseInt(urlParams.get("challenge")),
        createdBy: {
          email: this.props.userData.email,
        },
      };
      this.setState({
        challengeID: parseInt(urlParams.get("challenge")),
        isChallengeIdea: true,
      });
      this.props?.fetchChallengeIdea(payload);
      this.setState({ rightArrowDisable: true });
      setTimeout(() => {
        this.setState({ rightArrowDisable: true });
        this.setState({ ideaTypeValue: "", problemTypeValue: "" });
      }, 200);
    } else {
      this.setState({ draftId: 0 });
      this.props.getCreationIdeaDetails(this.props.userData.email);
      if (Object.keys(this.state.basicIdeaData).length > 0) {
        this.setArrowData("");
      }
      setTimeout(() => {
        this.setState({ rightArrowDisable: true });
        this.setState({ ideaTypeValue: "", problemTypeValue: "" });
      }, 200);
    }

    async function getTeamName(challengeId, email, that) {
      try {
        const res = await getTeamNameForChallenge(challengeId, email);
        const response = await res.payload; // res.payload is also promise, that's why we have await keyword here again
        if('data' in response) {
          setTimeout(() => {that.setState({getTeamNameCalled: true})}, 250)
        }
        if (response?.data?.length) {
          that.setState({
            isAlreadyRegistered: !!response?.data?.length,
            teamMembersIds: [...response.data],
            teamName: response.data[0],
          });
        }
      } catch (err) {
        setTimeout(() => {that.setState({getTeamNameCalled: true})}, 250)
        console.log("[Err]: ", err);
      }
    }
    const challengeID = this.props.ideaSavedDetails.challengeID || this.state.challengeID || parseInt(urlParams.get("challenge"));
    if (challengeID !== 0 && !isNaN(challengeID)) {
      getTeamName(
        challengeID,
        this.props.userData.email,
        this
      );
    }

    // this.getTeamMembersDetailsForDraft(this.state.draftId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.createIdeaDetails !== this.props.createIdeaDetails) {
      this.setState({
        basicIdeaData: this.props.createIdeaDetails,
        challengeID: this.props.createIdeaDetails.challengeID,
        isChallengeIdea: !!this.props.createIdeaDetails.challengeID,
      });
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("challenge")) {
        this.setState({ draftId: this.props.createIdeaDetails.ideaId });
        this.props.setIdeaDraftId(
          this.props.createIdeaDetails.ideaId,
          this.props.userData.email
        );
      }
    }
    if (
      prevProps.attachmentList !== this.props.attachmentList &&
      this.state.basicIdeaData.ideaStatus !== "New"
    ) {
      this.setState({
        attachFileUrl: this.props.attachmentList,
      });
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  updateIdeaDetails = (obj, savedraft) => {
    this.props.updateIdeaDetail(
      obj,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && obj.ideaName !== "") {
      this.saveDraftDetails();
    }
    this.setState({
      hideHeaderCrossBtn:
        obj.ideaName !== "" && obj.ideaName !== null ? false : true,
    });
  };

  updateTools = (value, savedraft) => {
    this.props.updateTools(
      value.tools,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && value.ideaName !== "") {
      this.saveDraftDetails();
    }
  };

  updateTeamName = (value, savedraft) => {
    this.props.updateTeamName(
      value.teamName,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && value.ideaName !== "") {
      this.saveDraftDetails();
    }
  };

  updateSelectedKeywords = (arr) => {
    this.props.updateSelectedKeywords(
      arr,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
  };

  updateMarketdetails = (marketItem) => {
    this.props.updateMarketdetails(
      marketItem,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
  };

  saveTalentGrp = (item, savedraft=false) => {
    this.props.saveTalentGrp(
      item,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && item.ideaName !== "") {
      this.saveDraftDetails();
    }
  };

  saveTalentOffering = (item, savedraft=false) => {
    this.props.saveTalentOffering(
      item,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && item.ideaName !== "") {
      this.saveDraftDetails();
    }
  };

  saveOfferingPortfolio = (item, savedraft=false) => {
    this.props.saveOfferingPortfolio(
      item,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && item.ideaName !== "") {
      this.saveDraftDetails();
    }
  };

  saveArchetype = (item) => {
    this.props.saveArchetype(
      item,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
  };

  saveIndustryGrp = (item, savedraft=false) => {
    this.props.saveIndustryGrp(
      item,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft) {
      this.saveDraftDetails();
    }
  };

  saveDomainGrp = (item, savedraft=false) => {
    this.props.saveDomainGrp(
      item,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && item.ideaName !== "") {
      this.saveDraftDetails();
    }
  };

  saveSector = (item, savedraft=false) => {
    this.props.saveSector(
      item,
      this.state.basicIdeaData.ideaStatus,
      this.state.draftId,
      this.props.userData.email
    );
    if (savedraft && item.ideaName !== "") {
      this.saveDraftDetails();
    }
  };

  /**
   * handles the idea type change on click
   * @param {Number} selectedValue selected idea type
   */

  updateIdeaType = (
    selectedValue,
    value,
    ideaStatus,
    draftId,
    email,
    teamName
  ) => {
    this.props.updateIdeaType(
      selectedValue,
      value,
      ideaStatus,
      draftId,
      email,
      teamName
    );
  };

  updateProblemType = (
    selectedValue,
    value,
    ideaStatus,
    draftId,
    typeId,
    email,
    teamName
  ) => {
    this.props.updateProblemType(
      selectedValue,
      value,
      ideaStatus,
      draftId,
      typeId,
      email,
      teamName
    );
  };
  /**
   * handles the idea type change on click
   * @param {Number} selectedValue selected idea type
   */
  // handleProblemType = (selectedValue, value, typeId) => {
  //   let renderJSONTemp = this.state.basicIdeaData;
  //   renderJSONTemp.problemTypes.forEach((ideaType) => {
  //     ideaType.selected = false;
  //     if (ideaType.text + ideaType.problemTypeID === selectedValue) {
  //       ideaType.selected = true;
  //     }
  //   });
  //   this.setState({
  //     problemTypeValue: selectedValue,
  //     basicIdeaData: renderJSONTemp,
  //     // rightArrowDisable:
  //     //   this.state.draftId === 0 || this.state.challengeID !== 0
  //     //     ? selectedValue === "" || this.state.ideaTypeValue === ""
  //     //     : selectedValue === "" && this.state.ideaTypeValue === "",
  //   });
  //   this.props.updateProblemType(
  //     selectedValue,
  //     value,
  //     this.state.basicIdeaData.ideaStatus,
  //     this.state.draftId,
  //     typeId,
  //     this.props.userData.email,
  //     this.state.basicIdeaData.teamName
  //   );
  //   this.saveDraftDetails();
  // };

  handleAllianceType = (id, value) => {
    this.props.ideaSavedDetails["alliance"] = { allianceId: id, allianceName: value };
    this.saveDraftDetails();
  };

  handleAllianceOthers = (v) => {
    this.props.ideaSavedDetails["allianceOthers"] = v;
    this.saveDraftDetails();
  };

  handlePriorityType = (v) => {
    this.props.ideaSavedDetails["priority"] = v;
    this.saveDraftDetails();
  };

  handleUseCaseType = (v) => {
    this.props.ideaSavedDetails["useCase"] = v;
    this.saveDraftDetails();
  };

  saveDraftDetailsFromHeader = () => {
    this.saveDraftDetails();
    window.location.href = "/dashboard";
  };

  saveDraftDetails = () => {
    showLoader();
    const properties = ['priority'];
    properties.forEach(prop => {
      if(!(prop in this.props.ideaSavedDetails)) this.props.ideaSavedDetails[prop] = 'N/A'
    })
    if(!( 'alliance' in this.props.ideaSavedDetails)) this.props.ideaSavedDetails['alliance'] = {allianceId: 28, allianceName: "N/A"}
    // if(!('industry' in this.props.ideaSavedDetails)) this.props.ideaSavedDetails['industry'] = {industryId: 0, industryName: 'N/A'}
    // if(!('sector' in this.props.ideaSavedDetails)) this.props.ideaSavedDetails['sector'] = {industryId: 0, sectorId: 0, sectorName: 'N/A'}
    // if(!('domain' in this.props.ideaSavedDetails)) this.props.ideaSavedDetails['domain'] = {domainId: 0, domainName: 'N/A'}
    if (this.state.isChallengeIdea) {
      this.props.ideaSavedDetails["challengeID"] = this.state.challengeID;
      this.props.ideaSavedDetails["tools"] = this.props.ideaSavedDetails.ideaDescription?.tools || this.props.ideaSavedDetails["tools"] || '';
    }
    axios
      .post(
        config.saveIdeaConfig.requestPayload.url,
        this.props.ideaSavedDetails,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((data) => {
        if (this.state.basicIdeaData.ideaStatus.toLowerCase() === "new") {
          this.props.setIdeaDraftId(
            data.data,
            this.state.basicIdeaData.ideaStatus
          );
          this.setState({ draftId: data.data });
        }
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  };

  checkForValidType = () => {
    let isIdeaTypeValid = false,
      isProblemTypeValid = false;
    if (Object.keys(this.state.basicIdeaData).length > 0) {
      this.state.basicIdeaData.ideaTypes.forEach((ideaType) => {
        if (ideaType.selected) {
          isIdeaTypeValid = true;
          this.setState({
            ideaTypeValue: ideaType.text,
          });
        }
      });
    }
    if (Object.keys(this.state.basicIdeaData).length > 0) {
      this.state.basicIdeaData.problemTypes.forEach((problemType) => {
        if (problemType.selected) {
          isProblemTypeValid = true;
          this.setState({
            problemTypeValue: problemType.text,
          });
        }
      });
    }
    if (isIdeaTypeValid && isProblemTypeValid) {
      this.setState({ rightArrowDisable: false });
    } else {
      this.setState({ rightArrowDisable: true });
    }
  };

  /**
   * makes right arrow visible
   */
  enableRightArrow = () => {
    this.setState({ rightArrowDisable: false });
  };

  /**
   * makes submit button enabled
   */
  enableSubmitBtn = () => {
    this.setState({ disableSubmit: false, rightArrowDisable: false });
  };

  /**
   * makes submit button disabled
   */
  disableSubmitBtn = () => {
    this.setState({ disableSubmit: true, rightArrowDisable: true });
  };

  isAddDetail = () => {
    this.setState({ isAddDetail: true });
  };

  isNotAddDetail = () => {
    this.setState({ isAddDetail: false });
  };

  /**
   * sets arrow data when view changes
   * @param {Number} viewData currently active view
   */
  setArrowData(viewData) {
    if (viewData === "") {
      viewData = 1;
    }
    this.setState({
      // leftArrowVisible:
      //   viewData < this.state.basicIdeaData.breadcrumbs.length &&
      //   viewData !== 1,
      // rightArrowVisible:
      //   viewData !== this.state.basicIdeaData.breadcrumbs.length,
      // leftArrowDisable: viewData < this.state.basicIdeaData.breadcrumbs.length &&
      // viewData !== 1,
      leftArrowDisable: viewData === 1,
      rightArrowDisable: true,
    });

    // if (this.state.basicIdeaData !== undefined) {
    //   let rightArrowDisable = true;
    //   this.state.basicIdeaData.ideaTypes.forEach((ideaType) => {
    //     if (rightArrowDisable && ideaType.selected) {
    //       rightArrowDisable = false;
    //       this.setState({ rightArrowDisable: rightArrowDisable });
    //     }
    //   });
    // }
  }

  /**
   * next arrow click function
   */
  handleNextClick = () => {
    this.setState({ previousview: this.state.currentView });
    let self = this;
    let isSet = false;
    this.state.basicIdeaData.breadcrumbs.forEach((step, index) => {
      if (!isSet && self.state.currentView === step.breadcrumbID) {
        self.setState({
          currentView:
            self.state.basicIdeaData.breadcrumbs[index + 1].breadcrumbID,
        });
        self.setArrowData(
          self.state.basicIdeaData.breadcrumbs[index + 1].breadcrumbID
        );
        isSet = !isSet;
      }
    });
    if (this.state.currentView === 1 && this.state.challengeID !== 0) {
      this.setState({ hideHeaderCrossBtn: false });
    }
    if (this.state.currentView === 1) {
      this.setState({ showSubmitBtn: true });
    }
    if (this.state.currentView === 2) {
      this.handleSubmitIdea();
    }
  };

  /**
   * previous arrow click function
   */
  handlePreviousClick = () => {
    this.setState({ currentView: this.setState.previousview });
    let self = this;
    let isSet = false;
    this.state.basicIdeaData.breadcrumbs.forEach((step, index) => {
      if (!isSet && self.state.currentView === step.breadcrumbID) {
        self.setState({
          previousview:
            index <= 1
              ? ""
              : self.state.basicIdeaData.breadcrumbs[index - 2].breadcrumbID,
        });
        self.setState({
          currentView:
            self.state.basicIdeaData.breadcrumbs[index - 1].breadcrumbID,
        });
        self.setArrowData(
          self.state.basicIdeaData.breadcrumbs[index - 1].breadcrumbID
        );
        isSet = !isSet;
      }
    });
  };

  /**
   * submits the idea
   */
  handleSubmitIdea = () => {
    showLoader();
    const {basicIdeaData} = this.state;
    const {ideaSavedDetails} = this.props;

    if (this.state.isChallengeIdea) {
      ideaSavedDetails["challengeID"] = this.state.challengeID;
    }

    let allianceIndex = basicIdeaData.allainces.findIndex((el) => el.selected);
    let industryIndex = basicIdeaData.industries.findIndex((el) => el.selected);
    let sectorIndex = basicIdeaData.sectors.findIndex((el) => el.selected);
    let domainIndex = basicIdeaData.domains.findIndex((el) => el.selected);
    let archIndex = basicIdeaData.archeTypes.findIndex((el) => el.selected);
    let offPortfolioIndex = basicIdeaData.offeringPortfolios.findIndex((el) => el.selected);
    let talentOffIndex = basicIdeaData.talentOfferings.findIndex((el) => el.selected);
    let talentGrpIndex = basicIdeaData.talentGroups.findIndex((el) => el.selected);

    allianceIndex = allianceIndex !== -1 ? allianceIndex : 27;
    industryIndex = industryIndex !== -1 ? industryIndex : 6;
    sectorIndex = sectorIndex !== -1 ? sectorIndex : 23;
    domainIndex = domainIndex !== -1 ? domainIndex : 0;
    archIndex = archIndex !== -1 ? archIndex : 6;
    offPortfolioIndex = offPortfolioIndex !== -1 ? offPortfolioIndex : 0;
    talentOffIndex = talentOffIndex !== -1 ? talentOffIndex : 4;
    talentGrpIndex = talentGrpIndex !== -1 ? talentGrpIndex : 4;

    const allaince = 'alliance' in ideaSavedDetails ? ideaSavedDetails.alliance : basicIdeaData.allainces[allianceIndex];
    const industry = ideaSavedDetails?.industry?.industryId ? ideaSavedDetails.industry : basicIdeaData.industries[industryIndex];
    const sector = ideaSavedDetails?.sector?.industryId ? ideaSavedDetails.sector : basicIdeaData.sectors[sectorIndex];
    const domain = ideaSavedDetails?.domain?.domainId ? ideaSavedDetails.domain : basicIdeaData.domains[domainIndex];
    const priority = 'priority' in ideaSavedDetails ? ideaSavedDetails.priority : basicIdeaData.priority ? basicIdeaData.priority : 'N/A';
    const archeType = ideaSavedDetails?.archeType?.talentGroupId ? ideaSavedDetails.archeType : basicIdeaData.archeTypes[archIndex];
    const offeringPortfolio = ideaSavedDetails?.offeringPortfolio?.offeringPortfolioId ? ideaSavedDetails.offeringPortfolio : basicIdeaData.offeringPortfolios[offPortfolioIndex];
    const talentOffering = ideaSavedDetails?.talentOffering?.talentOfferingId ? ideaSavedDetails?.talentOffering : basicIdeaData.talentOfferings[talentOffIndex];
    const talentGroup = ideaSavedDetails?.talentGroup?.talentGroupId ? ideaSavedDetails.talentGroup : basicIdeaData.talentGroups[talentGrpIndex];
    const tools = ideaSavedDetails.ideaDescription?.tools == '' ? 'N/A' : ideaSavedDetails.ideaDescription?.tools

    for(const property in ideaSavedDetails.ideaDescription) {
      if(ideaSavedDetails.ideaDescription[property] === '') {
        ideaSavedDetails.ideaDescription[property] = 'N/A'
      }
      if(!('tools' in ideaSavedDetails.ideaDescription)) {
        ideaSavedDetails.ideaDescription['tools'] = 'N/A'
        ideaSavedDetails.tools = 'N/A'
      }
    }
    const sourceObj= {
      createdBy: { email: this.props.userData.email },
      ideaID: this.state.draftId,
      team: ideaSavedDetails.teamName || ideaSavedDetails.ideaDescription.teamName,
      // ideaTypeID: ideaSavedDetails.ideaType.ideaTypeID,
      // problemTypeID: ideaSavedDetails.problemType.problemTypeID,
      industry: {industryId: industry.industryId, industryName: industry.industryName},
      sector: {industryId: industry.industryId, sectorId: sector.sectorId, sectorName: sector.sectorName},
      domain: {domainId: domain.domainId, domainName: domain.domainName},
      alliance: {allianceId: allaince.allianceId, allianceName: allaince.allianceName},
      allianceOthers: null,
      priority: priority,
      archeType: {archetypesId: archeType.archetypesId, archetypesName: archeType.archetypesName, talentGroupId: archeType.talentGroupId},
      offeringPortfolio: {offeringPortfolioId: offeringPortfolio.offeringPortfolioId, offeringPortfolioName: offeringPortfolio.offeringPortfolioName},
      talentGroup: {talentGroupId: talentGroup.talentGroupId,  talentGroupName:talentGroup.talentGroupName},
      talentOffering: {offeringportfolioId: talentOffering.offeringportfolioId, talentOfferingId: talentOffering.talentOfferingId, talentOfferingName: talentOffering.talentOfferingName},
      tools: tools
    };
    
    Object.assign(this.props.ideaSavedDetails, sourceObj);
    delete this.props.ideaSavedDetails.ideaDescription.teamName;
    delete this.props.ideaSavedDetails.teamName;

    axios
      .post(
        config.submitIdeaConfig.requestPayload.url,
        this.props.ideaSavedDetails,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        // console.log(data.data + "idea submitted");
        this.setState({ previousview: this.state.currentView });
        let self = this;
        let isSet = false;
        // this.state.basicIdeaData.breadcrumbs.forEach((step, index) => {
        //   if (!isSet && self.state.currentView === step.breadcrumbID) {
        //     self.setState({
        //       currentView:
        //         self.state.basicIdeaData.breadcrumbs[index + 1].breadcrumbID,
        //     });
        //     self.setArrowData(
        //       self.state.basicIdeaData.breadcrumbs[index + 1].breadcrumbID
        //     );
        //     isSet = !isSet;
        //   }
        // });
        // if (this.state.currentView === 3) {
        //   this.setState({ hideHeaderCrossBtn: true });
        // }
        const urlParams = new URLSearchParams(window.location.search);
        // if (!urlParams.has("challenge")) {
        this.setState({ showRewardsScreen: true, ideaId: response.data });
        setTimeout(() => {
          this.setState({
            showRewardsScreen: false,
          });
        }, 5000);
        window.location.href = `/idea/${this.state.ideaId}`;
        hideLoading();
        // }
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
        hideLoading();
      });
  };

  render = () => {
    const { userData } = this.props;
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "/idealist",
        text: "Ideas",
      },
      {
        url: "",
        text: "Create Idea",
      },
    ];

    return (
      <>
        <Header
          values={userData}
          showSearch={false}
          showUser={false}
          discardDraft={true}
          hideHeaderCrossBtn={this.state.hideHeaderCrossBtn}
          pageName={"createIdea"}
          saveDraftDetails={() => this.saveDraftDetailsFromHeader()}
        />
        <Breadcrumbs breadcrumbs={breadcrumbJSON} />
        {Object.keys(this.state.basicIdeaData).length > 0 && this.state.getTeamNameCalled && (
          <>
            <div className="createIdeaOuter-cont">
              <div
                className={classNames("createIdeaOuter", {
                  "have-explore-btn": this.state.currentView === 3,
                })}
              >
                <div className="createIdeaOuter_btn-cont">
                  <div className="createIdeaOuter_btn-cont_inner">
                    <button
                      className="createIdeaNext"
                      disabled={this.state.rightArrowDisable}
                      onClick={this.handleSubmitIdea}
                    >
                      <div className="arrow"></div>
                      Submit
                    </button>
                  </div>
                </div>
                <div className="createIdeaMain">
                  <AddDetails
                    isAlreadyRegistered={this.state.isAlreadyRegistered}
                    teamName={this.state.teamName}
                    teamMembersIds={this.state.teamMembersIds}
                    email={this.props.userData.email}
                    challengeID={this.state.challengeID}
                    isChallengeIdea={this.state.isChallengeIdea}
                    marketFileList={this.state.attachFileUrl}
                    renderJSON={this.state.basicIdeaData}
                    draftId={this.state.draftId}
                    userData={this.state.userData}
                    ideaStatus={this.state.basicIdeaData.ideaStatus}
                    updateSelectedKeywords={(arr) =>
                      this.updateSelectedKeywords(arr)
                    }
                    isAddDetail={this.isAddDetail}
                    rightArrowEnable={this.enableSubmitBtn}
                    rightArrowDisable={this.disableSubmitBtn}
                    updateIdeaDetail={(obj, savedraft) =>
                      this.updateIdeaDetails(obj, savedraft)
                    }
                    updateTools={(obj, savedraft) =>
                      this.updateTools(obj, savedraft)
                    }
                    updateTeamName={(obj, savedraft) =>
                      this.updateTeamName(obj, savedraft)
                    }
                    updateMarketdetails={(marketItem) =>
                      this.updateMarketdetails(marketItem)
                    }
                    uploadFile={(fileData) => {
                      this.props.ideaFileUpload(
                        this.state.draftId,
                        "DRAFT_IDEA",
                        fileData,
                        _CONSTANTS.CHALLENGESTATUS.UPLOAD,
                        null
                      );
                    }}
                    deleteFile={(fileName) => {
                      this.props.doFileDelete(this.state.draftId, fileName);
                    }}
                    saveTalentGrp={(item) => this.saveTalentGrp(item)}
                    saveIndustryGrp={(item, savedraft) =>
                      this.saveIndustryGrp(item, savedraft)
                    }
                    saveDomainGrp={(item, savedraft) =>
                      this.saveDomainGrp(item, savedraft)
                    }
                    saveOfferingPortfolio={(item) =>
                      this.saveOfferingPortfolio(item)
                    }
                    saveTalentOffering={(item) => this.saveTalentOffering(item)}
                    saveArchetype={(item) => this.saveArchetype(item)}
                    saveSector={(item, savedraft) =>
                      this.saveSector(item, savedraft)
                    }
                    // onProblemTypeChange={this.handleProblemType}
                    problemTypes={this.state.basicIdeaData.problemTypes}
                    checkForValidType={this.checkForValidType}
                    isNotAddDetail={this.isNotAddDetail}
                    // onDataChange={this.handleIdeaType}
                    ideaTypes={this.state.basicIdeaData.ideaTypes}
                    handleAllianceType={this.handleAllianceType}
                    handleAllianceOthers={this.handleAllianceOthers}
                    handlePriorityType={this.handlePriorityType}
                    handleUseCaseType={this.handleUseCaseType}
                    problemTypeValue={this.state.problemTypeValue}
                    ideaTypeValue={this.state.ideaTypeValue}
                    basicIdeaData={this.state.basicIdeaData}
                    updateProblemType={this.updateProblemType}
                    updateIdeaType={this.updateIdeaType}
                    saveDraftDetails={this.saveDraftDetails}
                  />
                  {/* // )} */}
                  {/* {this.state.currentView === 3 && */}
                  {/* !this.state.isChallengeIdea && ( */}

                  {/* SUCCESS MESSAGE */}
                  {/* <CreateIdeaSuccess
                        renderJSON={this.state.basicIdeaData}
                      /> */}

                  {/* )} */}
                  {/* {this.state.currentView === 3 &&
                    this.state.isChallengeIdea && ( */}

                  {/* SUCCESS MESSAGE */}
                  {/* <CreateChallengeIdeaSuccess
                        renderJSON={this.state.basicIdeaData}
                      /> */}

                  {/* )} */}
                </div>
                {this.state.showRewardsScreen && (
                  <>
                    <div className="backdrop"></div>
                    <div className="reward-modal">
                      <button
                        onClick={() =>
                          this.setState({ showRewardsScreen: false })
                        }
                      >
                        <img src={closeIcon} alt="close" />
                      </button>
                      <div className="reward-modal_title">
                        You are a step ahead!
                      </div>
                      <div className="reward-modal_detail">
                        Congratulations! Keep adding new ideas to earn more seeds
                      </div>
                      <p className="reward-modal_seed">
                        Earned 20 <img src={seedIcon} alt="seed" />
                      </p>
                      {/* <img src={rewradIdea} alt="close" /> */}
                    </div>
                  </>
                )}
              </div>
              {/* {this.state.currentView === 2 && (
              <div className="idea-explore-btn">
                <button
                  className="btn primary"
                  role="button"
                  onClick={() => this.handleSubmitIdea()}
                  disabled={this.state.disableSubmit}
                >
                  Submit
                </button>
              </div>
            )} */}
              {/* {this.state.currentView === 3 && (
                <div className="idea-explore-btn view-details">
                  <a href="/idealist" className="btn secondary" role="button">
                    Explore more ideas
                  </a>
                  <a
                    href={"/idea/" + this.state.ideaId}
                    className="btn primary"
                    role="button"
                  >
                    View idea details
                  </a>
                </div>
              )} */}
            </div>
          </>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    FETCH_CREATE_IDEA_STATUS: state.ideas.meta.FETCH_CREATE_IDEA_STATUS,
    createIdeaDetails: state.ideas.data.createIdeaDetails,
    ideaSavedDetails: state.ideas.data.ideaSavedDetails,
    ATTACHMENT_LIST_STATUS: state.utilities.meta.ATTACHMENT_LIST_STATUS,
    attachmentList: state.utilities.data.attachmentList,
  };
};

const mapDispatchToProps = {
  getCreationIdeaDetails,
  fetchSavedDraft,
  fetchChallengeIdea,
  fetchIdeaAttachmentsList,
  updateIdeaDetail,
  updateSelectedKeywords,
  updateMarketdetails,
  ideaFileUpload,
  doFileDelete,
  updateIdeaType,
  updateProblemType,
  updateTools,
  updateTeamName,
  saveOfferingPortfolio,
  saveTalentOffering,
  saveTalentGrp,
  saveArchetype,
  saveIndustryGrp,
  saveDomainGrp,
  saveSector,
  setIdeaDraftId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIdea);
