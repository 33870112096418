import { Component } from "react";
import Header from "../components/global/header";
import Breadcrumbs from "../components/global/breadcrumbs";
import "../css/pages/event.scss";
import CreateEvent from "../components/event/createEvent";
import NewPointEntry from "../components/event/newPointEntry";
import EventList from "../components/event/eventList";
import { hideLoading, showLoader } from "../helpers/utils";
import EventBoard from "../components/event/eventBoard";
import { getEventDetails, getEventList } from "../redux/actions/utilities";

class Events extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showCreateEvent: false,
        showUpdateEvent: false,
        showNewPointEntry: false,
        showDeleteParticipant: false,
        eventDetails: {
          eventId: null,
          eventName: '',
          participantsList: []
        },
        totalEventCount: 0,
        eventList: [],
        isAdmin: this.props.userData.userType === 'ADMIN',
        urlEventName: ''
      }
    }
    
    getEventList = async () => {
      const {eventId, eventName} = this.state.eventDetails
      if(eventId === null && eventName === '') {
        const res = await getEventList();
        const { data } = await res.payload;
        this.setState({eventList: data.eventsList, totalEventCount: data.totalEventCount})
      }
    }

  handleCreateEvent = () => { this.setState({showCreateEvent: true}) };
  handleUpdateEvent = () => { this.setState({showUpdateEvent: true}) };
  handleNewPoint = () => { this.setState({showNewPointEntry: true}) };
  handleDeleteParticipant = () => { this.setState({showDeleteParticipant: true}) };
  handleCloseModal = () => { this.setState({showCreateEvent: false, showUpdateEvent: false, showNewPointEntry: false, showDeleteParticipant: false}, this.getEventList) }

  async componentDidMount() {
    showLoader();
    const urlParams = new URLSearchParams(window.location.search);
    const id = parseInt(urlParams.get('id'))
    const name = urlParams.get('name')?.replace('%20', ' ')
    if(!isNaN(id)) {
        this.setState({urlEventName: name})
        const res = await getEventDetails(id);
        const {data} = await res.payload;
        const {participantsList, eventId, eventName} = data
        this.setState({ eventDetails: { eventName, eventId, participantsList, eventList: [] } })
        if('eventId' in data) {this.handleCloseModal(); hideLoading();}
    } else {
      this.getEventList()
    }
    hideLoading();
}

  render() {
    const {eventDetails, eventList, showCreateEvent, showUpdateEvent, showNewPointEntry, showDeleteParticipant, isAdmin, urlEventName} = this.state
    let breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "",
        text: "Commerce Day Events",
      },
    ];
    const { userData } = this.props;
    const {eventId, eventName} = this.state.eventDetails;
    if(eventId || urlEventName){
        breadcrumbJSON[1].url = '/events'
        breadcrumbJSON.push({url: '', text: eventName || urlEventName})
    }
    const header = urlEventName || 'Event'
    return (
      <>
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"events"}
        />
        <>
          <Breadcrumbs breadcrumbs={breadcrumbJSON} />
          <div className="headerTitleContent section-container container-assign event-container-main">
            {!urlEventName ? (
                <>
                    <div className="event-container">
                        <h1 className="header-update">{header}</h1>
                        {isAdmin ? (
                          <div className="event-btn-container">
                              <button className="btn btn-primary" onClick={this.handleCreateEvent}>Create Event</button>
                              <button className="btn btn-primary" onClick={this.handleUpdateEvent}>Update Event</button>
                              <button className="btn btn-primary" onClick={this.handleNewPoint}>New Points Entry</button>
                              <button className="btn btn-primary" onClick={this.handleDeleteParticipant}>Delete Participant</button>
                          </div>
                        ): null}
                    </div>
                      <>
                        {showCreateEvent && <CreateEvent handleCloseModal={this.handleCloseModal} mode='create'/>}
                        {showUpdateEvent && <CreateEvent handleCloseModal={this.handleCloseModal} mode='edit' eventList={eventList}/>}
                        {showNewPointEntry && <NewPointEntry handleCloseModal={this.handleCloseModal} mode='create' eventList={eventList}/>}
                        {showDeleteParticipant && <NewPointEntry handleCloseModal={this.handleCloseModal} mode='delete' eventList={eventList}/>}
                        {eventList?.length > 0
                          ? (<div className="event-list-container"><EventList eventList={eventList} /></div>)
                          : null
                        }
                      </>
                </>
            ): ( <EventBoard eventDetails={eventDetails} header={header}/> )}
        </div>
        </>
      </>
    );
  }
}

export default Events;
