import { getBaseUrl } from "./utils";
const baseUrl = getBaseUrl(),
  ipAddress = baseUrl.ipAddress;

const config = {
  createIdeaConfig: {
    //get all the details for idea flow
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/new/{emailId}",
      method: "GET",
    },
  },
  fetchDraftConfig: {
    //get draft by id
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/draft/{ideaId}/{emailId}",
      method: "GET",
    },
  },
  fetchChallengeIdeaConfig: {
    //get all the details for challenge to idea submission
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/save-idea-from-challenge",
      method: "POST",
    },
  },
  saveIdeaConfig: {
    //save the idea as draft
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/save",
      method: "POST",
    },
  },
  updateRoleConfig: {
    //Update or assign roles
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/update-role",
      method: "POST",
    },
  },
  submitIdeaConfig: {
    //submit an idea
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/submit",
      method: "POST",
    },
  },
  fetchSubmitIdeasConfig: {
    //get all the available submitted ideas by the user
    requestPayload: {
      url: ipAddress + "api/v1/ideas/users/submittedIdea/{userID}",
      method: "GET",
    },
  },
  draftIdeaConfig: {
    //get all the available drafts by the user
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/users/draft/{userID}",
      method: "GET",
    },
  },
  userProfileConfig: {
    //fetch basic user details
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/first-time-user",
      method: "POST",
    },
  },
  userIdeaConfig: {
    //fetch user personal corner having totalDraftCount, totalSubmittedCount, totalLikeCount
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/profile/ideasCount/{userID}",
      method: "GET",
    },
  },
  userCurrencyConfig: {
    //fetch user overall summary details
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/gamify/profile/currencies/{userID}",
      method: "GET",
    },
  },
  userTransactionConfig: {
    //fetch user transaction details
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/gamify/user-transactions/{userID}",
      method: "GET",
    },
  },
  userInvestedIdeaConfig: {
    //fetch user invested Ideas
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/gamify/invested-ideas/{userID}",
      method: "GET",
    },
  },
  ideaLikeConfig: {
    //POST call when user likes an Idea
    requestPayload: {
      url: ipAddress + "api/v1/ideas/like",
      method: "POST",
    },
  },
  ideaListConfig: {
    //fetch idea list
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/view",
      method: "GET",
    },
  },
  searchIdeaConfig: {
    //POST call when user search with tags
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/keyword",
      method: "POST",
    },
  },
  ideaDetailsConfig: {
    // fetch idea deatils based on idea id
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/{ideadId}/{userId}",
      method: "GET",
    },
  },
  challengeDetail: {
    //get all the details for the challenge
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/challenges",
      method: "GET",
    },
  },
  stopChallengeConfig: {
    //stop the challenge
    url: ipAddress + "api/v1/challenges/stop",
    method: "POST",
  },
  challengeListAllConfig: {
    //get the challenges for user
    url: ipAddress + "api/v1/challenges/global-search",
    method: "POST",
  },
  globalSearchConfig: {
    // fetch global results
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/global-search",
      method: "POST",
    },
  },
  investInIdea: {
    //POST call for saving investment in an idea by the user
    useMocks: false,
    requestPayLoad: {
      url: ipAddress + "api/v1/gamify/transaction/invest",
      method: "POST",
    },
  },
  deInvestConfig: {
    //POST call for de-investment in an idea by the user
    useMocks: false,
    requestPayLoad: {
      url: ipAddress + "api/v1/gamify/return-coins/{ideaId}",
      method: "POST",
    },
  },
  ideaInvestedSummary: {
    //Fetch the investment summary of a user on an idea
    useMocks: false,
    requestPayLoad: {
      url: ipAddress + "api/v1/gamify/invest/summary/{ideaId}/{userId}",
      method: "GET",
    },
  },
  getDashboardConfig: {
    //get dashboard banner and tags details
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/getBannerAndTags/{userID}",
      method: "POST",
    },
  },
  getIdeaSubmissionListConfig: {
    //get Idea Submission List for Panel/Panel Lead View
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/list",
      method: "POST",
    },
  },
  getEvaluateDetails: {
    //get evaluate flow details
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/review-ratings/{ideaId}/{panelistId}",
      method: "GET",
    },
  },
  saveEvaluateDetails: {
    //save evaluate flow details
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/evaluateIdea",
      method: "GET",
    },
  },
  assignPanelConfig: {
    //assign panel by Panel Lead
    useMocks: false,
    requestPayload: {
      url: ipAddress + "api/v1/ideas/assign",
      method: "POST",
    },
  },
  utilConfig: {
    // global utility config
    requestPayload: {
      url: ipAddress + "api/v1/utils/file",
    },
  },
  validateEmailConfig: {
    // validates email
    requestPayload: {
      url: ipAddress + "api/v1/ideas/validate-email",
      method: "POST",
    },
  },
  getReviewDetailsConfig: {
    // gets the review details of panelist
    requestPayload: {
      url: ipAddress + "api/v1/ideas/tg-reviewed-details/{ideaId}",
      method: "GET",
    },
  },
  rejectIdeaConfig: {
    // reject idea as per the requested Status
    requestPayload: {
      url: ipAddress + "api/v1/ideas/rejectIdea",
      method: "POST",
    },
  },
  approveIdeaConfig: {
    // approve idea config
    requestPayload: {
      url: ipAddress + "api/v1/ideas/approve",
      method: "POST",
    },
  },
  getTeamMemberConfig: {
    // get team members list for any idea
    requestPayload: {
      url: ipAddress + "api/v1/ideas/get-members/{ideaId}",
      method: "GET",
    },
  },
  addTeamMemberConfig: {
    // add any member
    requestPayload: {
      url: ipAddress + "api/v1/ideas/member",
      method: "POST",
    },
  },

  deleteTeamMemberConfig: {
    //delete a member
    requestPayLoad: {
      url: ipAddress + "api/v1/ideas/member/",
      method: "DELETE",
    },
  },

  addMentorConfig: {
    //Add a mentor
    requestPayload: {
      url: ipAddress + "api/v1/ideas/mentor",
      method: "PATCH",
    },
  },

  approveTeamMemberConfig: {
    // approve team member
    requestPayload: {
      url: ipAddress + "api/v1/ideas/member/approve",
      method: "POST",
    },
  },
  rejectTeamMemberConfig: {
    // reject team member
    requestPayload: {
      url: ipAddress + "api/v1/ideas/member/deny",
      method: "POST",
    },
  },
  ideaStateChangeConfig: {
    //increment rewards to user when state changes
    requestPayload: {
      url: ipAddress + "api/v1/ideas/update/status",
      method: "PUT",
    },
  },
  challengeIdeasConfig: {
    //increment rewards to user when state changes
    requestPayload: {
      url: ipAddress + "api/v1/ideas/solutionsSubmitted",
      method: "GET",
    },
  },
  topIdeators: {
    requestPayload: {
      url: ipAddress + "api/v1/ideas/topIdeators?recordCount=",
    },
  },
  topTeamIdeators: {
    requestPayload: {
      url: ipAddress + "api/v1/ideas/topTeamIdeators?recordCount=",
    },
  },
  mostLikedIdeas: {
    requestPayload: {
      url: ipAddress + "api/v1/ideas/mostLikedIdeas?recordCount=",
    },
  },
  mostInvestedIdeas: {
    requestPayload: {
      url: ipAddress + "api/v1/gamify/topInvestedIdeas?recordCount=",
    },
  },
  registerTeamForChallenge: {
    // approve idea config
    requestPayload: {
      url: ipAddress + "api/v1/challenges/{challengeId}/registerTeam",
      method: "POST",
    },
  },
  getTeamNameForChallenge: {
    // approve idea config
    requestPayload: {
      url:
        ipAddress +
        "api/v1/ideas/teamName/challengeId/{challengeId}/userId/{email}",
      method: "POST",
    },
  },
  eventList: {
    //increment rewards to user when state changes
    requestPayload: {
      url: ipAddress + "api/v1/gamify/events/allEvents",
      method: "GET",
    },
  },
  eventDetails: {
    //increment rewards to user when state changes
    requestPayload: {
      url: ipAddress + "api/v1/gamify/events/{eventId}/participants?pageSize=10",
      method: "GET",
    },
  },
  eventDetailsLeaderboard: {
    //increment rewards to user when state changes
    requestPayload: {
      url: ipAddress + "api/v1/gamify/events/{eventId}/participants?pageSize=3",
      method: "GET",
    },
  },
  registerEvent: {
    requestPayload: {
      url: ipAddress + "api/v1/gamify/events/newEvent",
      method: "POST",
    },
  },
  updateEvent: {
    requestPayload: {
      url: ipAddress + "api/v1/gamify/events/{eventId}",
      method: "POST",
    },
  },
  addParticipantsToEvent: {
    requestPayload: {
      url: ipAddress + "api/v1/gamify/events/participants/update",
      method: "POST",
    },
  },
  deleteParticipantsToEvent: {
    requestPayload: {
      url: ipAddress + "api/v1/gamify/events/participants/delete",
      method: "POST",
    },
  },
};

export default config;
