import React, { Component } from "react";
import CircularProgressBar from "./circularProgressBar";
import MediaQuery from "react-responsive";
import QuestionLayerView from "./questionLayerView";
import { generateRandomKey } from "../../helpers/utils";

class OverallSummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || {},
      overAllScore: this.props.overAllScore || 40,
      score: this.props.score || 0,
      firstReviewer: this.props.firstReviewer
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.score !== this.props.score) {
      this.setState({ score: this.props.score });
    }
  }

  render() {
    const score = this.state.score,
      max_score = 20,
      scorePercent = Math.round((score / max_score) * 100);
    let color = '';
    if (score > 30) {
      color = '#077F5A';
    } else if (score <= 30 && score >= 15) {
      color = "#f7bf46";
    } else if (score < 15) {
      color = '#A5143A';
    }

    return (
      <>
        <div className="IdeaApprovalFlow">
          <div className="Idealeft">
            <div className="OverallScore">Overall Score</div>
            <div className="score">
              <span style={{ "color": color }}>{this.state.score}</span>
              <span className="totalScore">
                /{this.state.overAllScore}
              </span>
            </div>
            <div className="evaluator-chart">
              Overall Confidence of Evaluator
            </div>
            <div className="evaluator-chart-circle">
              <MediaQuery maxWidth={767}>
                <CircularProgressBar
                  selectedValue={score}
                  maxValue={20}
                  textColor={color}
                  activeStrokeColor={color}
                  strokeWidth={40}
                  radius={80}
                  valueFontSize={24}
                  inactiveStrokeColor="#e6e6e6"
                  initialAngularDisplacement={0}
                  label={scorePercent + "%"}
                />
              </MediaQuery>
              <MediaQuery minWidth={768}>
                <CircularProgressBar
                  selectedValue={score}
                  maxValue={20}
                  textColor={color}
                  activeStrokeColor={color}
                  strokeWidth={60}
                  radius={120}
                  valueFontSize={40}
                  inactiveStrokeColor="#e6e6e6"
                  initialAngularDisplacement={0}
                  label={scorePercent + "%"}
                />
              </MediaQuery>
            </div>
          </div>
          <div className="Idearight">
            <div className="question-type">{this.state.data.stepName}</div>
            <div className="question-cont">
              {this.state.data.stepHeader !== '' && (
                <div className="question-title">{this.state.data.stepHeader}</div>
              )}
              {this.state.data.stepSubHeader !== '' && (
                <div className="question-subtitle">{this.state.data.stepSubHeader}</div>
              )}
              {this.state.data.questions.map((question) => {
                return (
                  <QuestionLayerView
                    stepID={this.state.data.stepID}
                    firstReviewer={this.state.firstReviewer}
                    questionDetail={question}
                    key={generateRandomKey()}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OverallSummaryView;
