import DOMPurify from 'dompurify';
import React, { useState, useEffect } from 'react';
import seedIcon from "../../img/seedIcon.svg";

const Counter = ({ counterIncrementor, sendDataToParent }) => {
    const [count, setCount] = useState(100);
    const [tempVal, setTempVal] = useState("100");
    const incrementCount = () => {
        var updatedCount = isNaN(count) ? 0 : count + counterIncrementor;
        setCount(updatedCount);
        sendDataToParent(updatedCount);
    };

    const decrementCount = () => {
        var updatedCount = count > 0 ? count - counterIncrementor : count;
        setCount(updatedCount);
        sendDataToParent(updatedCount);
    }

    const updateCount = (event) => {
        setTempVal(DOMPurify.sanitize(event.target.value));
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => console.log(`I can see you're not typing. I can use "${tempVal}" now!`), 1000);
        setCount(parseInt(tempVal));
        sendDataToParent(parseInt(tempVal));
        return () => clearTimeout(timeoutId);
    }, [tempVal]);


    return (
        <div>
            <div className="convert-modal_body-detail-incrementer">
                <span className={`decrementBtn ${count <= 100 ? "disabled" : ""}`}
                    onClick={decrementCount}
                >
                    -
                </span>
                <div className='counterVal_cont'>
                    <input type="number" className="counterVal" value={count} onChange={updateCount} readOnly/>
                    <img src={seedIcon} alt="coin" />
                </div>
                <span className={`incrementBtn ${count >= 300 ? "disabled" : ""}`}
                    onClick={incrementCount}
                >
                    +
                </span>
            </div>
        </div>
    );
}

export default Counter;