import { combineReducers } from 'redux';
import {
    FETCH_TOP_INNOVATORS_PENDING,
    FETCH_TOP_INNOVATORS_FULFILLED,
    FETCH_TOP_INNOVATORS_REJECTED,
    FETCH_DASHBOARD_PENDING,
    FETCH_DASHBOARD_FULFILLED,
    FETCH_DASHBOARD_REJECTED
} from '../action-types/dashboard';
import {
    PENDING,
    FULFILLED,
    FAILED,
    DEFAULT
} from '../../helpers/constants';


const initialMetaState = {
    FETCH_TOP_INNOVATORS_STATUS: 'DEFAULT',
    FETCH_DASHBOARD: DEFAULT
}

const initialDataState = {
    topInnovators: [],
    honorableMentions: [],
    dashboard: {}
}

function metaReducer(state = initialMetaState, action) {

    switch (action.type) {
        case FETCH_TOP_INNOVATORS_PENDING:
            return { ...state, FETCH_TOP_INNOVATORS_STATUS: PENDING };
        case FETCH_TOP_INNOVATORS_FULFILLED:
            return { ...state, FETCH_TOP_INNOVATORS_STATUS: FULFILLED };
        case FETCH_TOP_INNOVATORS_REJECTED:
            return { ...state, FETCH_TOP_INNOVATORS_STATUS: FAILED };
        case FETCH_DASHBOARD_PENDING:
            return { ...state, FETCH_DASHBOARD: PENDING };
        case FETCH_DASHBOARD_FULFILLED:
            return { ...state, FETCH_DASHBOARD: FULFILLED };
        case FETCH_DASHBOARD_REJECTED:
            return { ...state, FETCH_DASHBOARD: FAILED };
        default:
            return state;
    }

}

function dataReducer(state = initialDataState, action) {

    switch (action.type) {
        case FETCH_TOP_INNOVATORS_FULFILLED:
            return {
                ...state,
                topInnovators: action.payload.topInnovators,
                honorableMentions: action.payload.honorableMentions
            };
        case FETCH_DASHBOARD_FULFILLED:
            return {
                ...state,
                dashboard: action.payload.data,
            };
        default:
            return state;
    }
}

export default combineReducers({
    meta: metaReducer,
    data: dataReducer
});