import { Component } from 'react';
import Header from '../components/global/header';
import faqs from '../API_JSONs/FAQ/faq.json';
import '../css/pages/seedFAQs.scss';

class SeedFAQs extends Component {

    render() {
        const { userData } = this.props;
        return (
            <>
                <Header
                    values={userData}
                    showSearch={true}
                    showUser={true}
                    pageName={"seedFAQs"}
                />
                <section className="seed-faqs">
                    <h2>{faqs.title}</h2>
                    <ul className='faqs'>
                        {faqs.questions?.map((question,index) => <QuestionItem key={'question_index'} question={question} />)}
                    </ul>
                </section>
            </>
        );
    }
}

class QuestionItem extends Component {
    render() {
        const { question } = this.props;
        const { paragraphAnswers = [], listAnswers = [] } = question;
        return <li>
            <p className='question-title'>{question.name}</p>
            {paragraphAnswers.length > 0 && paragraphAnswers.map(paragraph => <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>)}
            {listAnswers.length > 0 && 
                <ul>
                    {listAnswers.map(listItem => <li className="answer-list" dangerouslySetInnerHTML={{ __html: listItem }}></li>)}
                </ul>
            }
        </li>
    }
}

export default SeedFAQs;
