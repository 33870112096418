import axios from "axios";
import config from "../../helpers/api-urls";
import mockIdeasFromArnd from "../../API_JSONs/Dashboard/ideasFromYourInterest.json";
import globalResultsMock from "../../API_JSONs/Global/globalAutocompleteResults.json";
import createIdeaMock from "../../API_JSONs/Create Idea GET/createIdea.json";
import draftIdeaMock from "../../API_JSONs/Draft Ideas GET/draftIdeas.json";
import mockEvaluateDetails from "../../API_JSONs/Approval Flow/Reviewer View/Review Steps/reviewRatings.json";

const _fetchIdeasFrmArnd = (itemsCount, user) => {
  if (config.ideaListConfig.useMocks) {
    return Promise.resolve(mockIdeasFromArnd);
  } else {
    const payloadUrl =
      config.ideaListConfig.requestPayload.url +
      "?pageSize=" +
      itemsCount +
      "&userId=" +
      user;
    return axios.get(payloadUrl, {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchIdeasFrmArnd = (itemsCount = 8, user) => {
  return {
    type: "FETCH_IDEAS_FRM_ARND",
    payload: _fetchIdeasFrmArnd(itemsCount, user),
  };
};

const _searchIdeaRequest = (data) => {
  if (config.searchIdeaConfig.useMocks) {
    return Promise.resolve(mockIdeasFromArnd);
  } else {
    return axios.post(config.searchIdeaConfig.requestPayload.url, data, {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const searchIdeas = (data) => {
  return {
    type: "FETCH_IDEAS_FRM_ARND",
    payload: _searchIdeaRequest(data),
  };
};

const _fetchIdeaDetails = (id, user) => {
  const payloadUrl = config.ideaDetailsConfig.requestPayload.url
    .replace("{ideadId}", id)
    .replace("{userId}", user);
  return axios.get(payloadUrl, {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const fetchIdeaDetails = (id, user) => {
  return {
    type: "FETCH_IDEA_DETAILS",
    payload: _fetchIdeaDetails(id, user),
  };
};

const _getGlobalResults = (data) => {
  if (config.globalSearchConfig.useMocks) {
    return Promise.resolve(globalResultsMock);
  } else {
    return axios.post(config.globalSearchConfig.requestPayload.url, data, {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const getGlobalResults = (data) => {
  return {
    type: "FETCH_GLOBAL_RESULTS",
    payload: _getGlobalResults(data),
  };
};
export const getAutocompleteResults = (data) => {
  return {
    type: "FETCH_AUTOCOMPLETE_RESULTS",
    payload: _getGlobalResults(data),
  };
};

const _investInIdea = (requestData) => {
  return axios.post(config.investInIdea.requestPayLoad.url, requestData, {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const investInIdea = (requestData) => {
  return {
    type: "INVEST_IN_IDEA",
    payload: _investInIdea(requestData),
  };
};

const _investedIdeaSummary = (ideaId, userId) => {
  const url = config.ideaInvestedSummary.requestPayLoad.url
    .replace("{ideaId}", ideaId)
    .replace("{userId}", userId);
  return axios.get(url, {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const investedIdeaSummary = (ideaId, userId) => {
  return {
    type: "FETCH_IDEA_INVESTMENT_DETAILS",
    payload: _investedIdeaSummary(ideaId, userId),
  };
};

const _getCreationIdeaDetails = (emailId) => {
  if (config.createIdeaConfig.useMocks) {
    return Promise.resolve(createIdeaMock);
  } else {
    return axios.get(config.createIdeaConfig.requestPayload.url.replace("{emailId}", emailId), {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const getCreationIdeaDetails = (emailId) => {
  return {
    type: "FETCH_CREATE_IDEA",
    payload: _getCreationIdeaDetails(emailId),
  };
};

const _fetchSavedDraft = (id, emailId) => {
  if (config.fetchDraftConfig.useMocks) {
    return Promise.resolve(createIdeaMock);
  } else {
    return axios.get(
      config.fetchDraftConfig.requestPayload.url
      .replace("{ideaId}", id)
      .replace("{emailId}", emailId), {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    }
    );
  }
};

export const fetchSavedDraft = (id, emailId) => {
  return {
    type: "FETCH_CREATE_IDEA",
    payload: _fetchSavedDraft(id, emailId),
  };
};

const _fetchChallengeIdea = (payload) => {
  if (config.fetchChallengeIdeaConfig.useMocks) {
    return Promise.resolve(createIdeaMock);
  } else {
    return axios.post(
      config.fetchChallengeIdeaConfig.requestPayload.url,
      payload,
      {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      }
    );
  }
};

export const fetchChallengeIdea = (id) => {
  return {
    type: "FETCH_CREATE_IDEA",
    payload: _fetchChallengeIdea(id),
  };
};

export const updateIdeaDetail = (obj, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_IDEA_DETAILS",
    obj,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const saveTalentGrp = (item, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_TALENT_DETAILS",
    item,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const saveIndustryGrp = (item, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_INDUSTRY_DETAILS",
    item,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const saveDomainGrp = (item, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_DOMAIN_DETAILS",
    item,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const saveOfferingPortfolio = (item, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_OFFERING_PORTFOLIO",
    item,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const saveTalentOffering = (item, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_TALENT_OFFERING",
    item,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const saveArchetype = (item, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_ARCHETYPE",
    item,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const saveSector = (item, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_SECTOR",
    item,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const updateSelectedKeywords = (arr, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_USER_IDEA_TAGS",
    arr,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const updateIdeaType = (id, value, ideaStatus, draftId, userEmail, teamName) => {
  return {
    type: "ADD_IDEA_TYPE",
    id,
    value,
    ideaStatus,
    draftId,
    userEmail,
    teamName
  };
};

export const updateProblemType = (
  id,
  value,
  ideaStatus,
  draftId,
  typeId,
  userEmail,
  teamName
) => {
  return {
    type: "ADD_PROBLEM_TYPE",
    id,
    value,
    ideaStatus,
    draftId,
    typeId,
    userEmail,
    teamName
  };
};

export const updateTools = (value, ideaStatus, draftId, userEmail) =>{
  return {
    type: "ADD_TOOLS",
    value,
    ideaStatus,
    draftId,
    userEmail,
  };
}

export const updateTeamName = (value, ideaStatus, draftId, userEmail) =>{
  return {
    type: "ADD_TEAM_NAME",
    value,
    ideaStatus,
    draftId,
    userEmail,
  };
}

export const updateMarketdetails = (arr, ideaStatus, draftId, userEmail) => {
  return {
    type: "ADD_MARKET_DETAILS",
    arr,
    ideaStatus,
    draftId,
    userEmail,
  };
};

export const setIdeaDraftId = (id, ideaStatus, userEmail) => {
  return {
    type: "ADD_DRAFT_ID",
    id,
    ideaStatus,
    userEmail,
  };
};

const _getDraftIdeasList = (pageNum, pageSize, userId) => {
  if (config.draftIdeaConfig.useMocks) {
    return Promise.resolve(draftIdeaMock);
  } else {
    return axios.get(
      config.draftIdeaConfig.requestPayload.url.replace("{userID}", userId) +
      "?pageNum=" +
      pageNum +
      "&pageSize=" +
      pageSize,
      {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      }
    );
  }
};

export const getDraftIdeasList = (pageNum, pageSize, userId) => {
  return {
    type: "FETCH_DRAFT_IDEA",
    payload: _getDraftIdeasList(pageNum, pageSize, userId),
  };
};

const _fetchSubmittedIdeas = (pageNum, pageSize, userId) => {
  return axios.get(
    config.fetchSubmitIdeasConfig.requestPayload.url.replace(
      "{userID}",
      userId
    ) +
    "?pageNum=" +
    pageNum +
    "&pageSize=" +
    pageSize,
    {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    }
  );
};

export const fetchSubmittedIdeas = (pageNum, pageSize, userId) => {
  return {
    type: "FETCH_SUBMITTED_IDEAS",
    payload: _fetchSubmittedIdeas(pageNum, pageSize, userId),
  };
};

const _getIdeaSubmissionList = (payload) => {
  return axios.post(
    config.getIdeaSubmissionListConfig.requestPayload.url,
    payload,
    {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    }
  );
};

export const getIdeaSubmissionList = (payload) => {
  return {
    type: "FETCH_IDEA_SUBMISSION",
    payload: _getIdeaSubmissionList(payload),
  };
};

const _getEvaluateDetails = (ideaId, panelId) => {
  if (config.getEvaluateDetails.useMocks) {
    return Promise.resolve(mockEvaluateDetails);
  } else {
    const payloadUrl = config.getEvaluateDetails.requestPayload.url
      .replace("{ideaId}", ideaId)
      .replace("{panelistId}", panelId);
    return axios.get(payloadUrl,
      {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      });
  }
};

export const getEvaluateDetails = (ideaId, panelId) => {
  return {
    type: "FETCH_EVALUATE_DETAILS",
    payload: _getEvaluateDetails(ideaId, panelId),
  };
};

const _saveEvaluateDetails = (payload) => {
  const payloadUrl = config.saveEvaluateDetails.requestPayload.url;
  return axios.get(payloadUrl, payload, {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const saveEvaluateDetails = (payload) => {
  return {
    type: "FETCH_EVALUATE_DETAILS",
    payload: _saveEvaluateDetails(payload),
  };
};

const _getReviewDetails = (ideaId) => {
  const payloadUrl = config.getReviewDetailsConfig.requestPayload.url;
  return axios.get(payloadUrl.replace("{ideaId}", ideaId), {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const getReviewDetails = (ideaId) => {
  return {
    type: "FETCH_REVIEW_DETAILS",
    payload: _getReviewDetails(ideaId),
  };
};

const _getTeamMembersDetails = (ideaId) => {
  const payloadUrl = config.getTeamMemberConfig.requestPayload.url;
  return axios.get(payloadUrl.replace("{ideaId}", ideaId), {
    headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
  });
};

export const getTeamMembersDetails = (ideaId) => {
  return {
    type: "GET_MEMBER_DETAILS",
    payload: _getTeamMembersDetails(ideaId),
  };
};
