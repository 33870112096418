import { Component } from "react";

class TopMembers extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
       const { member: { participantName, points }, index } = this.props;
        const order = index === 2 ? 0 : 1;
        const footerHeight = 70 / index + '%';
        const footerBackgroundColor = index === 1 ? 'rgb(0 185 128)' : (index === 2 ? 'rgb(0 47 128)' : 'rgb(0 68 102)');
        const boxShadow = `4px 4px 12px ${footerBackgroundColor}`;
        const suffix = index === 1 ? 'st' : index == 2 ? 'nd' : 'rd';


        return <li className="top-ideator" style={{ 'order': order }}>
            <header>
                <p className="top-ideator-name">{participantName}</p>
                <p className="top-ideator-count">{points} points</p>
            </header>
            <footer style={{ 'height': footerHeight, 'background': footerBackgroundColor, 'boxShadow': boxShadow}} >
                <span className="rank">{index}</span><span className="suffix">{suffix}</span>
            </footer>
        </li>
    };
}

export default TopMembers;
