import axios from "axios";
import { _CONSTANTS } from "./constants";

export const makeCall = (url, method, data) => {
    const config = {
        url,
        method,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    };
    if (method === _CONSTANTS.HTTPMETHOD.POST) {
        config.data = data;
    }
    return axios(config);
};
