import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfileInvestedIdeas } from "../../redux/actions/user-profile";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import seedIcon from "../../img/seedIcon.svg";
import config from "../../helpers/api-urls";
import axios from "axios";
import GlobalMessagePopup from "../global/globalMessagePopup";
import { generateRandomKey, hideLoading, showLoader } from "../../helpers/utils";

class ProfileInvestedIdeas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeInvestModal: false,
            showMessagePopup: false,
            investedValue: 0,
            currentValue: 0,
            modalIdeaId: 0
        };
    }
    openDeInvestModal = (idea) => {
        this.setState({ investedValue: idea.amountInvested, currentValue: idea.amountInvested, modalIdeaId: idea.ideaId, showDeInvestModal: true });
    };
    hideDeInvestModal = () => {
        this.setState({ showDeInvestModal: false });
    };
    handleDeInvest = (id) => {
        showLoader();
        axios
            .post(config.deInvestConfig.requestPayLoad.url.replace('{ideaId}', id), {}, {
                headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
            })
            .then((data) => {
                this.props.fetchProfileInvestedIdeas(this.props.userInfo.id);
                this.setState({
                    showMessagePopup: true
                });
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                setTimeout(() => {
                    this.setState({
                        showMessagePopup: false
                    });
                }, 5000);
                this.props.updateProfileCurrency();
                hideLoading();
            })
            .catch(function (error) {
                console.log(error);
                hideLoading();
            });
        this.setState({ showDeInvestModal: false });
    };
    componentDidMount () {
        this.props.fetchProfileInvestedIdeas(this.props.userInfo.id);
    }
    navigateToIdea = (id, user) => {
        window.location.href = "/idea/" + parseInt(id);
    };
    render () {
        const profileInvestedIdeas = this.props.profileInvestedIdeas || {};
        const deInvestMessageData = {
            type: "success",
            // title: "WoHooo! Bravo",
            detailText: "Your seeds are returned to Investment Wallet. Happy exploring!"
        };
        return (
            <>
                {this.state.showMessagePopup && (
                    <GlobalMessagePopup
                        showMessagePopup={this.state.showMessagePopup}
                        messageData={deInvestMessageData}
                    />
                )}
                {profileInvestedIdeas.length > 0 && (
                    <div className="profile-investedIdea-container">
                        <p>Invested Ideas</p>
                        <ul>
                            {profileInvestedIdeas.map((idea, index) => {
                                return (
                                    <>
                                        <li key={generateRandomKey()}>
                                            <span className="investedIdea_ideaName" title={idea.ideaName?idea.ideaName:''}><span onClick={() => { this.navigateToIdea(idea.ideaId) }}>{idea.ideaName && idea.ideaName.length > 45
                                                ? `${idea.ideaName.substring(0, 40)} ...`
                                                : idea.ideaName}</span>
                                            </span>
                                            <span className="profile-transaction-itemAmount">
                                                <div>
                                                    {idea.amountInvested}
                                                    <img src={seedIcon} alt="coin-icon" />
                                                </div>
                                                <div>
                                                    {idea.locked ? (
                                                        <span className="profile-transaction-investedType">
                                                            Locked-In
                                                        </span>
                                                    ) : (
                                                        <a
                                                            className="profile-transaction-investedType"
                                                            onClick={() => this.openDeInvestModal(idea)}
                                                        >
                                                            De-Invest
                                                        </a>
                                                    )}
                                                </div>
                                            </span>
                                        </li>
                                    </>
                                );
                            })}
                        </ul>
                        {/* <p className="profile-investedIdea-tip">
                        <em>Tip: You can only invest in 5 ideas at a time</em>
                    </p> */}
                        <Modal
                            show={this.state.showDeInvestModal}
                            onHide={this.hideDeInvestModal}
                            backdrop="static"
                            keyboard={false}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            dialogClassName="profile-modal"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>De-Invest</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modal-data-withdraw">
                                    {/* <p className="modal-data-withdraw_title">Blockchain App</p> */}
                                    <div className="coin-detail">
                                        <div className="coin-detail_info">
                                            <span>Invested</span>
                                            <div>
                                                <img src={seedIcon} alt="coin" />
                                                <span className="coin-detail_info-amt">{this.state.investedValue}</span>
                                            </div>
                                        </div>
                                        <div className="coin-detail_info">
                                            <span>Current Value</span>
                                            <div>
                                                <img src={seedIcon} alt="coin" />
                                                <span className="coin-detail_info-amt">{this.state.currentValue}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btnText header-lm-20"
                                    onClick={this.hideRedeemModal}
                                >
                                    Cancel
                                </button>
                                <Button variant="primary" onClick={() => this.handleDeInvest(this.state.modalIdeaId)}>
                                    De-Invest
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        FETCH_PROFILE_INVESTED_IDEA_STATUS:
            state.userProfile.meta.FETCH_PROFILE_INVESTED_IDEA_STATUS,
        profileInvestedIdeas: state.userProfile.data.profileInvestedIdeas
    };
};

const mapDispatchToProps = {
    fetchProfileInvestedIdeas
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileInvestedIdeas);
