import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { generateRandomKey } from "../../helpers/utils";
import { _CONSTANTS } from "../../helpers/constants";

class RadioText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedArea: '',
            selectedAreaValue: '',
            questionDetail: this.props.question || {},
            isOptionChanged: false,
            stepID: this.props.stepID || 0
        };
    }

    componentDidMount = () => {
        this.props.question && this.props.question.options.forEach((option) => {
            if (option.selected) {
                this.setState({ selectedAreaValue: option.text, selectedArea: option.text });
                // setTimeout(() => {
                //     this.props.defaultSelectedDetails(this.props.question.questionId, this.props.question.questionType, option.id, "", this.props.stepID);
                // }, 100);
                // this.props.defaultSelectedDetails(this.props.question.questionId, this.props.question.questionType, option.id, "", this.props.stepID);
            }
        });
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.question !== this.props.question) {
            this.setState({ questionDetail: this.props.question });
        }
    };

    saveAreaDetails = () => {
        this.setState({ selectedAreaValue: this.state.selectedArea });
        if (this.state.selectedArea === _CONSTANTS.IDEAPROBLEM.OTHERS) {
            this.props.handleOtherArea(true, 1);
        } else if (this.state.selectedArea === _CONSTANTS.IDEAPROBLEM.CLIENTS) {
            this.props.handleOtherArea(false, 3);
        } else if (this.state.selectedArea === _CONSTANTS.IDEAPROBLEM.DELOITTEINTERNAL) {
            this.props.handleOtherArea(false, 2);
        }
        if (this.state.isOptionChanged && parseInt(this.props.stepID) !== 5) {
            this.props.resetScore();
            this.props.updateQuestionArr([]);
        }
        // if (this.state.isOptionChanged && parseInt(this.props.stepID) == 5) {
        //     // this.props.resetScore();
        //     this.props.updateQuestionArr([]);
        // }
        this.props.overallSummaryDetails();
        this.handleModal();
    };

    /**
    * handles the radio change
    * @param {Event} e event
    */
    handleAreaChange = (e) => {
        const { id, value } = e.target;
        this.setState({ selectedArea: value, isOptionChanged: true });
        this.props.onAreaChange(id, value);
    };

    handleModal() {
        this.setState({ show: !this.state.show });
    };

    render() {
        return (
            <>
                <div className="question-option">
                <span className="area-text">{this.state.selectedAreaValue}</span>
                    {this.state.selectedAreaValue !== '' && this.state.questionDetail.questionId !== "1" 
                    && (
                        <>
                            <button
                                type="button"
                                className="btn btnText"
                                onClick={() => {
                                    this.handleModal();
                                }}
                            >
                                Edit
                            </button>
                        </>
                    )}
                    {this.state.selectedAreaValue === '' && (
                        <button
                            type="button"
                            className="btn btnText"
                            onClick={() => {
                                this.handleModal();
                            }}
                        >
                            Select
                        </button>
                    )}
                </div>
                <Modal
                    show={this.state.show}
                    onHide={() => {
                        this.handleModal();
                    }}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>What area the idea is aimed at?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h1>Select a category</h1>
                        {this.state.questionDetail.options.map((radioItem) => {
                            return (
                                <label className="radio-label" key={generateRandomKey()}>
                                    {radioItem.text}
                                    <input
                                        type="radio"
                                        name={this.state.questionDetail.questionType + this.state.questionDetail.questionId}
                                        id={radioItem.id}
                                        value={radioItem.text}
                                        checked={radioItem.selected}
                                        onChange={this.handleAreaChange}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            );
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => { this.saveAreaDetails() }}
                        >
                            Save changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default RadioText;