import { combineReducers } from 'redux';
import {
    FILE_UPLOAD_PENDING,
    FILE_UPLOAD_REJECTED,
    FILE_UPLOAD_FULFILLED,
    FILE_DOWNLOAD_PENDING,
    FILE_DOWNLOAD_FULFILLED,
    FILE_DOWNLOAD_REJECTED,
    FILE_DELETE_PENDING,
    FILE_DELETE_FULFILLED,
    FILE_DELETE_REJECTED,
    FILE_LIST_PENDING,
    FILE_LIST_FULFILLED,
    FILE_LIST_REJECTED,
    FILE_LIST1_PENDING,
    FILE_LIST1_FULFILLED,
    FILE_LIST1_REJECTED,
    FILE_LIST2_PENDING,
    FILE_LIST2_FULFILLED,
    FILE_LIST2_REJECTED,
    ATTACHMENT_LIST_PENDING,
    ATTACHMENT_LIST_FULFILLED,
    ATTACHMENT_LIST_REJECTED
} from '../action-types/utilities';
import {
    PENDING,
    DEFAULT,
    SUCCESS,
    REJECTED
} from '../../helpers/constants';


const initialMetaState = {
    FILE_UPLOAD_STATUS: DEFAULT,
    FILE_DOWNLOAD_STATUS: DEFAULT,
    FILE_DELETE_STATUS: DEFAULT,
    FILE_LIST_STATUS: DEFAULT,
    FILE_LIST1_STATUS: DEFAULT,
    FILE_LIST2_STATUS: DEFAULT,
    ATTACHMENT_LIST_STATUS: DEFAULT
}

const initialDataState = {
    existingFileList: [],
    existingFileList1: [],
    existingFileList2: []
}

function metaReducer(state = initialMetaState, action) {

    switch (action.type) {
        case FILE_UPLOAD_PENDING:
            return { ...state, FILE_UPLOAD_STATUS: PENDING }
        case FILE_UPLOAD_FULFILLED:
            return { ...state, FILE_UPLOAD_STATUS: SUCCESS }
        case FILE_UPLOAD_REJECTED:
            return { ...state, FILE_UPLOAD_STATUS: REJECTED }
        case FILE_DOWNLOAD_PENDING:
            return { ...state, FILE_DOWNLOAD_STATUS: PENDING }
        case FILE_DOWNLOAD_FULFILLED:
            return { ...state, FILE_DOWNLOAD_STATUS: SUCCESS }
        case FILE_DOWNLOAD_REJECTED:
            return { ...state, FILE_DOWNLOAD_STATUS: REJECTED }
        case FILE_DELETE_PENDING:
            return { ...state, FILE_DELETE_STATUS: PENDING }
        case FILE_DELETE_FULFILLED:
            return { ...state, FILE_DELETE_STATUS: SUCCESS }
        case FILE_DELETE_REJECTED:
            return { ...state, FILE_DELETE_STATUS: REJECTED }
        case FILE_LIST_PENDING:
            return { ...state, FILE_LIST_STATUS: PENDING }
        case FILE_LIST_FULFILLED:
            return { ...state, FILE_LIST_STATUS: SUCCESS }
        case FILE_LIST_REJECTED:
            return { ...state, FILE_LIST_STATUS: REJECTED }
        case FILE_LIST1_PENDING:
            return { ...state, FILE_LIST1_STATUS: PENDING }
        case FILE_LIST1_FULFILLED:
            return { ...state, FILE_LIST1_STATUS: SUCCESS }
        case FILE_LIST1_REJECTED:
            return { ...state, FILE_LIST1_STATUS: REJECTED }
        case FILE_LIST2_PENDING:
            return { ...state, FILE_LIST2_STATUS: PENDING }
        case FILE_LIST2_FULFILLED:
            return { ...state, FILE_LIST2_STATUS: SUCCESS }
        case FILE_LIST2_REJECTED:
            return { ...state, FILE_LIST2_STATUS: REJECTED }
        case ATTACHMENT_LIST_PENDING:
            return { ...state, ATTACHMENT_LIST_STATUS: PENDING }
        case ATTACHMENT_LIST_FULFILLED:
            return { ...state, ATTACHMENT_LIST_STATUS: SUCCESS }
        case ATTACHMENT_LIST_REJECTED:
            return { ...state, ATTACHMENT_LIST_STATUS: REJECTED }
        default:
            return state;
    }

}

function dataReducer(state = initialDataState, action) {

    switch (action.type) {
        case FILE_UPLOAD_FULFILLED:
            {
                return { ...state, fileData: action.payload.data }
            }
        case FILE_DOWNLOAD_FULFILLED:
            {
                return { ...state, file: action.payload.data }
            }
        case FILE_LIST_FULFILLED:
            return { ...state, existingFileList: action.payload.data }
        case FILE_LIST1_FULFILLED:
            return { ...state, existingFileList1: action.payload.data }
        case FILE_LIST2_FULFILLED:
            return { ...state, existingFileList2: action.payload.data }
        case ATTACHMENT_LIST_FULFILLED:
            return { ...state, attachmentList: action.payload.data }
        default:
            return state;
    }
}

export default combineReducers({
    meta: metaReducer,
    data: dataReducer
});