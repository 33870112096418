import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchTopChallenges } from '../../redux/actions/challenges';
import HomeChallengeItem from "./homeChallengeItem";
import { generateRandomKey, hideLoading, showLoader } from "../../helpers/utils";
import challenge from "../../img/ideation/challenge2.jpg";
import { fetchFileList, fetchFileList1, fetchFileList2 } from "../../redux/actions/utilities";

class topChallenges extends Component {
    state = {
        perPageRows: 3,
        pageIndex: 0,
        totalRecords: 0,
        renderJSON: [],
        userId: this.props.values && this.props.values.id
    };

    componentDidMount() {
        showLoader();
        this.props.fetchTopChallenges(this.state.pageIndex, this.state.perPageRows, this.state.userId);
        if (this.props.topChallenges && this.props.topChallenges.challenges && this.props.topChallenges.challenges.length > 0) {
            this.props.fetchFileList(this.props.topChallenges && this.props.topChallenges.challenges ? this.props.topChallenges.challenges[0].challengeID : '', "CHALLENGE");
        }
        if (this.props.topChallenges && this.props.topChallenges.challenges && this.props.topChallenges.challenges.length > 1) {
            this.props.fetchFileList1(this.props.topChallenges && this.props.topChallenges.challenges ? this.props.topChallenges.challenges[1].challengeID : '', "CHALLENGE");
        }
        if (this.props.topChallenges && this.props.topChallenges.challenges && this.props.topChallenges.challenges.length > 2) {
            this.props.fetchFileList2(this.props.topChallenges && this.props.topChallenges.challenges ? this.props.topChallenges.challenges[2].challengeID : '', "CHALLENGE");
        }
        setTimeout(() => {
            hideLoading();
        }, 1000);
    }

    render() {
        const { topChallenges } = this.props;
        let imageUrl0 = '';
        let imageUrl1 = '';
        let imageUrl2 = '';
        if (this.props.existingFileList !== undefined && this.props.existingFileList.length !== 0) {
            imageUrl0 = this.props.existingFileList.profile !== null ? this.props.existingFileList.profile.fileUrl : challenge;
        }
        if (this.props.existingFileList1 !== undefined && this.props.existingFileList1.length !== 0) {
            imageUrl1 = this.props.existingFileList1.profile ? this.props.existingFileList1.profile.fileUrl : challenge;
        }
        if (this.props.existingFileList2 !== undefined && this.props.existingFileList2.length !== 0) {
            imageUrl2 = this.props.existingFileList2.profile ? this.props.existingFileList2.profile.fileUrl : challenge;
        }
        return <>
            <section className="challengeMain">
                <article className="challengeLeft">
                    <div className="challengeHeader">
                        Our top challenges that will make your brain flex!
                    </div>
                    <div className="challengeSubHeader">
                        Try to solve these challenges to make your mark on the leadership
                        <div className="view-all-btn">
                            <a href={(this.props.values.userType === 'PANELLEAD' || this.props.values.userType === 'ADMIN') ? "/challengeList/admin" : "/challengeList/user"} className="btn btnText">View All Challenges</a>
                        </div>
                    </div>
                </article>
                <article className="challengeRight">
                    {topChallenges.challenges && topChallenges.challenges.map((challengeDetail, index) => {
                        return (
                            <HomeChallengeItem userId={this.state.userId} itemIndex={index} imageUrl0={imageUrl0} imageUrl1={imageUrl1} imageUrl2={imageUrl2} detail={challengeDetail} id={challengeDetail.challengeID} key={generateRandomKey()} />
                        );
                    })}
                    {topChallenges.challenges !== undefined && topChallenges.challenges.length === 0 && (
                        <p className="no-challenge-result">No Active Challenges to show</p>
                    )}
                </article>
            </section>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        FETCH_TOP_CHALLENGES_STATUS: state.challenges.meta.FETCH_TOP_CHALLENGES_STATUS,
        topChallenges: state.challenges.data.topChallenges,
        FILE_LIST_STATUS: state.utilities.meta.FILE_LIST_STATUS,
        FILE_LIST1_STATUS: state.utilities.meta.FILE_LIST1_STATUS,
        FILE_LIST2_STATUS: state.utilities.meta.FILE_LIST2_STATUS,
        existingFileList: state.utilities.data.existingFileList,
        existingFileList1: state.utilities.data.existingFileList1,
        existingFileList2: state.utilities.data.existingFileList2
    }
}

const mapDispatchToProps = {
    fetchTopChallenges,
    fetchFileList,
    fetchFileList1,
    fetchFileList2
}

export default connect(mapStateToProps, mapDispatchToProps)(topChallenges);