import React, { Component } from "react";
import logo from "../img/logo.svg";
import "../css/pages/amcPage.scss";


class siteDown extends Component{
    render() {
        return (
            <section className="sorry-page">
                <div className="blur-bg"></div>
                <article className="seedLogo clickable-logo">
                    <img src={logo} alt="Seed Logo" onClick={() => {
                        window.location.href = "/dashboard";
                    }}/>
                    <h1 className="heading">SEED is currently down for maintenance</h1>
                    <article className="sorry-page_content">
                        We are performing scheduled maintenance. We should be back online shortly. Thanks for your patience.
                        <p className="sorry-page_content-text">Keep Innovating!</p>
                        <p>-Team SEED</p>
                    </article>
                    {/* <a href="/dashboard" className="btn primary sorry-page_content-btn">Try Again</a> */}
                </article>
            </section>
        )
    }
}

export default siteDown;