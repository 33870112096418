import { Component } from "react";

class Release extends Component {

    render() {
        const { title, context, sections } = this.props;
        return <div className="release">
            <h2>{title}</h2>
            <p>{context}</p>
            {sections?.length > 0 && <ul className="sections">
                {sections?.map(sectionElem => <Section data={sectionElem} />)}
            </ul>}
        </div>
    }
}

class Section extends Component {
    render() {
        const { data } = this.props;
        console.log('sectionelem',data);
        return <>
            <h3>{data.title}</h3>
            <ul>
                {data.content?.map(ele => 
                    <li>{ele}</li>
                )}
            </ul>
        </>
    }
}
export default Release;