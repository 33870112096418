import React, { Component } from "react";
import logo from "../img/logo.svg";
import "../css/pages/importExportPage.scss";
import calendar from "../img/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "../css/pages/challengeCreationFlow.scss";
import closeBtn from "../img/close.svg";
import axios from "axios";
import { getBaseUrl } from "../helpers/utils";
import moment from "moment";
import { showLoader, hideLoading } from "../helpers/utils";
import DOMPurify from "dompurify";

const baseUrl = getBaseUrl();

const setDatePickerDate = new Date();
setDatePickerDate.setDate(setDatePickerDate.getDate());

class importExportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datePickerIsOpen: false,
      startDate: null,
      endDate: null,
      file: [],
      hideAttachmentBtn: false,
    };
    this.exportClickHandler = this.exportClickHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.importClickHandler = this.importClickHandler.bind(this);
  }

  openDatePickerKeyEventHandler(e) {
    if (e.key === "Enter") {
      this.setState({
        datePickerIsOpen: !this.state.datePickerIsOpen,
      });
    }
  }

  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  handleChange(dates) {
    const setDate = dates;
    const [start, end] = dates;
    this.setState({
      startDate: start,
      endDate: end,
    });
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  exportClickHandler() {
    showLoader();
    let startDate = new Date(this.state.startDate).toISOString();
    let endDate = new Date(this.state.endDate).toISOString();

    let exportUrl =
      baseUrl.ipAddress +
      "api/v1/ideas/export?" +
      `fromDate=${startDate}&toDate=${endDate}`;
    const a = document.createElement("a");
    a.setAttribute("href", exportUrl);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => {
      this.setState({
        startDate: null,
        endDate: null,
      });
      hideLoading();
    }, 3000);
  }

  importClickHandler() {
    showLoader();
    let formData = new FormData();
    if (this.state.file !== null){      
      formData.append('file', this.state.file);
    }
    axios.post(baseUrl.ipAddress + "api/v1/ideas/import", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        alert(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
      setTimeout(() => {
        this.setState({
          hideAttachmentBtn: false,
          file: []
        });
        hideLoading();
      }, 3000);
  }

  /**
   * removes file from the file list
   */
  removeSelectedFile = () => {
    this.setState({
      hideAttachmentBtn: false,
      file: []
    });
  };

  /**
   * on file selection, add to file list
   * @param {event} event event
   */
  onAttachmentSelectFile = (event) => {
    const files = event.target.files
    if (files) {
      if (
        !(files[0].name.indexOf(".xls") > -1)
      ) {
        alert("Please upload only .xls.");
      } else {
        this.setState({file: files[0]})
      }
    }
    this.setState({ hideAttachmentBtn: files.length === 1 });
    event.target.value = "";
  };

  render() {
    return (
      <>
        <section className="sorry-page">
          <div className="blur-bg"></div>
          <article className="seedLogo">
            <img src={logo} alt="Seed Logo" />
            <article className="sorry-page_content">
              <div className="import-export-container">
                <div className="export-container">
                  <p>Export Ideas</p>
                  <p>Select Date Range</p>
                  <div className="date-range-container d-flex">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      placeholderText={'Please select a date range'}
                      name="startDate"
                      dateFormat="dd/MM/yyyy"
                      onClickOutside={this.openDatePicker}
                      open={this.state.datePickerIsOpen}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      tabIndex={-1}
                      selectsRange
                    />
                    <img
                      src={calendar}
                      onClick={this.openDatePicker}
                      onKeyDown={(event) =>
                        this.openDatePickerKeyEventHandler(event)
                      }
                      alt="calendar"
                      className="YearMonth_calendar"
                      tabIndex={0}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary createChallenge exportBtn"
                      onClick={this.exportClickHandler}
                      disabled={this.state.endDate === null}
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div className="import-container">
                  <p className="import-header">Import Ideas</p>
                  <div>
                    <a className="btn btnText" href="https://amedeloitte.sharepoint.com/:x:/r/sites/SEEDCoreOperations/Shared%20Documents/General/Template_For_Ideas_Import.xlsx?d=wd98a98063ed54a14a89360c13080106a&csf=1&web=1&e=t8YHit" target="_blank">Click here to download the required format</a>
                  </div>
                  {!this.state.hideAttachmentBtn && (
                    <>
                      <button className="btn secondary" id="btnAttachFile">
                        Attach File
                      </button>
                      <input
                        type="file"
                        name="mrAttachments"
                        id="mrAttachments"
                        className="import-file"
                        accept=".pdf,.xls, .xlsx, .doc, .ppt, .docx, .pptx"
                        file={this.state.file}
                        onChange={(e) => this.onAttachmentSelectFile(e)}
                      />
                    </>
                  )}
                  {this.state.file.length !== 0 && (
                      <ul className="attachment-list">
                        <li key={this.state.file.name}>
                          <span>
                            {this.state.file.name}
                            {/* <button
                              onClick={() => this.removeSelectedFile()}
                            >
                              <img src={closeBtn} alt="removeFile" />
                            </button> */}
                          </span>
                        </li>
                      </ul>
                    )}
                    <div>
                      <button
                        className="btn btn-primary createChallenge importBtn"
                        onClick={this.importClickHandler}
                        disabled={this.state.file.length === 0}
                      >
                        Import
                      </button>
                  </div>
                </div>
              </div>
            </article>
          </article>
        </section>
      </>
    );
  }
}

export default importExportData;
