import React, { Component } from "react";
import Header from "../components/global/header";
import HomeHero from "../components/home/homeHero";
// import TopInnovators from "../components/home/topInnovators";
// import TopIdeasFromAround from "../components/home/topIdeasFrmAround";
import Leaderboard from "./leaderboard";
import TopChallenges from "../components/home/topChallenges";
import Topics from "../components/home/exploreTopics";
import "../css/home.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import firstLoginImg from "../img/home/rewards.svg";
import seedIcon from "../img/seedIcon.svg";
import { addPolicyCookieForProd } from "../helpers/utils";
import axios from "axios";
import HeroSlider from "../components/hero-slider/heroSlider";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFirstLoginModal: false,
    };
  }

  componentDidMount() {
    if (this.props.userData && this.props.userData.firstTimeUser) {
      this.setState({
        showFirstLoginModal: true,
      });
    }
    addPolicyCookieForProd();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userData.firstTimeUser !== this.props.userData.firstTimeUser) {
      if (this.props.userData.firstTimeUser) {
        this.setState({
          showFirstLoginModal: true,
        });
      }
    }
  }

  render() {
    const userJson = this.props.userData;
    const dashboard = this.props.dashboard;
    return (
      <>
        <section className="homeMain">
          <Header
            values={userJson}
            showSearch={true}
            showUser={true}
            pageName={"home"}
          />
          <HeroSlider />
          <div className="homeWrapper">
            <HomeHero dashboardDetails={dashboard} />
            <Leaderboard userData={userJson} />
            <TopChallenges values={userJson} />
          </div>
          {/* <TopIdeasFromAround values={userJson} /> */}
          <Topics dashboardDetails={dashboard} />
          <div className="homeWrapper">
            {/* <TopInnovators values={userJson} /> */}
          </div>
        </section>
        <Modal
          show={this.state.showFirstLoginModal}
          onHide={() => this.setState({ showFirstLoginModal: false })}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="rewards-modal"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="rewards-modal_body">
              <img src={firstLoginImg} alt="first-login" />
              <span>Congratulations</span>
              <p>
                You have received 500<img src={seedIcon} alt="seed" />
                <br />
                Keep Exploring the page to earn seeds
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Home;
