import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Header from "../components/global/header";
import Breadcrumbs from "../components/global/breadcrumbs";
import thumbsUp from "../img/thumbsup.svg";
import thumbsUpBlue from "../img/thumbsup-blue.svg";
import value from "../img/value.PNG";
import tools from "../img/tools.png";
import "../css/pages/draftSubmitted.scss";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  fetchIdeaDetails,
  getTeamMembersDetails,
} from "../redux/actions/ideas";
import { getTeamNameForChallenge } from "../redux/actions/utilities";
import config from "../helpers/api-urls";
import InvestInIdeaModal from "../components/ideation/investInIdeaModal";
import {
  doFileDownload,
  fetchIdeaAttachmentsList,
} from "../redux/actions/utilities";
import downloadIcon from "../img/downloadIcon.svg";
import LinkImg from "../img/external-link.svg";
import {
  generateRandomKey,
  checkForStatusColor,
  getNameInitials,
  showLoader,
  hideLoading,
  getBaseUrl,
} from "../helpers/utils";
import ideaImage from "../img/ideation/idea1.png";
import DropDown from "../components/global/dropdown";
import GlobalMessagePopup from "../components/global/globalMessagePopup";
import { _CONSTANTS } from "../helpers/constants";
import IdeaToChallenge from "./ideaToChallenge";
import Comments from "../components/idea-details/comments";
import activityIcon from "../img/activity.svg";
import problemIcon from "../img/problem.svg";
import attachIcon from "../img/attachment.svg";
import solutionIcon from "../img/solution.svg";
import downArr from "../img/acc-down-arr.svg";
import deleteIcon from "../img/delete-icon.svg";
import DOMPurify from "dompurify";

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, item } = props;
  const { header, id, type, iconUrl, text, html } = item;

  return (
    <>
      {header !== "" && (
        <div className="accordion-card">
          <div className="accordion-header">
            <div
              className={`accordion-toggle ${active === id ? "active" : ""}`}
              onClick={() => handleToggle(id)}
            >
              <img className="accordion-img" src={iconUrl} />
              <h5 className="accordion-title">{header}</h5>
              <img className="accordion-icon" src={downArr} atl="downarrow" />
            </div>
          </div>
          <div
            ref={contentEl}
            className={`collapse ${active === id ? "show" : ""}`}
          >
            <div className="accordion-body"
              dangerouslySetInnerHTML={{ __html: type === "text" ? text : ['attachment', 'activity'].includes(type) ? html : '' }}
            >
              {/* {type === "text" && <p>{text}</p>}
              {(type === "attachment" || type === "activity") && (
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const baseUrl = getBaseUrl();
const ideaStatusDropdownRole = [
  _CONSTANTS.USER.PANELLEAD,
  _CONSTANTS.USER.ADMIN,
];
let filterIdeaStatusDropdownOptions = [];
let selectedDropdownStatusItem = {};
const ideaStatusDropdownOptions = [
  {
    status: "Backlog",
    text: "Send to backlog, not an immediate priority",
  },
  {
    status: "Pitch",
    text: "Select for Pitch/Presentation",
  },
  {
    status: "Prototype",
    text: "Select for Prototype",
  },
  {
    status: "Pilot",
    text: "Select for Pilot",
  },
  {
    status: "Selected For Launch",
    text: "Selected For Launch",
  },
];
let s3FileUrl = "";

class Idea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likesCount: this.props.ideaDetails.likesCount || 0,
      likedByCurrentUser: this.props.ideaDetails.likedByCurrentUser || false,
      investInIdea: false,
      investButtonClicked: false,
      downloadedFileName: "",
      amountInvested: 0,
      downloadedFile: {
        ref: React.createRef(),
        url: null,
        fileName: "",
      },
      userId: 0,
      attachFileUrl: this.props.attachmentList
        ? this.props.attachmentList.attachments
        : [],
      showDuplicateModal: false,
      showSendBackModal: false,
      showOtherComment: false,
      disableSendBackBtn: true,
      disableDuplicateBtn: true,
      sendBackComment: "",
      maxCharCount: 255,
      sendBackCommentCount: 0,
      otherComment: "",
      otherCommentCount: 0,
      selectedReason: "",
      showBacklogModal: false,
      showAddTeamMemberModal: false,
      showDeleteTeamMemberModal: false,
      selectedDeleteTeamMember: {},
      disableAddBtn: true,
      showJoinContributorModal: false,
      disableJoinBtn: true,
      roleText: "",
      contLocation: "",
      addRoleText: "",
      addEmailText: "",
      addNameText: "",
      addLocationText: "",
      isEmailValid: false,
      membersDetails: {},
      requestDetails: [],
      showConfirmationModal: false,
      errorClass: "",
      errorMessage: "",
      showErrorModal: false,
      showMentorModal: false,
      mentorEmail: "",
      isMentorEmailValid: false,
      isMentorAddedSuccess: false,
      showMentorEmailValue: false,
      mentorName: this.props.ideaDetails.mentorName,
      isPanelAssigned: false,
      hasPanelEvaluated: false,
      showErrorText: true,
      showContributorBtn: true,
      selectedDropdownStatus: this.props.ideaDetails.ideaStatus,
      showUpdateBtn: false,
      showMessagePopup: false,
      showMentorEmailErrorMessage: false,
      s3FileUrl: "",
      active: null,
    };
    this.likeClickHandler = this.likeClickHandler.bind(this);
    this.setIsPanelAssigned = this.setIsPanelAssigned.bind(this);
    this.setIsPanelEvaluated = this.setIsPanelEvaluated.bind(this);
    this.isTeamMember = this.isTeamMember.bind(this);
  }

  hideDuplicateModal = () => {
    this.setState({ showDuplicateModal: false, disableDuplicateBtn: true });
  };

  hideSendBackModal = () => {
    this.setState({
      showSendBackModal: false,
      disableSendBackBtn: true,
      sendBackComment: "",
    });
  };

  updateSendBackComment = (e) => {
    let descp = e.target.value;
    if (descp.length <= this.state.maxCharCount) {
      this.setState({
        sendBackComment: descp,
        sendBackCommentCount: descp.length,
      });
    } else {
      descp = descp.substring(
        0,
        Math.min(descp.length, this.state.maxCharCount)
      );
      this.setState({
        sendBackComment: descp,
        sendBackCommentCount: descp.length,
      });
    }
    if (descp !== "") {
      this.setState({ disableSendBackBtn: false });
    } else {
      this.setState({ disableSendBackBtn: true });
    }
  };

  onMentorInputBlur = () => {
    if (this.state.mentorEmail === "") {
      this.setState({
        errorClass: "invalidInputField",
        showMentorEmailErrorMessage: true,
      });
    } else {
      const payload = {
        memberName: "",
        memberRole: "",
        panelist: false,
        userEmail: this.state.mentorEmail,
      };
      const url = config.validateEmailConfig.requestPayload.url;
      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        })
        .then((response) => {
          this.setState({
            errorClass: response.data ? "" : "invalidInputField",
            isMentorEmailValid: response.data,
            showMentorEmailValue: response.data,
            showMentorEmailErrorMessage: !response.data,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorClass: "invalidInputField",
            isMentorEmailValid: false,
            showMentorEmailValue: false,
            showMentorEmailErrorMessage: true,
          });
        });
    }
  };

  mentorchangeHandler = (e) => {
    const value = DOMPurify.sanitize(e.target.value)
    this.setState({ mentorEmail: value });
  };

  addMentorHandler = (ideaId) => {
    if (this.state.isMentorEmailValid && this.state.mentorEmail) {
      const payload = {
        mentorEmail: this.state.mentorEmail,
        ideaId: ideaId,
      };
      const addMentorUrl = config.addMentorConfig.requestPayload.url;
      var data = payload;
      var addMentorRequest = {
        method: "patch",
        url: addMentorUrl,
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`,
        },
        data: data,
      };
      axios(addMentorRequest)
        .then((response) => {
          const pageUrl = window.location.href;
          const ideaVal = pageUrl.substring(pageUrl.lastIndexOf("/") + 1);
          const userId = this.props.userData.id;
          if (userId) {
            this.setState({ userId });
            this.props.fetchIdeaDetails(
              parseInt(ideaVal.split("?")[0]),
              userId
            );
          }
          setTimeout(() => {
            this.setState({
              isMentorEmailValid: false,
              isMentorAddedSuccess: true,
              mentorName: this.props.ideaDetails.mentorName,
            });
          }, 1000);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  updateOtherComment = (e) => {
    const descp = e.target.value;
    if (descp.length <= this.state.maxCharCount) {
      this.setState({ selectedReason: descp });
      this.setState({ otherComment: descp, otherCommentCount: descp.length });
    }
  };

  handleSendBackToSubmitter = (ideaId, url, status) => {
    showLoader();
    this.hideSendBackModal();
    this.hideDuplicateModal();
    const payload = {
      status: status,
      feedbackComments:
        status.toLowerCase() === _CONSTANTS.IDEASTATUS.DUPLICATE.toLowerCase()
          ? this.state.selectedReason
          : this.state.sendBackComment,
      ideaID: ideaId,
      firstReviewer: this.props.ideaDetails?.ideaPanelistPojo?.length === 1,
    };
    axios
      .post(baseUrl.ipAddress + url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`,
        },
      })
      .then((response) => {
        window.location.href = "/ideaSubmission";
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  };

  componentDidMount() {
    showLoader();
    const pageUrl = window.location.href;
    const ideaId = pageUrl.substring(pageUrl.lastIndexOf("/") + 1);
    const userId = this.props.userData.id;
    if (userId) {
      this.setState({ userId });
      this.props.fetchIdeaDetails(parseInt(ideaId.split("?")[0]), userId)
        .then(res => console.log('[RES]: ', res))
        .catch(err =>  {
            // alert(err?.response?.data?.errorMessage || 'Something went wrong');
            alert('Idea details not found');
            window.location.href = "/dashboard"
        })
    }
    // this.props.fetchIdeaAttachmentsList(parseInt(ideaId.split("?")[0]), "IDEA");
    this.props.getTeamMembersDetails(parseInt(ideaId.split("?")[0]));
    // this.props.getTeamNameForChallenge(this.props.ideaDetails.challengeId, this.props.userData.email)
    setTimeout(() => {
      this.checkForEvaluationStatus();
    }, 1000);
    // console.log('[PROPS]: ', this.props);
  }

  getUploadedS3FileUrl = (fileName) => {
    showLoader();
    axios
      .get(baseUrl.getS3FileUrl + fileName)
      .then((response) => {
        this.setState({ s3FileUrl: response.data });
        hideLoading();
      })
      .catch((err) => {
        console.log(err);
        hideLoading();
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.addEmailText !== this.state.addEmailText) {
      if (
        this.state.addEmailText.includes("@") &&
        this.state.addEmailText.includes(".com")
      ) {
        const validateEmail = setTimeout(() => {
          this.onInputBlur();
        }, 2000);
      }
    }
    if (
      prevProps.FILE_DOWNLOAD_STATUS !== "SUCCESS" &&
      this.props.FILE_DOWNLOAD_STATUS === "SUCCESS"
    ) {
      const url = window.URL.createObjectURL(
        new Blob([this.props.file], { type: "application/pdf" })
      );
      this.setState(
        {
          downloadedFile: {
            ...this.state.downloadedFile,
            url: url,
            fileName: this.state.downloadedFileName,
          },
        },
        () => this.state.downloadedFile.ref.current.click()
      );
    }

    if (prevProps.attachmentList !== this.props.attachmentList) {
      this.setState({ attachFileUrl: this.props.attachmentList.attachments });
    }
    if (prevProps.membersDetails !== this.props.membersDetails) {
      const tempArr = this.props.membersDetails.requesters.filter(function (
        item
      ) {
        return item.approveStatus !== "REJECTED";
      });
      this.setState({
        membersDetails: this.props.membersDetails,
        requestDetails: tempArr,
        showContributorBtn: !this.userAlreadyExistsInTeam(
          this.props.userData.email
        ),
      });
    }
    if (prevProps.ideaDetails !== this.props.ideaDetails) {
      // this.setState({ ideaDetails: this.props.ideaDetails });
      this.setState({
        mentorName: this.props.ideaDetails.mentorName,
        likesCount: this.props.ideaDetails.likesCount,
        likedByCurrentUser: this.props.ideaDetails.likedByCurrentUser,
      });

      if (this.props.ideaDetails.ideaPanelistPojo?.length > 0) {
        const isPanelAssigned = this.setIsPanelAssigned(
          this.props.userData.email,
          this.props.ideaDetails.ideaPanelistPojo
        );
        const isPanelEvaluated = this.setIsPanelEvaluated(
          this.props.ideaDetails.ideaPanelistPojo
        );
        this.setState({ isPanelAssigned, hasPanelEvaluated: isPanelEvaluated });
      }

      if (
        ideaStatusDropdownRole.includes(this.props.userData.userType) &&
        // ideaStatusDropdownOptions.includes(this.props.ideaDetails.ideaStatus)
        ideaStatusDropdownOptions.some(
          (e) => e.status === this.props.ideaDetails.ideaStatus
        ) &&
        filterIdeaStatusDropdownOptions.length == 0
      ) {
        selectedDropdownStatusItem.name = this.props.ideaDetails.ideaStatus;
        ideaStatusDropdownOptions.map((option, index) => {
          // if (option.status !== this.props.ideaDetails.ideaStatus) {
          filterIdeaStatusDropdownOptions.push({
            text: option.text,
            value: option.status,
            selected: this.props.ideaDetails.ideaStatus === option.status,
            id: index + 1,
          });
          // }
        });
      }
      this.getUploadedS3FileUrl(this.props.ideaDetails.fileName);
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  setIsPanelAssigned(userEmail, panelReviewList) {
    return (
      panelReviewList?.some((panel) => {
        const { status = "", email = "" } = panel;
        return (
          userEmail?.toLowerCase() === email.toLowerCase() &&
          status !== "Completed"
        );
      }) || false
    );
  }

  setIsPanelEvaluated(panelReviewList) {
    return (
      panelReviewList?.some((panel) => {
        const { status = "" } = panel;
        return (
          status !== "Completed" && status !== _CONSTANTS.IDEASTATUS.PENDING
        );
      }) || false
    );
  }

  setSelectedDropdownItem(event, item) {
    this.setState({ selectedDropdownStatus: item.value, showUpdateBtn: true });
  }

  /**
   * user click on download file
   * @param {object} file file object to be downloaded
   */
  downloadFile(file) {
    this.props.doFileDownload(file.requestId, file.fileName);
    this.setState({ downloadedFileName: file.fileName });
  }

  /**
   * user click on like button
   * @param {Number} ideaId idea on which user clicked
   */
  likeClickHandler = (ideaId) => {
    showLoader();
    const isLiked = this.state.likedByCurrentUser ? false : true;
    // fire axios post call
    axios
      .post(
        config.ideaLikeConfig.requestPayload.url +
          "?ideaId=" +
          ideaId +
          "&isLiked=" +
          isLiked +
          "&userId=" +
          this.state.userId,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        this.setState({
          likesCount: response.data.likesCount,
          likedByCurrentUser: !this.state.likedByCurrentUser,
        });
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  };

  /**
   * user click on invest button
   */
  investInIdeaClickHandler = () => {
    this.setState({ investInIdea: true });
  };

  /**
   * updates the floag to show button
   * @param {boolean} invetInIdeaFlag
   * @param {boolean} investButtonClickedFlag
   * @param {Number} amountInvested amount invested by user
   */
  updateIdeaInvestmentFlag = (
    invetInIdeaFlag,
    investButtonClickedFlag,
    amountInvested
  ) => {
    this.setState({
      investInIdea: invetInIdeaFlag,
      investButtonClicked: investButtonClickedFlag,
      amountInvested: isNaN(amountInvested) ? 0 : amountInvested,
    });
  };

  /**
   * handles the radio change
   * @param {Event} e event
   */
  handleChange = (e) => {
    const value = DOMPurify.sanitize(e.target.value);
    if (value === _CONSTANTS.IDEASTATUS.OTHER) {
      this.setState({
        showOtherComment: true,
        selectedReason: value,
        disableDuplicateBtn: false,
      });
    } else {
      this.setState({
        showOtherComment: false,
        selectedReason: value,
        disableDuplicateBtn: false,
      });
    }
  };

  sendToBacklogHandler = (ideaId) => {
    showLoader();
    this.setState({ showBacklogModal: false });
    const payload = {
      status: _CONSTANTS.IDEASTATUS.BACKLOG,
      feedbackComments: this.state.sendBackComment,
      ideaID: ideaId,
    };
    axios
      .post(config.rejectIdeaConfig.requestPayload.url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`,
        },
      })
      .then((response) => {
        window.location.href = "/ideaSubmission";
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  };

  evaluateHandler = (ideaId) => {
    const userEmail = this.props.userData.email;
    const foundPanel = this.props.ideaDetails.ideaPanelistPojo.find(
      (panel) => panel.email === userEmail
    );
    window.location.href =
      "/evaluateIdea?id=" + ideaId + "&panel=" + foundPanel.panelId;
  };

  checkForEvaluationStatus = () => {
    const isPanelAssigned = this.setIsPanelAssigned(
      this.props.userData.email,
      this.props.ideaDetails.ideaPanelistPojo
    );
    const isPanelEvaluated = this.setIsPanelEvaluated(
      this.props.ideaDetails.ideaPanelistPojo
    );
    this.setState({ isPanelAssigned, hasPanelEvaluated: isPanelEvaluated });
  };

  handleRoleTextChange = (e) => {
    this.setState({ roleText: e.target.value });
    setTimeout(() => {
      const isValid = this.validateContributorFields();
      this.setState({ disableJoinBtn: !isValid });
    }, 500);
  };

  handleContributorLocationChange = (e) => {
    this.setState({
      contLocation: e.target.value,
    });
    setTimeout(() => {
      const isValid = this.validateContributorFields();
      this.setState({ disableJoinBtn: !isValid });
    }, 500);
  };

  handleAddRoleChange = (e) => {
    const val = DOMPurify.sanitize(e.target.value);
    this.setState({ addRoleText: val });
    setTimeout(() => {
      const isValid = this.validateModalFields();
      this.setState({ disableAddBtn: !isValid });
    }, 500);
  };

  handleAddEmailChange = (e) => {
    const val = DOMPurify.sanitize(e.target.value)
    this.setState({ addEmailText: val });
  };

  userAlreadyExistsInTeam = (userEmail) => {
    const { members, requesters } = this.props.membersDetails;
    let isAlreadyExists = false;
    isAlreadyExists = this.isTeamMember(members, userEmail);
    if (!isAlreadyExists) {
      isAlreadyExists = this.isTeamMember(requesters, userEmail);
    }
    return isAlreadyExists;
  };

  isTeamMember(members, userEmail) {
    return members?.some((member) => {
      const memberEmail = member?.userEmail || member?.memberEmail;
      return memberEmail === userEmail;
    });
  }

  onInputBlur = () => {
    if (
      this.state.addEmailText === "" ||
      this.state.addEmailText === this.props.userData.email
    ) {
      this.setState({ isEmailValid: false, errorClass: "invalidInputField" });
    } else if (this.userAlreadyExistsInTeam(this.state.addEmailText)) {
      this.setState({ isEmailValid: false, errorClass: "invalidInputField" });
    } else {
      const payload = {
        memberName: "",
        memberRole: "",
        panelist: false,
        userEmail: this.state.addEmailText,
      };
      const url = config.validateEmailConfig.requestPayload.url;
      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        })
        .then((response) => {
          this.setState({
            isEmailValid: response.data,
            errorClass: response.data ? "" : "invalidInputField",
            showErrorText: !response.data,
          });
          setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isEmailValid: false,
            errorClass: "invalidInputField",
            showErrorText: false,
          });
          setTimeout(() => {
            const isValid = this.validateModalFields();
            this.setState({ disableAddBtn: !isValid });
          }, 500);
        });
    }
    // setTimeout(() => {
    //   const isValid = this.validateModalFields();
    //   this.setState({ disableAddBtn: !isValid });
    // }, 1000);
  };

  handleAddNameChange = (e) => {
    const val = DOMPurify.sanitize(e.target.value);
    this.setState({ addNameText: val });
    setTimeout(() => {
      const isValid = this.validateModalFields();
      this.setState({ disableAddBtn: !isValid });
    }, 500);
  };

  //Add Member Location
  handleAddLocationChange = (e) => {
    const val = DOMPurify.sanitize(e.target.value);
    this.setState({ addLocationText: val });
    setTimeout(() => {
      const isValid = this.validateModalFields();
      this.setState({ disableAddBtn: !isValid });
    }, 500);
  };

  validateContributorFields = () => {
    let validated =
      this.state.roleText !== "" && this.state.contLocation !== "";
    return validated;
  };

  validateModalFields = () => {
    let validated =
      this.state.addNameText !== "" &&
      this.state.addEmailText !== "" &&
      this.state.addRoleText !== "" &&
      this.state.addLocationText !== "" &&
      this.state.isEmailValid;
    return validated;
  };

  deleteMember = (ideaId) => {
    showLoader();
    const payload = {
      memberEmail: this.state.selectedDeleteTeamMember.userEmail,
      ideaId: ideaId,
    };
    const deleteMemberUrl = config.deleteTeamMemberConfig.requestPayLoad.url;
    var data = payload;
    var deleteMemberRequest = {
      method: "delete",
      url: deleteMemberUrl,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        idToken: `${localStorage.getItem("id_token")}`,
      },
      data: data,
    };
    axios(deleteMemberRequest)
      .then((response) => {
        let newMemberDetails = this.state.membersDetails.members.filter(
          (member) => {
            if (member.userEmail !== payload.memberEmail) {
              return member;
            }
          }
        );
        this.setState({
          showDeleteTeamMemberModal: false,
        });
        this.props.getTeamMembersDetails(this.props.ideaDetails.ideaId);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  };

  addMember = (ideaId, isSubmitter) => {
    showLoader();
    const payload = {
      ideaId: ideaId,
      memberEmail: isSubmitter
        ? this.state.addEmailText
        : this.props.userData.email,
      memberName: isSubmitter
        ? this.state.addNameText
        : this.props.userData.firstName + " " + this.props.userData.lastName,
      memberRole: isSubmitter ? this.state.addRoleText : this.state.roleText,
      memberLocation: isSubmitter
        ? this.state.addLocationText
        : this.state.contLocation,
      submitter: isSubmitter ? isSubmitter : false,
      requesterComment: "",
      submitterComment: "",
    };
    axios
      .post(config.addTeamMemberConfig.requestPayload.url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          idToken: `${localStorage.getItem("id_token")}`,
        },
      })
      .then((response) => {
        this.setState({
          showConfirmationModal: true,
          showAddTeamMemberModal: false,
          showJoinContributorModal: false,
        });
        setTimeout(() => {
          this.setState({
            showConfirmationModal: false,
            errorClass: "invalidInputField",
            addEmailText: "",
            addNameText: "",
            addRoleText: "",
            roleText: "",
            addLocationText: "",
          });
          this.props.getTeamMembersDetails(this.props.ideaDetails.ideaId);
        }, 3000);
        // this.setState({ newTeamMemberDetails: payload });
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: error.response.data.errorMessage,
          showErrorModal: true,
          showJoinContributorModal: false,
          showAddTeamMemberModal: false,
        });
        setTimeout(() => {
          this.setState({ showErrorModal: false, errorMessage: "" });
        }, 3000);
        hideLoading();
      });
  };

  handleStatusChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      selectedDropdownStatus: value,
      showUpdateBtn: value === this.props.ideaDetails.ideaStatus ? false : true,
    });
    filterIdeaStatusDropdownOptions = [];
    ideaStatusDropdownOptions.map((option, index) => {
      filterIdeaStatusDropdownOptions.push({
        text: option.text,
        value: option.status,
        selected: value === option.status,
        id: index + 1,
      });
    });
  };

  updateIdeaClickHandler = (ideaId, prevIdeaStatus, nextIdeaStatus) => {
    showLoader();
    const pageUrl = window.location.href;
    const ideaVal = pageUrl.substring(pageUrl.lastIndexOf("/") + 1);
    let updateIdeaUrl = config.ideaStateChangeConfig.requestPayload.url;
    const userId = this.props.userData.id;
    updateIdeaUrl += `?ideaId=${ideaId}&previousStateLevel=${prevIdeaStatus}&currentStateLevel=${nextIdeaStatus}`;
    axios
      .put(
        updateIdeaUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((data) => {
        this.props.fetchIdeaDetails(parseInt(ideaVal.split("?")[0]), userId);
        this.setState({ showMessagePopup: true, showUpdateBtn: false });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          this.setState({
            showMessagePopup: false,
          });
        }, 5000);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
  };

  handleToggle = (index) => {
    if (this.state.active === index) {
      this.setState({ active: null });
    } else {
      this.setState({ active: index });
    }
  };

  render() {
    const userData = this.props.userData;
    const ideaDetails = this.props.ideaDetails || {};
    const { tgleadComment = "", userType } = ideaDetails;
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "",
        text: "Idea",
      },
    ];
    const messageData = {
      type: "success",
      title: "Idea Status Updated",
      // detailText: "You have moved the idea to a new status"
    };

    const accordionItems = [
      {
        id: 1,
        header: "Business opportunity",
        iconUrl: problemIcon,
        type: "text",
        text: typeof ideaDetails !== undefined ? ideaDetails.description : "",
      },
      {
        id: 2,
        header: "High level overview of solution",
        iconUrl: solutionIcon,
        type: "text",
        text: typeof ideaDetails !== undefined ? ideaDetails.ideaSolution : "",
      },
      {
        id: 3,
        header: "Tool(s) needed to bring solution to life",
        iconUrl: tools,
        type: "text",
        text: typeof ideaDetails !== undefined ? ideaDetails.tools : "",
      },
      {
        id: 4,
        header: "Potential value delivered (Qualitative and Quantitative)",
        iconUrl: value,
        type: "text",
        text:
          typeof ideaDetails !== undefined ? ideaDetails.potentialValue : "",
      },
      {
        id: 5,
        header:
          ideaDetails.fileName !== null &&
          ideaDetails.attachmentURL !== null &&
          ideaDetails.attachmentURL !== ""
            ? "Market research/Supporting document"
            : "",
        iconUrl: attachIcon,
        type: "attachment",
        html: `<div class="attachment-detail"><div>${ideaDetails.fileName}</div>
                    <a target="_blank" class="btn btnText attachment-detail_link" href=${
                      this.state.s3FileUrl || ideaDetails.attachmentURL
                    }>
                      View <img src=${LinkImg} alt="link" />
                    </a></div>`,
      },
      {
        id: 6,
        header:
          ideaDetails.userACreator &&
          ideaDetails.feedbackComments !== null &&
          ideaDetails.feedbackComments !== undefined
            ? "Comments"
            : "",
        iconUrl: activityIcon,
        type: "activity",
        html: `<div class="comment-section" id="feedbackComment">
                    <div class="comment-section_tag"><p>Panelist</p><span>This comment is only visible to you</span></div>
                    <p class="comment-section_comment">${ideaDetails.feedbackComments}</p>
                  </div>`,
      },
    ];

    return (
      <>
        {this.state.showMessagePopup && (
          <GlobalMessagePopup
            showMessagePopup={this.state.showMessagePopup}
            messageData={messageData}
          />
        )}
        <Header
          values={userData}
          showSearch={true}
          showUser={true}
          pageName={"ideaDetail"}
        />
        <Breadcrumbs breadcrumbs={breadcrumbJSON} />
        <section className="homeMain ideaMain">
          <div className="ideaLeftSection">
            <div
              className="ideaHeader"
              title={
                Object.keys(ideaDetails) && Object.keys(ideaDetails)?.length > 0 ? ideaDetails.ideaName : ""
              }
            >
              {Object.keys(ideaDetails)?.length > 0
                ? ideaDetails.ideaName?.length > 100
                  ? `${ideaDetails.ideaName.substring(0, 95)} ...`
                  : ideaDetails.ideaName
                : ""}
            </div>
            {(ideaDetails.userType === _CONSTANTS.USER.PANELIST ||
              ideaDetails.userType === _CONSTANTS.USER.PANELLEAD ||
              ideaDetails.userType === _CONSTANTS.USER.ADMIN ||
              ideaDetails.userType === _CONSTANTS.USER.SUBMITTER) && (
              <div className="idea-details_panelView">
                <div className="panelView-item">
                  Code : {ideaDetails.ideaId}
                </div>
                <div className="panelView-item">
                  Category : {ideaDetails.ideaType ? ideaDetails.ideaType : ""}
                </div>
              </div>
            )}
            <div className="selectedTags">
              {typeof ideaDetails !== undefined && ideaDetails.tags
                ? ideaDetails.tags.map((tag, index) => {
                    return (
                      <label className="checkLabel" key={generateRandomKey()}>
                        {tag.tagName}
                      </label>
                    );
                  })
                : ""}
            </div>
            <div className="ideaStatus-indutry">
              <div className="ideaStatus">
                <span
                  className={checkForStatusColor(
                    ideaDetails.ideaStatus,
                    "ideaStatus-heading"
                  )}
                >
                  Idea Status:
                </span>
                {ideaDetails.ideaStatus}
              </div>
              <div className="industry">
                <span
                  className={checkForStatusColor(
                    ideaDetails.ideaStatus,
                    "ideaStatus-heading"
                  )}
                >
                  Industry:
                </span>
                {ideaDetails.industry}
              </div>
            </div>
            <div className="ideaStatus-indutry">
              <div className="ideaStatus">
                <span
                  className={checkForStatusColor(
                    ideaDetails.ideaStatus,
                    "ideaStatus-heading"
                  )}
                >
                  Sector:
                </span>
                {ideaDetails.sector}
              </div>
              <div className="industry">
                <span
                  className={checkForStatusColor(
                    ideaDetails.ideaStatus,
                    "ideaStatus-heading"
                  )}
                >
                  Domain:
                </span>
                {ideaDetails.domain}
              </div>
            </div>
            <div className="ideaStatus-indutry">
              {(ideaDetails.priority !== null) && (<div className="ideaStatus"><span className={checkForStatusColor(ideaDetails.ideaStatus, "ideaStatus-heading")}>Priority:</span> {ideaDetails.priority}</div>)}
              {(ideaDetails.alliance !== null) && (<div className="industry"><span className={checkForStatusColor(ideaDetails.ideaStatus, "ideaStatus-heading")}>Alliance:</span> {ideaDetails.alliance}</div>)}
              {(ideaDetails.allainceOther !== null) && (<div className="industry"><span className={checkForStatusColor(ideaDetails.ideaStatus, "ideaStatus-heading")}>Alliance Others:</span> {ideaDetails.allainceOther}</div>)}
              {(ideaDetails.useCase !== null) && (<div className="industry"><span className={checkForStatusColor(ideaDetails.ideaStatus, "ideaStatus-heading")}>Use Case:</span> {ideaDetails.useCase}</div>)}
            </div>
            <div className="ideaPeople">
              <div className="idea-people-mentor d-flex">
                <div className="idea-details-item">
                  <div className="title">idea by</div>
                  <div className="description">
                    <div className="userCreds">
                      {typeof ideaDetails !== undefined &&
                      ideaDetails.peopleData
                        ? getNameInitials(ideaDetails.peopleData.ideaBy.name)
                        : ""}
                    </div>
                    <p>
                      {typeof ideaDetails !== undefined &&
                      ideaDetails.peopleData
                        ? ideaDetails.peopleData.ideaBy.name
                        : ""}
                    </p>
                  </div>
                </div>
                {ideaDetails.mentorName && (
                  <div className="idea-details-item">
                    <div className="title">Mentor</div>
                    <div className="description">
                      <div className="userCreds">
                        {typeof ideaDetails !== undefined &&
                        this.state.mentorName
                          ? getNameInitials(this.state.mentorName)
                          : ""}
                      </div>
                      <p>
                        {typeof ideaDetails !== undefined &&
                        this.state.mentorName
                          ? this.state.mentorName
                          : ""}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {/* {(ideaDetails.userType === _CONSTANTS.USER.PANELIST ||
                ideaDetails.userType === _CONSTANTS.USER.PANELLEAD ||
                ideaDetails.userType === _CONSTANTS.USER.ADMIN) && (
                  <div className="idea-dueDate">
                    <div className="title">due in</div>
                    <div className="description">
                      <p>{ideaDetails.dueIn.replace(/-/g, "")}</p>
                      <span>{`(${ideaDetails.dueDate})`}</span>
                    </div>
                  </div>
                )} */}
              {typeof ideaDetails !== undefined &&
                ideaDetails.peopleData !== undefined &&
                ideaDetails.peopleData.collaborators !== undefined &&
                ideaDetails.peopleData.collaborators.length > 0 && (
                  <div className="idea-people-collab">
                    <div className="title">collaborators</div>
                    <div className="collab-container">
                      {typeof ideaDetails !== undefined &&
                        ideaDetails.peopleData.collaborators &&
                        ideaDetails.peopleData.collaborators.map(
                          (user, index) => {
                            return (
                              <div
                                className="description"
                                key={generateRandomKey()}
                              >
                                <img src={user.imageUrl} alt={user.name} />
                                <p>{user.name}</p>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                )}
            </div>
            {/* <div className="ideaDesc">
              <div className="ideaDesc-title">Problem Statement:</div>
              <p className="ideaDesc-detail">
                {typeof ideaDetails !== undefined
                  ? ideaDetails.description
                  : ""}
              </p>
              <div className="ideaDesc-title">Solution:</div>
              <p className="ideaDesc-detail">
                {typeof ideaDetails !== undefined
                  ? ideaDetails.ideaSolution
                  : ""}
              </p>
            </div> */}
            <div className="ideaDesc">
              {accordionItems.map((item, index) => {
                if(item.text !== 'N/A' && item.text !== '') {
                  return (
                    <AccordionItem
                      key={index}
                      active={this.state.active}
                      handleToggle={(index) => this.handleToggle(index)}
                      item={item}
                    />
                  );
                }
              })}
            </div>
            {/* {ideaDetails.userACreator && (ideaDetails.feedbackComments !== null && ideaDetails.feedbackComments !== undefined) && (
              <div className="comment-section" id="feedbackComment">
                <div className="comment-section_heading">Comments</div>
                <div className="comment-section_tag"><p>Panelist</p><span>This comment is only visible to you</span></div>
                <p className="comment-section_comment">{ideaDetails.feedbackComments}</p>
              </div>
            )} */}
          </div>
          <div className="ideaRightSection">
            {ideaDetails.userType === _CONSTANTS.USER.PANELIST &&
              (ideaDetails.ideaStatus === _CONSTANTS.IDEASTATUS.SUBMITTED ||
                ideaDetails.ideaStatus === _CONSTANTS.IDEASTATUS.UNDERREVIEW ||
                ideaDetails.ideaStatus ===
                  _CONSTANTS.IDEASTATUS.EVALUATIONREVIEW) &&
              this.state.isPanelAssigned && (
                <div className="evaluate-section">
                  <div className="evaluate-section_btn-cont">
                    <button
                      className="btn primary"
                      type="button"
                      disabled={this.state.hasPanelEvaluated}
                      onClick={() => this.evaluateHandler(ideaDetails.ideaId)}
                    >
                      Evaluate
                    </button>
                  </div>

                  {/* <div className="align-center">
                  <div>
                    <span>Insufficient information?</span>
                    <button
                      className="btn btnText"
                      type="button"
                      disabled={this.state.hasPanelEvaluated}
                      onClick={() => this.setState({ showSendBackModal: true })}
                    >
                      Let the submitter know
                    </button>
                  </div>
                  <div>
                    <span>Seen similar idea?</span>
                    <button
                      className="btn btnText"
                      type="button"
                      disabled={this.state.hasPanelEvaluated}
                      onClick={() =>
                        this.setState({ showDuplicateModal: true })
                      }
                    >
                      Mark as Duplicate/Not Relevant
                    </button>
                  </div>
                </div> */}
                </div>
              )}
            {(ideaDetails.userType === _CONSTANTS.USER.PANELLEAD ||
              ideaDetails.userType === _CONSTANTS.USER.ADMIN) &&
              (ideaDetails.ideaStatus === _CONSTANTS.IDEASTATUS.MENTOR ||
                ideaDetails.ideaStatus === _CONSTANTS.IDEASTATUS.SUBMITTED ||
                ideaDetails.ideaStatus === _CONSTANTS.IDEASTATUS.UNDERREVIEW ||
                ideaDetails.ideaStatus ===
                  _CONSTANTS.IDEASTATUS.EVALUATIONREVIEW) && (
                <div className="tg-lead-view-btn">
                  <div className="tg-lead-view-btn_btn-cont">
                    {this.props.ideaDetails.ideaStatus !==
                      _CONSTANTS.IDEASTATUS.MENTOR && (
                      <button
                        className="btn primary assign-panelist-btn"
                        onClick={() => {
                          window.location.href =
                            "/talentReview?id=" + ideaDetails.ideaId;
                        }}
                      >
                        {ideaDetails.ideaPanelistPojo?.length > 0 &&
                        ideaDetails.ideaPanelistPojo[0].email
                          ? "Review"
                          : "Assign Panelist(s)"}
                      </button>
                    )}
                  </div>

                  <div>
                    

                    <button
                      className="btn btnText assign-mentor-btn secondary"
                      
                      onClick={() =>
                        this.setState({
                          showMentorModal: true,
                          isMentorAddedSuccess: false,
                        })
                      }
                    >
                      {this.props.ideaDetails.mentorEmail ||
                      this.state.mentorEmail
                        ? "Edit Mentor"
                        : "Assign Mentor"}
                    </button>
                    <Modal
                      show={this.state.showMentorModal}
                      onHide={() => this.setState({ showMentorModal: false })}
                      backdrop="static"
                      keyboard={false}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      dialogClassName="mentor-modal-body"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{ideaDetails.ideaName}</Modal.Title>
                      </Modal.Header>
                      {!this.state.isMentorAddedSuccess ? (
                        <Modal.Body>
                          <div className="bodyHeader">
                            <div className="bodyHeaderHeading">
                              Assign a new mentor
                            </div>
                            <div className="bodyHeaderSubHeading">
                              Please enter mentor's email ID
                            </div>
                            <input
                              className={this.state.errorClass}
                              type="email"
                              placeholder="Enter Email addess"
                              value={this.state.mentorEmail}
                              onChange={(e) => this.mentorchangeHandler(e)}
                              // onBlur={this.onMentorInputBlur}
                              onKeyUp={(e) => this.onMentorInputBlur(e)}
                            />
                            {this.state.showMentorEmailErrorMessage && (
                              <label className="validate-email-label">
                                Please enter a valid email address
                              </label>
                            )}
                          </div>
                        </Modal.Body>
                      ) : (
                        <Modal.Body>
                          <div className="ideaSuccessMain">
                            <div className="submittedImage">
                              <img
                                src={ideaImage}
                                alt={_CONSTANTS.IDEASTATUS.SUBMITTED}
                              />
                            </div>
                            <div className="submittedDetails">
                              {/* <div className="submittedDetails_header">
                              Mentor notified successfully!
                            </div> */}
                              <div className="submittedDetails_subheader">
                                This Idea is sent to "{this.state.mentorEmail}"
                                for mentoring
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      )}
                      <Modal.Footer>
                        {!this.state.isMentorAddedSuccess ? (
                          <button
                            className="btn primary"
                            disabled={
                              !this.state.isMentorEmailValid ||
                              !this.state.mentorEmail
                            }
                            onClick={() =>
                              this.addMentorHandler(ideaDetails.ideaId)
                            }
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            onClick={(event) =>
                              (window.location.href = "/idealist")
                            }
                            className="btn primary mentor-success-container"
                          >
                            Evaluate more ideas
                          </button>
                        )}
                      </Modal.Footer>
                    </Modal>
                    
                    <button
                          className="btn btnText assign-mentor-btn secondary"
                          type="button"
                          disabled={this.state.hasPanelEvaluated}
                          onClick={() =>
                            this.setState({ showDuplicateModal: true })
                          }
                        >
                          Mark As Duplicate
                        </button>
                        <Modal
                          show={this.state.showDuplicateModal}
                          onHide={this.hideDuplicateModal}
                          backdrop="static"
                          keyboard={false}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          dialogClassName="duplicate-modal"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Duplicate/Not Relevant Idea
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="bodyHeader">
                              <div className="bodyHeaderHeading">
                                Select a reason from the options below
                              </div>
                              <label>
                                Similar idea
                                <input
                                  type="radio"
                                  name="duplicateIdea"
                                  className=""
                                  id="1"
                                  value="Similar idea"
                                  onChange={this.handleChange}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label>
                                Resonates with an internal live project
                                <input
                                  type="radio"
                                  name="duplicateIdea"
                                  className=""
                                  id="2"
                                  value="Resonates with an internal live project"
                                  onChange={this.handleChange}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label>
                                Other
                                <input
                                  type="radio"
                                  name="duplicateIdea"
                                  className=""
                                  id="3"
                                  value={_CONSTANTS.IDEASTATUS.OTHER}
                                  onChange={this.handleChange}
                                />
                                <span className="checkmark"></span>
                              </label>
                              {this.state.showOtherComment && (
                                <>
                                  <textarea
                                    value={this.state.otherComment}
                                    onChange={(e) => this.updateOtherComment(e)}
                                    type="text"
                                    placeholder="Add in your comment"
                                    className="other-textarea"
                                  />
                                  <span className="charcter-counter">
                                    {this.state.otherCommentCount}/
                                    {this.state.maxCharCount}
                                  </span>
                                </>
                              )}
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              className="btn primary"
                              disabled={this.state.disableDuplicateBtn}
                              onClick={(event) =>
                                this.handleSendBackToSubmitter(
                                  ideaDetails.ideaId,
                                  ideaDetails.sendBackToSubmitterUrl,
                                  _CONSTANTS.IDEASTATUS.DUPLICATE
                                )
                              }
                            >
                              Mark as Duplicate
                            </button>
                          </Modal.Footer>
                        </Modal>

                    <div>

                      {this.props.ideaDetails.ideaStatus !==
                      _CONSTANTS.IDEASTATUS.MENTOR && (
                      <button
                        className=" mr-4 btn btnText assign-mentor-btn secondary"
                        onClick={() =>
                          this.setState({ showBacklogModal: true })
                        }
                      >
                        Backlog
                      </button>
                    )}
                    <Modal
                      show={this.state.showBacklogModal}
                      onHide={() =>
                        this.setState({
                          showBacklogModal: false,
                          sendBackComment: "",
                        })
                      }
                      backdrop="static"
                      keyboard={false}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      dialogClassName="sendBack-modal"
                    >
                      <Modal.Header>
                        <Modal.Title>Send to Backlog</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="bodyHeader">
                          <div className="bodyHeaderHeading">
                            Add your comment below
                          </div>
                          <textarea
                            value={this.state.sendBackComment}
                            type="text"
                            placeholder="Add in your comment"
                            onChange={(e) => this.updateSendBackComment(e)}
                          />
                          <span className="charcter-counter">
                            {this.state.sendBackCommentCount}/
                            {this.state.maxCharCount}
                          </span>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn secondary"
                          onClick={() =>
                            this.setState({ showBacklogModal: false })
                          }
                        >
                          Cancel
                        </button>
                        <button
                          className="btn primary"
                          disabled={this.state.disableSendBackBtn}
                          onClick={() =>
                            this.sendToBacklogHandler(ideaDetails.ideaId)
                          }
                        >
                          Send to Backlog
                        </button>
                      </Modal.Footer>
                    </Modal>

                        <button
                          className="btn btnText assign-mentor-btn secondary"
                          //type="button"
                          disabled={this.state.hasPanelEvaluated}
                          onClick={() =>
                            this.setState({ showSendBackModal: true })
                          }
                        >
                          Insufficient Information
                        </button>
                        <Modal
                          show={this.state.showSendBackModal}
                          onHide={this.hideSendBackModal}
                          backdrop="static"
                          keyboard={false}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          dialogClassName="sendBack-modal"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Insufficient information</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="bodyHeader">
                              <div className="bodyHeaderHeading">
                                Add your comment below
                              </div>
                              <textarea
                                value={this.state.sendBackComment}
                                type="text"
                                placeholder="Add in your comment"
                                onChange={(e) => this.updateSendBackComment(e)}
                              />
                              <span className="charcter-counter">
                                {this.state.sendBackCommentCount}/
                                {this.state.maxCharCount}
                              </span>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              className="btn primary"
                              disabled={this.state.disableSendBackBtn}
                              onClick={(event) =>
                                this.handleSendBackToSubmitter(
                                  ideaDetails.ideaId,
                                  ideaDetails.sendBackToSubmitterUrl,
                                  "Draft"
                                )
                              }
                            >
                              Send back to the submitter
                            </button>
                          </Modal.Footer>
                        </Modal>
                  
                    </div>
                  </div>
                </div>
              )}
            {!ideaDetails.userACreator &&
              ((ideaDetails.userType !== _CONSTANTS.USER.PANELIST &&
                ideaDetails.userType !== _CONSTANTS.USER.PANELLEAD &&
                ideaDetails.userType !== _CONSTANTS.USER.ADMIN &&
                !ideaDetails.userACreator) ||
                (ideaDetails.userType === _CONSTANTS.USER.PANELIST &&
                  !this.state.isPanelAssigned)) && (
                <div className="dvGameLikes">
                  {/* <div className="dvGameLikes_detail">{this.state.likesCount}{this.state.likesCount > 1 ? " Likes" : " Like"}<span>1,234 Seeds invested</span></div> */}
                  <div className="dvGameLikes_btn-cont">
                    <button
                      className={classNames("btn secondary", {
                        isLiked: this.state.likedByCurrentUser,
                      })}
                      onClick={() => {
                        this.likeClickHandler(ideaDetails.ideaId);
                      }}
                    >
                      <img
                        src={
                          this.state.likedByCurrentUser
                            ? thumbsUp
                            : thumbsUpBlue
                        }
                        alt=""
                        className="likeImg"
                      />
                      {this.state.likedByCurrentUser &&
                      this.state.likesCount > 1
                        ? " Likes"
                        : " Like"}
                      <span className="likeCount">{this.state.likesCount}</span>
                    </button>
                    {ideaDetails.ideaStatus !== "Backlog" &&
                      ideaDetails.ideaStatus !== "Duplicate" && (
                        <button
                          className="btn primary"
                          onClick={() => {
                            this.investInIdeaClickHandler();
                          }}
                        >
                          Invest Seeds
                        </button>
                      )}
                  </div>
                </div>
              )}
            {(this.state.investInIdea || this.state.investButtonClicked) && (
              <div className="investInIdeaSection">
                <InvestInIdeaModal
                  onDataChange={this.updateIdeaInvestmentFlag}
                  ideaDetails={ideaDetails}
                  userData={this.props.userData}
                  investInIdeaFlag={this.state.investInIdea}
                  investButtonClicked={this.state.investButtonClicked}
                  investedAmount={this.state.amountInvested}
                />
              </div>
            )}
            {/* <div className="ideaDetaisImage">
              {typeof ideaDetails !== undefined && ideaDetails.imageURL
                ? <img
                  src={ideaDetails.imageURL}
                  alt="idea"
                />
                : <img
                  src={placeholderImg}
                  alt="idea"
                />}
            </div> */}
            {!ideaDetails.userACreator &&
              ((ideaDetails.userType !== _CONSTANTS.USER.PANELIST &&
                ideaDetails.userType !== _CONSTANTS.USER.PANELLEAD &&
                ideaDetails.userType !== _CONSTANTS.USER.ADMIN &&
                !ideaDetails.userACreator) ||
                (ideaDetails.userType === _CONSTANTS.USER.PANELIST &&
                  !this.state.isPanelAssigned)) && (
                <div className="dvGameLikes mobile-view">
                  {/*  <div className="dvGameLikes_detail">{this.state.likesCount}{this.state.likesCount > 1 ? " Likes" : " Like"}<span>1,234 Seeds invested</span></div> */}
                  <div className="dvGameLikes_btn-cont">
                    <button
                      className={classNames("btn secondary", {
                        isLiked: this.state.likedByCurrentUser,
                      })}
                      onClick={() => {
                        this.likeClickHandler(ideaDetails.ideaId);
                      }}
                    >
                      <img
                        src={
                          this.state.likedByCurrentUser
                            ? thumbsUp
                            : thumbsUpBlue
                        }
                        alt=""
                        className="likeImg"
                      />
                      {this.state.likedByCurrentUser &&
                      this.state.likesCount > 1
                        ? " Likes"
                        : " Like"}
                      <span className="likeCount">{this.state.likesCount}</span>
                    </button>
                    {ideaDetails.ideaStatus !== "Backlog" &&
                      ideaDetails.ideaStatus !== "Duplicate" && (
                        <button
                          className="btn primary"
                          onClick={() => {
                            this.investInIdeaClickHandler();
                          }}
                        >
                          Invest Seeds
                        </button>
                      )}
                  </div>
                </div>
              )}

            <IdeaToChallenge ideaDetails={ideaDetails} />
            {/* {this.state.attachFileUrl && this.state.attachFileUrl.length > 0 && ( */}
            {/* {(ideaDetails.fileName !== null && ideaDetails.attachmentURL !== null && ideaDetails.attachmentURL !== '') && (
              <div className="ideaSection-detail attachments">
                <div className="header">Attachments</div>
                <ul className="attachment-detail">
                        <li
                          key={generateRandomKey()}
                        >
                          <div>{ideaDetails.fileName}</div>
                          <a target="_blank" className="btn btnText attachment-detail_link" href={this.state.s3FileUrl || ideaDetails.attachmentURL}>
                            View <img src={LinkImg} alt="link" />
                          </a>
                        </li>
                </ul>
              </div>
            )} */}
            {
              //ideaDetails.userACreator &&
              this.state.membersDetails.teamName !== "" &&
                this.state.membersDetails.teamName !== null && (
                  <div className="ideaSection-detail">
                    <div className="ideaSection-detail_subsection">
                      <div className="subsection-header">
                        <p>The Team</p>
                        <label>{this.props.membersDetails.teamName}</label>
                        {/*  {Object.keys(this.state.membersDetails).length > 0 &&
                      this.state.membersDetails.members.length < 8 && (
                        <button
                          className="btn btnText"
                          onClick={() =>
                            this.setState({ showAddTeamMemberModal: true, showErrorText: false, disableAddBtn : true })
                          }
                        >
                          <span>+</span>Add Team Member
                        </button>
                    )} */}
                      </div>
                      <ul className="team-list-idea">
                        <li>
                          <div className="section-left">
                            <p className="section-left__name" title="Members">
                              Members
                            </p>
                          </div>
                        </li>

                        <li>
                          {/* <div className="section-left">
                        <p title= {Object.keys(this.state.membersDetails).length > 0 ? this.state.membersDetails.ideaSubmitter : ""} >
                           {Object.keys(this.state.membersDetails).length > 0 ? this.state.membersDetails.ideaSubmitter : ""} 
                        </p>
                        </div> */}
                          {/* <div className="section-right is-not-creator"><p>Submitter</p></div> */}
                        </li>
                        {Object.keys(this.state.membersDetails).length > 0 &&
                          this.state.membersDetails.teamMembers.map(
                            (member) => {
                              return (
                                <>
                                  <li>
                                    <div className="section-left">
                                      <p title={member}>{member}</p>
                                      {/* <p title={member.userEmail}>@{member.userEmail.split('@')[0]}</p>
                                <p title={member.memberLocation}>({member.memberLocation})</p> */}
                                    </div>
                                    {/* <div className="section-right">
                                <p>{member.memberRole}</p>
                                <button
                                  onClick={() =>
                                    this.setState({
                                      showDeleteTeamMemberModal: true,
                                      selectedDeleteTeamMember: member,
                                    })
                                  }
                                >
                                  <img src={deleteIcon} alt="delete member"/>
                                </button>
                              </div>   */}
                                  </li>
                                </>
                              );
                            }
                          )}
                      </ul>
                      {Object.keys(this.state.membersDetails).length > 0 &&
                        this.state.membersDetails.members.length >= 8 && (
                          <em className="other-note-text">
                            Maximum of 9 Team Members
                          </em>
                        )}
                    </div>
                    <Modal
                      show={this.state.showDeleteTeamMemberModal}
                      onHide={() =>
                        this.setState({ showDeleteTeamMemberModal: false })
                      }
                      backdrop="static"
                      keyboard={false}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      dialogClassName="team-formation-modal"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Team Member</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="bodyHeader"></div>
                        <p className="bodyHeaderHeading">
                          Are you sure you want to delete{" "}
                          {this.state.selectedDeleteTeamMember
                            ? this.state.selectedDeleteTeamMember.memberName
                            : "a team member"}{" "}
                          ?
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btnText header-lm-20"
                          onClick={() => {
                            this.deleteMember(ideaDetails.ideaId);
                          }}
                        >
                          Yes
                        </button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            this.setState({ showDeleteTeamMemberModal: false });
                          }}
                        >
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      show={this.state.showAddTeamMemberModal}
                      onHide={() =>
                        // this.setState({ showAddTeamMemberModal: false })
                        this.setState({
                          showAddTeamMemberModal: false,
                          errorClass: "invalidInputField",
                          addEmailText: "",
                          addNameText: "",
                          addRoleText: "",
                          roleText: "",
                          addLocationText: "",
                        })
                      }
                      backdrop="static"
                      keyboard={false}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      dialogClassName="team-formation-modal"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Add Team Members</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="bodyHeader add-team">
                          <div className="add-team_section">
                            <div className="bodyHeaderHeading">Name</div>
                            <input
                              type="text"
                              placeholder="Full Name of the Person"
                              value={this.state.addNameText}
                              onChange={(e) => this.handleAddNameChange(e)}
                            />
                          </div>
                          <div className="email-container add-team_section">
                            <div className="bodyHeaderHeading">Email</div>
                            <input
                              className={this.state.errorClass}
                              type="email"
                              placeholder="Deloitte Email addess"
                              value={this.state.addEmailText}
                              onChange={(e) => this.handleAddEmailChange(e)}
                              // onBlur={this.onInputBlur}
                              // onKeyUp={(e) => this.onInputBlur(e)}
                            />
                            {this.state.showErrorText && (
                              <label className="validate-email-label">
                                Please enter a valid email address
                              </label>
                            )}
                          </div>
                          <div className="add-team_section">
                            <div className="bodyHeaderHeading">Role</div>
                            <input
                              placeholder="Describe Role"
                              type="text"
                              value={this.state.addRoleText}
                              onChange={(e) => this.handleAddRoleChange(e)}
                            />
                          </div>
                          <div className="add-team_section">
                            <div className="bodyHeaderHeading">Location</div>
                            <input
                              placeholder="Member Location"
                              type="text"
                              value={this.state.addLocationText}
                              onChange={(e) => this.handleAddLocationChange(e)}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn primary"
                          disabled={this.state.disableAddBtn}
                          onClick={() =>
                            this.addMember(ideaDetails.ideaId, true)
                          }
                        >
                          Add as Team Member
                        </button>
                      </Modal.Footer>
                    </Modal>
                    {/*  <div className="ideaSection-detail_subsection">
                  <div className="subsection-header"><p>Requests</p></div>
                  {Object.keys(this.state.membersDetails).length > 0 &&
                    this.state.requestDetails.length > 0 && (
                      <>
                        {/* <div className="flex-header">
                          <p>Name</p>
                          <p>Email</p>
                          <p>Date</p>
                        </div> */}
                    {/*  <ul className="team-list">
                          {this.state.requestDetails.map((request) => {
                            if (request.approveStatus !== "REJECTED") {
                              return (
                                <>
                                  <li className="request-list">
                                    <p className="request-date">{request.appliedDate}</p>
                                    <div className="section-left">
                                      <p className="section-left__name" title={request.memberName}>{request.memberName}</p>
                                      <p title={request.memberRole}>{request.memberRole}</p>
                                       <p title={member.memberLocation}>({member.memberLocation})</p> 
                                    </div>
                                    <div className="section-right is-not-creator">
                                      <p>{request.memberEmail}</p>
                                    </div>                              
                                  </li>
                                </>
                              );
                            }
                          })}
                        </ul> */}
                    {/* <ul className="request-list">
                          {this.state.requestDetails.map((request) => {
                            if (request.approveStatus !== "REJECTED") {
                              return (
                                <>
                                  <li>
                                    <div className="flex-header list-item">
                                      <p>{request.memberName}</p>
                                      <p>{request.memberEmail}</p>
                                      <p>{request.appliedDate}</p>
                                    </div>
                                    <div className="role">
                                      {request.memberRole}
                                    </div>
                                  </li>
                                </>
                              );
                            }
                          })}
                        </ul> 
                        <a
                          className="btn btnText view-all-btn"
                          href={`/teamRequests?id=${ideaDetails.ideaId}`}
                          target="_blank"
                        >
                          View all requests
                        </a>
                      </>
                    )}
                  {Object.keys(this.state.membersDetails).length > 0 &&
                    this.state.requestDetails.length === 0 && (
                      <div className="no-results">No New Requests!</div>
                    )}
                </div> */}
                  </div>
                )
            }
            {/*             {!ideaDetails.userACreator && (
              <div className="ideaSection-detail">
                <div className="ideaSection-detail_subsection">
                  <div className="subsection-header">
                    <p>The Team</p>
                    {(Object.keys(this.state.membersDetails).length > 0 &&
                      this.state.membersDetails.members.length < 8 &&
                      this.state.showContributorBtn && ideaDetails.ideaStatus !== 'Duplicate') && (ideaDetails.userType !== _CONSTANTS.USER.PANELIST) && (
                        <button
                          className="btn btnText"
                          onClick={() =>
                            this.setState({ showJoinContributorModal: true })
                          }
                        >
                          Join as Contributor
                        </button>
                    )}
                    {(Object.keys(this.state.membersDetails).length > 0 &&
                      this.state.membersDetails.members.length < 8 &&
                      this.state.showContributorBtn && ideaDetails.ideaStatus !== 'Duplicate') && (ideaDetails.userType === _CONSTANTS.USER.PANELIST && !this.state.isPanelAssigned) && (
                        <button
                          className="btn btnText"
                          onClick={() =>
                            this.setState({ showJoinContributorModal: true })
                          }
                        >
                          Join as Contributor
                        </button>
                    )}
                  </div>
                  <ul className="team-list">
                    <li>
                      <div className="section-left">
                      <p className="section-left__name" title={Object.keys(this.state.membersDetails).length > 0 ? this.state.membersDetails.ideaSubmitter : ""}>
                        {Object.keys(this.state.membersDetails).length > 0 ? this.state.membersDetails.ideaSubmitter : ""}</p>
                      </div>
                      <div className="section-right is-not-creator"><p>Submitter</p></div>
                    </li>
                    {Object.keys(this.state.membersDetails).length > 0 &&
                      this.state.membersDetails.members.map((member) => {
                        return (
                          <>
                            <li>
                              <div className="section-left">
                                <p className="section-left__name" title={member.memberName}>{member.memberName}</p>
                                <p title={member.userEmail}>@{member.userEmail.split('@')[0]}</p>
                                <p title={member.memberLocation}>({member.memberLocation})</p>
                              </div>
                              <div className="section-right is-not-creator">
                                <p>{member.memberRole}</p>
                              </div>                              
                            </li>
                          </>
                        );
                      })}
                  </ul>
                  {Object.keys(this.state.membersDetails).length > 0 &&
                    this.state.membersDetails.members.length >= 8 && (
                      <em className="other-note-text">
                        Maximum of 9 Team Members
                      </em>
                    )}
                </div>
                <Modal
                  show={this.state.showJoinContributorModal}
                  onHide={() =>
                    this.setState({
                      showJoinContributorModal: false,
                      roleText: "",
                      contLocation : "",
                    })
                  }
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  dialogClassName="team-formation-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Join as Contributor</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="bodyHeader add-team">
                      <div className="add-team_section">
                        <div className="bodyHeaderHeading">Name</div>
                        <input
                          type="text"
                          value={
                            this.props.userData.firstName +
                            " " +
                            this.props.userData.lastName
                          }
                          readOnly
                        />
                      </div>
                      <div className="add-team_section">
                        <div className="bodyHeaderHeading">Email</div>
                        <input
                          type="text"
                          value={this.props.userData.email}
                          readOnly
                        />
                      </div>
                      <div className="add-team_section">
                        <div className="bodyHeaderHeading">Role</div>
                        <input
                          placeholder="Describe Role"
                          type="text"
                          value={this.state.roleText}
                          onChange={(e) => this.handleRoleTextChange(e)}
                        />
                      </div>
                      <div className="add-team_section">
                        <div className="bodyHeaderHeading">Location</div>
                        <input
                          placeholder="Member Location"
                          type="text"
                          value={this.state.contLocation}
                          onChange={(e) => this.handleContributorLocationChange(e)}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn primary"
                      disabled={this.state.disableJoinBtn}
                      onClick={() =>
                        this.addMember(ideaDetails.ideaId, false)
                      }
                    >
                      Join as Contributor
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            )} */}
            <Modal
              show={this.state.showConfirmationModal}
              onHide={() => this.setState({ showConfirmationModal: false })}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              dialogClassName="team-formation-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Success!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="bodyHeader">
                  <div className="bodyHeaderHeading">
                    {this.props.ideaDetails.userACreator
                      ? `${this.state.addNameText}(${this.state.addEmailText}) Added Successfully!`
                      : "Request has been sent to the creator for approval."}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showErrorModal}
              onHide={() => this.setState({ showErrorModal: false })}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              dialogClassName=""
            >
              <Modal.Header closeButton>
                <Modal.Title>Error!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="bodyHeader">
                  <div className="bodyHeaderHeading">
                    {this.state.errorMessage}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <div className="update-status-cont">
              {ideaStatusDropdownRole.includes(ideaDetails.userType) &&
                // ideaStatusDropdownOptions.includes(ideaDetails.ideaStatus) &&
                ideaStatusDropdownOptions.some(
                  (e) => e.status === ideaDetails.ideaStatus
                ) &&
                ideaDetails.ideaStatus !== "Backlog" && (
                  <div>
                    <p className="update-status-cont_heading">
                      Change Idea Status
                    </p>
                    {filterIdeaStatusDropdownOptions.map((item) => {
                      return (
                        <label
                          className="radio-label"
                          key={generateRandomKey()}
                        >
                          {item.text}
                          <input
                            type="radio"
                            name="status-radio"
                            id={item.id}
                            value={item.value}
                            checked={item.selected}
                            onChange={(e) => this.handleStatusChange(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                )}
              {ideaStatusDropdownRole.includes(ideaDetails.userType) &&
                ideaStatusDropdownOptions.some(
                  (e) => e.status === ideaDetails.ideaStatus
                ) &&
                ideaDetails.ideaStatus !== "Backlog" && (
                  // (this.state.selectedDropdownStatus !== this.props.ideaDetails.ideaStatus) && (
                  <div className="update-status-container">
                    <button
                      className="btn primary"
                      disabled={!this.state.showUpdateBtn}
                      onClick={() =>
                        this.updateIdeaClickHandler(
                          this.props.ideaDetails.ideaId,
                          this.props.ideaDetails.ideaStatus,
                          this.state.selectedDropdownStatus
                        )
                      }
                    >
                      Update Idea Status
                    </button>
                  </div>
                )}
            </div>
          </div>
          {tgleadComment &&
            (ideaDetails.userType === _CONSTANTS.USER.PANELLEAD ||
              ideaDetails.userACreator ||
              this.isTeamMember(
                this.props.membersDetails.members,
                this.props.userData.email
              )) && <Comments comments={ideaDetails.tgleadComment} />}
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_IDEA_DETAILS_STATUS: state.ideas.meta.FETCH_IDEA_DETAILS_STATUS,
    ideaDetails: state.ideas.data.ideaDetails,
    FILE_DOWNLOAD_STATUS: state.utilities.meta.FILE_DOWNLOAD_STATUS,
    file: state.utilities.data.file,
    ATTACHMENT_LIST_STATUS: state.utilities.meta.ATTACHMENT_LIST_STATUS,
    attachmentList: state.utilities.data.attachmentList,
    GET_MEMBER_DETAILS_STATUS: state.ideas.meta.GET_MEMBER_DETAILS_STATUS,
    membersDetails: state.ideas.data.membersDetails,
  };
};

const mapDispatchToProps = {
  fetchIdeaDetails,
  doFileDownload,
  fetchIdeaAttachmentsList,
  getTeamMembersDetails,
  getTeamNameForChallenge
};

export default connect(mapStateToProps, mapDispatchToProps)(Idea);
