import React, { Component } from "react";
import { connect } from "react-redux";
import "../css/pages/evaluate-flow.scss";
import Header from "../components/global/header";
import OverallSummaryView from "../components/ideaapproval/overallSummaryView";
import IdeaSteps from "../components/ideation/ideaSteps";
import { getEvaluateDetails, fetchIdeaDetails } from "../redux/actions/ideas";
import ApprovalStepView from "../components/ideaapproval/approvalStepView";
import { hideLoading, showLoader } from "../helpers/utils";
import { _CONSTANTS } from "../helpers/constants";

class EvaluateDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousview: "",
      currentView: 1,
      leftArrowDisable: false,
      rightArrowDisable: false,
      leftArrowVisible: true,
      rightArrowVisible: true,
      basicData: {},
      ideaId: 0,
      panelId: 0,
      score: this.props.evaluateDetails.overallScore,
      isOtherAreaSelected: false,
      scoreQArr: [],
      relevanceScore: 0,
      fesibilityScore: 0,
      investmentScore: 0,
      impactScore: 0,
      hideHeaderCrossBtn: false,
      showOverallScore: true,
    };
  }

  componentDidMount() {
    showLoader();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("id") && urlParams.has("panel")) {
      this.setState({
        ideaId: urlParams.get("id"),
        panelId: parseInt(urlParams.get("panel")),
      });
      this.props.fetchIdeaDetails(
        parseInt(urlParams.get("id")),
        this.props.userData.id
      );
      this.props.getEvaluateDetails(
        parseInt(urlParams.get("id")),
        parseInt(urlParams.get("panel"))
      );
    }
    // if (Object.keys(this.state.basicData).length > 0) {
    //     this.setArrowData("");
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.evaluateDetails !== this.props.evaluateDetails) {
      this.setState({ basicData: this.props.evaluateDetails });
      setTimeout(() => {
        this.setArrowData("");
        this.calculateScoreOnLoad();
      }, 700);

      this.props.evaluateDetails.reviewSteps.forEach((step, index) => {
        // let count = 0;
        if (index === 0) {
          step.questions[0].options.forEach((option, index) => {
            if (option.selected) {
              // count = option.subQuestions.length;
              // if (option.showTextBox) {
              //     count = count + 1;
              // }
              if (option.text === _CONSTANTS.IDEAPROBLEM.OTHERS) {
                this.setState({ showOverallScore: false });
              } else if (
                option.text === _CONSTANTS.IDEAPROBLEM.CLIENTS ||
                option.text === _CONSTANTS.IDEAPROBLEM.DELOITTEINTERNAL
              ) {
                this.setState({ showOverallScore: true });
              }
              // this.setState({ questionCountStep1: count });
            }
          });
        }
      });
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  /**
   * calculates the score on page load for draft mode
   */
  calculateScoreOnLoad = () => {
    if (Object.keys(this.state.basicData).length > 0) {
      let mainScoreArr = [];
      this.state.basicData.reviewSteps.forEach((step) => {
        if (step.stepID === "1") {
          let parentId = "",
            questionId = "",
            score = 0,
            stepId = "",
            scoreArr = [];
          stepId = step.stepID;
          parentId = step.questions[0].questionId;
          step.questions[0].options.forEach((option) => {
            if (option.selected) {
              option.subQuestions.forEach((subQ) => {
                subQ.options.forEach((optn) => {
                  questionId = subQ.questionId;
                  if (optn.selected) {
                    score = optn.score;
                    if (subQ.questionType === "ratingSubtext") {
                      scoreArr = [
                        ...scoreArr,
                        ...[{ score: score, id: questionId, stepId: stepId }],
                      ];
                    }
                  }
                });
              });
            }
          });
          mainScoreArr = [...mainScoreArr, ...scoreArr];
        }
        if (step.stepID === "2" || step.stepID === "3" || step.stepID === "4") {
          let questionId = "",
            score = 0,
            stepId = "",
            scoreArr = [],
            questionArr = [];
          stepId = step.stepID;
          step.questions.forEach((question) => {
            questionId = question.questionId;
            question.options.forEach((option) => {
              if (option.selected) {
                score = option.score;
                scoreArr = [
                  ...scoreArr,
                  ...[{ score: score, id: questionId, stepId: stepId }],
                ];
              }
            });
          });
          mainScoreArr = [...mainScoreArr, ...scoreArr];
        }
      });
      this.setState({ scoreQArr: [...this.state.scoreQArr, ...mainScoreArr] });
    }
    setTimeout(() => {
      this.updateStepScoreDetails();
    }, 50);
  };

  /**
   * sets arrow data when view changes
   * @param {Number} viewData currently active view
   */
  setArrowData(viewData) {
    if (viewData === "") {
      viewData = 1;
    }
    this.setState({
      leftArrowVisible:
        viewData < this.state.basicData.breadcrumbs.length && viewData !== 1,
      rightArrowVisible: viewData !== this.state.basicData.breadcrumbs.length,
      // leftArrowDisable: false,
      leftArrowDisable: viewData === 1,
      rightArrowDisable: false,
    });
  }

  /**
   * next arrow click function
   */
  handleNextClick = () => {
    this.setState({ previousview: this.state.currentView });
    let self = this;
    let isSet = false;
    this.state.basicData.breadcrumbs.forEach((step, index) => {
      if (!isSet && self.state.currentView === step.breadcrumbID) {
        self.setState({
          currentView: this.state.basicData.breadcrumbs[index + 1].breadcrumbID,
        });
        self.setArrowData(
          this.state.basicData.breadcrumbs[index + 1].breadcrumbID
        );
        isSet = !isSet;
      }
    });
    if (this.state.currentView === 4) {
      this.setState({
        rightArrowVisible: false,
        rightArrowDisable: true,
      });
    }
    if (this.state.isOtherAreaSelected) {
      this.setState({ currentView: this.state.basicData.breadcrumbs.length });
      self.setArrowData(this.state.basicData.breadcrumbs.length);
    }
  };

  /**
   * previous arrow click function
   */
  handlePreviousClick = () => {
    this.setState({ currentView: this.setState.previousview });
    let self = this;
    let isSet = false;
    this.state.basicData.breadcrumbs.forEach((step, index) => {
      if (!isSet && self.state.currentView === step.breadcrumbID) {
        self.setState({
          previousview:
            index <= 1
              ? ""
              : this.state.basicData.breadcrumbs[index - 2].breadcrumbID,
        });
        self.setState({
          currentView: this.state.basicData.breadcrumbs[index - 1].breadcrumbID,
        });
        self.setArrowData(
          this.state.basicData.breadcrumbs[index - 1].breadcrumbID
        );
        isSet = !isSet;
      }
    });
  };

  /**
   * score update for each step individually
   */
  updateStepScoreDetails = () => {
    let relevanceArr = [],
      feasibilityArr = [],
      investmentArr = [],
      impactArr = [];
    this.state.scoreQArr.forEach((item) => {
      if (parseInt(item.stepId) === 1) {
        relevanceArr.push(item);
      }
      if (parseInt(item.stepId) === 2) {
        feasibilityArr.push(item);
      }
      if (parseInt(item.stepId) === 3) {
        investmentArr.push(item);
      }
      if (parseInt(item.stepId) === 4) {
        impactArr.push(item);
      }
    });
    this.setState({
      relevanceScore: this.calculateStepScore(relevanceArr),
      fesibilityScore: this.calculateStepScore(feasibilityArr),
      investmentScore: this.calculateStepScore(investmentArr),
      impactScore: this.calculateStepScore(impactArr),
      score:
        this.calculateStepScore(relevanceArr) +
        this.calculateStepScore(feasibilityArr) +
        this.calculateStepScore(investmentArr) +
        this.calculateStepScore(impactArr),
    });
  };

  /**
   * calculates cumulative score for each step
   * @param {Array} arr array for items
   */
  calculateStepScore = (arr) => {
    let score = 0;
    arr.forEach((item) => {
      score = score + item.score;
    });
    return score;
  };

  render() {
    const { userData } = this.props;
    const ideaDetails = this.props.ideaDetails || {};

    return (
      <>
        <Header
          hideHeaderCrossBtn={this.state.hideHeaderCrossBtn}
          crossBtnHref={"/talentReview?id=" + this.state.ideaId}
          values={userData}
          showSearch={false}
          showUser={false}
          pageName={"evaluate-idea-view"}
        />
        <div className="evaluate-header">
          <div
            className="evaluate-header_title"
            title={typeof ideaDetails !== undefined ? ideaDetails.ideaName : ""}
          >
            {typeof ideaDetails !== undefined
              ? ideaDetails.ideaName.length > 100
                ? `${ideaDetails.ideaName.substring(0, 99)} ...`
                : ideaDetails.ideaName
              : ""}
          </div>
          <div className="evaluate-header_rt">
            <div className="evaluate-header_rt-title">
              Skip details to your approval?
            </div>
            <button
              className="btn btnText"
              onClick={() =>
                (window.location.href = "/talentReview?id=" + this.state.ideaId)
              }
            >
              View approval page
            </button>
          </div>
        </div>
        <IdeaSteps
          ideaSteps={this.state.basicData.breadcrumbs}
          currentView={this.state.currentView}
        />
        <div className="createIdeaOuter-cont">
          <div
            className={`createIdeaOuter ${
              this.state.currentView === 5 ? "evaluate-flow-outer" : ""
            }`}
          >
            {this.state.showOverallScore && this.state.currentView !== 5 && (
              <div className="scoring-div">
                <div className="Ideascore">Overall Score</div>
                <div className="OverallScore">{this.state.score}/20</div>
              </div>
            )}
            <div className="createIdeaMain evaluate-flow evaluate-flow-view">
              {this.state.currentView === 1 &&
                Object.keys(this.state.basicData).length > 0 && (
                  // Relevance
                  <ApprovalStepView
                    stepDetails={this.state.basicData.reviewSteps[0]}
                  />
                )}
              {this.state.currentView === 2 && (
                // Feasibility
                <ApprovalStepView
                  stepDetails={this.state.basicData.reviewSteps[1]}
                  customClass={"feasibility"}
                />
              )}
              {this.state.currentView === 3 && (
                // Investment
                <ApprovalStepView
                  stepDetails={this.state.basicData.reviewSteps[2]}
                  customClass={"investment"}
                />
              )}
              {this.state.currentView === 4 && (
                // Impact
                <ApprovalStepView
                  stepDetails={this.state.basicData.reviewSteps[3]}
                />
              )}
              {this.state.currentView === 5 && (
                <OverallSummaryView
                  score={this.state.score}
                  overAllScore={20}
                  data={this.state.basicData.reviewSteps[4]}
                  firstReviewer={this.state.basicData.firstReviewer}
                />
              )}
            </div>
          </div>
        </div>
        <div className="createIdeaOuter_btn-cont">
          <div className="createIdeaOuter_btn-cont_inner">
            {
              /* this.state.leftArrowVisible && */ <button
                className="btn secondary createIdeaPrevious"
                disabled={this.state.leftArrowDisable}
                onClick={this.handlePreviousClick}
              >
                <div className="arrow"></div>Back
              </button>
            }
            {
              /* this.state.rightArrowVisible && */ <button
                className="createIdeaNext"
                disabled={this.state.rightArrowDisable}
                onClick={this.handleNextClick}
              >
                <div className="arrow"></div>Next
              </button>
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_EVALUATE_DETAILS_STATUS:
      state.ideas.meta.FETCH_EVALUATE_DETAILS_STATUS,
    evaluateDetails: state.ideas.data.evaluateDetails,
    FETCH_IDEA_DETAILS_STATUS: state.ideas.meta.FETCH_IDEA_DETAILS_STATUS,
    ideaDetails: state.ideas.data.ideaDetails,
  };
};

const mapDispatchToProps = {
  getEvaluateDetails,
  fetchIdeaDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluateDetail);
