import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Header from "../components/global/header";
import '../css/pages/draftSubmitted.scss';
import Breadcrumbs from "../components/global/breadcrumbs";
import { fetchSubmittedIdeas } from "../redux/actions/ideas";
import config from "../helpers/api-urls";
import axios from "axios";
import moment from "moment";
import { checkForStatusColor, showLoader, hideLoading } from "../helpers/utils";
import { _CONSTANTS } from "../helpers/constants";
import ChallengeIcon from "../components/global/challengeIcon";

class SubmittedIdea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submittedIdeas: (this.props.submittedIdeas && this.props.submittedIdeas.submittedIdeas) || [],
            loading: false,
            perPageRows: 5,
            totalPages: (this.props.submittedIdeas && this.props.submittedIdeas.totalSubmittedCount) || 0,
            pageIndex: 0
        };
    }

    componentDidMount() {
        showLoader();
        this.props.fetchSubmittedIdeas(this.state.pageIndex, this.state.perPageRows, this.props.userData.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.submittedIdeas !== this.props.submittedIdeas) {
            this.setState({ submittedIdeas: this.props.submittedIdeas.submittedIdeas, totalPages: this.props.submittedIdeas.totalSubmittedCount });
        }
        setTimeout(() => {
            hideLoading();
        }, 1000);
    }

    loadMoreItems = (pageIndex) => {
        showLoader();
        this.setState({
            loading: true,
            pageIndex: this.state.pageIndex + 1,
        });
        const payloadUrl = config.fetchSubmitIdeasConfig.requestPayload.url.replace("{userID}", this.props.userData.id) + "?pageNum=" + (pageIndex + 1) + "&pageSize=" + this.state.perPageRows;
        axios
            .get(payloadUrl, {
                headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
            })
            .then((response) => {
                this.setState({
                    submittedIdeas: [...this.state.submittedIdeas, ...response.data.submittedIdeas],
                    loading: false,
                });
                hideLoading();
            })
            .catch((error) => { console.log(error); hideLoading(); });
    };

    navigateToIdea = (id) => {
        window.location.href = '/idea/' + id;
    }

    render() {
        const breadcrumbJSON = [
            {
                url: "/dashboard",
                text: "Home",
            },
            {
                url: "/user-profile",
                text: "Profile",
            },
            {
                url: "",
                text: "Submitted Ideas",
            }
        ];
        let ideaList = [];
        const userJson = this.props.userData;
        // let self = this;
        if (this.state.submittedIdeas) {
            ideaList = this.state.submittedIdeas.map((idea, index) =>
                <div key={idea.ideaName + index}>
                    <div className="ideaBubble" id={idea.ideaID} onClick={() => this.navigateToIdea(idea.ideaID)}>
                        {idea.challengeID && <ChallengeIcon />}
                        {/* <div className="ideaName" title={idea.ideaName ? idea.ideaName : ''}>{idea.ideaName && idea.ideaName.length > 100 ? `${idea.ideaName.substring(0, 99)} ...` : idea.ideaName}</div> */}
                        <div className="ideaDetails">
                            <div className="ideaNameDescDetail-left">
                                <div className="ideaName" title={idea.ideaName ? idea.ideaName : ''}>{idea.ideaName && idea.ideaName.length > 100 ? `${idea.ideaName.substring(0, 99)} ...` : idea.ideaName}</div>
                                <div
                                    className="ideaDescription"
                                    dangerouslySetInnerHTML={{ __html: idea.ideaDescription }}
                                >
                                </div>
                            </div>
                            <div className="ideaNameDescDetail-right">
                                <div className="ideaDate">
                                <span className={checkForStatusColor(
                                        idea.ideaStatus, "review"
                                    )}></span>{idea.ideaStatus ? idea.ideaStatus : _CONSTANTS.IDEASTATUS.INREVIEW.toUpperCase()}
                                    {/* <label className={checkForStatusColor(idea.ideaStatus, "review")}>{idea.ideaStatus ? idea.ideaStatus : _CONSTANTS.IDEASTATUS.INREVIEW.toUpperCase()}</label> */}
                                </div>
                                <div className="ideaDelete">
                                    {idea.dateModified ? moment(idea.dateModified).format("DD MMM YY") : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ideaBubble-mobile" id={idea.ideaID} onClick={() => this.navigateToIdea(idea.ideaID)}>
                        <div className="name-container">
                            <div className="ideaName">
                                {idea.ideaName}
                            </div>
                            <div className="ideaDate">
                                {idea.dateModified ? moment(idea.dateModified).format("DD MMM YY") : '-'}
                            </div>
                        </div>
                        <div className="ideaDetails">
                            <div className="ideaDelete">
                                <label className={checkForStatusColor(idea.ideaStatus, "review")}>{idea.ideaStatus ? idea.ideaStatus : _CONSTANTS.IDEASTATUS.INREVIEW.toUpperCase()}</label>
                            </div>
                            <div className="ideaDescription">
                                {idea.ideaDescription}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <>
            <Header
                values={userJson}
                showSearch={true}
                showUser={true}
                pageName={"submittedIdea"}
            />
            <section className="homeMain">
                <Breadcrumbs breadcrumbs={breadcrumbJSON} />
                <article className="draftHeader">
                    <div className="heading">
                        Submitted Ideas
                    </div>
                    <div className="ideaCount">
                        {(this.state.submittedIdeas !== undefined && this.state.submittedIdeas !== null) && this.state.submittedIdeas.length > 0 && (
                            <>
                                Showing {this.state.submittedIdeas ? this.state.submittedIdeas.length : 0} of {this.state.totalPages || 0}
                            </>
                        )}
                    </div>
                </article>
                <article className="ideaBubbels">
                    {ideaList}
                </article>
                {this.state.submittedIdeas.length > 0 && (
                    <div className="load-more-btn-cont">
                        <div className={(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) ? 'load-more-cta-count-data load-more-hidden' : 'load-more-cta-count-data'}>Showing 1 - {Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows)) > this.state.totalPages ? this.state.totalPages : Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows))}/{this.state.totalPages}</div>
                        {!(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) && (
                            <button
                                type="button"
                                disabled={
                                    this.state.totalPages <=
                                    (this.state.pageIndex + 1) * this.state.perPageRows
                                }
                                className={classNames("btn btnText load-more-cta", {
                                    disabled:
                                        this.state.totalPages <=
                                        (this.state.pageIndex + 1) * this.state.perPageRows,
                                })}
                                onClick={() => this.loadMoreItems(this.state.pageIndex)}
                            >
                                {this.state.loading ? "Loading..." : "Load More"}
                            </button>
                        )}
                    </div>
                )}
                {(this.state.submittedIdeas !== undefined && this.state.submittedIdeas !== null) && this.state.submittedIdeas.length === 0 && (
                    <article className="ideaBubbels">
                        No Submitted Ideas!
                    </article>
                )}
            </section>
        </>

    }
}

const mapStateToProps = (state) => {
    return {
        FETCH_SUBMITTED_IDEAS_STATUS: state.ideas.meta.FETCH_SUBMITTED_IDEAS_STATUS,
        submittedIdeas: state.ideas.data.submittedIdeas,
    };
};

const mapDispatchToProps = {
    fetchSubmittedIdeas
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedIdea);