import { Component } from "react";
import TopMembers from "./topMemberEvent";

class EachBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDetails: this.props.eventDetails
    };
  }

  render() {
    const {eventDetails } = this.state
    return (
      <>
        <div className='each-board-container'>
          <div className="event-container">
            <h1 className="header-update">{eventDetails.eventName || this.props.header}</h1>
          </div>
          {eventDetails?.participantsList?.length > 0 && (
            <div className="leaderboard">
              <div className="article-1">
                <ul className="top-ideators">
                  {eventDetails?.participantsList?.map((el, i) => {
                      return <TopMembers member={el} index={i + 1} key={i} />
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default EachBoard;
