import { Component } from "react";

class Comments extends Component {

    render() {
        const { comments } = this.props;
        return <div className="comments">
            <h2>Comments</h2>
            <p>{comments}</p>
        </div>
    }
}

export default Comments;
