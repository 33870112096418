import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfileCurrency } from "../../redux/actions/user-profile";
import seedIcon from "../../img/seedIcon.svg";
import { showLoader, hideLoading } from "../../helpers/utils";

class ProfileCurrency extends Component {
  componentDidMount() {
    showLoader();
    this.props.fetchProfileCurrency(this.props.userInfo.id);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.profileUpdateRequired !== this.props.profileUpdateRequired) {
      this.props.fetchProfileCurrency(this.props.userInfo.id);
      this.props.disableProfileUpdate();
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  render() {
    const { currency = {} } = this.props;

    return (
      <div className="profile-seed-container_inner gamify-profile">
        <div className="profile-seed-container_inner-seedDetail border-right">
          <div className="seed-detail">
            <div>
              <img src={seedIcon} alt="gem-icon" />
              <p className="seed-detail_earned-total">{currency.investmentsBalance ? currency.investmentsBalance : 0}</p>
            </div>
            <div className="seed-detail_earned">
              <p>Investment Seeds</p>
              <span>Total seeds in your wallet</span>
            </div>
          </div>
        </div >
        <div className="profile-seed-container_inner-seedDetail">
          <div className="seed-detail">
            <div>
              <img src={seedIcon} alt="gem-icon" />
              <p className="seed-detail_earned-total">
                {currency.rewardsBalance ? currency.rewardsBalance : 0}
              </p>
            </div>
            <div className="seed-detail_earned">
              <p>Rewards Earned</p>
            </div>
          </div>
        </div>
        <div className="profile-tipDetail">
          <div className="profile-tipDetail_text">
            <p>Tip: You can invest maximum of <img src={seedIcon} alt="seed" /> 300 in an idea at any time</p>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    FETCH_PROFILE_CURRENCY_STATUS:
      state.userProfile.meta.FETCH_PROFILE_CURRENCY_STATUS,
    currency: state.userProfile.data.currency,
  };
};

const mapDispatchToProps = {
  fetchProfileCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCurrency);
