import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { investInIdea, investedIdeaSummary } from "../../redux/actions/ideas";
import "../../css/pages/draftSubmitted.scss";
import seedIcon from "../../img/seedIcon.svg";
import wellDoneImage from "../../img/ideation/well_done.png";
import Counter from "../global/counter";
import { Container, Row, Col } from "react-bootstrap";
import { generateRandomKey, hideLoading, showLoader } from "../../helpers/utils";

class investInIdeaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investInIdeaStatus: this.props.investInIdeaStatus,
      userData: this.props.userData,
      amountInvested: 0,
      investInIdeaSuccessModal:
        typeof this.props.investInIdeaStatus !== "undefined" &&
          this.props.investInIdeaStatus.result === "Success"
          ? true
          : false,
      investInIdeaErrorModal:
        typeof this.props.investInIdeaStatus !== "undefined" &&
          this.props.investInIdeaStatus.result === "Insufficient Coin Balance"
          ? true
          : false,
      isMaximumInvestmentReached: true,
      investInIdeaModalFlag: this.props.investInIdeaFlag,
      investButtonClicked: false,
      updateInvestedCoinsFlag: false,
    };
  }

  hideInvestInIdeaSuccessModel = () => {
    this.setState({ investInIdeaSuccessModal: false });
    this.props.onDataChange(false, false, 0);
  };

  hideInvestInIdeaErrorModel = () => {
    this.setState({ investInIdeaErrorModal: false });
    this.props.onDataChange(false, false, 0);
  };

  hideIdeaInvestModel = () => {
    this.setState({
      investInIdeaModalFlag: false,
      amountInvested: 0,
      investButtonClicked: false,
    });
    this.props.onDataChange(false, false, 0);
  };

  launchInvestInIdeaModal = () => {
    this.setState({
      investInIdeaModalFlag: true,
      amountInvested: 0,
      investButtonClicked: false,
      investInIdeaErrorModal: false,
      investInIdeaSuccessModal: false,
      updateInvestedCoinsFlag: false,
    });
    this.props.onDataChange(true, false, 0);
  };

  setInvestedAmount = (amount) => {
    this.setState({ amountInvested: isNaN(amount) ? 0 : amount });
  };

  submitInvestment = (ideaName) => {
    showLoader();
    const userId =
      this.props.userData.id !== undefined ? this.props.userData.id : 1;
    const ideaId = this.props.ideaDetails.ideaId;
    const currentDate = new Date();
    const requestData = {
      amountInvested: this.state.amountInvested,
      endDate: currentDate,
      ideaId: ideaId,
      startDate: currentDate,
      userId: userId,
      locked: true,
      ideaName: ideaName,
      investmentId: generateRandomKey()
    };
    this.props.investInIdea(requestData);
    this.setState({ investInIdeaModalFlag: false, investButtonClicked: true });
    this.props.onDataChange(false, true, this.state.amountInvested);
  };

  componentDidMount = () => {
    this.props.investedIdeaSummary(this.props.ideaDetails.ideaId, this.props.userData.id);
  };

  componentDidUpdate() {
    if (
      this.props.investButtonClicked &&
      this.props.investInIdeaStatus.result === "Success" &&
      !this.state.updateInvestedCoinsFlag
    ) {
      this.props.investedIdeaSummary(this.props.ideaDetails.ideaId, this.props.userData.id);
      this.setState({ updateInvestedCoinsFlag: true });
    }
    if (this.props.investButtonClicked && this.props.investInIdeaStatus.result === "Tip: You can only invest in 5 ideas at any time" && !this.state.updateInvestedCoinsFlag) {
      this.setState({ isMaximumInvestmentReached: true, updateInvestedCoinsFlag: true });
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  static getDerivedStateFromProps = (props) => {
    return {
      investInIdeaSuccessModal:
        typeof props.investInIdeaStatus !== "undefined" &&
          props.investInIdeaStatus.result === "Success" &&
          props.investButtonClicked
          ? true
          : false,
      investInIdeaErrorModal:
        typeof props.investInIdeaStatus !== "undefined" &&
          props.investInIdeaStatus.result === "Insufficient Coin Balance" &&
          props.investButtonClicked
          ? true
          : false,
    };
  };

  render() {
    const ideaDetails = this.props.ideaDetails;
    const investedIdeaSummaryData = this.props.investedIdeaSummaryDetails || {};
    // const disableInvestButtonTotalAmount =
    //   this.state.amountInvested + investedIdeaSummaryData.ideaInvestedAmount >
    //     investedIdeaSummaryData.balanceAmount
    //     ? true
    //     : false;
    // const disableInvestButtonBalanceAmount =
    //   this.state.amountInvested > investedIdeaSummaryData.amountInvested
    //     ? true
    //     : false;
    return (
      <>
        <section>
          <div>
            <Modal
              show={this.state.investInIdeaModalFlag}
              onHide={this.hideIdeaInvestModel}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              dialogClassName="invest-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>{ideaDetails.ideaName && ideaDetails.ideaName.length > 45 ? `${ideaDetails.ideaName.substring(0, 40)} ...` : ideaDetails.ideaName}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col className="investIdeaMainHeaderStyle invest-heading">
                    SEED Wallet Details
                      <p>Minimum Investment: 100 seeds</p>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col className="investIdeaColHeaderStyle">
                      Minimum Investment
                    </Col> */}
                    <Col className="investIdeaColHeaderStyle">Your Investment Wallet Balance</Col>
                    <Col className="investIdeaColHeaderStyle">
                      Your Invested Seeds
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col>
                      <span className="investedIdeaAmountStyle">
                        100
                        <img src={seedIcon} alt="seed" />
                      </span>
                    </Col> */}
                    <Col>
                      <span className="investedIdeaAmountStyle">
                        <img src={seedIcon} alt="seed" />
                        {investedIdeaSummaryData.balanceAmount}
                      </span>
                    </Col>
                    <Col>
                      <span className="investedIdeaAmountStyle">
                        <img src={seedIcon} alt="seed" />
                        {investedIdeaSummaryData.amountInvested}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="investIdeaMainHeaderStyle header-padding-4-2">
                      How much would you want to invest?
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Counter
                        counterIncrementor={50}
                        sendDataToParent={this.setInvestedAmount}
                      />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn primary invest-btn"
                  onClick={() => this.submitInvestment(ideaDetails.ideaName)}
                  disabled={
                    this.state.amountInvested < 100 || this.state.amountInvested > 300
                    // disableInvestButtonTotalAmount ||
                    // disableInvestButtonBalanceAmount
                  }
                >
                  Invest Now
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          {this.props.investButtonClicked &&
            this.props.investInIdeaStatus.result === "Success" &&
            this.state.investInIdeaSuccessModal && (
              <div className="investInIdeaSuccess">
                <Modal
                  show={this.state.investInIdeaSuccessModal}
                  onHide={this.hideInvestInIdeaSuccessModel}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  dialogClassName="invest-success-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{ideaDetails.ideaName && ideaDetails.ideaName.length > 45 ? `${ideaDetails.ideaName.substring(0, 40)} ...` : ideaDetails.ideaName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container fluid>
                      <Col className="investedIdeaSuccessHeaderStyle">
                        <img src={wellDoneImage} alt="welldone" />
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <span className="investedIdeaSuccessHeaderText">
                              You Invested
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <img
                              src={seedIcon}
                              alt="coin"
                              className="coinImg"
                            />
                            <span className="investedIdeaAmountTextStyle">
                              {this.props.investedAmount}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                      <button
                        className="btn primary invest-btn"
                        onClick={this.launchInvestInIdeaModal}
                      >
                        Invest More
                      </button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          {this.props.investButtonClicked &&
            this.props.investInIdeaStatus.result === "Insufficient Coin Balance" && (
              <div className="investInIdeaError">
                <Modal
                  show={this.state.investInIdeaErrorModal}
                  onHide={this.hideInvestInIdeaErrorModel}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <span className="investInIdeaErrorMsgStyle">
                      You do not have enough seeds to invest.
                    </span>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn primary invest-btn"
                      onClick={this.launchInvestInIdeaModal}
                    >
                      Try Again
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          {this.props.investButtonClicked &&
            this.props.investInIdeaStatus.result === 'Tip: You can only invest in 5 ideas at any time' && (
              <div className="investInIdeaSuccess">
                <Modal
                  show={this.state.isMaximumInvestmentReached}
                  onHide={() => this.setState({ isMaximumInvestmentReached: false })}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{ideaDetails.ideaName && ideaDetails.ideaName.length > 45 ? `${ideaDetails.ideaName.substring(0, 40)} ...` : ideaDetails.ideaName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <span className="investedIdeaTipHeader">
                      {this.props.investInIdeaStatus.result}
                    </span>
                  </Modal.Body>
                </Modal>
              </div>
            )}
        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    INVEST_IN_IDEA_STATUS: state.ideas.meta.INVEST_IN_IDEA_STATUS,
    investInIdeaStatus: state.ideas.data.investInIdeaStatus,
    FETCH_IDEA_INVESTMENT_DETAILS_STATUS:
      state.ideas.meta.FETCH_IDEA_INVESTMENT_DETAILS_STATUS,
    investedIdeaSummaryDetails: state.ideas.data.investedIdeaSummaryDetails,
  };
};

const mapDispatchToProps = {
  investInIdea,
  investedIdeaSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(investInIdeaModal);
