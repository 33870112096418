import { Component } from "react";
import RestMembers from "./restMemberEvent";
import TopMembers from "./topMemberEvent";

class EventBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDetails: this.props.eventDetails,
      participantsList: this.props.eventDetails.participantsList,
      top3:[],
      rest:[]
    };
  }

  setData = (participantsList) => {
    const slicedArr3 = participantsList?.slice(0, 3)
    const slicedArrRest = participantsList?.slice(3)
    this.setState({eventDetails: this.props.eventDetails, top3: slicedArr3, rest: slicedArrRest})
  }

  componentDidMount() {
    if (this.state.participantsList?.length > 0 ) this.setData(this.state.participantsList)
  }

  componentDidUpdate(prevProps) {
    if (
        prevProps.eventDetails?.participantsList?.length !== this.props.eventDetails?.participantsList?.length &&
        this.props.eventDetails?.participantsList?.length > 0
      ) {
      this.setData(this.props.eventDetails.participantsList)
    }
  }

  render() {
    const {eventDetails, top3, rest} = this.state
    return (
      <>
          <div className="event-container">
            <h1 className="header-update">{eventDetails.eventName || this.props.header}</h1>
          </div>
          {eventDetails?.participantsList?.length > 0 ? (
            <div className="leaderboard">
              <div className="article-1">
                <ul className="top-ideators">
                  {top3.length > 0 ? top3.map((el, i) => {
                      return <TopMembers member={el} index={i + 1} key={i} />
                  }): null}
                </ul>
                <ul className="ideators">
                  {rest.length > 0 ? rest.map((el, i) => {
                      return <RestMembers member={el} index={i + 4} key={i} />
                  }): null}
                </ul>
              </div>
            </div>
          ) : (<div className="event-list-container"><h2>No leaderboard data has been added yet. Please check again soon!</h2></div>)}
      </>
    );
  }
}

export default EventBoard;
