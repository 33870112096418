import React, { Component } from "react";
import classNames from "classnames";
import downArr from "../../img/down-arr.svg";

class StatusDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventStatus: '',
      selectedOption: null,
      isListOpen: false,
    };
    this.eventTypeWrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentDidUpdate(prevProps) {
    if(this.props.dataList !== prevProps.dataList) { this.setState({eventList: this.props.dataList}) }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.eventTypeWrapperRef &&
      !this.eventTypeWrapperRef.current.contains(event.target) &&
      this.state.isListOpen
    ) {
      this.setState({ isListOpen: false });
    }
  }

  onToggleList() {
    this.setState({ isListOpen: !this.state.isListOpen });
  }

  handleEventChange = (e) => {
    const { id, text } = e.target;
    this.setState({
      selectedOption: text,
    });
    this.props.handleEventChange(id, text);
    this.setState({ isListOpen: false });
  };

  render() {
    const {selectedOption, isListOpen, eventStatus} = this.state
    const statusList = ['To be started', 'On going', 'Completed', 'Inactive', 'Expired']
    return (
      <>
        <div className="regsiterTeamGroup">
          <div className="regTeamGrpIn w-100">
            <div className="regsiterTeamIndi_title">
              Status: 
            </div>
            <div className="dropdown-wrapper" ref={this.eventTypeWrapperRef}>
              <div className="dropdown selected">
                <button
                  aria-expanded="false"
                  type="button"
                  className={classNames(
                    "dropdown-select",
                    isListOpen ? "is-open" : ""
                  )}
                  onClick={() => this.onToggleList()}
                  id="eventTypeGroup"
                >
                  {selectedOption || "Choose an option"}
                  <img src={downArr} alt="down-arrow" />
                </button>
                <ul
                  role="menu"
                  className={classNames(
                    "dropdown-menu",
                    isListOpen ? "show" : "d-none"
                  )}
                  aria-labelledby="priorityGroup"
                >
                  <div className="list-container">
                    {statusList.map((option, index) => {
                        return (
                          <li key={`${index}`}>
                            <a
                              role="menuitem"
                              className={classNames(
                                "dropdown-item",
                                option === eventStatus
                                  ? "selected"
                                  : ""
                              )}
                              disabled={["All", "default"].includes(option)}
                              onClick={(e) => this.handleEventChange(e)}
                              id={option}
                              data-index={index + 1}
                            >
                              {option}
                            </a>
                          </li>
                        );
                    })}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default StatusDropdown;
