import React from "react";
import noResultImg from "../../img/no-result.svg";

const GlobalSearchNoResults = ({ term }) => {
  return (
    <div className="no-results-detail">
      <p>Oh no!</p>
      <p>We couldn’t find any match</p>
      <q>{term}</q>
      <p className="no-results-detail-text">Try Changing filters to find your match!</p>
      <img src={noResultImg} alt="no-result"/>
    </div>
  );
};

export default GlobalSearchNoResults;
