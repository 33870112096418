import axios from "axios";
import config from "../../helpers/api-urls";
import mockData from "../../API_JSONs/Profile/profileData.json";
import userProfileMock from "../../API_JSONs/Profile/userProfile.json";
import userProfileCornerMock from "../../API_JSONs/Profile/profileCorner.json";
import profileCurrency from "../../API_JSONs/Profile/profileCurrency.json";
import transactionMock from "../../API_JSONs/Profile/profileTransaction.json";
import userInvestedIdeaMock from "../../API_JSONs/Profile/userInvestedIdea.json";

const _fetchProfile = (userObj) => {
  if (config.userProfileConfig.useMocks) {
    return Promise.resolve(userProfileMock);
  } else {
    return axios.post(config.userProfileConfig.requestPayload.url, userObj, {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchProfile = (userObj) => {
  return {
    type: "FETCH_USER",
    payload: _fetchProfile(userObj),
  };
};

const _fetchProfileCorner = (userId) => {
  if (config.userIdeaConfig.useMocks) {
    return Promise.resolve(userProfileCornerMock);
  } else {
    return axios.get(config.userIdeaConfig.requestPayload.url.replace("{userID}", userId), {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchProfileCorner = (userId) => {
  return {
    type: "FETCH_PERSONAL_CORNER",
    payload: _fetchProfileCorner(userId),
  };
};

const _fetchProfileCurrency = (userId) => {
  if (config.userCurrencyConfig.useMocks) {
    return Promise.resolve(profileCurrency);
  } else {
    return axios.get(config.userCurrencyConfig.requestPayload.url.replace("{userID}", userId), {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchProfileCurrency = (userId) => {
  return {
    type: "FETCH_PROFILE_CURRENCY",
    payload: _fetchProfileCurrency(userId),
  };
};

const _fetchProfileTransaction = (userId) => {
  if (config.userTransactionConfig.useMocks) {
    return Promise.resolve(transactionMock);
  } else {
    return axios.get(config.userTransactionConfig.requestPayload.url.replace("{userID}", userId), {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchProfileTransaction = (userId) => {
  return {
    type: "FETCH_PROFILE_TRANSACTION",
    payload: _fetchProfileTransaction(userId),
  };
};

const _fetchProfileInvestedIdeas = (userId) => {
  if (config.userInvestedIdeaConfig.useMocks) {
    return Promise.resolve(transactionMock);
  } else {
    return axios.get(config.userInvestedIdeaConfig.requestPayload.url.replace("{userID}", userId), {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    }).catch(error => {
      console.log(error);
    });
  }
};

export const fetchProfileInvestedIdeas = (userId) => {
  return {
    type: "FETCH_PROFILE_INVESTED_IDEA",
    payload: _fetchProfileInvestedIdeas(userId),
  };
};

const _fetchInvestedIdeas = (userId) => {
  if (config.userInvestedIdeaConfig.useMocks) {
    return Promise.resolve(userInvestedIdeaMock);
  } else {
    return axios.get(config.userInvestedIdeaConfig.requestPayload.url.replace("{userID}", userId), {
      headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
    });
  }
};

export const fetchInvestedIdeas = (userId) => {
  return {
    type: "FETCH_INVESTED_IDEA",
    payload: _fetchInvestedIdeas(userId),
  };
};

export const setEmail = (email) => {
  return {
    type: "SET_USER_EMAIL",
    payload: email
  }
}