import React, { Component } from "react";
import moment from "moment";
import challengeImage from "../../img/ideation/challenge2.jpg";

class homeChallengeItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId,
            challengeFileUrl0: this.props.imageUrl0,
            challengeFileUrl1: this.props.imageUrl1,
            challengeFileUrl2: this.props.imageUrl2
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageUrl0 !== this.props.imageUrl0) {
            this.setState({
                challengeFileUrl0: this.props.imageUrl0,
            });
        }
        if (prevProps.imageUrl1 !== this.props.imageUrl1) {
            this.setState({
                challengeFileUrl1: this.props.imageUrl1,
            });
        }
        if (prevProps.imageUrl2 !== this.props.imageUrl2) {
            this.setState({
                challengeFileUrl2: this.props.imageUrl2,
            });
        }
    }
    openChallengeDetail = id => {
        window.location.href = "/challenge?id=" + id;
    };
    render() {
        const { detail, itemIndex } = this.props;
        let finalImage = '';
        if (itemIndex === 0) {
            finalImage = this.state.challengeFileUrl0;
        } else if (itemIndex === 1) {
            finalImage = this.state.challengeFileUrl1;
        } else if (itemIndex === 2) {
            finalImage = this.state.challengeFileUrl2;
        }
        return (
            <>
                {/* {detail.status === 'ACTIVE' && ( */}
                <div className="challenge" onClick={() => this.openChallengeDetail(detail.challengeID)}>
                    <div className="challengeTopic" style={{ backgroundImage: `url(${finalImage})` }}>
                        {/* <div className="topicHeader">Topic</div> */}
                        <div className="overlay"></div>
                        <div className="topicDesc">{detail.challengeName ? detail.challengeName : '-'}</div>
                    </div>
                    <div className="challengeTimer">
                        <div className="challengeTimerHeader">{detail.status === 'ACTIVE' ? 'Challenge ends in' : 'Challenge Ended'}</div>
                        {detail.status === 'ACTIVE' ? (
                            <div className="challengeTime">
                                {moment(detail.challengeDate).isAfter(new Date().toISOString())
                                    ? detail.timeRemaining.days + (detail.timeRemaining.days > 1 ? " days " : " day ") + detail.timeRemaining.hours + (detail.timeRemaining.hours > 1 ? " hrs " : " hr ")
                                    : '-'
                                }
                            </div>
                        ) : ''}
                    </div>
                </div>
                {/* )} */}
            </>
        );
    }
}

export default homeChallengeItem;