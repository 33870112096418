import { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import EventDropdown from "./eventDropdown";
import StatusDropdown from "./eventStatusDropdown";
import { registerEvent, updateEvent } from "../../redux/actions/utilities";
import { hideLoading, showLoader } from "../../helpers/utils";

class CreateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: '',
      eventId: null,
      eventStatus: '',
      isRegisterBtnDisabled: true,
      mode: this.props.mode,
      eventList: this.props.eventList
    };
    this.typingTimeout = null;
  }

  validateInput = (name, val) => {
    const trimVal = val.trim();
    this.setState({ [name]: trimVal }, this.checkAllValidations);
  };

  checkAllValidations = () => {
    const { eventName, eventStatus, mode } = this.state;
    const isEventNameValid = eventName.length <= 50 && eventName !== '';
    const isEventStatusValid = eventStatus !== '';
    const isValid = mode === 'create' ? isEventNameValid : isEventNameValid && isEventStatusValid
    this.setState({ isRegisterBtnDisabled: !isValid });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.typingTimeout = setTimeout(() => {
      this.validateInput(name, value);
    }, 500);
  };

  handleEventChange = (id, text) => {
    this.setState({eventName: text, eventId: id}, this.checkAllValidations)
  }

  handleStatusChange = (id, text) => {
    const modifiedText = text === 'To be started' ? 'TO_BE_STARTED' :
                          text === 'On going' ? 'ONGOING' :
                            text === 'Completed' ? 'COMPLETED' :
                            text === 'Inactive' ? 'INACTIVE' : 'EXPIRED'
    this.setState({eventStatus: modifiedText}, this.checkAllValidations)
  }

  handleRegisterEvent = async () => {
    showLoader();
    // Register event api call here...
    const {eventName, eventStatus, mode, eventId} = this.state;
    let res, data;
    switch(mode) {
      case 'create': 
        res = await registerEvent({eventName});
        data = await res.payload;
        if(data.status === 200) {this.props.handleCloseModal(); hideLoading();} else { alert('Something went wrong!'); hideLoading();}
        break;
      case 'edit': 
        res = await updateEvent({eventId, eventStatus});
        data = await res.payload;
        if (data.status === 200) {this.props.handleCloseModal(); hideLoading();} else { alert('Something went wrong!'); hideLoading();}
        break
      default:
        alert('Something went wrong!')
    }
    
  };

  render() {
    const inputFields = [
      {
        label: "Event Name",
        name: "eventName",
        type: "text",
        placeholder: "Event name should not contain more than 50 characters",
        maxLength: 50,
      },
    ];
    const {mode, isRegisterBtnDisabled, eventList} = this.state
    return (
      <>
        <Modal
          show={true}
          onHide={() => this.props.handleCloseModal()}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{mode === 'create' ? 'Create an event' : 'Update an event'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bodyHeader">
              <div className="bodyHeaderHeading">
                {mode === 'create' ? inputFields.map((field) => (
                  <div className="regsiterTeamIndi" key={field.name}>
                    <div className="regsiterTeamIndi_title">
                      {field.label}:{" "}
                    </div>
                    <div className="regsiterTeamIndi_val">
                      <input
                        type={field.type}
                        name={field.name}
                        maxLength={field.maxLength}
                        placeholder={field.placeholder}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                )) : (
                  <>
                    <EventDropdown eventList={eventList} handleEventChange={this.handleEventChange} page='ADD_EVENT'/>
                    <StatusDropdown handleEventChange={this.handleStatusChange}/>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btnText"
              onClick={() => this.props.handleCloseModal()}
            >
              Cancel
            </button>
            <Button
              variant="primary"
              disabled={isRegisterBtnDisabled}
              onClick={this.handleRegisterEvent}
            >
              {mode === 'create' ? 'Create Event' : 'Update Event'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CreateEvent;
