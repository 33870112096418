import React, { Component } from "react";
import classNames from "classnames";
import downArr from "../../img/down-arr.svg";

class ProblemType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideaType: "",
      selectedOption: null,
      isListOpen: false,
    };
    this.problemTypeWrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    let arrowDisable = true;

    this.props.problemTypes !== null &&
      this.props.problemTypes.length > 0 &&
      this.props.problemTypes.forEach((ideaType) => {
        if (arrowDisable && ideaType.selected) {
          this.props.checkForValidType();
          arrowDisable = false;
        }
        if(ideaType.selected) {
          this.setState({ideaType: ideaType.text, selectedOption: ideaType.text})
        }
      });
    this.props.isNotAddDetail();
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.problemTypeWrapperRef &&
      !this.problemTypeWrapperRef.current.contains(event.target) &&
      this.state.isListOpen
    ) {
      this.setState({ isListOpen: false });
    }
  }

  onToggleList() {
    this.setState({ isListOpen: !this.state.isListOpen });
  }

  handleIdeaChange = (e, typeId) => {
    const { id, text } = e.target;
    this.setState({
      problemType: id,
      selectedOption: text,
    });
    this.props.onProblemTypeChange(id, text, typeId);
    this.setState({ isListOpen: false });
  };
  /**
   * handles the idea type change on click
   * @param {Event} e event
   */
  // handleChange = (e, typeId) => {
  //   const { name, id, value } = e.target;
  //   this.setState({
  //     [name]: id,
  //   });
  //   this.props.onProblemTypeChange(id, value, typeId);
  // };

  render() {
    return (
      <>
        <div className="addDetailSubSection">
          <div className="addDetailsTalentGrp">
            <div className="addDetailsTalentGrp_sub-heading">
              What type of problem your idea is aiming to solve?
            </div>
            <div className="dropdown-wrapper" ref={this.problemTypeWrapperRef}>
              <div className="dropdown selected">
                <button
                  aria-expanded="false"
                  type="button"
                  className={classNames(
                    "dropdown-select",
                    this.state.isListOpen ? "is-open" : ""
                  )}
                  onClick={() => this.onToggleList()}
                  id="problemTypeGroup"
                >
                  {this.state.selectedOption || "Choose an option"}
                  <img src={downArr} alt="down-arrow" />
                </button>
                <ul
                  role="menu"
                  className={classNames(
                    "dropdown-menu",
                    this.state.isListOpen ? "show" : "d-none"
                  )}
                  aria-labelledby="priorityGroup"
                >
                  <div className="list-container">
                    {this.props.problemTypes.map((option, index) => {
                      if (option.problemTypeID == 1) {
                        return (
                          <li key={`option-problemTypeGroup-${index}`}>
                            <a
                              role="menuitem"
                              className={classNames(
                                "dropdown-item",
                                option.text === this.state.ideaType
                                  ? "selected"
                                  : ""
                              )}
                              disabled={["All", "default"].includes(
                                option.text
                              )}
                              onClick={(e) =>
                                this.handleIdeaChange(e, option.problemTypeID)
                              }
                              id={option.text+option.problemTypeID}
                              data-index={index + 1}
                            >
                              {option.text}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ProblemType;
