import { combineReducers } from "redux";
import {
  FETCH_TOP_CHALLENGES_PENDING,
  FETCH_TOP_CHALLENGES_FULFILLED,
  FETCH_TOP_CHALLENGES_REJECTED,
  FETCH_CHALLENGE_DETAILS_PENDING,
  FETCH_CHALLENGE_DETAILS_FULFILLED,
  FETCH_CHALLENGE_DETAILS_REJECTED,
  // ACCEPT_CHALLENGE_PENDING,
  // ACCEPT_CHALLENGE_FULFILLED,
  // ACCEPT_CHALLENGE_REJECTED,
  FETCH_CHALLENGE_USER_PENDING,
  FETCH_CHALLENGE_USER_FULFILLED,
  FETCH_CHALLENGE_USER_REJECTED,
  FETCH_SAVED_CHALLENGE_PENDING,
  FETCH_SAVED_CHALLENGE_FULFILLED,
  FETCH_SAVED_CHALLENGE_REJECTED,
  SET_SUCCESS_MESSAGE,
} from "../action-types/challenges";
import {
  PENDING,
  FAILED,
  SUCCESS,
  DEFAULT,
} from "../../helpers/constants";

const initialMetaState = {
  FETCH_TOP_CHALLENGES_STATUS: DEFAULT,
  FETCH_CHALLENGE_DETAILS_STATUS: DEFAULT,
  // ACCEPT_CHALLENGES_STATUS: DEFAULT,
  FETCH_CHALLENGE_USER_STATUS: DEFAULT,
  FETCH_SAVED_CHALLENGE_STATUS: DEFAULT,
  SET_SUCCESS_MESSAGE_STATUS: DEFAULT,
};

const initialDataState = {
  topChallenges: [],
  challenge: [],
  acceptChallenge: "",
  challengeList: [],
  savedChallenge: [],
  messageData: {},
  challengeDetail: {},
};

function metaReducer(state = initialMetaState, action) {
  switch (action.type) {
    case FETCH_TOP_CHALLENGES_PENDING:
      return { ...state, FETCH_TOP_CHALLENGES_STATUS: PENDING };
    case FETCH_TOP_CHALLENGES_FULFILLED:
      return { ...state, FETCH_TOP_CHALLENGES_STATUS: SUCCESS };
    case FETCH_TOP_CHALLENGES_REJECTED:
      return { ...state, FETCH_TOP_CHALLENGES_STATUS: FAILED };
    case FETCH_CHALLENGE_DETAILS_PENDING:
      return { ...state, FETCH_CHALLENGE_DETAILS_STATUS: PENDING };
    case FETCH_CHALLENGE_DETAILS_FULFILLED:
      return { ...state, FETCH_CHALLENGE_DETAILS_STATUS: SUCCESS };
    case FETCH_CHALLENGE_DETAILS_REJECTED:
      return { ...state, FETCH_CHALLENGE_DETAILS_STATUS: FAILED };
    // case ACCEPT_CHALLENGE_PENDING:
    //   return { ...state, ACCEPT_CHALLENGE_STATUS: PENDING };
    // case ACCEPT_CHALLENGE_FULFILLED:
    //   return { ...state, ACCEPT_CHALLENGE_STATUS: SUCCESS };
    // case ACCEPT_CHALLENGE_REJECTED:
    //   return { ...state, ACCEPT_CHALLENGE_STATUS: FAILED };
    case FETCH_CHALLENGE_USER_PENDING:
      return { ...state, FETCH_CHALLENGE_USER_STATUS: PENDING };
    case FETCH_CHALLENGE_USER_FULFILLED:
      return { ...state, FETCH_CHALLENGE_USER_STATUS: SUCCESS };
    case FETCH_CHALLENGE_USER_REJECTED:
      return { ...state, FETCH_CHALLENGE_USER_STATUS: FAILED };
    case FETCH_SAVED_CHALLENGE_PENDING:
      return { ...state, FETCH_SAVED_CHALLENGE_STATUS: PENDING };
    case FETCH_SAVED_CHALLENGE_FULFILLED:
      return { ...state, FETCH_SAVED_CHALLENGE_STATUS: SUCCESS };
    case FETCH_SAVED_CHALLENGE_REJECTED:
      return { ...state, FETCH_SAVED_CHALLENGE_STATUS: FAILED };
    default:
      return state;
  }
}

function dataReducer(state = initialDataState, action) {
  switch (action.type) {
    case FETCH_TOP_CHALLENGES_FULFILLED:
      return {
        ...state,
        topChallenges: action.payload.data,
      };
    case FETCH_CHALLENGE_DETAILS_FULFILLED:
      return {
        ...state,
        challenge: action.payload.data,
      };
    // case ACCEPT_CHALLENGE_FULFILLED:
    //   return {
    //     ...state,
    //     acceptChallenge: action.payload.data,
    //   };
    case FETCH_CHALLENGE_USER_FULFILLED:
      return {
        ...state,
        challengeList: action.payload.data,
      };
    case FETCH_SAVED_CHALLENGE_FULFILLED:
      return {
        ...state,
        challengeDetail: action.payload.data,
      };
    case FETCH_SAVED_CHALLENGE_REJECTED:
      return {
        ...state,
        challengeDetail: {},
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        messageData: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  meta: metaReducer,
  data: dataReducer,
});
