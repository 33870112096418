import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/global/header";
import "../css/pages/draftSubmitted.scss";
import Breadcrumbs from "../components/global/breadcrumbs";
import { getDraftIdeasList } from "../redux/actions/ideas";
import axios from "axios";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GlobalMessagePopup from "../components/global/globalMessagePopup";
import config from "../helpers/api-urls";
import moment from "moment";
import { hideLoading, showLoader, getBaseUrl } from "../helpers/utils";
import ChallengeIcon from "../components/global/challengeIcon";
import DeleteBtn from '../img/delete-icon.svg';

const baseUrl = getBaseUrl();
class DraftIdea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessagePopup: false,
      draftIdeas: this.props.draftIdeas.draftideas || [],
      loading: false,
      perPageRows: 5,
      totalPages: this.props.draftIdeas.totalDraftCount || 0,
      pageIndex: 0,
      showDeleteModal: false,
      draftDeleteUrl: ""
    };
  }

  componentDidMount() {
    showLoader()
    this.props.getDraftIdeasList(this.state.pageIndex, this.state.perPageRows, this.props.userData.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.draftIdeas !== this.props.draftIdeas) {
      this.setState({
        draftIdeas: this.props.draftIdeas.draftideas,
        totalPages: this.props.draftIdeas.totalDraftCount,
      });
    }
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }

  loadMoreItems = (pageIndex) => {
    showLoader();
    this.setState({
      loading: true,
      pageIndex: this.state.pageIndex + 1,
    });
    const payloadUrl = config.draftIdeaConfig.requestPayload.url.replace("{userID}", this.props.userData.id) + "?pageNum=" + (pageIndex + 1) + "&pageSize=" + this.state.perPageRows;
    axios
      .get(payloadUrl, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      })
      .then((response) => {
        this.setState({
          draftIdeas: [...this.state.draftIdeas, ...response.data.draftideas],
          loading: false,
        });
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  };

  openDraftIdea = (id) => {
    window.location.href = "/createidea?draft=" + id;
  };

  hideDeleteModal = () => {
    this.setState({ showDeleteModal: false })
  };

  navigateToIdea = (id) => {
    window.location.href = "/idea/" + parseInt(id) + '#feedbackComment';
  };

  deleteDraft = (event, deleteURL) => {
    showLoader();
    event && event.stopPropagation();
    axios
      .delete(baseUrl.ipAddress + deleteURL, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            idToken: `${localStorage.getItem("id_token")}`
          }
      })
      .then((data) => {
        this.props.getDraftIdeasList(0, this.state.perPageRows, this.props.userData.id);
        this.setState({
          pageIndex: 0,
          showMessagePopup: true
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setTimeout(() => {
          this.setState({
            showMessagePopup: false
          });
        }, 5000);
        hideLoading();
      })
      .catch(function (error) {
        console.log(error);
        hideLoading();
      });
    this.hideDeleteModal();
    // this.showDeleteConfirmationMessage();
  };
  render() {
    const messageData = {
      type: "success",
      // title: "WoHooo! Bravo",
      detailText: "Your Draft is deleted! Keep coming up with more ideas :)"
    };
    const breadcrumbJSON = [
      {
        url: "/dashboard",
        text: "Home",
      },
      {
        url: "/user-profile",
        text: "Profile",
      },
      {
        url: "",
        text: "Draft Ideas",
      }
    ];
    const userJson = this.props.userData;
    return (
      <>
        <section className="homeMain">
          {this.state.showMessagePopup && (
            <GlobalMessagePopup
              showMessagePopup={this.state.showMessagePopup}
              messageData={messageData}
            />
          )}
          <Header
            values={userJson}
            showSearch={true}
            showUser={true}
            pageName={"draftIdea"}
          />
          <Breadcrumbs breadcrumbs={breadcrumbJSON} />
          <article className="draftHeader">
            <div className="heading">Draft Ideas</div>
            <div className="ideaCount">
              {(this.state.draftIdeas !== undefined && this.state.draftIdeas !== null) && this.state.draftIdeas.length > 0 && (
                <>
                  Showing {this.state.draftIdeas.length > 0 ? this.state.draftIdeas.length : 0} of {this.state.totalPages}
                </>
              )}
            </div>
          </article>
          <article className="ideaBubbels">
            {(this.state.draftIdeas !== undefined && this.state.draftIdeas !== null) && this.state.draftIdeas.length > 0 &&
              this.state.draftIdeas.map((idea, index) => {
                return (
                  <div key={idea.ideaName + idea.ideaID + index}>
                    <div className="ideaBubble">
                      {(idea.feedbackComment!==null&&idea.feedbackComment!==undefined) && (
                        // <div className="idea-panel-comment"><span onClick={() => { this.navigateToIdea(idea.ideaID) }}>Panelist's Comment</span></div>
                        <div className="idea-panel-comment"><span className="feedtitle">Admin's Comment: </span><span className="feedcomment">{idea.feedbackComment}</span></div>
                      )}
                      {idea.challengeID && <ChallengeIcon />}
                      {/* <div className="ideaName" onClick={() => this.openDraftIdea(idea.ideaID)} title={idea.ideaName ? idea.ideaName : ''}>{idea.ideaName && idea.ideaName.length > 100 ? `${idea.ideaName.substring(0, 99)} ...` : idea.ideaName}</div> */}
                      <div className="ideaDetails">
                        <div className="ideaNameDescDetail-left">
                          <div className="ideaName" onClick={() => this.openDraftIdea(idea.ideaID)} title={idea.ideaName ? idea.ideaName : ''}>{idea.ideaName && idea.ideaName.length > 80 ? `${idea.ideaName.substring(0, 79)} ...` : idea.ideaName}</div>
                          <div
                            className="ideaDescription"
                            onClick={() => this.openDraftIdea(idea.ideaID)}
                            dangerouslySetInnerHTML={{ __html: idea.ideaDescription }}
                            >
                          </div>
                        </div>
                        <div className="ideaNameDescDetail-right">
                          <div className="ideaDate" onClick={() => this.openDraftIdea(idea.ideaID)}>{idea.dateModified ? moment(idea.dateModified).format("DD MMMM YYYY") : "-"}</div>
                          <div className="ideaDelete">
                            <button
                              className="btn btnText"
                              onClick={() => this.setState({ draftDeleteUrl: idea.deleteURL, showDeleteModal: true })}
                            >
                              <img src={DeleteBtn} alt="delete member"/>
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ideaBubble-mobile draft-ideas">
                      {(idea.feedbackComment!==null&&idea.feedbackComment!==undefined) && (
                        // <div className="idea-panel-comment"><span onClick={() => { this.navigateToIdea(idea.ideaID) }}>Panelist's Comment</span></div>
                        <div className="idea-panel-comment"><span className="feedtitle">Admin's Comment: </span><span className="feedcomment">{idea.feedbackComment}</span></div>
                      )}
                      <div className="name-container">
                        <div className="ideaName" onClick={() => this.openDraftIdea(idea.ideaID)}>
                          {idea.ideaName}
                        </div>
                        <div className="ideaDate">
                          {idea.dateModified ? moment(idea.dateModified).format("DD MMMM YYYY") : '-'}
                        </div>
                      </div>
                      <div className="ideaDetails">
                        <div className="ideaDescription">
                          {idea.ideaDescription}
                        </div>
                        <div className="ideaDelete">
                          <button
                            className="btn btnText"
                            onClick={() => this.setState({ draftDeleteUrl: idea.deleteURL, showDeleteModal: true })}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <Modal
              show={this.state.showDeleteModal}
              onHide={this.hideDeleteModal}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              dialogClassName="delete-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Delete  Draft</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="bodyHeader">
                  <div className="bodyHeaderHeading">
                    <span className="delete-warn"></span>Are you sure you want to delete this idea?
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btnText header-lm-20"
                  onClick={this.hideDeleteModal}
                >
                  Keep Draft
                </button>
                <Button variant="primary"
                  // onClick={() => this.deleteDraft(this.state.challengeID)}
                  onClick={(event) => this.deleteDraft(event, this.state.draftDeleteUrl)}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </article>
          {(this.state.draftIdeas !== undefined && this.state.draftIdeas !== null) && this.state.draftIdeas.length === 0 && (
            <article className="ideaBubbels">
              No Draft Ideas!
            </article>
          )}
          {(this.state.draftIdeas !== undefined && this.state.draftIdeas !== null) && this.state.draftIdeas.length > 0 && (
            <div className="load-more-btn-cont">
              <div className={(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) ? 'load-more-cta-count-data load-more-hidden' : 'load-more-cta-count-data'}>Showing 1 - {Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows)) > this.state.totalPages ? this.state.totalPages : Number(parseInt(this.state.pageIndex + 1) * parseInt(this.state.perPageRows))}/{this.state.totalPages}</div>
              {!(this.state.totalPages <= (this.state.pageIndex + 1) * this.state.perPageRows) && (
                <button
                  type="button"
                  disabled={
                    this.state.totalPages <=
                    (this.state.pageIndex + 1) * this.state.perPageRows
                  }
                  className={classNames("btn btnText load-more-cta", {
                    disabled:
                      this.state.totalPages <=
                      (this.state.pageIndex + 1) * this.state.perPageRows,
                  })}
                  onClick={() => this.loadMoreItems(this.state.pageIndex)}
                >
                  {this.state.loading ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          )}
        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    FETCH_DRAFT_IDEA_STATUS: state.ideas.meta.FETCH_DRAFT_IDEA_STATUS,
    draftIdeas: state.ideas.data.draftIdeas,
  };
};

const mapDispatchToProps = {
  getDraftIdeasList,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftIdea);
